import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetClassesByContractor, useGetContractors } from '../../util/db';
import { useRouter } from '../../util/router';
import ContractorCard from './ContractorCard';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  paper: {
    position: 'relative',
    margin: 'auto',

    // backgroundColor: '#fff',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10vh',
  },
}));

function ClassesMarket(props) {
  const classes = useStyles();
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentContractorId, setCurrentContractorId] = useState();
  const [renderReady, setRenderReady] = useState(false);
  const { data: contractors } = useGetContractors();
  const { data: contractorClasses } =
    useGetClassesByContractor(currentContractorId);

  useEffect(() => {
    if (contractorClasses && !renderReady) {
      let obj = {};
      contractorClasses &&
        contractorClasses.forEach((meeting) => {
          if (
            meeting.studentCount &&
            meeting.studentCount >= meeting.maxStudents
          ) {
            meeting.classFull = true;
          }

          if (obj[meeting.contractorId]) {
            obj[meeting.contractorId].push(meeting);
          } else {
            obj[meeting.contractorId] = [meeting];
          }
        });
      console.log(obj);
      setRenderReady(true);
    }
  }, [contractorClasses]);

  const selectClass = (classData) => {
    console.log('classData', classData);
    router.push(
      `/contractor-classes/${classData.contractorId}/${classData.id}/view`,
    );
  };

  const setContractor = (id) => {
    setCurrentContractorId(id);
  };

  return (
    <Container maxWidth={'lg'}>
      <Grid>
        {contractors &&
          contractors.map((contractorData) => (
            <Grid lg={true} key={contractorData.id}>
              <Grid>
                <ContractorCard
                  setContractor={setContractor}
                  selectClass={selectClass}
                  classList={contractorClasses}
                  data={contractorData}
                  key={contractorData.id}
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}

export default ClassesMarket;
