import firebase from './firebase';
import moment from 'moment-timezone';
const firestore = firebase.firestore();
const url = process.env.REACT_APP_API_URL;
const isProd = process.env.NODE_ENV !== 'development';

async function googleConversionEvent({ eventName, productId, eventSourceURL, resourceId, email, price }) {
  // Google analytics should be able to determine ip address automatically if not we can try server side.
  // let ip;
  // try {
  //   const ipRequest = await apiRequest('conversion-api/ip', 'GET');
  //   ip = ipRequest.ip;
  //   console.log('ip:', ip);
  // } catch (error) {
  //   console.log(error);
  // }

  if (eventName === 'purchase completed') {
    // console.log('purchase event:', {
    //   user_data: { email_address: email },
    //   page_location: eventSourceURL,
    //   item: productId || resourceId,
    //   price,
    // });

    /* eslint-disable-next-line */
    gtag('event', 'purchase', {
      user_data: { email_address: email },
      page_location: eventSourceURL,
      item: productId || resourceId,
      price,
    });
  }
}

export function conversionsEvent({ eventName, productId, classType, resourceId, subType, price, email }) {
  console.log('sending conversion event to server');
  let eventSourceURL = window.location.href;
  apiRequest('conversion-api/new-event', 'POST', {
    eventSourceURL,
    eventName,
    productId,
    classType,
    resourceId,
    subType,
    price,
  });

  console.log('google conversions event');

  googleConversionEvent({ eventName, productId, eventSourceURL, resourceId, email, price });
}

export async function apiRequest(path, method = 'GET', data) {
  const accessToken = firebase.auth().currentUser ? await firebase.auth().currentUser.getIdToken() : undefined;

  console.log('gonna fetch', `${url}/${path}`);
  return fetch(`${url}/${path}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 'error') {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === 'auth/invalid-user-token') {
          firebase.auth().signOut();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

export async function unauthenticatedRequest(url, method = 'GET', data) {
  return fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 'error') {
        throw new CustomError(response.code, response.message);
      } else {
        return response;
      }
    });
}

const pad = (num, size) => {
  var s = '000000' + num;
  return s.substr(s.length - size);
};

export function paddedDateHash(d) {
  return d.getFullYear() + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
}

export function renderDate(d) {
  // if(typeof d.getMonth !== 'function'){
  d = new Date(d);
  //  }
  return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
}

export function slotToLocalTime(timeSlot) {
  const split = timeSlot.split(':');
  let minute = split[1];
  let hour = split[0];
  console.log('hour', hour, 'minute', minute);
  let pacificTime = moment().tz('America/Los_Angeles');
  pacificTime.set('hour', hour);
  pacificTime.set('minute', minute);
  console.log('local time:', pacificTime.local().format('h:mm A'));
  return pacificTime.local().format('h:mm A') + ' ' + guessTimeZone();
}

export const slotToLocalTime2 = (timeSlot) => {
  let minute = timeSlot.slice(-2);
  let hour = timeSlot.slice(0, timeSlot.length - 2);
  console.log('hour', hour, 'minute', minute);
  let pacificTime = moment().tz('America/Los_Angeles');
  pacificTime.set('hour', hour);
  pacificTime.set('minute', minute);
  console.log('local time:', pacificTime.local().format('HH:mm A z'));
  return pacificTime.local().format('HH:mm A z') + ' ' + guessTimeZone();
};

function guessTimeZone() {
  let timeZone = moment.tz.guess();
  let time = new Date();
  let timeZoneOffset = time.getTimezoneOffset();
  return moment.tz.zone(timeZone).abbr(timeZoneOffset);
}

export function shortSchedule(classData) {
  let schedule = '';
  if (!classData) {
    return schedule;
  }
  schedule += classData.mondayClass ? 'Mon ' : '';
  schedule += classData.tuesdayClass ? 'Tue ' : '';
  schedule += classData.wednesdayClass ? 'Wed ' : '';
  schedule += classData.thursdayClass ? 'Thu ' : '';
  schedule += classData.fridayClass ? 'Fri' : '';
  return schedule;
}

export function longSchedule(classData) {
  let schedule = [];
  if (classData.mondayClass) schedule.push('Mondays');
  if (classData.tuesdayClass) schedule.push('Tuesdays');
  if (classData.wednesdayClass) schedule.push('Wednesdays');
  if (classData.thursdayClass) schedule.push('Thursdays');
  if (classData.fridayClass) schedule.push('Fridays');
  if (schedule.length > 1) {
    const len = schedule.length;
    schedule[len - 2] = schedule[len - 2] + ', and ' + schedule[len - 1];
    schedule.pop();
  }
  return schedule.join(', ');
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

//days before semester to open assessment
export const getCurrentSchedule = async (childId) => {
  let dateSnap = await firestore
    .collection('children')
    .doc(childId)
    .collection('video-schedules')
    .orderBy('created', 'desc')
    .limit(1)
    .get();
  const latestSchedule = dateSnap.docs[0].data();

  let ret = latestSchedule;
  console.log('latest sched', latestSchedule);
  // if(latestSchedule.semesterId !== 'now'){
  //     const semesterSnap = await firestore.collection('semester-dates').doc(latestSchedule.semesterId).get();
  //     const semesterData = semesterSnap.data();
  //     ret = semesterData;
  // } else {
  //     ret = { startDate: new Date(), endDate: new Date() }
  // }

  return ret;
};

/* eslint-disable */
export const getSup = (day) => {
  switch (day) {
    case 2:
    case 22:
      return day + 'nd';
    case 3:
    case 23:
      return day + 'rd';
    case 1:
    case 21:
    case 31:
      return day + 'st';
    default:
      return day + 'th';
  }
};

/* eslint-enable */

export const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
export const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];
