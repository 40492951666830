import { useEffect, useState } from 'react'

const useAudio = (url, initAudio = new Audio()) => {
  const [audio] = useState(initAudio)
  const [playing, setPlaying] = useState(false)

  const toggle = (value) => setPlaying(value || !playing)
  const play = () => toggle(true)
  const pause = () => toggle(false)
  useEffect(() => {
    audio.src = url
    playing ? audio.play() : audio.pause()
  }, [playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  return [playing, toggle, play, pause]
}

export default useAudio

