import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function SimpleSelect(props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const filters = props.filters;
  const handleChange = props.handleChange;

  return (
    <div>
      { filters.map((controls) => {
        return <FormControl key={controls.label} className={classes.formControl}>
          <InputLabel>{controls.label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={controls.value}
            onChange={(rowClass, value) => {
              handleChange(controls.label, value.props.value)
            }
            }
          >
            {
              controls.options.map((val) => {
                return <MenuItem key={val} value={val}>{val}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      })
      }
    </div>
  );
}
