import React from 'react'
import StudentRoster from '../components_admin/StudentRoster/StudentRoster';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function StudentRosterPage() {
  return (<>
    <GoBack/>
    <StudentRoster />
  </>
  )
}

export default requireAuth(StudentRosterPage)
