import React from 'react'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { LinkContainer } from 'react-router-bootstrap'
import AspectRatio from '../components/AspectRatio'

const getIcon = (type) => {
  switch (type) {
      case 'video':
        return 'video'
      case 'printable-pdf':
        return 'file-alt'
      case 'pictures':
        return 'image'
      case 'instructions':
        return 'chalkboard-teacher'
  }
}
function LessonContentCards(props) {
  return props.items.map((item, index) => (
    <React.Fragment key={index}>
      <Col xs={12} md={6} lg={6} xl={4} className="py-3" key={index}>
        <LinkContainer to={item.url}>
          <Card.Link>
            <Card className={'shadow-lg bg-white rounded text-center h-100'}>
              <AspectRatio ratio={1 / 0.5}>
                <Card.Img
                  src={item.image}
                  alt={item.title}
                  variant="top"
                />
              </AspectRatio>
              <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.body}</Card.Text>

              </Card.Body>
              <Card.Footer>
                <Card.Text>
                  {item.contains.map((type, index) => (
                    <span style={{ fontSize: 25, paddingLeft: 20 }} key={index} ><i className={`fas fa-${getIcon(type)}`} /></span>
                    // <Badge variant="primary" space={2}>
                    //     {type}
                    // </Badge>
                  ))}
                </Card.Text>
              </Card.Footer>
            </Card>
          </Card.Link>
        </LinkContainer>
      </Col>
    </React.Fragment>
  ))
}

export default LessonContentCards
