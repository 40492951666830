import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();

  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const { header, message } = props.data[index];
    return (
      <ListItem onClick={() => { props.rowSelect(props.data[index]) }} button style={style} key={props.data[index].id}>
        <ListItemText primary={header} secondary={message} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };  
  console.log('props:',props)
  if (props.data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={400} width={400} itemSize={46} itemCount={props.data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
