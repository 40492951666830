import React from 'react'
import ContractorDashboard from '../components_contractor/ContractorDashboard'
import { requireAuth } from '../util/auth.js'
import Grid from '@material-ui/core/Grid'
import { useRouter } from '../util/router'
import Button from '@material-ui/core/Button';

function DashboardPage(props) {

  return (<>
    <ContractorDashboard
      bg="white"
      textColor="dark"
      size="md"
      title="Dashboard"
      subtitle=""
    />
  </>
  )
}

export default requireAuth(DashboardPage)
