import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useRouter } from '../util/router.js'
import { useGetClassesByContractorStatus, useGetContractorProfile } from '../util/db'
import Container from '@material-ui/core/Container';
import ClassMiniRow from './ClassMiniRow.js';

export default function ClassesList(props) {
  const router = useRouter()
  const { data: liveClasses } = useGetClassesByContractorStatus(props.contractorId, 'live')
  const { data: submitted } = useGetClassesByContractorStatus(props.contractorId, 'submitted')
  const { data: drafts } = useGetClassesByContractorStatus(props.contractorId, 'draft')
  const { data: profileData } = useGetContractorProfile(props.contractorId)
  const [partialProfile, setPartialProfile] = useState(false)
  const [profileReady, setProfileReady] = useState(false)
  const [noClasses, setNoClasses] = useState(false)

  const createNewClass = () => {
    router.push(`/contractor-classes/${props.contractorId}/new`)
  }

  const editTeacherProfile = () => {
    router.push('/contractor-profile/edit')
  }

  const viewClass = (data) => {
    router.push(`/contractor-classes/${props.contractorId}/${data.id}/view`)
  }

  useEffect(() => {
    if (liveClasses && liveClasses.length === 0 && submitted && submitted.length === 0 && drafts && drafts.length === 0) {
      setNoClasses(true)
    }
  }, [liveClasses, submitted])

  useEffect(() => {
    if (profileData) {
      if (profileData.profilePhoto && profileData.profilePhoto !== '') {
        if (profileData.bio && profileData.bio !== '') {
          if (profileData.teacherName && profileData.teacherName !== '') {
            setProfileReady(true)
          }
        }
      }
      setPartialProfile(true)
    }
  }, [profileData])

  return (<>
    <Container maxWidth="sm">
      {!profileReady ?
        <Grid
          className={'m-2'}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Typography>You need a profile before you can start your first class!</Typography>
          <Button
            variant="text"
            color="primary"
            onClick={editTeacherProfile}
          >
            {partialProfile ? 'Continue your profile' : 'Create your profile'}
          </Button>
        </Grid> :
        <>
          <Grid
            className={'m-2'}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {noClasses && <Typography> Looks like you dont have any classes yet!</Typography>}
            <Button
              variant="contained"
              color="primary"
              onClick={createNewClass}
              className="m-2"
            >
                            Create new class
            </Button>
          </Grid>
          <Grid>
            {liveClasses && liveClasses.length > 0 && <Grid container><Typography align="center" variant="h5">Published:</Typography>{liveClasses.map(classData => <ClassMiniRow selectClass={viewClass} key={classData.id} data={classData} />)} </Grid>}
            {submitted && submitted.length > 0 && <Grid container><Typography align="center" variant="h5">In Review:</Typography> {submitted.map(classData => <ClassMiniRow selectClass={viewClass} key={classData.id} data={classData} />)}</Grid>}
            {drafts && drafts.length > 0 && <Grid container><Typography align="center" variant="h5">Drafts:</Typography> {drafts.map(classData => <ClassMiniRow selectClass={viewClass} key={classData.id} data={classData} />)}</Grid>}
          </Grid>
        </>}
    </Container >
  </>)

}