import React, { useEffect, useState } from 'react';
import { useGetQuestions } from '../../util/db';
import QuestionList from './QuestionList';
import QuestionDetails from './QuestionDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
function QuestionManager(props) {
  const { data: questionList, error, loading } = useGetQuestions();
  const [selectedQuestion, setQuestion] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const selectQuestion = (question) => {
    setCreateNew(false)
    console.log('selecting video', question)
    setQuestion(() => question);
    setShowDetails(true);
  };

  const createNewQuestion = () => {
    setCreateNew(true)
    setQuestion(() => {
      return { id: 0, text: 'new question?', lastUsed: '2017-12-12' } //adding an arbitrary old date so new questions get asked sooner
    })
    setShowDetails(true)
  };

  useEffect(() => {
    console.log('question List', questionList)
  }, [questionList])

  return (
    <>
      <Container className="mx-3">
        <h2>Question Manager</h2>
        <Row>
          <Col>
            <Row>
              {loading ? 'loading' :
                <QuestionList rowSelect={selectQuestion} data={questionList} />}
            </Row>
            <Row className="m-3">
              <Button variant="primary" size="md" onClick={createNewQuestion}>
                                Create New
              </Button>
            </Row>
          </Col>
          <Col>
            {showDetails ?
              <QuestionDetails createNew={createNew} data={selectedQuestion} />
              : null
            }
          </Col>
        </Row>
      </Container>
    </>);
}

export default QuestionManager
