import React from 'react'
import AuthSection from '../components/AuthSection'
import { useRouter } from '../util/router.js'

function AuthPage(props) {
  const router = useRouter()
  // console.log(router.history)

  return (
    <AuthSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      inputSize="lg"
      type={router.query.type}
      providers={['google', 'facebook']} //'facebook', 'twitter'
      afterAuthPath={router.query.next || '/lesson'}
    />
  )
}

export default AuthPage
