import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();
  const [currentZoomUserId, setCurrentZoomUserId] = useState(0)

  useEffect(() => {
    console.log()
    if (props.currentZoomUser && props.currentZoomUser.id) {
      setCurrentZoomUserId(props.currentZoomUser.id)
      console.log(props.currentZoomUser.id)
    } else {
      setCurrentZoomUserId(0)
    }
  }, [currentZoomUserId, props.currentZoomUser])

  //   first_name: 'Kirsten',
  //   last_name: 'Alcaraz',
  //   email:
  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const data = props.data[index];
    const name = data.first_name + ' ' + data.last_name;
    const id = props.data[index].id;
    return (
      <ListItem selected={id === currentZoomUserId} key={index} onClick={() => { props.rowSelect(props.data[index]) }} button style={style}>
        <ListItemText primary={name} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={400} width={400} itemSize={46} itemCount={props.data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
