import React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.background,
    border: 0,
    fontSize: 16,
    borderRadius: 3,
    boxShadow: theme.boxShadow,
    color: 'white',
    height: 48,
    padding: '0 30px',
    zIndex:100,
    width:'max-content',
  },
}));



export default function GradientButton({ label, onClick }) {
  const CustomButton = ({ label }) => {
    const classes = useStyles();
    
    return (
      <button onClick={onClick} type="button" className={classes.root} >
        {label}
      </button>
    );
  }
  return (
  // <div style={{ marginLeft: 'auto',marginRight:'auto' }}>
    <ThemeProvider
      theme={{
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      }}
    >
      <CustomButton label={label} />
    </ThemeProvider>
  // </div>
  );
}