import React from 'react'
import ParentDashboard from '../components_parent/ParentDashboard'
import { requireAuth } from '../util/auth.js'

function DashboardPage(props) {
  return (<>
    <ParentDashboard
      bg="white"
      textColor="dark"
      size="md"
      title="Dashboard"
      subtitle=""
    />
  </>
  )
}

export default requireAuth(DashboardPage)
