import React from 'react'
import AdminClassRegistration from '../components_admin/ClassRegistrationAdmin/ClassRegistration';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function AdminClassRegistrationPage() {
  return (<>
    <GoBack />
    <AdminClassRegistration />
  </>
  )
}

export default requireAuth(AdminClassRegistrationPage)
