import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea,CardContent,CardMedia,Button,Typography,Grid } from '@material-ui/core';
import { updateContractorClass, useGetChildrenByIds } from '../util/db'
import { longSchedule, slotToLocalTime } from '../util/util';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import ChildrenCards from './ChildrenCards';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),

    },
    width: '100%',
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    width: '80vw',
    backgroundColor: '#cacaca',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '20vh',
  },
}));

export default function ViewClass(props) {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const { data: registeredStudents } = useGetChildrenByIds(props.data.registrations)
  const [emailList, setEmailList] = useState()

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (registeredStudents && registeredStudents.length > 0) {
      const emailArray = registeredStudents.map(student => {
        return student.email
      })
      setEmailList(emailArray.join(','))
    }
  }, [registeredStudents])

  let price = props.data.classPrice ? '$' + props.data.classPrice.toFixed(2) + ' per week' : 'price not set';
  let schedule = longSchedule(props.data)
  let localTime = props.data.timeString ? slotToLocalTime(props.data.timeString) : '';

  const cardClick = () => {
    if (props.onClick) {
      props.onClick(props.data.id)
    }
  }

  const approveClass = async () => {
    const res = await updateContractorClass(props.data.contractorId, props.data.id, { status: 'live' })
    router.goBack()
  }

  const CardMainContent = () => {
    return (<>
      <CardMedia
        component="img"
        alt={props.data.name + ' profile photo'}
        // height="140"
        image={props.data.classPhoto && props.data.classPhoto !== '' ? props.data.classPhoto : '/images/image_placeholder.png'}
        title={props.data.className}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {props.data.className}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.data.description}
        </Typography>
      </CardContent>
    </>)
  }


  return (
    <Grid container className={'mt-3'} alignItems={'center'} alignContent={'center'} direction={'column'}>
      {auth.user.admin && props.data.status === 'submitted' && <Button onClick={approveClass}>Approve Class</Button>}
      <Card className={classes.root}>
        {props.onClick ? <CardActionArea onClick={cardClick}><CardMainContent /></CardActionArea> : <CardMainContent />}



        <Typography className={'pt-1'} align="center" variant="subtitle1" color="textSecondary" >
          {schedule}
        </Typography>
        <Typography className={'pt-1'} align="center" variant="subtitle1" color="textSecondary" >
                    at {localTime} ({props.data.timeString} PST)
        </Typography>
        <Typography className={'pt-1'} align="center" variant="subtitle1" color="textSecondary" >
                    Your price: {price}
        </Typography>
        <Grid container justify={'space-around'} direction="row">
          <Typography className={'pt-1'} variant="subtitle2" color="textSecondary" >
                        Start: {moment(props.data?.startDate.toDate()).format('MMM Do, YYYY')}
          </Typography>
          <Typography className={'pt-1'} variant="subtitle2" color="textSecondary" >
                        End: {moment(props.data?.endDate.toDate()).format('MMM Do, YYYY')}
          </Typography>
        </Grid>
        <Typography className={'pt-1'} align="center" variant="subtitle2" color="textSecondary" >
                    Maximum Students: {props.data.maxStudents}
        </Typography>

      </Card>
      {props.data.registrations && props.data.registrations.length > 0 &&
                <>
                  <Typography className={'my-3'}>
                        Zoom Start Link: <a target="_blank" rel="noopener noreferrer" href={props.data.zoomStartURL}>Click here to start Zoom Meeting</a>
                  </Typography>
                  <Typography className={'my-3'}>
                        Email All students: <a href={`mailto:${emailList}`}>Email Class</a>
                  </Typography>

                  <Typography className={'my-3'}>
                        Registered Students:
                  </Typography>
                  <ChildrenCards childList={registeredStudents} />
                </>}
    </Grid >


  );
}
