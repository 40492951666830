import React from 'react'
import VideoManager from '../components_admin/VideoManager/VideoManager';
import { requireAuth } from '../util/auth.js'

import GoBack from '../components/GoBack'
function VideoManagerPage(props) {
  return (<>
    <GoBack />
    <VideoManager />
  </>
  )
}

export default requireAuth(VideoManagerPage)
