import React, { useEffect } from 'react'
import PageLoader from '../components/PageLoader'
import { requireAuth } from '../util/auth.js'
import { useRouter } from '../util/router.js'
import { redirectToCheckout } from '../util/stripe.js'
import { useChildContext } from '../util/childProfileContext'

function PurchasePage(props) {
  const router = useRouter()
  const { childProfile, status } = useChildContext();

  useEffect(() => {
    let successURL = 'https://app.mightykidsvirtualacademy.com/class-registration'
    if (process.env.NODE_ENV === 'development') {
      successURL = 'http://localhost:3000/class-registration'
    }

    if(status === 'success'){
      if (childProfile) {
        redirectToCheckout({ planId: router.query.plan, childId: childProfile.id, successURL })
      } else {
        redirectToCheckout({ planId: router.query.plan, childId: 'unregistered', successURL })
      }
    }
  }, [childProfile])

  return <PageLoader />
}

export default requireAuth(PurchasePage)
