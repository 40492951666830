import React, { useEffect } from 'react'
import PageLoader from '../components/PageLoader'
import { useAuth } from '../util/auth.js'
import { useGetUserActiveSubscriptions } from '../util/db'
import { useRouter } from '../util/router.js'
import { redirectToBilling } from '../util/stripe.js'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import SubscriptionCard from './SubscriptionCard';


function SettingsBilling(props) {
  const router = useRouter()
  const auth = useAuth()
  const { data } = useGetUserActiveSubscriptions(auth.user.id)
  // // const { data, status } = useGetUserActiveSubscriptions(auth.user.id)
  // useEffect(() => {
  console.log('user id:',auth.user.id)
  //  if (status === 'success'){

  const goToStripeBilling = () => {
    if (auth.user.stripeActive) {
      // If user has a plan then take them to the
      // Stripe billing portal to manage it.
      redirectToBilling()
    } else {
      //redirectToBilling()
      router.push('/pricing')
    }
  }


  return (
    <Grid container direction="column" alignItems="center" justifyContent={'center'}>
      <Typography align="center">Click below to update your card or view your billing details on stripe</Typography>
      <Button style={{ width:200 }} color="primary" className="mt-2" variant="contained" onClick={goToStripeBilling}>Go to Stripe</Button>
      <Grid>
        {data && data.map(sub => <SubscriptionCard key={sub.id} data={sub}/>) }
      </Grid>
    </Grid>
  )
}

export default SettingsBilling
