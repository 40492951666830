import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActions,CardContent,CardMedia,Button,Typography,Grid,Modal } from '@material-ui/core';
import PhotoGallery from './PhotoGallery'
import ClassMiniRow from '../../components_contractor/ClassMiniRow'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),

    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    width: '80vw',
    backgroundColor: '#cacaca',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '20vh',
  },
}));

export default function ImgMediaCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container className={'mt-2'} alignItems={'center'} alignContent={'center'} direction={'column'}>
      <Card className={classes.root}>
        <CardMedia
          component="img"
          alt={props.data.name + ' profile photo'}
          // height="140"
          image={props.data.profilePhoto && props.data.profilePhoto !== '' ? props.data.profilePhoto : '/images/profile_picture.png'}
          title={props.data.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.data.teacherName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.data.bio}
          </Typography>
        </CardContent>

        <CardActions>
          {props.data.photoGallery && props.data.photoGallery.length > 0 && <Button onClick={handleOpen} size="small" color="primary">
                        View Photos
          </Button>}
        </CardActions>
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="subtitle1">
                        Available Classes:
          </Typography>
          <Grid>
            {props.classList.map((thisClass) => (<ClassMiniRow registeredList={props.registeredList} liveData={true} selectClass={props.selectClass} key={thisClass.id} data={thisClass} />))}
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <PhotoGallery photos={props.data.photoGallery} />
        </div>
      </Modal>
    </Grid >
  );
}
