import React from 'react'
import TeacherManager from '../components_admin/TeacherManager/TeacherManager';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'

function TeacherManagerPage(props) {
  return (<>
    <GoBack />
    <TeacherManager />
  </>
  )
}

export default requireAuth(TeacherManagerPage)
