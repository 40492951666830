import React, { useEffect, useState } from 'react';
import {
  CssBaseline,
  Typography,
  Container,
  Switch,
  Grid,
} from '@material-ui/core';
import { useAuth } from '../util/auth';
import ViewClass from './ViewClass';
import EditClass from './EditClass';
import { useGetContractorClass, useGetContractorProfile } from '../util/db';
import { useRouter } from '../util/router';

export default function ClassDetail(props) {
  const router = useRouter();
  const auth = useAuth();
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const { data: contractorData } = useGetContractorProfile(props.contractorId);
  const { data: classData, status } = useGetContractorClass(
    props.contractorId,
    props.classId,
  );

  const toggleEditMode = () => {
    if (props.editMode === 'edit') {
      router.replace('view');
    } else {
      router.replace('edit');
    }
  };

  useEffect(() => {
    if (
      status === 'loading' ||
      //(classData && classData.status === 'live') ||
      props.editMode === 'new'
    ) {
      setShowSwitch(false);
    } else {
      setShowSwitch(true);
    }
  }, [classData, props.editMode]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={'p-5'} maxWidth="sm">
        <Typography variant={'h5'}>
          {props.editMode === 'new' ? 'Create a new class' : 'Manage Class'}
        </Typography>
        {showSwitch && (
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>View</Grid>
            <Grid item>
              <Switch
                checked={props.editMode === 'edit'}
                disabled={disableSwitch}
                onChange={toggleEditMode}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item>Edit</Grid>
          </Grid>
        )}
        {
          <>
            {(props.editMode === 'edit' || props.editMode === 'new') && (
              <EditClass
                editMode={props.editMode}
                contractorData={contractorData}
                disableSwitch={setDisableSwitch}
                contractorId={auth.user.uid}
                data={props.editMode === 'new' ? {} : classData}
              />
            )}
            {classData && props.editMode === 'view' && (
              <ViewClass data={classData} contractorId={props.contractorId} />
            )}
          </>
        }
      </Container>
    </React.Fragment>
  );
}
