import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea,CardActions,CardContent,CardMedia,Button, Typography } from '@material-ui/core';
import { longSchedule, slotToLocalTime } from '../../util/util'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const schedule = longSchedule(props.classData)
  const localTime = slotToLocalTime(props.classData.timeString)
  return (
    <Card key={props.classData.id} className={classes.root}>
      <CardActionArea>
        <CardActionArea>

          <CardMedia
            className={classes.media}
            image={props.classData.classPhoto}
            // title=""
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h5">
              {props.classData.className}
            </Typography>
            <Typography style={{ maxHeight: 200, overflow: 'scroll' }} gutterBottom variant="p" component="p">
              {props.classData.description}
            </Typography>
            <Typography gutterBottom variant="subtitle1" >
                            On {schedule}
            </Typography>
            <Typography gutterBottom variant="subtitle1" >
                            At {localTime} ({props.classData.timeString} PST)
            </Typography>
          </CardContent>
        </CardActionArea>
      </CardActionArea>
      <CardActions>
        {props.registerFunction && <Button onClick={() => { props.registerFunction(props.classData) }} size="small" color="primary">
                    Register
        </Button>}
        <Button onClick={props.closeModal} size="small" color="primary">
                    Close
        </Button>
      </CardActions>
    </Card>
  );
}