import { apiRequest } from './util'

const simpleSubjects = ['Art', 'STEM', 'Social Emotional'];
const sectionMap = { 'Language': 'LL', 'Math': 'MA', 'Writing': 'WR', 'Social Emotional': 'SE', 'STEM': 'STEM', 'Art': 'ART' }

function pad(num, size) {
  var s = '000000' + num;
  return s.substr(s.length - size);
}

export function createVideoHash(video) {
  if (simpleSubjects.includes(video.Section)) {
    return `${video.Section}-Episode-${pad(video.Episode, 2)}`;
  }
  return `${video.Section}-Level-${video.Level}-Episode-${pad(video.Episode, 2)}-Standard-${pad(video.Standard, 2)}`
}

export function searchVimeo(vidObj) {
  let searchKey = '';

  let section = sectionMap[vidObj.Section];

  let episode = vidObj.Episode;

  if (simpleSubjects.includes(vidObj.Section)) {
    searchKey = section + '-E' + episode;
  } else {
    let standard = vidObj.Standard;
    let level = vidObj.Level;
    searchKey = section + '-S' + standard + '-L' + level + '-E' + episode;
  }

  return apiRequest('vimeo?query=' + searchKey, 'GET');
}