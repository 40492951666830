import React, { useEffect, useState } from 'react'
import ThankYouSection from '../components_parent/ThankYou'
import { useRouter } from '../util/router'
import { requireAuth,useAuth } from '../util/auth.js'
import { Typography, Grid, Paper, Button, CircularProgress } from '@material-ui/core'
import ProfileSelect from '../components_parent/ProfileSelect'
import { useChildContext  } from '../util/childProfileContext.js'
import { makeStyles } from '@material-ui/core/styles';
import { useGetMightyKidsPlanBySlug } from '../util/db'
import ZoomTimeSlotPoll from '../components_parent/ZoomTimeSlotPoll';
import PromotionCard from '../components_public/PromotionCard'
import MightyKidsIntroSection from '../components_public/MightyKidsIntroSection'
import TestimonialSection from '../components_public/TestimonialSection'
import CallToActionSection from '../components_public/CallToActionSection'
import  { conversionsEvent } from '../util/util'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    // padding: theme.spacing(2),
  },
}));

function ProductLanding(props) {
  const classes = useStyles();
  const router = useRouter();
  const pageSlug = router.query.pageSlug
  const { data:mightyKidsPlan } = useGetMightyKidsPlanBySlug(pageSlug)
  useEffect(()=>{
    setTimeout(() => conversionsEvent({ eventName:'page visit',productId:pageSlug }),1000)
  },[])
  return (
    <Grid container alignItems="center" direction="column" alignContent="center">
      <Grid container> 
        <MightyKidsIntroSection />
      </Grid>
      <Grid container> 
        {mightyKidsPlan?.length > 0 && <PromotionCard fullSize={true} data={mightyKidsPlan[0]} />}
      </Grid> 
      <Grid className="pt-5" container>
        <TestimonialSection />
      </Grid>
      {mightyKidsPlan?.length > 0 && <CallToActionSection mkPlanId={mightyKidsPlan[0].id} />}
    </Grid>
  )
}

export default ProductLanding