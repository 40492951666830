import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { SPECIALTIES } from '../../constants';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, specialtyName, theme) {
  return {
    fontWeight:
      specialtyName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectSpecialty(props) {
  const theme = useTheme();
  // const [specialtyName, setspecialtyName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    props.setSpecialties(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel style={{ color: 'white', paddingTop: '5px' }} id="demo-multiple-chip-label">
          Teacher Specializations
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={props.specialties}
          onChange={handleChange}
          input={<Input style={{ marginTop: '16px' }} id="select-multiple-chip" label="Specialties" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip style={{ color: 'white' }} key={value.value} label={value.label} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {SPECIALTIES.map((name) => (
            <MenuItem key={name.label} value={name} style={getStyles(name.value, props.specialties, theme)}>
              {name.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
