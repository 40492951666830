import React, { useState } from 'react';
import { addDays } from 'date-fns';
import Typography from '@mui/material/Typography';
import { DateRangePicker } from 'react-date-range';
import { Grid } from '@material-ui/core';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function ControlDateSelection(props) {
  return (
    <Grid>
      <Grid>
        <div>
          <Typography sx={{ mt: 2, mb: 1 }}> Calendar </Typography>
          <div style={{ width: '400px' }}>
            <DateRangePicker
              onChange={(item) => {
                props.setDates([item.selection]);
              }}
              months={1}
              minDate={addDays(new Date(), -30)}
              maxDate={addDays(new Date(), 180)}
              direction="vertical"
              ranges={props.dates}
              renderStaticRangeLabel={(row) => (
                <span>
                  {/* <i>{row.label}</i> */}
                  <span className={'random-date-string'}>
                    <b>{row.label}</b>
                  </span>
                </span>
              )}
              inputRanges={[]}
              staticRanges={[
                {
                  label: 'Today',
                  hasCustomRendering: true,
                  range: () => ({
                    startDate: new Date(),
                    endDate: new Date(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: 'Next 7 days',
                  hasCustomRendering: true,
                  range: () => ({
                    startDate: new Date(),
                    endDate: new Date().setDate(new Date().getDate() + 7),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: 'Next 14 days',
                  hasCustomRendering: true,
                  range: () => ({
                    startDate: new Date(),
                    endDate: new Date().setDate(new Date().getDate() + 14),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: 'All Upcoming Dates',
                  hasCustomRendering: true,
                  range: () => ({
                    startDate: new Date(),
                    endDate: '', //new Date().setDate(new Date().getDate() + 180),
                  }),
                  isSelected() {
                    return true;
                  },
                },
              ]}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
