import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { List,Grid } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Pricing from '../../components_public/Pricing'
import PromotionCard from '../../components_public/PromotionCard';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 800,
  },
}));

export default function VirtualizedList(props) {
  const [sortedList, setSortedList] = useState([]);

  useEffect(()=> {
    if(props.data){
      const sorted = props.data.sort((a,b) => {
        if(a.active){
          return -1
        }
        if(b.active){
          return 1
        }
        return 0
      });
      console.log('sorted:',sorted)
      setSortedList(sorted)
    }
  },[props.data])
    
  const classes = useStyles();
  const renderRow = (rowProps) => {
    const activeColor = rowProps.active ? '#0fff97' : '#ffdbce'
    return (
      <ListItem  style={{ backgroundColor:activeColor }} onClick={() => { props.rowSelect(rowProps) }} button key={rowProps.id} >
        <PromotionCard key={rowProps.id} fullSize={true}  data={rowProps}/>
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <List className={classes.root} itemCount={sortedList.length}>
        {sortedList.map( row => renderRow(row))}
      </List>
    );
  }
  return (null);
}
