import React from 'react'
import { requireAuth } from '../util/auth.js'
import CssBaseline from '@material-ui/core/CssBaseline';
import ClassesMarket from '../components_parent/ClassesMarket/ClassesMarket'
function ClassesMarketPage() {
  return (<>
    <React.Fragment>
      <CssBaseline />
      <ClassesMarket />
    </React.Fragment>
  </>
  )
}

export default requireAuth(ClassesMarketPage)
