import React, { useEffect, useState } from 'react'
import { requireAuth, useAuth } from '../util/auth.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import { redirectToAccount, loginToExpressAccount } from '../util/stripe.js'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useRouter } from '../util/router'
function DashboardPage(props) {
  const auth = useAuth();
  const [accountReady, setAccountReady] = useState(false)
  const router = useRouter()
  useEffect(() => {
    if (auth && auth.user && auth.user.stripeConnectAccountId) {
      setAccountReady(true)
    }
  }, [auth])



  return (<>
    {accountReady ? <Grid
      className={'m-2'}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container><Button onClick={router.goBack} variant="text">Back</Button></Grid>
      <Typography > Click below to access your stripe account!</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={loginToExpressAccount}
        className="m-2"
        // className={classes.button}
      >
                Login to your Stripe Account
      </Button>
    </Grid> :
      <Grid
        className={'m-2'}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography>Click below to create and link your stripe account</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={redirectToAccount}
          className="m-2"
          // className={classes.button}
        >
                    Create a Stripe Account
        </Button>
      </Grid>}
  </>
  )
}

export default requireAuth(DashboardPage)
