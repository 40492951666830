import { truncate } from 'fs';
import { useState, useEffect, useRef } from 'react';
import { useAuth } from './auth';
import firebase from './firebase';

const firestore = firebase.firestore();

if (location.hostname === 'localhost') {
  firestore.useEmulator('localhost', 5002);
}
/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
export function useUser(uid) {
  return useQuery(uid && firestore.collection('users').doc(uid));
}

// Update an existing user
export function updateUser(uid, data) {
  return firestore.collection('users').doc(uid).update(data);
}

// Create a new user
export function createUser(uid, data) {
  return firestore
    .collection('users')
    .doc(uid)
    .set({ uid, ...data }, { merge: true });
}

/***** ZOOM *****/

// List all children associated with an adult
export const useGetZooms = () => {
  return useQuery(firestore.collection('zoom-sessions').orderBy('time', 'asc'));
};

export const useGetZoomTime = (id, sortBy = 'name') => {
  return useQuery(
    id && firestore.collection('zoom-sessions').doc(id).collection('registrations').orderBy(sortBy, 'desc'),
  );
};

export const useGetClasses = (id, sortBy = 'name') => {
  return useQuery(
    id && firestore.collection('zoom-sessions').doc(id).collection('registrations').orderBy(sortBy, 'desc'),
  );
};

export const useGetWaitlist = (id, sortBy = 'name') => {
  return useQuery(id && firestore.collection('zoom-sessions').doc(id).collection('waitlist').orderBy(sortBy, 'desc'));
};

export const useGetZoomClass = (slot, classId) => {
  return useQuery(
    slot && classId && firestore.collection('zoom-sessions').doc(slot).collection('classes').doc(classId.toString()),
  );
};

export const useGetAvailableRegistrations = (childId, id) => {
  return useQuery(
    id &&
      childId &&
      firestore
        .collection('users')
        .doc(id)
        .collection('subscriptions')
        .where('childId', '==', childId)
        .where('status', 'in', ['active', 'trialing'])
        .where('availableRegistrations', '!=', false),
  );
};

export const useGetCurrentRegistrations = (childId, id) => {
  return useQuery(
    id &&
      childId &&
      firestore
        .collection('users')
        .doc(id)
        .collection('subscriptions')
        .where('childId', '==', childId)
        .where('status', 'in', ['active', 'trialing']),
  );
};

export const useGetActiveSubscriptions = (childId, id) => {
  return useQuery(
    id &&
      childId &&
      firestore
        .collection('users')
        .doc(id)
        .collection('subscriptions')
        .where('childId', '==', childId)
        .where('status', 'in', ['active', 'trialing']),
  );
};

export const useGetUserActiveSubscriptions = (id) => {
  return useQuery(
    id &&
      firestore.collection('users').doc(id).collection('subscriptions').where('status', 'in', ['active', 'trialing']),
  );
};

export const useGetUserSubscriptions = (id) => {
  return useQuery(id && firestore.collection('users').doc(id).collection('subscriptions'));
};

export const useGetAvailableZoomMeetings = (productId, semesterId) => {
  return useQuery(
    productId &&
      semesterId &&
      firestore.collection('zoom-meetings').where('productId', '==', productId).where('semesterId', '==', semesterId),
  );
};

export const useGetStudentsForMeeting = (classId) => {
  return useQuery(classId && firestore.collection('children').where('classes', 'array-contains', classId));
};

export const useGetZoomMeetingsForChild = (childId) => {
  return useQuery(childId && firestore.collection('zoom-meetings').where('registrations', 'array-contains', childId));
};

export const useGetContractorMeetingsForChild = (childId) => {
  return useQuery(
    childId && firestore.collectionGroup('contractor-classes').where('registrations', 'array-contains', childId),
  );
};

const classDays = {
  0: 'sundayClass',
  1: 'mondayClass',
  2: 'tuesdayClass',
  3: 'wednesdayClass',
  4: 'thursdayClass',
  5: 'fridayClass',
  6: 'saturdayClass',
};
const date = new Date();
const dayOfWeek = date.getDay();
const dayString = classDays[dayOfWeek];

export const useGetTodaysMeetings = (childId) => {
  return useQuery(
    childId &&
      firestore
        .collection('zoom-meetings')
        .where('registrations', 'array-contains', childId)
        .where('startDate', '<=', date)
        .where(dayString, '==', true),
  );
};

export const useGetTodaysContractorClasses = (childId) => {
  return useQuery(
    childId &&
      firestore
        .collectionGroup('contractor-classes')
        .where('registrations', 'array-contains', childId)
        .where('startDate', '<=', date)
        .where(dayString, '==', true),
  );
};

export const hideProductBanner = (userId) => {
  return userId && firestore.collection('users').doc(userId).update({ showZoom2Promo: false });
};

export function moveOffWaitlist(id, classId, data) {
  // console.log(id, classId, data)
  return firestore
    .collection('zoom-sessions')
    .doc(classId)
    .collection('registrations')
    .doc(id)
    .set(data, { merge: true })
    .then(() => {
      return firestore.collection('zoom-sessions').doc(classId).collection('waitlist').doc(id).delete();
    });
}
export function createClass(docid, classId, change, data) {
  // console.log(docid, classId, change, data)
  return firestore
    .collection('zoom-sessions')
    .doc(classId)
    .collection('registrations')
    .doc(docid)
    .set(change, { merge: true })
    .then(() => {
      updateChild(change, data.id);
    });
}
export function setClass(docid, classId, change, data) {
  // console.log(docid, classId, change, data)
  return firestore
    .collection('zoom-sessions')
    .doc(classId)
    .collection('registrations')
    .doc(docid)
    .set(change, { merge: true })
    .then(() => {
      updateChild(change, data.id);
    });
}

export function deleteRegistration(timeSlot, classNumber, childId) {
  console.log(timeSlot, classNumber, childId);
  if (timeSlot && classNumber && childId) {
    firestore
      .collection('zoom-sessions')
      .doc(timeSlot)
      .collection('registrations')
      .where('id', '==', childId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          console.log('deleting', doc.data());
          doc.ref.delete();
        });
      });
  }
}

export function removeStudentFromZoomClass(zoomMeetingId, childId) {
  if (zoomMeetingId && childId) {
    firestore
      .collection('children')
      .doc(childId)
      .update({
        classes: firebase.firestore.FieldValue.arrayRemove(zoomMeetingId),
      });
    firestore
      .collection('zoom-meetings')
      .doc(zoomMeetingId)
      .update({
        registrations: firebase.firestore.FieldValue.arrayRemove(childId),
      });
  }
  return;
}

export function addRegistration(data) {
  let zoomSlot = data.zoomSlot;
  let classNumber = data.class;
  console.log('adding reg', data);
  firestore.collection('zoom-sessions').doc(zoomSlot).collection('registrations').add(data);
  firestore.collection('children').doc(data.id).update({ zoomSlot: zoomSlot, class: classNumber });
}

export function createRegistration(id, data) {
  if (data.schedule === undefined) delete data.schedule;
  updateChild({ zoomSlot: id, ...data }, data.id);
  firestore.collection('zoomRegistrations').add({ zoomSlot: id, ...data });
  return firestore
    .collection('zoom-sessions')
    .doc(id.toString())
    .collection('registrations')
    .add({ zoomSlot: id, ...data });
}

export function useGetRegistration({ owner }) {
  // console.log('usegen', owner)
  return useQuery(
    firestore.collection('zoomRegistrations').where('owner', '==', owner),
    // .where('id', '==',id )
    // .orderBy('name', 'desc'),
  );
}

export function createWaitlist(id, data) {
  updateChild(data, data.id);
  return firestore.collection('zoom-sessions').doc(id.toString()).collection('waitlist').add(data);
}

/***** Teacher MGMT *****/

export const useGetTeachers = () => {
  // console.log('usegetzoom', slot, classId)
  return useQuery(firestore.collection('teachers'));
};
// Only Zoom enabled teachers
export const useGetLinkedTeachers = () => {
  // console.log('usegetzoom', slot, classId)
  return useQuery(firestore.collection('teachers').orderBy('zoomUserId'));
};

export function updateTeacher(id, data) {
  return firestore.collection('teachers').doc(id).set(data, { merge: true });
}

export const useGetTeacherMeetings = (teacherId) => {
  // console.log('usegetzoom', slot, classId)
  return useQuery(teacherId && firestore.collection('zoom-meetings').where('teacherId', '==', teacherId));
};

export const useGetAllMeetings = () => {
  // console.log('usegetzoom', slot, classId)
  return useQuery(firestore.collection('zoom-meetings'));
};

export const useGetMeetingsBySemester = (semesterId) => {
  // console.log('usegetzoom', slot, classId)
  return useQuery(semesterId && firestore.collection('zoom-meetings').where('semesterId', '==', semesterId));
};

export const useGetSingleMeeting = (meetingId) => {
  // console.log('usegetzoom', slot, classId)
  return useQuery(meetingId && firestore.collection('zoom-meetings').doc(meetingId));
};

/***** Contractor MGMT *****/
export const useGetContractorProfile = (id) => {
  return useQuery(id && firestore.collection('contractors').doc(id));
};

export const useGetContractors = () => {
  return useQuery(firestore.collection('contractors'));
};

export const updateContractor = (id, data) => {
  return firestore.collection('contractors').doc(id).set(data, { merge: true });
};

export const createContractorClass = (id, data) => {
  return firestore.collection('contractors').doc(id).collection('contractor-classes').add(data);
};

export const updateContractorClass = (contractorId, classId, data) => {
  return firestore
    .collection('contractors')
    .doc(contractorId)
    .collection('contractor-classes')
    .doc(classId)
    .update(data);
};

export const useGetClassesByContractorStatus = (id, status) => {
  return useQuery(
    id && firestore.collection('contractors').doc(id).collection('contractor-classes').where('status', '==', status),
  );
};

export const useGetClassesByContractor = (id) => {
  return useQuery(id && firestore.collection('contractors').doc(id).collection('contractor-classes'));
};

export const useGetContractorClasses = (status) => {
  return useQuery(firestore.collectionGroup('contractor-classes').where('status', '==', status));
};

export const useGetContractorClass = (contractorId, classId) => {
  return useQuery(
    contractorId &&
      classId &&
      firestore.collection('contractors').doc(contractorId).collection('contractor-classes').doc(classId),
  );
};

export const useGetContractorClassById = (classId) => {
  return useQuery(classId && firestore.collectionGroup('contractor-classes').where(classId, '==', 'docId'));
};

export const addNewClassTag = (tag) => {
  //    console.log('Adding Daily Weather----------', id, data)
  return firestore.collection('class-tags').doc(tag).set({ tag: tag });
};

export const getClassTags = () => {
  //    console.log('Adding Daily Weather----------', id, data)
  return useQuery(firestore.collection('class-tags'));
};

/****** Utility ******/

export const useGetConfig = (scope) => {
  console.log('scope', scope);
  return useQuery(scope && firestore.collection('config').doc(scope));
};

export const useGetSchedule = () => {
  return useQuery(firestore.collection('class-schedules').limit(1));
};

export const useGetChildSchedule = (childId) => {
  return useQuery(
    childId &&
      firestore
        .collection('children')
        .doc(childId)
        .collection('generated-schedules')
        .orderBy('created', 'desc')
        .limit(1),
  );
};

export const useGetScores = (id) => {
  return useQuery(
    id &&
      firestore
        .collection('children')
        .doc(id.toString())
        .collection('scores')
        .orderBy('timeStamp', 'desc')
        // .where("timeStamp", "==", "Wed Oct 14 2020 10:34:31 GMT-0700 (Pacific Daylight Time)")
        .limit(1),
  );
};

export const useScores = (id, section) => {
  return useQuery(
    section &&
      id &&
      firestore
        .collection('children')
        .doc(id.toString())
        .collection(section + '-scores')
        .orderBy('timeStamp', 'desc')
        .limit(1),
  );
};

export const useOldScores = (id, section) => {
  return useQuery(
    section &&
      id &&
      firestore
        .collection('children')
        .doc(id.toString())
        .collection(section + '-scores')
        .orderBy('timeStamp', 'asc')
        .limit(1),
  );
};

export const useGetSupplies = () => {
  return useQuery(firestore.collection('supplies'));
};

/****** DAILY ******/
export const useGetConfettiDates = () => {
  return useQuery(firestore.collection('confetti-dates').where('date', '==', paddedDateHash()).limit(1));
};

export const useGetDailyQoD = (id) => {
  return useQuery(
    id &&
      firestore
        .collection('children')
        .doc(id.toString())
        .collection('question-of-the-day')
        .orderBy('created', 'desc')
        .limit(1),
  );
};

function currentDateHash() {
  const d = new Date();
  return d.getMonth() + '-' + d.getDate() + '-' + d.getFullYear();
}

export const addDailyQoD = (id, data) => {
  console.log('QOD----------', id.toString(), data);
  let response = firestore
    .collection('children')
    .doc(id.toString())
    .collection('question-of-the-day')
    .doc(currentDateHash())
    .set(data);
  console.log(response);
  return id && response;
};

export const useGetDailyQoDAnswer = (id) => {
  return useQuery(
    id && firestore.collection('children').doc(id.toString()).collection('question-of-the-day').doc(currentDateHash()),
  );
};

export const useGetDailyEmotionAnswer = (id) => {
  return useQuery(
    id && firestore.collection('children').doc(id.toString()).collection('emotion-answer').doc(currentDateHash()),
  );
};

export const useGetDailyWeatherAnswer = (id) => {
  return useQuery(
    id && firestore.collection('children').doc(id.toString()).collection('weather-answer').doc(currentDateHash()),
  );
};

export const useGetEmotion = (id) => {
  return useQuery(
    id &&
      firestore
        .collection('children')
        .doc(id.toString())
        .collection('emotion-answer')
        .orderBy('created', 'desc')
        .limit(1),
  );
};

export const useGetAllEmotions = (id) => {
  console.log(id);
  return useQuery(id && firestore.collection('children').doc(id.toString()).collection('emotion-answer'));
};

const pad = (num, size) => {
  var s = '000000' + num;
  return s.substr(s.length - size);
};

const paddedDateHash = () => {
  const d = new Date();
  return d.getFullYear() + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
};

export const useGetTodaysQuestion = () => {
  return useQuery(firestore.collection('questions').where('lastUsed', '==', paddedDateHash()));
};

export const useGetTodaysLetter = () => {
  return useQuery(firestore.collection('letter-of-the-day').where('lastUsed', '==', paddedDateHash()));
};

export const addDailyEmotion = (id, data) => {
  //    console.log('Adding Daily Emotion----------', id, data)
  return (
    id &&
    firestore.collection('children').doc(id.toString()).collection('emotion-answer').doc(currentDateHash()).set(data)
  );
};

export const addDailyWeather = (id, data) => {
  //    console.log('Adding Daily Weather----------', id, data)
  return (
    id &&
    firestore.collection('children').doc(id.toString()).collection('weather-answer').doc(currentDateHash()).set(data)
  );
};

/**** CHILDREN ****/

// List all children associated with an adult
export const useGetChildrenByOwner = (owner) => {
  return useQuery(owner && firestore.collection('children').where('owner', '==', owner));
};

export const useGetAllChildren = () => {
  return useQuery(firestore.collection('children').orderBy('name', 'desc'));
};

export const useGetChildrenByIds = (idList) => {
  return useQuery(
    idList &&
      idList.length > 0 &&
      firestore.collection('children').where(firebase.firestore.FieldPath.documentId(), 'in', idList),
  );
};

export const useGetChild = (id) => {
  return useQuery(id && firestore.collection('children').doc(id));
};

export function createChild(data) {
  return firestore.collection('children').add(data);
}

export function updateChild(data, id) {
  // console.log('updateChild', id, data)
  return firestore.collection('children').doc(id).set(data, { merge: true });
}

/**** Assessments ****/
export const useAssessments = (cid, type, limit = 2) => {
  return useQuery(
    cid &&
      firestore
        .collection('children')
        .doc(cid)
        .collection(
          type === 'writing' ? 'writing-assessments' : type === 'language' ? 'language-assessments' : 'math-assessments',
        )
        .orderBy('created', 'desc')
        .limit(limit),
  );
};

/**** Videos ****/

export const useGetVideos = () => {
  return useQuery(firestore.collection('videos'));
};

export const useGetVideosByIds = (idList) => {
  return useQuery(
    idList &&
      idList.length > 0 &&
      firestore.collection('videos').where(firebase.firestore.FieldPath.documentId(), 'in', idList),
  );
};

export const useGetSingleVideo = (id) => {
  return useQuery(id && firestore.collection('videos').doc(id));
};

export const getSingleVideo = async (id) => {
  let snap = await firestore.collection('videos').doc(id).get();
  return snap.data();
};

export function updateVideo(videoID, data) {
  return firestore.collection('videos').doc(videoID).update(data);
}

export const addNewSupply = (supplyName) => {
  //    console.log('Adding Daily Weather----------', id, data)
  return firestore.collection('supplies').doc(supplyName).set({ supply: supplyName });
};
/**** Class Management ****/

export function updateMKClass(classId, data) {
  return firestore.collection('mk-classes').doc(classId).update(data);
}

export function addMKClass(data) {
  return firestore.collection('mk-classes').add(data);
}

export function useGetMKClasses(data) {
  return useQuery(firestore.collection('mk-classes'));
}

export function useGetMeetingsByTeacher(teacherId) {
  return useQuery(teacherId && firestore.collection('zoom-meetings').where('teacherId', '==', teacherId));
}

/**** QUESTIONS ****/
export const useGetQuestions = () => {
  return useQuery(firestore.collection('questions'));
};

export const useGetLetters = () => {
  return useQuery(firestore.collection('letter-of-the-day'));
};

export function updateQuestion(questionId, data) {
  return firestore.collection('questions').doc(questionId).update(data);
}

export function updateLetter(letterId, data) {
  return firestore.collection('letter-of-the-day').doc(letterId).update(data);
}

export function createQuestion(data) {
  if (!data.text || data.text === '') {
    throw new Error('question text required!');
  }
  let safeHash = data.text.replace(/\//g, '-');
  return firestore.collection('questions').doc(safeHash).set(data);
}

/**** Alerts ****/
export function useGetAlertsAdmin() {
  return useQuery(firestore.collection('alerts-admin').orderBy('startDate', 'desc'));
}

export function createAlert(alertData) {
  return firestore.collection('alerts-admin').add(alertData);
}

export function updateAlert(alertId, alertData) {
  return firestore.collection('alerts-admin').doc(alertId).update(alertData);
}

export function useGetMyAlerts(userId) {
  return useQuery(firestore.collection('users').doc(userId).collection('alerts').orderBy('startDate', 'desc'));
}

export function useGetActiveAlerts(userId) {
  return useQuery(userId && firestore.collection('users').doc(userId).collection('alerts').where('active', '==', true));
}

export function hideAlert(userId, alertId) {
  return firestore.collection('users').doc(userId).collection('alerts').doc(alertId).update({ hidden: true });
}

/**** Plans / Subscriptions ****/
export function useGetSemesters() {
  return useQuery(firestore.collection('semester-dates').orderBy('startDate', 'asc'));
}

export function useGetSemester(semesterId) {
  return useQuery(semesterId && firestore.collection('semester-dates').doc(semesterId));
}

export function createSemester(data) {
  return firestore.collection('semester-dates').add(data);
}

export function updateSemester(semesterId, data) {
  return firestore.collection('semester-dates').doc(semesterId).update(data);
}

export function useGetMKPlans() {
  return useQuery(firestore.collection('mighty-kids-plans'));
}

export function useGetMKProducts() {
  return useQuery(firestore.collection('mighty-kids-products'));
}

export function useGetMightyKidsProduct(productId) {
  return useQuery(productId && firestore.collection('mighty-kids-products').doc(productId));
}

export function useGetMightyKidsPlan(planId) {
  return useQuery(planId && firestore.collection('mighty-kids-plans').doc(planId));
}

export function useGetMightyKidsPlanBySlug(pageSlug) {
  return useQuery(pageSlug && firestore.collection('mighty-kids-plans').where('pageSlug', '==', pageSlug));
}

export function useGetMightyKidsPlansBySemester(semesterId) {
  return useQuery(
    semesterId &&
      firestore.collection('mighty-kids-plans').where('semesterId', '==', semesterId).where('active', '==', true),
  );
}

export function useGetActiveMKPlans() {
  return useQuery(firestore.collection('mighty-kids-plans').where('active', '==', true));
}

export function createMKPlan(data) {
  return firestore.collection('mighty-kids-plans').add(data);
}

export function updateMKPlan(id, data) {
  return firestore.collection('mighty-kids-plans').doc(id).set(data, { merge: true });
}

export function updateMKProduct(id, data) {
  return firestore.collection('mighty-kids-products').doc(id).set(data);
}

export function useAllUsers() {
  return useQuery(firestore.collection('users'));
}

export function useGetContractorUsers() {
  return useQuery(firestore.collection('users').where('contractor', '==', true));
}

export function useGetTeacherUsers() {
  return useQuery(firestore.collection('users').where('teacher', '==', true));
}

export function useGetAllStripeUsers(active) {
  return useQuery(active && firestore.collection('users').orderBy('stripeCustomerId'));
}

//Zoom polling
export function voteForTimeSlot(data) {
  return firestore.collection('zoom-time-polls').doc(`${data.childId}-${data.semesterId}`).set(data, { merge: true });
}

// Fetch item data
export function useItem(id) {
  return useQuery(id && firestore.collection('items').doc(id));
}

// Update an item
export function updateItem(id, data) {
  return firestore.collection('items').doc(id).update(data);
}

// Create a new item
export function createItem(data) {
  return firestore.collection('items').add(data);
}

/**** HELPERS ****/

// Custom React hook that subscribes to a Firestore query
function useQuery(query) {
  // console.log('query', query)
  const initialState = {
    status: 'loading',
    data: undefined,
    error: null,
  };

  const [state, setState] = useState(initialState);

  // Gives us previous query object if query is the same
  // ensuring we don't unsubscribe and resubscribe below.
  const queryCached = useQueryCache(query);

  useEffect(() => {
    // Subscribe to query unless falsy, which indicates we're
    // waiting on other data needed to construct the query object.
    if (queryCached) {
      // console.log('-------',queryCached)
      queryCached.onSnapshot(
        (response) => {
          // Get data for collection or doc
          const data = response.docs ? getCollectionData(response) : getDocData(response);

          setState({
            status: 'success',
            data: data,
            error: null,
          });
        },
        (error) => {
          setState((state) => ({
            status: 'error',
            data: undefined,
            error: error,
          }));
        },
      );
    } else {
      // Reset back to initial state
      if (state.status !== initialState.status) {
        setState(initialState);
      }
    }
  }, [queryCached]);

  return state;
}

// Get doc data
function getDocData(doc) {
  return doc.exists === true ? { id: doc.id, docId: doc.id, ...doc.data() } : null;
}

// Get array of doc data from collection
function getCollectionData(collection) {
  return collection.docs.map((doc) => {
    return { id: doc.id, docId: doc.id, ...doc.data() };
  });
}

function useQueryCache(query) {
  // Ref for storing previous query object
  const previousRef = useRef();
  const previous = previousRef.current;

  // Determine if query object is equal to previous
  const isEqual = (!previous && !query) || (previous && query && previous.isEqual(query));

  // If not equal update previous to query (for next render)
  // and then return new query below.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = query;
    }
  });

  return isEqual ? previous : query;
}
