import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();


  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const data = props.data[index];
    const classId = data.id;
    const className = data.className
    const teacherName = data.teacherName;
    const timeSlot = data.timeSlot
    let Schedule = data.mondayClass ? 'mon ' : '';
    Schedule += data.tuesdayClass ? 'tue ' : '';
    Schedule += data.wednesdayClass ? 'wed ' : '';
    Schedule += data.thursdayClass ? 'thu ' : '';
    Schedule += data.fridayClass ? 'fri' : '';
    return (
      <ListItem selected={classId === props.currentClassId} key={classId} onClick={() => { props.rowSelect(data) }} button style={style} >
        <ListItemText primary={`${className} - ${teacherName} `} secondary={timeSlot + ' ' + Schedule} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={400} width={400} itemSize={70} itemCount={props.data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
