import React, { useEffect, useState } from 'react';
import { CircularProgress, Switch,Grid,CssBaseline,Typography,Container } from '@material-ui/core';

import { useAuth } from '../util/auth'
import ViewProfile from './ViewProfile';
import EditProfile from './EditProfile';
import { useGetContractorProfile } from '../util/db'
import { useRouter } from '../util/router'

export default function TeacherProfile(props) {
  const router = useRouter()
  const auth = useAuth();
  const [contractorId, setContractorId] = useState()
  const { data, status } = useGetContractorProfile(contractorId)
  const [teacherData, setTeacherData] = useState({})
  const [disableSwitch, setDisableSwitch] = useState(false)

  const toggleEditMode = () => {
    if (props.editMode) {
      router.replace('view')
    } else {
      router.replace('edit')
    }
  }

  useEffect(() => {
    if (data) {
      setTeacherData(data)
    } else if (auth && auth.user) {
      let profileCopy = (({ name, email, location, uid, stripeConnectAccountId }) => ({ name, email, location, uid, stripeConnectAccountId }))(auth.user)
      setTeacherData(profileCopy)
    }
  }, [data, auth])

  useEffect(() => {
    if (auth && auth.user) {
      setContractorId(auth.user.uid)
    }
  }, [auth])

  return (

    <React.Fragment>
      <CssBaseline />

      <Container className={'p-5'} maxWidth="sm">
        <Typography variant={'h5'}>
                    Teacher Profile
        </Typography>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>View</Grid>
          <Grid item>
            <Switch
              checked={props.editMode}
              disabled={disableSwitch}
              onChange={toggleEditMode}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item>Edit</Grid>
        </Grid>
        {status !== 'loading' && <>
          {
            props.editMode ? <EditProfile disableSwitch={setDisableSwitch} contractorId={contractorId} data={teacherData} /> :
              <ViewProfile data={teacherData} />
          }
        </>}
        {status === 'loading' && <CircularProgress />}
      </Container>

    </React.Fragment >
  );
}
