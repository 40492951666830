import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SectionHeader from './SectionHeader'
import './Date.scss'
import Button from 'react-bootstrap/Button'

function DateComponent({ today, playing: { todayPlaying, dayPlaying, numPlaying,monthPlaying }, day, number, month, year, title, subtitle, date }) {
  const [player, setPlayer] = useState(false)
  // useEffect(() => {
  //    if(day || number || month) setPlayer(true)
  // }, [day, number, month])

  return (
    <>
      <Card
        className={'shadow-lg bg-white rounded text-center h-100'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (!player) {
            setTimeout(() => {
              setPlayer(true)
              today()
            }, 0)
            setTimeout(() => {
              setPlayer(true)
              day()
            }, 1500)
            setTimeout(() => {
              setPlayer(true)
              !dayPlaying && month()
            }, 3000)
            setTimeout(() => {
              setPlayer(true)
              !dayPlaying && !monthPlaying && number()
            }, 4000)
            setTimeout(() => {
              setPlayer(true)
              year()
            }, 5000)
            setTimeout(() => {
              setPlayer(false)
            }, 6500)
          }

          // Promise.resolve(day()).then(number()).then(month())
          // playing ? pause() : play() //toggle()
        }}
      >
        {/*{console.log(playing, day,dayPlaying,numPlaying,monthPlaying)}*/}
        <Card.Body>
          <Row>
            <Col md={12} lg={4}>

              <span className="icon">
                <i className="Date__no-classname fas fa-calendar text-danger" />
              </span>
            </Col>
            <Col xs={12} xl={8} lg={8} md={12}>

              <Card.Title>Today is:</Card.Title>
              <Card.Text
                style={{
                  fontSize: 40,
                }}
              >
                <h3>{date}</h3>
              </Card.Text>

            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${player ? 'up' : 'off'}`} /></sub>
        </Card.Footer>
      </Card>
      <SectionHeader
        title={title}
        subtitle={subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
    </>
  )
}

export default DateComponent
