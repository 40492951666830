import React, { useEffect, useState } from 'react';
import { useGetAlertsAdmin } from '../../util/db';
import AlertList from './AlertList';
import AlertDetails from './AlertDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

function UserAlertManager(props) {
  const { data: alerts, error, loading } = useGetAlertsAdmin();
  const [selectedAlert, setAlert] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const selectAlert = (Alert) => {
    setCreateNew(false)
    console.log('selecting video', Alert)
    let copyAlert = { ...Alert }
    copyAlert.startDate = Alert.startDate?.toDate() || new Date();
    copyAlert.endDate = Alert.endDate?.toDate() || new Date();
    setAlert(copyAlert);
    setShowDetails(true);
  };


  useEffect(()=>{
    if(alerts && selectedAlert){
      setAlert(alerts.find(row => row.id === selectedAlert.id))
    }
  },[alerts])

  const createNewAlert = () => {
    setCreateNew(true)
    setAlert(() => {
      return { startDate: new Date(), endDate: new Date(), name:'new Alert' } //adding an arbitrary old date so new questions get asked sooner
    })
    setShowDetails(true)
  };

  useEffect(() => {
    console.log('question List', AlertList)
  }, [AlertList])

  return (
    <>
      <Container className="mx-3">
        <h2>Alert Manager</h2>
        <Row>
          <Col xs={'4'}>
            <Row>
              {loading ? 'loading' :
                <AlertList rowSelect={selectAlert} data={alerts} />}
            </Row>
            <Row className="m-3">
              <Button variant="primary" size="md" onClick={createNewAlert}>
                                Create New
              </Button>
            </Row>
          </Col>
          <Col>
            {showDetails && selectedAlert ?
              <AlertDetails createNew={createNew} data={selectedAlert} />
              : null
            }
          </Col>
        </Row>
      </Container>
    </>);
}

export default UserAlertManager
