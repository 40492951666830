import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useGetSemesters } from '../util/db'

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//             width: '50ch',
//         },
//     },
// }));


const SemesterSelector = (props) => {
  const [startDate, handleChange] = useState(new Date())
  const { data: semesterData } = useGetSemesters()


  const handleInputChange = e => {
    const { name, value } = e.target
    props.setSemesterId(value)
  }

  return (
    <FormControl>
      <InputLabel id="select-semester">Semester Select</InputLabel>
      <Select
        style={{ width:300 }}
        name="semesterId"
        labelId="select-semester"
        value={props.semesterId}
        onChange={handleInputChange}
      >   
        {!props.hideNow && <MenuItem key={'now'} value={'now'}>Starts Immediately</MenuItem>}
        {semesterData && semesterData.map(semester => <MenuItem key={semester.id} value={semester.id}>{semester.semesterName}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default SemesterSelector