import React from 'react'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import Segment from '../components/Segment'

const SegmentSection = ({ bg, textColor, size, bgImage, bgImageOpacity, title, subtitle, children }) => 
  <Segment
    bg={bg}
    textColor={textColor}
    size={size}
    bgImage={bgImage}
    bgImageOpacity={bgImageOpacity}
  >
    <Container>
      <SectionHeader
        title={title}
        subtitle={subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
      {children}
    </Container>
  </Segment>

export default SegmentSection