import React, { useEffect, useState } from 'react';
import ControlHolder from '../ControlHolder';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Typography } from '@material-ui/core';

const TIMES = [
  {
    key: 'anytime',
    label: 'Anytime',
    subtext: '9am-6pm',
    min: 9,
    max: 18,
  },
  {
    key: 'morning',
    label: 'Morning',
    subtext: '9am-12pm',
    min: 9,
    max: 12,
  },
  {
    key: 'afternoon',
    label: 'Afternoon',
    subtext: '12pm-4pm',
    min: 12,
    max: 16,
  },
  {
    key: 'afterschool',
    label: 'afterschool',
    subtext: '2pm-6pm',
    min: 14,
    max: 18,
  },
];

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      border: '1px solid',
      borderColor: '#692B7C',
      // borderRadius: '20%',
      borderRadius: '20px',
    },
    '&:first-child': {
      border: '1px solid',
      borderColor: '#692B7C',
      // borderRadius: '20%',
      borderRadius: '20px',
    },
    textAlign: 'center',
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
  root: {
    color: '#692B7C',
    '&$selected': {
      color: 'white',
      background: '#692B7C',
    },
    '&:hover': {
      borderColor: '#BA9BC3',
      background: '#BA9BC3',
    },
    '&:hover$selected': {
      borderColor: '#BA9BC3',
      background: '#BA9BC3',
    },
    minWidth: 100,
    maxWidth: 120,
    height: 60,
    textTransform: 'unset',
    fontSize: '0.75rem',

    margin: '4px',
    display: 'inline',
  },
  span: {
    flexDirection: 'column',
  },
  div: {
    display: 'inline',
  },

  selected: {},
})(ToggleButton);

const ToggleTimes = (props) => {
  const [times, setTimes] = useState([]);

  useEffect(() => {
    if (props.value) {
      //consider resetting the time select
    }
  }, [props.value]);
  return (
    <>
      <Typography>Time of Day (PDT)</Typography>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Time of Day"
        value={times}
        onChange={(event, values) => {
          setTimes(values);
          let max = 0;
          let min = 18;
          for (let val of values) {
            if (TIMES[val].min < min) {
              min = TIMES[val].min;
            }
            if (TIMES[val].max > max) {
              max = TIMES[val].max;
            }
            props.setValue([min, max]);
          }
        }}
      >
        {TIMES.map((day, index) => (
          <StyledToggle key={day.key} value={index} aria-label={day.key}>
            <div>{day.label}</div>
            <div>{day.subtext}</div>
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
};

export default ToggleTimes;
