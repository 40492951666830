import React, { useEffect, useState } from 'react';
import { useGetTeachers, useGetTeacherMeetings } from '../../util/db';
import { getZoomUsers } from '../../util/zoomUtility';
import TeacherList from './TeacherList';
import ZoomClasses from './ZoomClasses';
import TeacherDetails from './TeacherDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
function TeacherManager(props) {
  const [selectedTeacher, setTeacher] = useState();
  const [currentZoomUser, setCurrentZoomUser] = useState();
  const [userList, setUserList] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [currentTeacherId, setCurrentTeacherId] = useState();
  const { data: teacherList, error, loading } = useGetTeachers();
  const { data: zoomClasses, error: zoomClassesError, loading: zoomClassesLoading } = useGetTeacherMeetings(currentTeacherId);

  // const [createNew, setCreateNew] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let users = await getZoomUsers();
      console.log('zoom users', users)
      setUserList(users)
    }

    if (!userList) {
      fetchData();
    }
  }, [userList]);

  const selectTeacher = (teacher) => {
    // setCreateNew(false)
    console.log('selecting teacher', teacher)
    setTeacher(() => teacher);
    setCurrentTeacherId(() => teacher.id)
    setShowDetails(true);
    if (teacher.zoomUserId && userList) {
      selectZoomUser(userList.find(element => element.id === teacher.zoomUserId))
    } else {
      selectZoomUser({})
    }
  };

  const selectZoomUser = (zoomUser) => {
    console.log(zoomUser)
    setCurrentZoomUser(zoomUser);
  }

  return (
    <>
      <Container className="mx-3">

        <Row>
          <Col xs={2}>
            <h2>Teacher Manager</h2>
            <Row>
              {loading ? 'loading' :
                <TeacherList rowSelect={selectTeacher} data={teacherList} />}
            </Row>
          </Col>

          <Col xs={5}>
            {showDetails ?

              <TeacherDetails zoomUsers={userList} selectZoomUser={selectZoomUser} data={selectedTeacher} currentZoomUser={currentZoomUser} />

              : null
            }
          </Col>
          <Col xs={5}>
            {showDetails ? <>
              <h4>Zoom Meetings</h4>
              <ZoomClasses zoomClasses={zoomClasses} />
            </>
              : null
            }
          </Col>
        </Row>
      </Container>
    </>);
}

export default TeacherManager
