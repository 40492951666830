import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import TeamBios from './TeamBios'

function TeamBiosSection(props) {
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <TeamBios
          items={[
            {
              avatar: '/images/staff/sonia_small.jpg',
              name: 'Sonia Graves',
              role: 'Founder & Director',
              bio: 'Born and raised in the PNW, she has experience working in Special Education classrooms, traditional preschool classrooms, kids Technology camp and college training in Early Childhood Education. She is a mother of three and enjoys helping kids reach their full potential.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/Haley-small.jpg',
              name: 'Haley Futialo',
              role: 'Teacher',
              bio: 'Haley has a BS in Human Development and Family Studies and Early Childhood Education. She has taught elementary and preschool in the public school system and is a mom of 2.',
            },

            {
              avatar: '/images/staff/raycheal-small.jpg',
              name: 'Raycheal Larson',
              role: 'Teacher',
              bio: 'Raycheal has over 11 years of experience in children\'s education is nationally certified and is credentialed in Early Childhood Education. She has taught and directed in a preschool setting and has her CDA. As the oldest of 5 kids, she knows that there is always something new to learn and explore everyday! Her favorite quote is "Let them be little.."',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/stacy_small.jpg',
              name: 'Stacy Morales',
              role: 'Teacher',
              bio: 'Stacy has a degree in Early Childhood Education paired with over 10 years of experience in the education field. From infants to fifth grade, she has taught in both public and private school settings, including SpED classes, and loves inspiring children to love learning. A mom to four daughters, Stacy loves the sound of laughter and hosting family and friends in her home in sunny, South Florida.',

            },
            {
              avatar: '/images/staff/dani_small.jpg',
              name: 'Dani Bean',
              role: 'Video Teacher',
              bio: 'Dani has a double bachelor\'s degree in English and Early Childhood Education. She has over 15 years of ECE experience and is a mom of two pre-school aged kids.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/hayley_small.jpg',
              name: 'Hayley Waisome',
              role: 'Teacher',
              bio: 'Hayley Waisome is from South Florida. She graduated from Broward College with a BA in Special Education and endorsements in Reading and ESOL. Hayley has experience educating children from preschool to high school and takes pride in assisting them reach their full potential.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/cameron_small.jpg',
              name: 'Cameron McBride',
              role: 'Customer Relations Manager',
              bio: 'Cameron is an Army veteran, who spent his career in Communications. Now out of the Army, he is pursuing a degree in management. As the father of 4 girls, he enjoys hanging out with his family and his gym time.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/dustin_small.jpg',
              name: 'Dustin Graves',
              role: 'Founder & Engineer',
              bio: 'Dustin is a Software Engineer with over 12 years of experience in designing technology solutions. He serves on the Board for the Mount Baker Council, BSA. Dustin has a BS in Information Technology and has worked in several different industries including healthcare, defense, telematics and media. Dustin can be found hanging out with his family or cycling.\n',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/szavio_small.jpg',
              name: 'Szavio Raaum',
              role: 'Software Architect',
              bio: 'Szavio graduated from the University of Minnesota with a double major in Computer Science and Art 8 years ago. Since then he has been designing and crafting solutions for businesses of all shapes and sizes. In his free time he can be found playing accordion or swimming in the ocean.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
          ]}
        />
      </Container>
    </Segment>
  )
}

export default TeamBiosSection
