import React from 'react';
import MetaTags from 'react-meta-tags';
import CoreMetaTags from './CoreMetaTags';

const PageTitle = 'Mighty Kids Virtual Academy • Preschool, Pre-K, Kindergarten Readiness'
const Page = 'Homepage'
const SiteTitle = 'Mighty Kids Virtual Academy'
const Slug = '/'
const Description = 'Virtual Academy including preschool & pre-k distance learning that\'s fun, easy, and completely customized to your child! Voted Most Loved by Hulafrog Families!'
const URL = 'https://app.mightykidsvirtualacademy.com'
const imgPath = 'images/mighty-kids-og.webp'

const IndexPageMetaTags = () =>
  <MetaTags>
    <title>{PageTitle}</title>
    {/* Index rules */}
    <meta name="robots" content="index/follow" />
    <meta name="googlebot" content="index, follow" />
    <meta name="description" content={Description} />
    <meta name="DC.title" content={`${Page} - ${SiteTitle}`} />
    <meta name="keywords" content="preschool, online preschool, pre-k, academy, virtual preschool, virtual academy, mighty kids, distance learning" />
    <meta name="pagename" content={`${Page} - ${SiteTitle}`} />
    <meta name="pageKey" content={Slug} />
    <meta property="og:title" content={`${Page} - ${SiteTitle}`} />
    <meta property="og:description" content={Description} />
    <meta property="og:image" content={`${URL}/${imgPath}`} />
    <meta name="og:url" content={`${URL}/${Slug}`} />
    <CoreMetaTags />
  </MetaTags>

export default IndexPageMetaTags