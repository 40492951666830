import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed'
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from 'react-bootstrap/Button';
import { updateTeacher } from '../../util/db';
import { uploadTeacherImage } from '../../util/firebaseStorage';
import ZoomList from './ZoomUserList'

const defaultValues = {
  text: '',
  imageURL: '',
  email: '',
  bio: '',
  name: '',
  prefix: '',
  vimeoURL: '',
  slackWebhook: '',
}

const prefixes = ['Mr', 'Mrs', 'Ms', 'Dr'];

export default function TeacherDetails(props) {
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [imgValue, setImage] = useState();
  const [imageLoad, setImageLoad] = useState(false);


  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues({ ...defaultValues, ...props.data });
    setImage(props.data.imageURL || '');
    showSuccess(false);
  }


  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleChange = (name, value) => {
    console.log('name', name, 'value', value)
    setValues({ ...values, [name]: value })
  }


  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    let zoomUserId = '';
    if (props.currentZoomUser && props.currentZoomUser.id) {
      zoomUserId = props.currentZoomUser.id;
    }

    let toSave = {
      imageURL: imgValue || '',
      email: values.email || '',
      bio: values.bio || '',
      name: values.name || '',
      prefix: values.prefix || '',
      vimeoURL: values.vimeoURL || '',
      zoomUserId: zoomUserId,
      slackWebhook: values.slackWebhook || '',
    }
    console.log(props.data.id)
    console.log(toSave)
    await updateTeacher(props.data.id, toSave)
    showSuccess(true)
  }

  const imageUpload = async (event) => {
    setImageLoad(true);
    const file = event.target.files[0];
    let imageURL = await uploadTeacherImage(file, props.data.id);
    setValues({ ...values, audio: imageURL })
    setImage(imageURL)
    setImageLoad(false);
  }

  useEffect(() => {
    resetValues();
  }, [props.data.id]);

  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Teacher Updated!🥳
        </Alert>
        : null}
      <Container key={props.data.id} className={'px-5'}>
        <Col>
          <h2>{values.name || ''}</h2>
          <h4>{values.id || ''}</h4>
          {props.currentZoomUser && props.currentZoomUser.email ?
            <Row className="my-3">
              <TextField
                fullWidth
                name="zoomUser"

                disabled
                value={props.currentZoomUser.email}
                placeholder="zoom user email"

                label="Zoom User" />
            </Row> : null
          }
          <Row className="mt-4" style={{ display: 'block' }}>
            <InputLabel id="select-prefix">Prefix:</InputLabel>
            <Select
              labelId="select-prefix"
              value={values.prefix}
              onChange={(rowClass, value) => {
                handleChange('prefix', value.props.value)
              }
              }
            >
              {
                prefixes.map((val) => {
                  return <MenuItem key={val} value={val}>{val}</MenuItem>
                })
              }
            </Select>
          </Row>

          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'text'} name="name" value={values.name} onChange={handleInputChange} label="Name" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'slack'} name="slackWebhook" value={values.slackWebhook} onChange={handleInputChange} label="Slack Webhook" />
          </Row>

          <Row className="my-3">
            <TextField
              fullWidth
              name="bio"
              multiline
              rows={4}
              value={values.bio}
              placeholder="A brief description"
              onChange={handleInputChange}
              label="Bio:" />
          </Row>
          <Row className="my-3">
            <TextField
              fullWidth
              name="vimeoURL"
              value={values.vimeoURL}
              placeholder="url to bio uploaded to vimeo"
              onChange={handleInputChange}
              label="Vimeo URL:" />
          </Row>
          {values.vimeoURL !== '' ? <div className="h-100 w-100" >
            <ResponsiveEmbed aspectRatio="16by9">
              <embed type="image/svg+xml" src={values.vimeoURL} />
            </ResponsiveEmbed>
          </div> : null
          }

          <Row className="my-3">
            <label htmlFor="image" className="btn" >teacher photo (optional)</label>
            <input type="file" id="image" multiple size="50" onChange={imageUpload} />
          </Row>

          <Row className="my-3">
            {imageLoad ?
              <Alert
                variant="warning"
                className="text-center mx-auto"
                style={{ maxWidth: '300px' }}>
                                uploading image...
              </Alert>
              :
              <img width="300" src={imgValue} key={imgValue} />
            }
          </Row>

          <Row>
            <h4>Link to Zoom</h4>
            <ZoomList rowSelect={props.selectZoomUser} currentZoomUser={props.currentZoomUser} data={props.zoomUsers} />
          </Row>

        </Col>
        <Col>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
      </Container>
    </>)
}
