import React from 'react'
import ThankYouSection from '../components_parent/ThankYou'
import { useRouter } from '../util/router'
import { requireAuth } from '../util/auth.js'

function ThankYouPage(props) {
  const router = useRouter()

  return (
    <ThankYouSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Thank You"
      subtitle="We are excited to have you with us!"
      buttonText="Go To Lesson"
      buttonColor="primary"
      buttonOnClick={() => {
        // Navigate to pricing page
        router.push('/lesson')
      }}
      showNameField={true}
      inputSize="md"
    />
  )
}

export default requireAuth(ThankYouPage)