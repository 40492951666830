import React from 'react'
import Nav from 'react-bootstrap/Nav'
import { LinkContainer } from 'react-router-bootstrap'

function SettingsNav(props) {
  return (
    <Nav variant="pills" {...props} >
      <Nav.Item>
        <LinkContainer to={'math'}>
          <Nav.Link>Math</Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to={'language'}>
          <Nav.Link>Language</Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to={'writing'}>
          <Nav.Link>Writing</Nav.Link>
        </LinkContainer>
      </Nav.Item>
    </Nav>
  )
}

export default SettingsNav
