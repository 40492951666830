import React from 'react'
import { useRouter } from '../util/router'
import { Grid, Button } from '@material-ui/core'
function GoBack(props) {
  const router = useRouter()
  return (
    <Grid container><Button onClick={router.goBack} variant="text">Back</Button></Grid>
  )
}

export default GoBack
