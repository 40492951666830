import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { useRouter } from '../../util/router.js'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const router = useRouter()
  const goToClassPage = (id)=> {
    router.push(`/teacher/student-roster/${id}`)
  }
  return (
    props.zoomClasses ? props.zoomClasses.map((zoomClass, index) =>
      <Card key={zoomClass.id} className={classes.root}>
        <CardActionArea onClick={()=>goToClassPage(zoomClass.id)}>

          <CardContent>
            <Typography gutterBottom variant="h5" component="h5">
              {zoomClass.className}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
              {zoomClass.teacherName}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
              {zoomClass.timeSlot}
            </Typography>

          </CardContent>
        </CardActionArea>
      </Card>) : null
  );
}