import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import { updateQuestion, createQuestion } from '../../util/db';
import { uploadQuestionAudio, uploadQuestionImage } from '../../util/firebaseStorage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

const defaultValues = {
  text: '',
  audio: '',
  imageURL: '',
}

export default function VideoDetails(props) {
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [warning, showWarning] = useState(false);
  const [imgValue, setImage] = useState();
  const [audioValue, setAudio] = useState();
  const [imageLoad, setImageLoad] = useState(false);
  const [audioLoad, setAudioLoad] = useState(false);


  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues({ ...defaultValues, ...props.data });
    setImage(props.data.imageURL || '');
    setAudio(props.data.audio || '');
    showSuccess(false);
  }


  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    if (values.text === '') {
      showWarning(true);
      return
    }
    showWarning(false)
    let toSave = {
      audio: audioValue || '',
      imageURL: imgValue || '',
      text: values.text,
      lastUsed: values.lastUsed || '2017-12-12',
    }
    if (props.createNew) {
      await createQuestion(toSave)
    } else {
      await updateQuestion(props.data.id, toSave)
    }
    showSuccess(true)
  }

  const imageUpload = async (event) => {
    setImageLoad(true);
    const file = event.target.files[0];
    let imageURL = await uploadQuestionImage(file);
    setValues({ ...values, audio: imageURL })
    setImage(imageURL)
    setImageLoad(false);
  }

  const audioUpload = async (event) => {
    setAudioLoad(true);
    const file = event.target.files[0];
    let audioURL = await uploadQuestionAudio(file);
    setValues({ ...values, audio: audioURL })
    setAudio(audioURL)
    setAudioLoad(false);
  }


  useEffect(() => {
    resetValues();
  }, [props.data.id]);


  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Question Updated!🥳
        </Alert>
        : null}
      { warning ?
        <Alert
          variant="warning"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Question text required!
        </Alert>
        : null}
      <Container key={props.data.id} className={'px-5'}>
        <Col>
          <h2>{values.text || ''}</h2>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'text'} name="text" value={values.text} onChange={handleInputChange} label="Question" />
          </Row>
          <Row className="my-3">
            {audioLoad ?
              <Alert
                variant="warning"
                className="text-center mx-auto"
                style={{ maxWidth: '300px' }}>
                                uploading audio...
              </Alert>
              :
              <audio key={audioValue + 'controls'} controls>
                <source src={audioValue} type="audio/mpeg" key={audioValue} />
                                Your browser does not support the audio element.
              </audio>
            }
          </Row>
          <Row className="my-3">
            <label htmlFor="audio" className="btn">Select Audio</label>
            <input type="file" id="audio" multiple size="50" onChange={audioUpload} />
          </Row>
          <Row className="my-3">
            {imageLoad ?
              <Alert
                variant="warning"
                className="text-center mx-auto"
                style={{ maxWidth: '300px' }}>
                                uploading image...
              </Alert>
              :
              <img width="300" src={imgValue} key={imgValue} />
            }
          </Row>
          <Row className="my-3">
            <label htmlFor="image" className="btn" >Select Image</label>
            <input type="file" id="image" multiple size="50" onChange={imageUpload} />
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
      </Container>
    </>)
}
