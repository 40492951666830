import React from 'react'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { CssBaseline } from '@material-ui/core';


const TwoWeekFreeTrialSection = () =>
  <Container maxWidth={false} style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#ffffff' }}>
    <Container maxWidth="md">
      <CssBaseline />
      <Grid container spacing={1}>
        <Grid container alignContent="center" justifyContent="center" item xs={12} spacing={1} style={{ paddingBottom: 20 }} >
          <img src="/images/free_trial.webp" width="150" height="150"/>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Typography align="center" gutterBottom>
                        We are so excited to be able to offer a 14 day, risk free trial! You can now take advantage of 14 days of daily interactive Zoom classes, catered curriculum, and so much more at no cost to you. Choose your plan now to get started!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Container>


export default TwoWeekFreeTrialSection;