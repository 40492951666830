import React,  { useEffect, useState, useRef } from 'react'
import { Modal, Grid, Button,FormControlLabel,Checkbox } from '@material-ui/core';
import TermsAndConditions from './TermsAndConditions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),

    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  textField: {
    width: '100%',
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    width: '400',
    backgroundColor: '#fff',
    // border: '2px solid #000',
    '&:focus': {
      outline:0,
    },
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '5vh',
    zIndex:10,
  },
}));

function PrivacyPolicyModal(props) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false);

  const agreeToTerms = ()=> {
    props.setTerms(true)
    setModalOpen(false)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <FormControlLabel
      control={<Checkbox onClick={()=> {
        setModalOpen(true)
      }} checked={props.privacy} name="privacy" />}
      label={<>
                    
        <Button onClick={()=> setModalOpen(true)} color="primary" >
                View and Accept Terms and Conditions
        </Button>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grid container
            className={classes.paper}
            direction="column"
            justify="center"
            alignItems="center">
            <TermsAndConditions agreeState={props.terms} closeModal={closeModal} agreeToTerms={agreeToTerms} />
          </Grid>
        </Modal>
      </>}
    />
  )
}

export default PrivacyPolicyModal
