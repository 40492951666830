import React, { useEffect } from 'react'
import { requireAuth, useAuth } from '../util/auth.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import { redirectToAccount } from '../util/stripe.js'

function DashboardPage(props) {
  const auth = useAuth();

  useEffect(() => {
    if (auth && auth.user) {
      redirectToAccount()
    }
  }, [auth])

  return (<>
    <CircularProgress
    />
  </>
  )
}

export default requireAuth(DashboardPage)
