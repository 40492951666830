import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import PhotoGallery from '../../components_contractor/PhotoGallery';
import ClassMiniRow from '../../components_contractor/ClassMiniRow';
import { useGetClassesByContractor } from '../../util/db';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    width: '80vw',
    backgroundColor: '#cacaca',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '20vh',
  },
}));

export default function ImgMediaCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { data: classData } = useGetClassesByContractor(props.data.id);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      className={'mt-2'}
      alignItems={'center'}
      alignContent={'center'}
      direction={'column'}
    >
      <Card className={classes.root}>
        <CardMedia
          component="img"
          alt={props.data.name + ' profile photo'}
          // height="140"
          image={
            props.data.profilePhoto && props.data.profilePhoto !== ''
              ? props.data.profilePhoto
              : '/images/profile_picture.png'
          }
          title={props.data.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.data.teacherName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.data.bio}
          </Typography>
        </CardContent>

        <CardActions>
          {props.data.photoGallery && props.data.photoGallery.length > 0 && (
            <Button onClick={handleOpen} size="small" color="primary">
              View Photos
            </Button>
          )}
        </CardActions>
        <CardContent>
          {
            <>
              <Grid container>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="subtitle1"
                >
                  Classes:
                </Typography>
              </Grid>
              <Grid container>
                {classData &&
                  classData.map((thisClass) => (
                    <ClassMiniRow
                      liveData={true}
                      selectClass={() => props.selectClass(thisClass)}
                      key={thisClass.id}
                      data={thisClass}
                    />
                  ))}
              </Grid>
            </>
          }
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <PhotoGallery photos={props.data.photoGallery} />
        </div>
      </Modal>
    </Grid>
  );
}
