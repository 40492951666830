import React, { useState } from 'react'
import FormAlert from './FormAlert'
import AuthForm from './AuthForm'
import AuthSocial from './AuthSocial'
import AuthFooter from './AuthFooter'
import { useRouter } from './../util/router.js'

function Auth(props) {
  const router = useRouter()
  const [formAlert, setFormAlert] = useState(null)

  const handleAuth = (user) => {
    if(props.afterAuthPath === '/lesson' && props.type === 'signup'){
      router.push('/pricing')
    } else {
      router.push(props.afterAuthPath)
    }
  }

  const handleFormAlert = (data) => {
    setFormAlert(data)
  }

  return (
    <>
      {formAlert && (
        <FormAlert
          type={formAlert.type}
          message={formAlert.message}
        />
      )}

      <AuthForm
        type={props.type}
        typeValues={props.typeValues}
        inputSize={props.inputSize}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
      />

      {['signup', 'signin'].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <>
              <small className="text-center d-block my-3">OR</small>
              <AuthSocial
                type={props.type}
                buttonText={props.typeValues.buttonText}
                inputSize={props.inputSize}
                providers={props.providers}
                showLastUsed={true}
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: 'error',
                    message: message,
                  })
                }}
              />
            </>
          )}

          <AuthFooter
            type={props.type}
            typeValues={props.typeValues}
          />
        </>
      )}
    </>
  )
}

export default Auth
