import React from 'react'
import UserManagement from '../components_admin/UserManagement/UserManagement';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function StudentRosterPage() {
  return (<>
    <GoBack />
    <UserManagement />
  </>
  )
}

export default requireAuth(StudentRosterPage)
