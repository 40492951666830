import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '../util/router'
import { Stepper,Step,StepLabel,StepContent,Button,Paper,Grid,Typography } from '@material-ui/core';

import { redirectToAccount, loginToExpressAccount } from '../util/stripe.js'
import { useAuth } from '../util/auth'
import { useGetClassesByContractor, useGetContractorProfile } from '../util/db'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function VerticalLinearStepper() {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [connectStatus, setConnectStatus] = useState(false)
  const [partialStatus, setPartialStatus] = useState(false)
  const [partialProfile, setPartialProfile] = useState(false)
  const [profileReady, setProfileReady] = useState(false)
  const { data: classData } = useGetClassesByContractor(auth.user.uid)
  const { data: profileData } = useGetContractorProfile(auth.user.uid)

  const setupStripeConnect = () => {
    redirectToAccount()
  }

  const editTeacherProfile = () => {
    router.push('contractor-profile/edit')
  }

  const createNewClass = () => {
    router.push('contractor-classes/new')
  }

  const viewClasses = () => {
    router.push(`contractor-classes/${auth.user.uid}`)
  }

  const viewProfile = () => {
    router.push('contractor-profile/view')
  }

  useEffect(() => {
    if (profileData) {
      if (profileData.profilePhoto && profileData.profilePhoto !== '') {
        if (profileData.bio && profileData.bio !== '') {
          if (profileData.teacherName && profileData.teacherName !== '') {
            setProfileReady(true)
          }
        }
      }
      setPartialProfile(true)
    }
  }, [profileData])

  useEffect(() => {
    if (!connectStatus) {
      setActiveStep(0)
      return
    }
    //stripe connected but profile not ready
    if (connectStatus && !profileReady) {
      setActiveStep(1)
      return
    }
    //profile is ready
    if (profileReady) {
      setActiveStep(2)
      return
    }
    //Class has been created
    if (classData && classData.length > 0) {
      setActiveStep(3)
      return
    }

  }, [connectStatus, profileReady, classData])

  useEffect(() => {
    if (auth && auth.user) {
      console.log()
      if (auth.user.stripeConnectAccount && auth.user.stripeConnectAccount.charges_enabled) {
        setConnectStatus(true)
      } else if (auth.user.stripeConnectAccountId) {
        setPartialStatus(true)
      }
    }
  }, [auth, connectStatus, partialStatus])

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={'stripe-setup'}>
          <StepLabel>Setup payout account</StepLabel>
          <StepContent>
            <Typography>Identity verification and payout method. Let&apos;s make sure you get paid!</Typography>
            <div className={classes.actionsContainer}>
              <div>
                {connectStatus && <Typography> Your stripe account has been successfully linked!</Typography>}
                {!connectStatus ? <Button
                  variant="contained"
                  color="primary"
                  onClick={setupStripeConnect}
                  className={classes.button}
                >
                  {partialStatus ? 'Continue sign-up' : 'Setup payout method.'}
                </Button> :
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={setupStripeConnect}
                    className={classes.button}
                  >
                                        View account details
                  </Button>}
              </div>
            </div>
          </StepContent>
        </Step>
        <Step key={'profile-information'}>
          <StepLabel>Create your teacher profile</StepLabel>
          <StepContent>
            <Typography>Your teacher profile is shown to potential learners and their families and will help generate interest in your classes. A teacher profile is required before you can publish classes.</Typography>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={editTeacherProfile}
                  className={classes.button}
                >
                  {partialProfile ? 'Continue your profile' : 'Create your profile'}
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step key={'create-class'}>
          <StepLabel>Create your first class!</StepLabel>
          <StepContent>
            <Typography>Looks like you&apos;re ready to start teaching, click below to create your first class!</Typography>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createNewClass}
                  className={classes.button}
                >
                  {'Create new class!'}
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
      </Stepper>
      {activeStep === 3 && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Great work! Here are some helpful shortcuts: </Typography>
          <Grid container alignItems="flex-start" direction="column" justify="center">
            <Button
              variant="text"
              color="primary"
              onClick={createNewClass}
              className={classes.button}
            >
              {'Add New Class'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={loginToExpressAccount}
              className={classes.button}
            >
              {'Access Stripe Account'}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={viewClasses}
              className={classes.button}
            >
              {'View Classes'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={viewProfile}
              className={classes.button}
            >
              {'View Teacher Profile'}
            </Button>

          </Grid>
        </Paper>
      )}
    </div>
  );
}
