import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AvatarSection from '../components_student/AvatarSection'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  return (
    props.childList ? props.childList.map((child, index) =>
      <Card key={child.id} className={classes.root}>
        <CardContent>
          <AvatarSection picture={child.picture} />
          <Typography gutterBottom variant="h5" component="h2">
            {child.name}
          </Typography>
          <Typography gutterBottom variant="p" component="p">
                        birthdate: {child.birthdate}
          </Typography>
          <Typography gutterBottom variant="p" component="p">
                        email: {child.email}
          </Typography>

        </CardContent>

      </Card>) : null
  );
}