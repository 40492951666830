import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, FormControlLabel,Checkbox } from '@material-ui/core';
import Button from 'react-bootstrap/Button';
import { updateSemester, createSemester } from '../../util/db';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

const defaultValues = {
  midtermEnabled: true,
  semesterName: '',
  startDate: new Date(),
  endDate: new Date(),
  midterm: new Date(),
}

export default function SemesterDetails(props) {
  const [startDate, setStartDate] = useState(moment(props.data?.startDate || new Date()).format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment(props.data?.endDate || new Date()).format('yyyy-MM-DD'));
  const [midterm, setMidterm] = useState(moment(props.data?.midterm || new Date()).format('yyyy-MM-DD'));
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [warning, showWarning] = useState(false);

  const handleStartChange = (date) => {
    console.log('date', date)
    setStartDate( moment(date).utc().format('yyyy-MM-DD'));
    // let toSave = moment(date).format('yyyy-MM-DD')
    date.setHours(0,0,0,0);
    console.log('saved date:', date)
    setValues({ ...values, startDate: date })

  };

  const handleEndChange = (date) => {
    // setEndDate(date);
    setEndDate( moment(date).utc().format('yyyy-MM-DD'));
    // let toSave = moment(date).format('yyyy-MM-DD')
    date.setHours(0,0,0,0);
    setValues({ ...values, endDate: date })
  };

  const handleMidtermChange = (date) => {
    // setEndDate(date);
    setMidterm( moment(date).utc().format('yyyy-MM-DD'));
    // let toSave = moment(date).format('yyyy-MM-DD')
    date.setHours(0,0,0,0);
    setValues({ ...values, midterm: date })
  };

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues({ ...defaultValues, ...props.data });
    setStartDate(moment(props.data.startDate).format('yyyy-MM-DD'))
    setEndDate(moment(props.data.endDate).format('yyyy-MM-DD'))
    setMidterm(moment(props.data.midterm).format('yyyy-MM-DD'))
    showSuccess(false);
  }


  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleCheckChange = e => {
    const { name, checked } = e.target
    console.log('name', name, 'value', checked)
    setValues({ ...values, [name]: checked })
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    let toSave = {
      startDate: values.startDate,
      endDate: values.endDate,
      semesterName: values.semesterName,
      midterm: values.midterm,
      midtermEnabled: values.midtermEnabled,
    }
    console.log('to save:'.toSave)
    if (props.createNew) {
      await createSemester(toSave)
    } else {
      await updateSemester(props.data.id, toSave)
    }
    showSuccess(true)
  }



  useEffect(() => {
    resetValues();
  }, [props.data.id]);


  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Semester Updated!🥳
        </Alert>
        : null}
      { warning ?
        <Alert
          variant="warning"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Question text required!
        </Alert>
        : null}
      <Container key={props.data.id} className={'px-5'}>
        <Col>
          <h2>{values.semesterName || ''}</h2>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'text'} name="semesterName" value={values.semesterName} onChange={handleInputChange} label="Semester Name" />
          </Row>

          <Grid className="my-3">
                          
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Semester Start"
                format="yyyy-MM-dd"
                        
                value={startDate}
                onChange={handleStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Semester End"
                               
                format="yyyy-MM-dd"
                value={endDate}
                onChange={handleEndChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
                            
              {values.midtermEnabled !== false && <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Mid Term Date"
                               
                format="yyyy-MM-dd"
                value={midterm}
                onChange={handleMidtermChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />}

              <FormControlLabel
                labelPlacement="top"
                control={
                  <Checkbox
                    name="midtermEnabled"
                    onChange={handleCheckChange}
                    checked={values.midtermEnabled}
                  />}
                label="Midterm Enabled"
              />
                            
            </MuiPickersUtilsProvider>
          </Grid>

        </Col>
        <Col>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
      </Container>
    </>)
}
