import React, { useEffect, useState } from 'react'
import { Card,ListGroup,ListGroupItem,CardGroup } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner';

let WritingQuestions = [
  {
    standard: 1,
    question: 'q113_wfm-s1',
    title: 'Sit with your child, set out crayons and a piece of paper and ask them to write down or draw a story about their favorite food.',
    subtitle: 'For this standard we are trying to get a feel for how well your child understands the concept of putting  ideas and words onto paper in drawing or written form. This starts with simple drawings and seemingly nonsensical scribbles in the early stages. Later on, this transfers to actual words and eventually sentences and beyond. Please select the level at which your child marks stories, ideas, names etc on paper.',

  },
  {
    standard: 2,
    question: 'q114_wfm-s2',
    title: 'Have your child hold a writing utensil and draw. Select the their grip style below:',
    subtitle: 'This is to assess how your child holds a pencil, crayon, marker etc. Toddlers hold writing utensils with a full fist and they gradually grow into a mature pinching grip known as a pincer grasp.',
  },
  {
    standard: 3,
    question: 'q115_wfm-s3',
    title: 'Have your child cut a straight line with safety scissors on paper. Select their method below',
    subtitle: 'Holding scissors correctly is very important and can be one of the more difficult things to maintain in preschool. Children should use scissors that are the correct size for their little hands (safety scissors). They should put their thumb in the small hole and all of their other fingers together in the bigger hole. Their thumb should always stay facing up and they should use their other hand to hold what they’re cutting still. Children go from only being able to cut small fringes on paper to cutting spirals and many different shapes. ',
  },
  {
    standard: 4,
    question: 'q116_wfm-s4',
    title: 'Have your child write as much of their name as they can. Select how many below:',
    subtitle: 'Children often learn to write their name before finishing learning to write all of their letters. It starts with getting just one, then a few letters on the page. Eventually they can write all of the letters somewhere on the paper and  finally can put them in order and in a straight line from left to right.',
  },
  {
    standard: 5,
    question: 'q117_wfm-s5',
    title: 'Have your child write as many letters as they know in the alphabet not in their name. If you already have an idea of how many letters they know please select below:',
    subtitle: 'This does not need to be an exact number but if you’ve already been observing your child writing letters you can give your best approximation.',
  },
]


const WritingReport = (props) => {
  const [scores, setScores] = useState(props?.scores?.scores ? props.scores.scores : false)
  useEffect(() => {
    console.log(props.currentIndex)
    console.log('currentInd score', props.scores)
    props?.scores?.scores && setScores(props.scores.scores)
  },[props.currentIndex])

  useEffect(() => {
    props?.scores?.scores && setScores(props.scores.scores)
  }, [props.scores, setScores])

  console.log(props.answers)
  console.log('scores', props.scores)
  let writingAnswers = {};

  if (props.answers && props.answers.request && props.answers.request.rawRequest) {
    writingAnswers = props.answers.request.rawRequest
  }

  const renderAnswers = (answers) => {
    if (answers) {
      if (typeof answers === 'string') {
        answers = [answers]
      }

      return answers.map((resp, ind) => {
        let text = '';
        let image = '';
        let arr = resp.split('|');
        if (arr[0]) {
          text = arr[0]
        }
        if (arr[1]) {
          image = arr[1]
        }
        return <div key={'math ans' + ind}> {text} {image !== '' ? <img src={image} height="100" width="100" /> : null} </div>

      })
    } else {
      return 'no answer available for this question'
    }
  }

  return (
    <React.Fragment>
      {!scores && <Spinner animation="border" variant="primary" />}
      {scores && props?.scores?.scores && WritingQuestions.map((row) =>
        <React.Fragment key={row.title}>
          <CardGroup className={'my-4'}>
            <Card>
              <ListGroup>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>Learning Standard {row.standard}: <br /> {row.title}</Card.Title>
                  <div className={'text-secondary'}>{row.subtitle}</div>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>response:</Card.Title>
                  <div className={'text-center text-info'}>{renderAnswers(writingAnswers[row.question])}</div>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>Level:</Card.Title>
                  <div className={'text-center text-info'}>{props?.scores?.scores[row.standard]}</div>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </CardGroup>
        </React.Fragment >)}
    </React.Fragment>
  )
}

export default WritingReport
