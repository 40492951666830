import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { shortSchedule } from '../util/util.js';

const useStyles = makeStyles({
  root: {
    maxWidth: 445,
    margin: 5,
    width: '100%',
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [registered, setRegistered] = useState(false)
  const [classFull, setClassFull] = useState(false)

  let schedule = shortSchedule(props.data)



  let price = 0
  if (props.liveData) {
    price = props.data && props.data.listPrice ? '$' + (props.data.listPrice / 100).toFixed(2) + ' per week' : 'price not set'
  } else {
    price = props.data && props.data.classPrice ? '$' + props.data.classPrice.toFixed(2) + ' per week' : 'price not set'
  }

  const cardClick = () => {
    if (props.selectClass) {
      props.selectClass(props.data)
    }
  }

  useEffect(() => {
    if (props.registeredList && props.registeredList.includes(props.data.id)) {
      setRegistered(true)
    }
  }, [props.registeredList])

  useEffect(() => {
    if (props.data.registrations && props.data.registrations.length >= props.data.maxStudents) {
      setClassFull(true)
    }
  }, [props.data])



  return (
    <Grid container direction="column"
      justify="center"
      alignItems="flex-start" item >
      <Button onClick={cardClick}>
        <Grid  >
          <Paper className={classes.root}  >
            <Grid
              container
              direction="row-reverse"
              justify="space-between"
              alignItems="center" >
              <Grid className={'pl-1 pr-5'}>
                <Typography align="left" variant="subtitle1" color="textSecondary" >
                  {props.data.className}
                </Typography>
                <Typography align="left" variant="subtitle2" color="textSecondary" >
                  {props.data.timeString}
                </Typography>
                <Typography align="left" variant="subtitle2" color="textSecondary" >
                  {schedule}
                </Typography>

              </Grid>
              <Grid>
                <img width={70} height={70} src={props.data.classPhoto} />
              </Grid>
            </Grid>
          </Paper>
          <Typography className={'pt-1'} align="left" variant="subtitle1" color="textSecondary" >
            {price}
          </Typography>
          {registered && <Typography className={'pt-1'} align="left" variant="subtitle1" color="textSecondary" >
                        Registered!
          </Typography>}
          {classFull && <Typography className={'pt-1'} align="left" variant="subtitle1" color="textSecondary" >
                        Class Full!
          </Typography>}
        </Grid>
      </Button>
    </Grid>
  );
}