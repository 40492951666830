import React from 'react'
import CtaSection2 from '../components_public/CtaSection2'
import HeroSection3 from '../components_public/HeroSection3'
import HeroSection4 from '../components_public/HeroSection4'
import ClientsSection from '../components_public/ClientsSection'
import CtaSection from '../components_public/CtaSection'
import { useRouter } from '../util/router.js'

function NewsPage(props) {
  const router = useRouter()

  return (
    <>

      <HeroSection3
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Mighty Kids Press Room "
        subtitle="Working on a story... you've come to the right place."
      />
      <CtaSection2
        bg="dark"
        textColor="light"
        size="sm"
        bgImage="https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
        bgImageOpacity={0.8}
        title="Check out our news"
        subtitle=""
        buttonText="Explore"
        buttonColor="light"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push('/pricing')
        }}
      />
      <HeroSection4
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Recent Stories"
        subtitle=""
      />
      <ClientsSection
        bg="secondary"
        textColor="white"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="As seen on"
        subtitle=""
      />
      <CtaSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Download Mighty Kids Resources"
        subtitle="Grab the complete media packet which provides all the necessary logos and assets for the Mighty Kids brand. Just let us know if you need anything else."
        buttonText="Download Media Kit"
        buttonColor="info"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push('#')
        }}
      />
    </>
  )
}

export default NewsPage
