import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import Faq from './Faq'


function FaqSection(props) {
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Faq
          items={[
            // {
            //     question: 'How many days per week does my child receive instruction?',
            //     answer: 'A Your child will 3 receive new videos/printouts 3 days per week (Monday, Wednesday, Friday). They will be still be able to view and use their daily question of the day, weather, letter of the day, and calendar on the off days as well. We will also offer live 1/2 hour Zoom classes 3 days per week, for an additional cost.',
            // },
            {
              question: 'Who will be teaching my child and what are their qualifications? ',
              answer: 'Your child will receive content created by one of our experienced teachers. Our lead teacher has a bachelor’s degree in Early Childhood Education and our other teachers have a combination of bachelors, associate’s degrees and/or 20+ years of experience in the education field. They have worked in the public school system, special education, and private preschools. They have  received countless state trainings in the areas of ECE, social emotional development, developmentally appropriate practices and more. \n',
            },
            {
              question: 'What curriculum do you use?',
              answer: 'As for language and writing we rely heavily on the well known curriculum Writing Without Tears. For social/emotional learning, language and literacy and mathematics we use our own proprietary curriculum developed by our educated and experienced educators as well as Zoo-phonics. \n',
            },
            {
              question: 'How long will my child be on the computer each lesson day?',
              answer: 'Each video is about 5 minutes in length but is designed to be paused and the activities done both in conjunction with the video and independently or with an adult. Your child will not be using the computer the entire time they are practicing their lesson. Actual learning time will be longer than the length of the videos and will depend on the day. In person preschool classes have similar instructional times as time is largely filled up with free play, snack time, clean up, washing hands etc.',
            },
            {
              question: 'How do I contact you to get help with the assessment if I get stuck?',
              answer: 'Parents/ caregivers are encouraged to contact us anytime they need help explaining a concept or further help with administrating the assessment to their child. There is a contact section on our website and you will be able to talk to one of our educators anytime during business hours. \n',
            },
            {
              question: 'What if my child is already Kindergarten ready?',
              answer: 'We keep track of every child’s development and are ready with advanced content for the children who are already reaching Kindergarten readiness levels in many areas. They will also have the opportunity to keep practicing those skills in fun new ways while they await their Kindergarten year. \n',
            },
            {
              question: 'How much involvement does this program require from parents/caregivers?',
              answer: 'While we provide all of the instruction and assessments you will need, we do need parents and caregivers to partner with us to administrate the assessments, print off needed worksheets to go with lessons and help kiddos find the lessons of the day and sometimes be a partner for the children in learning games along with our videos. \n',
            },
            {
              question: 'Where did the questions on the assessment come from and why is it so long?',
              answer: 'The assessment is a compilation of standards from the NAEYC (National Association of Education of Young Children), the Department of Health (for motor and social/emotional)) and well-known curriculum Learning Without Tears. They are research based standards and tried and true ways of telling when a child is ready for Kindergarten. The assessment is long because being an online academy, we need to include all of the behind the scenes things teachers would normally be working on in class but aren’t necessarily always reflected on the report cards sent home to parents/caregivers. \n',
            },
            {
              question: 'What is your teaching philosophy?',
              answer: 'The heart of research on Early Childhood Education and Developmentally Appropriate Practices (DAP) keeps leading us back to a play-based approach. We have been collaborating diligently with our experts on creative ways to bring play-based learning into a digital environment. We have found several ways that this is possible; including learning games that can be done in conjunction with our videos, craft projects and experiments that reinforce what the children have been learning, and providing curriculum that can be done in conjunction with our lessons including Handwriting without tears, which is already largely play-based. We also know from our training that social/emotional development is an integral part of Kindergarten readiness and we incorporate it into our curriculum regularly. \n',
            },
          ]}
        />
      </Container>
    </Segment>
  )
}

export default FaqSection
