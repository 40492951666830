import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import { updateVideo, addNewSupply } from '../../util/db';
import { uploadVideoMaterials } from '../../util/firebaseStorage';
import { Multiselect } from 'react-widgets';
import 'react-widgets/styles.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { searchVimeo } from '../../util/videoLogic';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

const formatSupplyList = (list) => {
  if (!list) {
    return []
  }
  let newArray = [];
  list.map((row) => {
    newArray.push(row.id);
  });
  return newArray;
}

const defaultValues = {
  id: 0,
  Name: '',
  Supplies: [],
  OptionalSupplies: [],
  Prep: '',
  Description: '',
  URL: '',
  'Download URL': '',
}
export default function VideoDetails(props) {
  const [values, setValues] = useState(defaultValues);
  const [supplySelectOptions, setSupplySelectOptions] = useState(formatSupplyList(props.supplyList));
  const [success, showSuccess] = useState(false);
  const [vimeoLink, setVimeoLink] = useState(false);

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues(() => {
      return { ...defaultValues, ...props.data }
    });
    showSuccess(false);
    setVimeoLink(false);
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const updateSupplies = (supplies) => {
    setValues({ ...values, Supplies: supplies });
  }

  const updateOptionalSupplies = (optionalSupplies) => {
    setValues({ ...values, OptionalSupplies: optionalSupplies });
  }

  const saveChanges = async (e) => {
    e.preventDefault();

    showSuccess(false);
    let results = await updateVideo(props.data.id, {
      Name: values.Name,
      URL: values.URL,
      ['Download URL']: values['Download URL'],
      Supplies: values.Supplies,
      OptionalSupplies: values.OptionalSupplies,
      Description: values.Description,
      Prep: values.Prep,

    })
    showSuccess(true)
    setVimeoLink(false)

  }

  const createSupply = async (text) => {
    await addNewSupply(text)
    setSupplySelectOptions([...supplySelectOptions, text]);
    setValues({ ...values, Supplies: [...values.Supplies, text] });
  }

  const fileUpload = async (event) => {
    const file = event.target.files[0];
    let downloadURL = await uploadVideoMaterials(file);
    setValues({ ...values, 'Download URL': downloadURL });
  }

  useEffect(() => {
    resetValues();
  }, [props.data.id]);

  useEffect(() => {
    if (values.URL === null || values.URL === '' && values.id != 0) {
      const asyncFetchVimeoData = async () => {
        const vidData = await searchVimeo(props.data); // fetchDailyData() is calling Api 
        console.log('vimeo', vidData)
        if (vidData && vidData.link) {
          let playerURL = vidData.link.replace('vimeo.com', 'player.vimeo.com/video')
          setValues((val) => {
            if (!val.URL || val.URL === '') {
              setVimeoLink(true);
              return { ...val, 'URL': playerURL }
            } else {
              setVimeoLink(false);
              return val

            }
          });

        }
      }
      try {
        asyncFetchVimeoData();
      } catch (e) {
        console.log('error fetching vimeo')
      }
    }
  }, [props.data.id, values]);

  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Video Updated!🥳
        </Alert>
        : null}
      { vimeoLink ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Vimeo match found!
        </Alert>
        : null}
      <Container key={props.data.id} className={'px-5'}>
        <Col>
          <h1>{props.data.id}</h1>
          <h2>{props.data.Name || ''}</h2>
          {values.name}

          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'name'} name="Name" value={values.Name} onChange={handleInputChange} label="Video Name" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'prep'} name="Prep" value={values.Prep} onChange={handleInputChange} label="Video Prep" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'description'} name="Description" value={values.Description} onChange={handleInputChange} label="Description" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth name="URL" value={values.URL} onChange={handleInputChange} label="Video URL" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth name="Download URL" value={values['Download URL']} onChange={handleInputChange} label="Download URL" />
          </Row>
          <Row className="my-3">
            <input type="file" id="myFile" multiple size="50" onChange={fileUpload} />
          </Row>
        </Col>
        <Col>
          <Row className="mt-4" style={{ display: 'block' }}>
            <h3>Supplies</h3>
            <Multiselect
              data={supplySelectOptions}
              value={values.Supplies}
              name="Supplies"
              onChange={updateSupplies}
              allowCreate
              onCreate={createSupply}
            />
          </Row>
          <Row className="mb-2" style={{ display: 'block' }}>
            <h3>Optional Supplies</h3>
            <Multiselect
              data={supplySelectOptions}
              value={values.OptionalSupplies}
              name="Optional Supplies"
              onChange={updateOptionalSupplies}
              allowCreate
              onCreate={createSupply}
            />

          </Row>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
      </Container>
    </>)
}
