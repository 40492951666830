import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Typography, Grid } from '@material-ui/core';
import InputBase from '@mui/material/InputBase';
import ClockIcon from '@material-ui/icons/AccessTime';
import AgesIcon from '@material-ui/icons/SupervisorAccount';
import BlockIcon from '@material-ui/icons/Block';
import DateRange from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import SpecialtyFilterControl from './SpecialtyFilterControl';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function SearchAppBar(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar style={{ color: 'white' }}>
          <Grid className="mt-1" container direction="row" justifyContent="space-around" alignItems="center">
            <Grid className="">
              <Typography noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                {props.filtersActive ? (
                  <Button variant={'contained'} onClick={props.clearFilters} style={{ color: 'black' }}>
                    {'Clear Filters '}
                    <BlockIcon />
                  </Button>
                ) : (
                  'Filter By:'
                )}
              </Typography>
            </Grid>
            <Grid>
              <Button
                variant={props.timesFilterActive ? 'contained' : 'text'}
                className="px-4"
                style={{ color: props.timesFilterActive ? 'black' : 'white' }}
                onClick={() => props.setShowTimesModal(true)}
              >
                <ClockIcon />

                <Typography
                  className="pt-1 ml-2"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  Class Times
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={props.agesFilterActive ? 'contained' : 'text'}
                className="px-4"
                style={{ color: props.agesFilterActive ? 'black' : 'white' }}
                onClick={() => props.setShowAgesModal(true)}
              >
                <AgesIcon />

                <Typography
                  className="pt-1 ml-2"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  Ages
                </Typography>
              </Button>
            </Grid>
            <Grid>
              <Button
                variant={props.calendarFilterActive ? 'contained' : 'text'}
                className="px-4"
                style={{ color: props.calendarFilterActive ? 'black' : 'white' }}
                onClick={() => props.setShowCalendarModal(true)}
              >
                <DateRange />

                <Typography
                  className="pt-1 ml-2"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  Start Date
                </Typography>
              </Button>
            </Grid>
            <SpecialtyFilterControl specialties={props.specialties} setSpecialties={props.setSpecialties} />
            <Grid>
              <Search style={{ marginLeft: 'auto' }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  onChange={(event) => props.setTextFilter(event.target.value)}
                  placeholder="Search…"
                  value={props.textFilter}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
