import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import 'fontsource-londrina-outline'
import 'fontsource-raleway-dots'
import { useRouter } from '../util/router';
import { Typography } from '@material-ui/core'


function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const NameTrace = ({ myName }) => {
  const router = useRouter()

  return (<React.Fragment>
    <style type="text/css">{
      `.strikeout {
                      font-size: 4em;
                      position: relative;
                    }
                    .strikeout::after {
                      border-bottom: 0.125em dashed rgba(255, 0, 0, 0.5);
                      content: "";
                      left: 0;
                      line-height: 1em;
                      margin-top: calc(0.125em / 2 * -1);
                      position: absolute;
                      right: 0;
                      top: 50%;
                    }`}
    </style>
    <Typography align="center" variant="h3">
                  Excercise #{router.query.level}
    </Typography>
    <Typography className="pt-3" align="center" variant="h5">
                    Print and practice with your child
    </Typography>
        
    {router.query.level === '2' && <><Row className="justify-content-center" style={{ paddingTop: 100 }}>
      <div style={{ fontSize: 20 }}>I can decorate my name! Use crayons and markers to decorate your name! </div>
    </Row>
    <Row className={'justify-content-center'} >
      {myName && myName.split(' ')[0].split('').map(letter =>
        <Col key={letter} className={'justify-content-center'} style={{ padding: 5 }}>
          <Row className={'justify-content-center'}>
            <div className={'text-center'}  ><span style={{ fontFamily: 'Londrina Outline', fontSize: '19vh', paddingRight: 5 }}>{letter}</span></div>
          </Row>
          <Row className={'justify-content-center'}>
            <div className={'text-center'} style={{ fontFamily: 'Roboto' }}>
              <i style={{ fontSize: 40 }} className={'fas fa-circle'}/>
            </div>
          </Row>
        </Col>)}
    </Row>
    </>}

    {router.query.level === '3' && <>
      {/*<hr style={{ borderTop: '1px solid' }}/>*/}
      <Row className="justify-content-center pt-2 pb-5">
        <div style={{ fontSize: 20 }}>I can trace my name!</div>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: 0, paddingTop: 0 }}>
        <div style={{ marginTop: 0, paddingTop: 0 }}>
          <span className={''} style={{ maxHeight: 150, fontFamily: 'Raleway Dots', fontSize: 225, marginTop: 0, marginBottom: 0, letterSpacing: 7 }}>{myName.split(' ')[0]}</span>
        </div>
        <Col xs={12} className={'mb-5 pb-5'} style={{ height: 250, border: '1px solid' }}/>
        <Col xs={12} className={'mb-5 pb-5'} style={{ height: 250, border: '1px solid' }}/>
        <Col xs={12} className={'mb-5 pb-5'} style={{ height: 250, border: '1px solid' }}/>
      </Row></>}

    {/*<hr style={{ borderTop: '1px solid' }}/>*/}
    {router.query.level === '' && <>
      <Row className="justify-content-center  pt-5">
        <div style={{ fontSize: 20 }}>I can trace my name!</div>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: 0, paddingTop: 0, paddingBottom: 100 }}>
        <div style={{ marginTop: 0, paddingTop: 0 }}>
          <span className={''} style={{ maxHeight: 150, fontFamily: 'Raleway Dots', fontSize: 225, marginTop: 0, marginBottom: 0, letterSpacing: 7 }}>{myName.split(' ')[0]}</span>
        </div>

      </Row>
    </>}

    {router.query.level === '4' && <>
      <Row className="justify-content-center  py-5">
        <div style={{ fontSize: 20 }}>Name puzzle, cut out each letter and have your child spell their name!</div>
      </Row>
   
      <Row className={'pb-5'}>
        {myName.split(' ')[0].split('').map((letter,index) => <Col key={index + 'blanks'} style={{ '-webkit-print-color-adjust': 'exact', height: 250, fontSize: '15vh', border: '1px solid black', color: 'white', lineHeight: 1 }} >{letter}</Col>)}
      </Row>
      <Row className={'align-items-center'}>
                
        {shuffle(myName.split(' ')[0].split('')).map((letter,index) => <Col key={index + 'cutout'} className={'align-items-center'} style={{ height: 250, fontSize: '15vh', border: '1px dashed', lineHeight: '250px' }}>{letter}</Col>)}
      </Row></>}


    {router.query.level === '1' && <>
      <Row className="justify-content-center  pt-5 pb-5">
        <div style={{ fontSize: 20 }}>I can trace the first letter of my name!</div>
      </Row>
      <Row className="justify-content-center" >
        <Col xs={12} className={'text-center'}>
          <span className={''} style={{ fontFamily: 'Londrina Outline', fontSize: '40vh', lineHeight: .8 }}>{myName.split(' ')[0].split('')[0].toUpperCase()}</span>
        </Col>
        <Col xs={12} className={'text-center'}>
          <span className={''} style={{ fontFamily: 'Londrina Outline', fontSize: '40vh', lineHeight: .8 }}>{myName.split(' ')[0].split('')[0].toLowerCase()}</span>
        </Col>
      </Row>
    </>}
  </React.Fragment>)
}
export default NameTrace