import React, { useEffect, useState } from 'react'
import ClassDetail from '../components_contractor/ClassDetail'
import ClassList from '../components_contractor/ClassesList'
import { requireAuth, useAuth } from '../util/auth.js'
import { useRouter } from '../util/router'
import CssBaseline from '@material-ui/core/CssBaseline';
import { setClass, useGetContractorClass } from '../util/db'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'

function ProfilePage(props) {
  const router = useRouter()
  const [mode, setMode] = useState('new')
  const [contractorId, setContractorId] = useState()
  const [classId, setClassId] = useState()
  const auth = useAuth()



  const { data: classData } = useGetContractorClass(contractorId, classId)

  useEffect(() => {
    const classId = router.query.classId;
    const contractorIdQuery = router.query.contractorId;
    const modeQuery = router.query.mode;
    if (contractorIdQuery === 'new') {
      setContractorId(auth.user.uid)
      setMode('new')
      return
    }

    if (!contractorIdQuery) {
      setMode('list')
      setContractorId(auth.user.uid)
      return
    }

    if (classId === 'new') {
      setMode('new')
      setContractorId(contractorIdQuery)
      return
    }

    if (!classId) {
      setMode('list')
      setContractorId(contractorIdQuery)
      return
    }
    if (classId && !modeQuery) {
      setMode('view')
      setClassId(classId)
      setContractorId(contractorIdQuery)
      return
    }
    if (classId && modeQuery) {
      setClassId(classId)
      setMode(modeQuery)
      setContractorId(contractorIdQuery)
      return
    }
  }, [auth, router])

  return (<>
    <React.Fragment>
      <CssBaseline />
      <Grid container><Button onClick={router.goBack} variant="text">Back</Button></Grid>
      {mode !== 'list' && <ClassDetail
        classId={classId}
        editMode={mode}
        contractorId={contractorId}
      />}
      {mode === 'list' && <ClassList contractorId={contractorId} />}
    </React.Fragment>
  </>
  )
}

export default requireAuth(ProfilePage)
