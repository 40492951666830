import React from 'react'
import SectionHeader from '../components/SectionHeader'
import Container from 'react-bootstrap/Container'
import Daily from './Daily'

function DailySection(props) {
  return (
    <>
      <SectionHeader
        style={{ paddingTop: 10 }}
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
      <Container className={'pb-4'}>
        <Daily/>
      </Container>
    </>
  )
}

export default DailySection
