import React, { useState, useEffect } from 'react'
import { Modal,Button,Spinner,Form, Alert } from 'react-bootstrap'
import { useAuth } from '../util/auth'
import { useForm } from 'react-hook-form'

import FormField from '../components/FormField'

import { createChild, updateChild } from '../util/db'
import { newChildSchema } from '../schema'
import AvatarSelector from '../components_student/AvatarSelector'

const NewChild = ({ show = false, setShow,setCurrentChild,currentChild, ...props }) => {
  const auth = useAuth()
  const [pending, setPending] = useState(false)
  const [saved, setSaved] = useState(false)
  // const [name,setName] = useState(currentChild.name)
  // const [birthdate,setBirthdate] = useState(currentChild.birthdate)
  // const [picture, setPicture] = useState(currentChild.picture)
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues: { ...currentChild } })

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true)
    if (!props.create) {
      console.log('update data:',data)
      updateChild(newChildSchema({ owner: auth.user.uid, ...data }), currentChild.id)
      // if (childProfile && childProfile.id === childProfile.id) setChildId(id)
      setSaved(true)
      setPending(false)
    } else {
      createChild(newChildSchema({ owner: auth.user.uid, email: auth.user.email, ownerName: auth.user.name || '', ...data }))
      setSaved(true)
      setPending(false)
    }
    setTimeout(()=> {
      setSaved(false)
      setShow(false)
    },1000)
        
  }

  const setPicture = (val) => {
    setCurrentChild({ ...currentChild, picture: val })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setCurrentChild({ ...currentChild, [name]: value })
    // if(name === 'name') setName(currentChild.name);
    // if(name === 'picture') setPicture(currentChild.picture);
    // if(name === 'birthdate') setBirthdate(currentChild.birthdate);
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
        setSaved(false)
      }}
      container={document.body}
    >
      <Modal.Body>
        <div className={'text-center'}><h2>Child Profile</h2></div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <AvatarSelector current={currentChild.picture} setValue={setValue} setPicture={setPicture} />
          {/*<AvatarSelection picture={picture}/>*/}
          <input type="hidden" ref={register} name="picture" value={currentChild.picture} />


          {/*<img src={auth.user.picture} alt={auth.user.name} width="100" height="100" />*/}
          <Form.Group controlId="formName">
            <FormField
              name="name"
              type="text"
              label="Name"
              value={currentChild.name}
              onChange={handleInputChange}
              placeholder="Name"
              error={errors.name}
              inputRef={register({
                required: 'Please enter the child\'s name',
              })}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            {/*<DatePickerComponent />*/}
            <FormField
              name="birthdate"
              type="date"
              label="Birthdate"
              onChange={handleInputChange}
              value={currentChild.birthdate}
              placeholder="Birthdate"
              error={errors.birthdate}
              inputRef={register({
                required: 'Please enter a birthdate',
              })}
            />
          </Form.Group>
          <Button type="submit" disabled={pending}>
            <span>Save</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Sending...</span>
              </Spinner>
            )}
          </Button> {saved && <Alert className={'mt-3'} variant={'success'}>Saved!</Alert>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="md" onClick={() => {
          setShow(false)
          setSaved(false)
        }}>
                    Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewChild