import React from 'react'
import useSound from 'use-sound'
import DateComponent from '../components/Date'
import { getSup } from '../util/util'
import { days, months } from '../util/util'
import { daysAudio, datesAudio, monthsAudio, todayIs, yearsAudio } from '../util/audioFiles';


// import fanfareSfx from '../../sounds/fanfare.mp3';

const FanfareButton = () => {
  const [play, { stop, pause, isPlaying }] = useSound('https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2FDATE-WED-SEPT-2-2020.mp3?alt=media&token=e936baec-8cb8-4f61-8de0-5c8ca82f2dcd')

  return (
    <button onMouseEnter={play} onMouseLeave={stop}>
      <span role="img" aria-label="trumpet">
        🎺
      </span>
    </button>
  )
}

function TodayIsCard(props) {
  const d = new Date()
  const dayName = days[d.getDay()]
  const monthName = months[d.getMonth()]
  const day = getSup(d.getDate())
  const year = d.getFullYear()
  const [dayAudio, { isPlaying: dayPlaying }] = useSound(daysAudio[d.getDay()], { volume: d.getDay() === 0 ? 0.5 : 1 })
  const [today, { isPlaying: todayPlaying } ] = useSound(todayIs)
  const [yearAudio, { isPlaying: yearPlaying }] = useSound(yearsAudio[year])
  const [numberAudio, { isPlaying: numPlaying }] = useSound(datesAudio[d.getDate()])
  const [monthAudio, { isPlaying: monthPlaying }] = useSound(monthsAudio[d.getMonth()])

  return <DateComponent
    playing={{ yearPlaying, todayPlaying, dayPlaying, numPlaying, monthPlaying }}
    year={yearAudio}
    today={today}
    day={dayAudio}
    number={numberAudio}
    month={monthAudio}
    date={`${dayName}, ${monthName} ${day}, ${year}`}
  />
}

export default TodayIsCard


