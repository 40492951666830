import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SectionHeader from '../components/SectionHeader'
import './Emotion.scss'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useGetDailyEmotionAnswer, addDailyEmotion } from '../util/db'
import { useChildContext } from '../util/childProfileContext'


function Emotion(props) {
  const { childProfile } = useChildContext();
  const [feeling, setFeeling] = useState({ icon: 'question-circle', name: 'Unknown', color: 'secondary' })
  const [showFeeling, setFeelingShow] = useState(false)
  const useAudio = (url) => {
    const [audio] = useState(new Audio(url))
    const [playing, setPlaying] = useState(false)

    const toggle = (bool) => bool === false ? setPlaying(false) : setPlaying(!playing)

    useEffect(() => {
      playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false))
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false))
      }
    }, [])

    return [playing, toggle]
  }

  const [playing, toggle] = useAudio(props.url)

  const { data: prevFeeling, status: feelingStatus } = useGetDailyEmotionAnswer(childProfile?.id);

  const response = (emotion) => {
    setFeeling(emotion)
    addDailyEmotion(childProfile.id,
      {
        emotion,
        owner: childProfile.owner,
      });
  }

  useEffect(() => {
    if (feelingStatus !== 'loading' && prevFeeling) {
      setFeeling(prevFeeling.emotion);
    }
  }, [prevFeeling]);


  return (
    <>
      <Modal
        show={showFeeling}
        onHide={() => { setFeelingShow(false); toggle(false) }}
        size="lg"
        container={document.body}
      >
        <Modal.Body>
          <Row style={{ fontSize: 100 }}>
            <Col xs={12}>
              <h2>How are you feeling today?</h2>
            </Col>
          </Row>
          <Row className="text-center" xs={3} md={3} lg={3} xl={3} style={{ fontSize: 100 }}>
            <Col xs={4} md={4} lg={4} xl={4} style={{ maxHeight: 20 }}>
              <div onClick={() => { response({ icon: 'smile', name: 'Happy' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }}>Happy</span>
                <span className="icon">
                  <i className="ModalLauncher__orangeColor fas fa-smile" />
                </span>
              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'angry', name: 'Angry' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Angry</span>
                <span className="icon">
                  <i className="ModalLauncher__redColor fas fa-angry" />
                </span>
              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'frown', name: 'Sad' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Sad</span>
                <span className="icon">
                  <i className="ModalLauncher__blueColor fas fa-frown" />
                </span>
              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'frown-open', name: 'Scared' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Scared</span>
                <span className="icon">
                  <i className="ModalLauncher__greenColor fas fa-frown-open" />
                </span>

              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'laugh', name: 'Excited' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Excited</span>
                <span className="icon">
                  <i className="text-warning fas fa-laugh" />
                </span>

              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'surprise', name: 'Surprised' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Surprised</span>
                <span className="icon">
                  <i className="ModalLauncher__purpleColor fas fa-surprise" />
                </span>

              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'meh', name: 'Shy' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Shy</span>
                <span className="icon">
                  <i className="ModalLauncher__brownColor fas fa-meh" />
                </span>


              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'grin-tongue-wink', name: 'Silly' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Silly</span>
                <span className="icon">
                  <i className="text-warning fas fa-grin-tongue-wink" />
                </span>

              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => { response({ icon: 'flushed', name: 'Embarrassed' }); setFeelingShow(false); toggle(false) }}>
                <span style={{ fontSize: 20 }} className="text-center">Embarrassed</span>
                <span className="icon">
                  <i style={{ color: 'pink' }} className="fas fa-flushed" />
                </span>

              </div>
            </Col>
            {/*<Col>*/}
            {/*    <div onClick={() => setFeeling('white') && setShow(false)}>*/}
            {/*        <span className="icon">*/}
            {/*            <i className="ModalLauncher__whiteColor fas fa-square" />*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</Col>*/}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="md" onClick={() => { setFeelingShow(false); toggle(false) }}>
                        Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className={'shadow-lg  bg-white rounded text-center h-100'} style={{ cursor: 'pointer' }} onClick={() => { setFeelingShow(true); toggle() }}>
        <Card.Body>
          <Row>
            <Col md={12} lg={4}>

              <span className="icon">
                <i className={`Emotion__no-classname fas text-${feeling && feeling.color ? feeling.color : 'warning'} warning fa-${feeling.icon}`} />
              </span>
            </Col>
            <Col xs={12} xl={8} lg={8} md={12}>
              <Card.Title>Today&apos;s Emotion</Card.Title>
              <Card.Text
                style={{
                  fontSize: 35,
                }}
              >
                {feeling.name}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${playing ? 'up' : 'off'}`} /></sub>
        </Card.Footer>
      </Card>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
    </>
  )
}

export default Emotion
