import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { DataGrid } from '@material-ui/data-grid';
import InputLabel from '@material-ui/core/InputLabel';
import { useGetAvailableZoomMeetings ,removeStudentFromZoomClass } from '../../util/db';
import 'react-widgets/styles.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { apiRequest } from '../../util/util';
import { useRouter } from '../../util/router';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

// Name
// Age
// Birthdate
// Parent
// Email
export default function ClassDetails(props) {
  const router = useRouter()
  const [selectedChildren, setSelectedChildren] = useState([])
  const [moveToMeeting, setMoveToMeeting] = useState()
  const [fullStudentDetails, setFullDetails] = useState()

  // const [productId, setProductId] = useState
  const { data: compatibleClasses } = useGetAvailableZoomMeetings(props.productId, props.semesterId)

  const [emailList, setEmailList] = useState('')
  let rowCount = 0;
  const columns = [
    { field: 'name', headerName: 'Name', flex: 2, renderCell: function NameButton(props){ 
      return (
      
        <Button onClick={()=>{ router.push('/admin/manage-students/'+ props.row.id)}} fullWidth
          variant="text"
          color="primary"
          size="small"
        >
          {props.row.name}
        </Button>
             
      ) 
    } },
    {
      field: 'age',
      headerName: 'Age',
      description: 'age',
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        return moment().diff(params.getValue('birthdate'), 'years')
      },
    },
    { field: 'birthdate', headerName: 'birthdate', flex: 1 },
    { field: 'ownerName', headerName: 'Parent', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },

  ];


  useEffect(() => {
    if (props.studentList) {
      let emails = props.studentList.map(student => student.email)
      setEmailList(emails.join(','))
    }
  }, [props.studentList])

  useEffect(() => {
    console.log(selectedChildren)
    if (selectedChildren && props.studentList) {
      let fullDetails = props.studentList.filter(val => selectedChildren.includes(val.id))
      console.log('full Details', fullDetails)
      setFullDetails(fullDetails)
    }
  }, [selectedChildren, props.studentList])

  const switchClassFunction = async () => {
    console.log('move to meeting', moveToMeeting)
    //let res = registerForClass(uid, childProfile.id, subscriptionId, zoomClass.id)
    fullStudentDetails.forEach(async child => {

      let data = { childId: child.id, owner: child.owner, oldClassId: props.currentClassDetails.id, newClassId: moveToMeeting }
      console.log('move data:', data)
      await apiRequest('private/switch-class', 'POST', data)
    })

  }

  const removeFromClass = () => {
    selectedChildren.forEach((child)=>{
      console.log(removeStudentFromZoomClass(props.currentClassDetails.id,child))
    })
  }

  return (
    <>
      <Container >


        {props.studentList && props.studentList.length > 0 ? <>
          <Row>
            <Col><Row> <Button className={'m-1'} variant="contained" color="primary" href={`mailto:${emailList}`}>
                            Email Class
            </Button></Row> </Col>
            <Col><Row> <Button className={'m-1'} variant="contained" color="primary" target="_blank" href={`${props.currentClassDetails?.zoomStartURL}`}>
                            Zoom Start Link
            </Button></Row> </Col>
            {selectedChildren && selectedChildren.length > 0 && <Row >
              <Col>
                <Button onClick={removeFromClass} className={'m-1'} variant="contained" color="secondary">
                            Remove from Class
                </Button>
              </Col>
              <Col>
                <InputLabel id="move-to">Move selected students to:</InputLabel>
                <Select
                  labelId="move-to"
                  value={moveToMeeting}
                  onChange={(rowClass, value) => {
                    console.log(value)
                    setMoveToMeeting(value.props.value)
                  }
                  }
                >
                  {
                    compatibleClasses?.length > 0 && compatibleClasses.map((val) => {
                      return <MenuItem key={val.className} value={val.id}>{`${val.className} ${val.teacherName} ${val.timeSlot}`}</MenuItem>
                    })
                  }
                </Select>
              </Col>
            </Row>}

            {moveToMeeting && <Col> <Button onClick={switchClassFunction} className={'m-1'} variant="contained" color="secondary">
                            Move selected children
            </Button> </Col >}

          </Row>
          <Row>
            <div style={{ height: 625, width: '100%' }}>
              <DataGrid
                rows={props.studentList}
                columns={columns}
                pageSize={10}
                checkboxSelection
                onSelectionChange={(newSelection) => {
                  setSelectedChildren(newSelection.rowIds);
                  // console.log(newSelection)
                }}
              />
            </div>
          </Row>
        </>

          : <p>No students registered</p>}
      </Container>
    </>)
}
