import React from 'react'
import ReportSection from '../components_student/ReportSection'
import { useRouter } from '../util/router.js'
import { requireAuth } from '../util/auth.js'

function ReportPage(props) {
  const router = useRouter()
  return (
    <>
      <ReportSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  )
}

export default requireAuth(ReportPage)