import React, { useState, useEffect } from 'react'
import { Button,Alert,Row,Col } from 'react-bootstrap'
import { redirectToBilling } from '../util/stripe.js'
import { useAuth } from '../util/auth'


function UpdateBilling(props) {
  const [isVisible, setVisible] = useState(false)

  const buttonClick = () => {

    redirectToBilling(window.location.href)
  }
  const auth = useAuth()

  useEffect(() => {
    if (auth.user.updateBilling) {
      setVisible(true);
    } else {
      setVisible(false)
    }
  })
  return (
    <>
      { isVisible && <Alert className="mt-5" key="1" variant="warning">
        <Row className="align-items-center">
          <Col sm="10"><div className="left d-flex">There was a problem billing your account. Please check your billing information.</div></Col>
          <Col sm="2"> <div className="right"><Button onClick={buttonClick} variant="warning">Fix now</Button></div></Col>
        </Row>
      </Alert>}
    </>
  )
}

export default UpdateBilling
