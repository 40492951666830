import React, { useState, useEffect } from 'react'
import Segment from '../components/Segment'
import ReauthModal from '../components/ReauthModal'
import ReportsNav from './ReportsNav'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import moment from 'moment'
import FormAlert from '../components/FormAlert'
import MathReport from './ReportsMath'
import WritingReport from './ReportsWriting'
import LanguageReport from './ReportsLanguage'
import { useAuth } from '../util/auth.js'
import { useAssessments, useScores } from '../util/db'
import { useChildContext } from '../util/childProfileContext'

function SettingsSection(props) {
  const { childProfile } = useChildContext()
  const [currentIndex, setIndex] = useState(0)
  const { data: writing, status: writeStatue, error: writeError } = useAssessments(childProfile.id, 'writing')
  const { data: language, status: langStatue, error: langError } = useAssessments(childProfile.id, 'language')
  const { data: math, status: mathStatue, error: mathError } = useAssessments(childProfile.id, 'math')
  const { data: writingScores, status: wScoreStatus, error: wScoreError } = useScores(childProfile.id, 'writing')
  const { data: languageScores, status: lScoreStatus, error: lSCoreError } = useScores(childProfile.id, 'language')
  const { data: mathScores, status: mScoreStatus, error: mScoreError } = useScores(childProfile.id, 'math')
  const [numberOfAssessments, setNumberOfAssessments] = useState(0)

  const auth = useAuth()
  const [formAlert, setFormAlert] = useState(null)

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  })

  const validSections = {
    math: true,
    writing: true,
    language: true,
  }

  const previousClick = () => {
    if (currentIndex < (numberOfAssessments - 1)) {
      setIndex(currentIndex + 1)
    }
  }

  const latestClick = () => {
    console.log('latest click', currentIndex)
    setIndex(0)
  }

  useEffect(() => {
    if (math && math.length) {
      console.log('math assessment', math)
      setNumberOfAssessments(math.length)
    }
  }, [math, numberOfAssessments])

  const section = validSections[props.section] ? props.section : 'math'
  console.log(section)
  if (!section) {
    this.props.router.push('/math')
  }
  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === 'requires-recent-login') {
      // First clear any existing message
      setFormAlert(null)
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      })
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      })
    }
  }

  console.log(numberOfAssessments)

  return (
    <>
      <a id="top" />
      <Segment
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <h1 className="text-center">Assessment Results</h1>
        <h4 className="text-center">{writingScores && writingScores.length > 1 ? `(${writingScores.length} Assessments available)` : null}</h4>
        {/*{console.log(Object.entries(auth.user))}*/}
        {reauthState.show && (
          <ReauthModal
            callback={reauthState.callback}
            provider={auth.user.providers[0]}
            onDone={() => setReauthState({ show: false })}
          />
        )}

        <ReportsNav
          childId={childProfile.id}
          activeKey={section}
          defaultActiveKey="/reports/math"
          className="justify-content-center"
        />
        {numberOfAssessments > 1 ?
          <Row className="my-4 justify-content-md-center" >
            <Button onClick={previousClick} disabled={!(currentIndex < numberOfAssessments - 1)} className="mx-1" variant="primary" size="md">
                            Previous
            </Button>
            <Button onClick={latestClick} disabled={(currentIndex === 0)} className="mx-1" variant="primary" size="md">
                            Latest
            </Button>
          </Row> : null}
        <Container
          className="mt-5"
          style={{
            maxWidth: section !== 'billing' ? '450px' : undefined,
          }}
        >
          {formAlert && (
            <FormAlert
              type={formAlert.type}
              message={formAlert.message}
              className="mx-auto mb-4"
              style={{ maxWidth: '450px' }}
            />
          )}
          {section === 'math' && math && (
            <>
              <h4 className="text-center">{moment(math[currentIndex].created.toDate()).format('MMM Do YYYY').toString()}</h4>
              <MathReport scores={mathScores ? mathScores[currentIndex] : 0} answers={math ? math[currentIndex] : 0} currentIndex={currentIndex} onStatus={handleStatus} />

            </>
          )}
          {section === 'language' && language && (
            <>
              <h4 className="text-center">{moment(language[currentIndex].created.toDate()).format('MMM Do YYYY').toString()}</h4>
              <LanguageReport scores={languageScores ? languageScores[currentIndex] : 0} answers={language ? language[currentIndex] : 0} currentIndex={currentIndex} onStatus={handleStatus} />
            </>
          )}

          {section === 'writing' && writing && (
            <>
              <h4 className="text-center">{moment(writing[currentIndex].created.toDate()).format('MMM Do YYYY').toString()}</h4>
              <WritingReport scores={writingScores ? writingScores[currentIndex] : 0} answers={writing ? writing[currentIndex] : 0} currentIndex={currentIndex} onStatus={handleStatus} />
            </>

          )}
          <Row className={'justify-content-center my-4'}><a href={'#top'}>scroll back to top</a></Row>

        </Container>
      </Segment>
    </>
  )
}

export default SettingsSection
