import React, { useEffect, useState } from 'react'
import { useAuth, requireAuth } from '../util/auth.js'
import { useRouter } from '../util/router'
import { apiRequest } from '../util/util';
import PaymentPortal from '../components_parent/PaymentPortal/PaymentPortal'
import { Container,Grid,CircularProgress, Button, Switch } from '@material-ui/core';
import { conversionsEvent } from '../util/util';
import queryString from 'query-string';
import { useGetSemester, useGetMightyKidsPlan, useGetContractorClass } from '../util/db'
import { CssBaseline } from '@material-ui/core';

function PaymentPortalPage(props) {

  const router = useRouter()
  const auth = useAuth()
  const [upcomingInvoice, setUpcomingInvoice] = useState()
  const [currentInvoice, setCurrentInvoice] = useState()
  const [couponCodes, setCouponCodes] = useState([]);
  const [ subType, setSubType ] = useState();
  const [couponId,setCouponId] = useState()
  const resourceId = router.query.resourceId;
  const classType = router.query.classType;
  const [mkPlanId, setMkPlanId] = useState();
  const [semesterId, setSemesterId] = useState();
  const [contractorId,setContractorId] = useState();
  const [contractorClassId, setContractorClassId] = useState();
  const [yearlyMode,setYearlyMode] = useState('yearly');

    
  const { data: mkPlanData } = useGetMightyKidsPlan(mkPlanId);
  const { data: semesterData } = useGetSemester(semesterId)
  const { data: enrichmentClassData } = useGetContractorClass(contractorId, contractorClassId);

  useEffect(() => {
    if(classType === 'core'){
      setMkPlanId(resourceId)
    }
    if(classType === 'enrichment'){
      const classHash = resourceId.split('-');
      setContractorId(classHash[0])
      setContractorClassId(classHash[1])

    }
  },[props.data])

  useEffect(()=> {
    if(mkPlanData && mkPlanData.semesterId !== 'now'){
      setSemesterId(mkPlanData.semesterId)
    }

  },[mkPlanData])

  useEffect(()=>{
    conversionsEvent({ eventName:'checkout started' })
    const initialQuote = queryString.parse(window.location.search)['sub_duration'];
    if(initialQuote){
      setSubType(initialQuote)
    } else {
      setSubType('monthly')
    }
  },[window.location])

  useEffect(()=> {
    if(semesterData && semesterData.startDate){
      let startDate =  semesterData.startDate.toDate();
      let now = new Date()
      if(startDate > now){
        setYearlyMode('once')
      }
    }
  },[semesterData])



  function PricingPageLink(props) {
    const router = useRouter()
    return (
      <Grid container><Button onClick={()=>{
        if(classType === 'enrichment'){
          router.push('/enrichment-classes')
        } else {
          router.push('/pricing')
        }
      }} variant="text">Choose a different plan</Button></Grid>
    )
  }

  useEffect(() => {
    if((classType === 'core' && subType) || classType == 'enrichment'){
      const previewSubscription = async () => {
        console.log('fetching preview for:',subType)
        const res = await apiRequest('stripe/preview-subscription','post',{ classType,resourceId,couponCodes, subType })
        console.log('api response:',res)
        // if created succesfully will include subscription id
        setUpcomingInvoice(res.upcomingInvoice)
        setCurrentInvoice(res.currentInvoice)
        setCouponId(res.couponId)
      }
      previewSubscription();
    }
    console.log('sub type:',subType)
        
  }, [couponCodes,subType])

  const toggleMonthly = () => {
    if(subType === 'monthly'){
      setSubType(yearlyMode)
      router.history.push({
        search: `?sub_duration=${yearlyMode}`,
      })
      return;
    }
    if(subType === 'yearly' || subType === 'once'){
      setSubType('monthly')
      router.history.push({
        search: '?sub_duration=monthly',
      })
      return;
    }
  }

  return (<Container maxWidth={'lg'}>
    <CssBaseline />
    <PricingPageLink/>
    { classType === 'core' && (mkPlanData?.yearlyPriceId || mkPlanData?.oneTimePriceId) && mkPlanData?.monthlyPriceId ? <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>Monthly</Grid>
      <Grid item>
        <Switch
          checked={subType === 'yearly' || subType === 'once'}
                                        
          onChange={toggleMonthly}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Grid>
      <Grid item>Yearly</Grid>
    </Grid> : null }
    {auth && currentInvoice ? <PaymentPortal semesterData={semesterData} enrichmentClassData={enrichmentClassData} mkPlanData={mkPlanData} setCouponCodes={setCouponCodes} subType={subType} couponCodes={couponCodes} couponId={couponId} classType={classType} resourceId={resourceId} user={auth.user} upcomingInvoice={upcomingInvoice} currentInvoice={currentInvoice} />
      : <Grid container xs={12}><CircularProgress style={{ margin:'auto' }} /></Grid>}
      
    <Grid className={'mt-5 pt-5'} container align="center" justify="center" xs={12}>
      <a target="_blank" href="http://stripe.com" rel="noreferrer">
        <img style={{ opacity:'.5' }} width={'200'} src={'/images/stripe_badge2.png'}  />
      </a>
    </Grid>
  </Container>)
}

export default requireAuth(PaymentPortalPage)
