import { loadStripe } from '@stripe/stripe-js'
import { apiRequest } from './util'

let stripe

// Load the Stripe script
loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance
  return stripe
})

export async function redirectToCheckout({ planId, childId, successURL }) {
  // Create a checkout session
  const session = await apiRequest('stripe-create-checkout-session', 'POST', {
    planId,
    childId,
    successURL,
  })
  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  if (!successURL) {
    window.history.replaceState({}, '', '/pricing')
  }
  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  })
}

export async function redirectToCustomCheckout({ childId, classId, contractorId }) {
  let successURL = 'https://app.mightykidsvirtualacademy.com/class-registration'
  if (process.env.NODE_ENV === 'development') {
    successURL = 'http://localhost:3000/class-registration'
  }
  //console.log('redirect to checkout... planId ', planId, getStripePriceId(planId))
  // Create a checkout session
  const session = await apiRequest('stripe-contractor-class-checkout', 'POST', {
    classId,
    contractorId,
    childId,
    success_url: successURL,
  })

  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  if (!successURL) {
    window.history.replaceState({}, '', '/pricing')
  }
  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  })
}

export async function redirectToBilling(return_url) {
  // Create a billing session
  const session = await apiRequest('stripe-create-billing-session', 'POST', {
    return_url,
  })

  // Redirect to billing session url
  window.location.href = session.url
}

export async function redirectToAccount(return_url) {
  // Create a billing session
  // Redirect to billing session url
  window.location.href = await apiRequest('stripe-create-connect-session', 'POST', {
    return_url,
  })
}

export async function loginToExpressAccount(return_url) {
  // Create a billing session
  // Redirect to billing session url
  window.location.href = await apiRequest('stripe-login-connect-session', 'POST', {
    return_url,
  })
}

export async function getStripeProducts() {

  // Create a billing session
  return await apiRequest('stripe/products', 'GET')
  //return products
  // Redirect to billing session url
  /// return products;
}

export async function getStripePrices(productId) {

  // Create a billing session
  return await apiRequest('stripe/prices/' + productId, 'GET')
  //return products
  // Redirect to billing session url
  /// return products;
}
