import React, { useEffect, useState } from 'react'
import { Grid, Card, Switch, CardHeader, CardMedia, CardContent, CardActions, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment-timezone'


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 375,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  media: {
    [theme.breakpoints.down('sm')]: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      maxWidth: 500,
      width: '100%',
      height: 'auto',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
}));

function SubscriptionCard(props) {
  const [item, setItem] = useState()
  const [endDateString, setEndDateString] = useState();
  const [startDateString, setStartDateString] = useState();
  const [futureStart, setFutureStart] = useState();
  const [amount, setAmount] = useState(0)

  useEffect(()=>{
    if(props.semesterData){
      const endDate =  props.semesterData.endDate.toDate();
      const startDate =  props.semesterData.startDate.toDate();
      const now = new Date();
      //Classes have already started
      if(startDate < now){
        setStartDateString('Program runs from now')
        setEndDateString('until ' + moment(endDate).format('dddd MMM Do YYYY'));
        setFutureStart()
      } else {
        setFutureStart(moment(startDate).format('dddd MMM Do YYYY'))
        setStartDateString('Program runs from ' + moment(startDate).format('dddd MMM Do YYYY') )
        setEndDateString('until ' + moment(endDate).format('dddd MMM Do YYYY'));
      }
    }

  },[props.semesterData])

  useEffect(()=>{
    if(props.classType === 'enrichment'){
      const classData = {
        bannerText:'Enrichment Class',
        name: props.enrichmentClassData.className,
        photoURL: props.enrichmentClassData.classPhoto,
        longText: props.enrichmentClassData.description,
        subType: 'weekly',
        price: props.enrichmentClassData.listPrice / 100,
      }
      setItem({ ...item,...classData })
    }
    if(props.classType === 'core'){
      setItem(props.mkPlanData)
      console.log('mk plan data',props.mkPlanData)
    }

  },[props.classType,props.mkPlanData,props.enrichmentClassData])

  useEffect(()=> {

    setAmount((props.currentInvoice.total / 100).toFixed(2))
  },[props.upcomingInvoice,props.currentInvoice,props.subType,props.classType])

  return (
    <Grid container>
      <Grid container className={'pt-2 pr-1'} align="center" direction="row" alignItems="center" justifyContent="center">
        <Card className="pt-3" >
          <CardMedia
            style={{ maxWidth: 500, maxHeight:500 }}
            component="img"
            alt={item?.photoURL}
            image={item?.photoURL}
            title={item?.name}
          />
                  
          <CardContent>
            <CardHeader title={item?.name} />
            <Typography variant="subtitle1" style={{ paddingLeft: 15 }}>
              {item?.longText}
            </Typography>

            <hr width={'100%'}/>
            <Grid container className={'pt-2'} align="center"  direction="column" alignItems="center" justifyContent="center">
                              
              <Typography   align="center" variant="overline" >
                {startDateString}
              </Typography>
              <Typography   align="center" variant="overline" >
                {endDateString}
              </Typography>

                               
            </Grid>
            <CardActions disableSpacing>
              <Grid container align="center" alignItems={'center'} justifyContent="center" direction={'row'}>
                <Grid item direction="column" align="center" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 10 }}>
                  {props.mkPlanData?.trialPeriod > 0 && props.classType === 'core' && <Typography color="secondary" className={'p-2 pt-1'} variant="h5">
                    {props.mkPlanData?.trialPeriod} day free trial!
                  </Typography>}
                                              
                  { (props.classType === 'core' && props.subType === 'monthly') && <Typography color="secondary" className={'p-2 pt-1'} variant="subtitle">
                                        ${amount} per month beginning {futureStart ? futureStart : props.freeTrialEnd }
                  </Typography>}

                  {props.classType === 'core' && props.subType === 'yearly' && <Typography color="secondary" className={'p-2 pt-1'} variant="subtitle">
                                           afterwards you pay ${amount} for the whole school year.
                  </Typography>}

                  {props.classType === 'core' && props.subType === 'once' && <Typography color="secondary" className={'p-2 pt-1'} variant="subtitle">
                                          ${amount} One Time.
                  </Typography>}

                             

                  { props.classType === 'enrichment' && <Grid>
                    <Typography className={'pt-1'} variant="subtitle2" color="textSecondary" >
                                        Start Date: {moment(props.enrichmentClassData?.startDate.toDate()).format('MMM Do, YYYY')}
                    </Typography>
                    <Typography className={'pt-1'} variant="subtitle2" color="textSecondary" >
                                        End Date: {moment(props.enrichmentClassData?.endDate?.toDate()).format('MMM Do, YYYY')}
                    </Typography>
                  </Grid>}

                  {props.classType === 'enrichment' && <Grid className={'mt-5'}><Typography color="secondary"  variant="subtitle">
                                            ${amount} per week.
                  </Typography></Grid>}
                                 
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SubscriptionCard
