import React, { useState, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useAuth } from '../util/auth'
import CircularProgress from '@material-ui/core/CircularProgress';
import RegistrationProgress from './RegistrationProgress'

export default function ContractorDashboard() {
  const auth = useAuth();
  return (
    <React.Fragment>
      <CssBaseline />
      { auth && auth.user ?
        <Container maxWidth="sm">
          <Typography className={'m-2'} align={'center'} variant={'h4'} component={'h4'}  >
                        Hello, {auth.user.name}!
          </Typography>
          <Typography align={'center'} variant={'subtitle1'} component={'h4'}>
                        Welcome to Mighty Kids Preschool contractor program.
          </Typography>
          <Typography className={'m-2'} align={'center'} variant={'subtitle1'} component={'h4'}>
                        Before you can create your own classes and begin intruction we will need to complete a few steps...
          </Typography>
          <RegistrationProgress />
        </Container>
        : <CircularProgress />}
    </React.Fragment>
  );
}
