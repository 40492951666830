import React, { useEffect, useState } from 'react'
import StarIcon from '@material-ui/icons/Star';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper, Hidden,
} from '@material-ui/core';
import { LinkContainer } from 'react-router-bootstrap'
import { useAuth } from '../util/auth.js'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useGetSemester } from '../util/db'
import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import GradientButton from '../components/GradientButton';
import { useRouter } from '../util/router.js';



const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 375,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  media: {
    [theme.breakpoints.down('sm')]: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      maxWidth: 500,
      width: '100%',
      height: 'auto',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
}));

function CallToActionSection(props) {
  const router = useRouter()
  const viewAllPlans= () => {
    router.push('/pricing')
  }

  const purchaseClick = () => {
    router.push('/purchase/core/' + props.mkPlanId)
  }


  return (
    <>
      <Grid className={'p-5 mb-5'} alignItems="center" justifyContent="center" direction="column" container>
        <Typography variant="h5" align="center">
            Ready to find out what makes our students so mighty?
        </Typography>
        <Button onClick={purchaseClick} className="p-3 mt-5" variant="contained" color="primary" >Enroll Now!</Button>
      </Grid>

      <Grid className={'p-5 mb-5'} alignItems="center" justifyContent="center" direction="column" container>
        <Typography align="center" variant="h5">
                Looking for something a little different?
        </Typography>
        <Button onClick={viewAllPlans} style={{ fontSize: '25px' }} size="large" className="p-3 mt-3" variant="text" color="primary" >View all of our plans</Button>
      </Grid>
    </>
  )
}

export default CallToActionSection
