import firebase from './firebase'
import 'firebase/storage';
import uuid from 'uuid'

export const uploadVideoMaterials = (file) => {
  if (file && file.name) {
    const path = 'video-materials/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadQuestionImage = (file) => {
  if (file && file.name) {
    const path = 'question-images/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadProductImage = (file) => {
  if (file && file.name) {
    const path = 'product-images/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadTeacherImage = (file, teacherId) => {
  if (file && file.name) {
    const path = 'teacher-images/' + teacherId + '/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadContractorImage = async (file, id) => {
  console.log('file', file, 'id', id)
  if (file && file.name && id) {
    const path = 'contractor/' + id + '/' + uuid.v1() + file.name
    console.log(path)
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(async () => {
      let src = await fileRef.getDownloadURL()
      let path = fileRef.fullPath;
      return { src, path }
    })
  }
}

// export const deleteContractorImage = async (url) => {
//
// }


export const uploadQuestionAudio = (file) => {
  if (file && file.name) {
    const path = 'question-audio/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadLetterAudio = (file) => {
  if (file && file.name) {
    const path = 'letter-audio/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadLetterImage = (file) => {
  if (file && file.name) {
    const path = 'letter-image/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}

export const uploadLetterWorksheet = (file) => {
  if (file && file.name) {
    const path = 'letter-worksheet/' + uuid.v1() + file.name
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)
    return fileRef.put(file).then(() => {
      return fileRef.getDownloadURL()
    })
  }
}
