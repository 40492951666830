import { Container,Row,Col,Alert,Button } from 'react-bootstrap'
import AvatarSection from '../components_student/AvatarSection'
import DailySection from '../components_student/DailySectionDemo'
import TodaysLessonSegment from '../components_student/TodaysLessonSegment'
import React, { useState, useEffect } from 'react'
import { useChildContext } from '../util/childProfileContext'

const DemoDay = () => {
  const { childProfile, currentSemester, contractorClasses } = useChildContext()

  const [name, setName] = useState('Demo')
  const [picture, setPicture] = useState(23)

  useEffect(()=> {
    if(childProfile){
      if(childProfile.name !== ''){
        setName(childProfile.name)
      } else {
        setName('Demo')
      }
      if(childProfile.picture !== ''){
        setPicture(childProfile.picture)
      } else {
        setPicture('23')
      }
    }

  },[childProfile])

  return (<><Container>
    <Row>
      <Col className={'text-center justify-content-center'}>
        <AvatarSection picture={picture}/>
        <div><h1>Hi, {name}</h1></div>
      </Col>
    </Row>
    {!currentSemester && <Alert className="mt-5" key="1" variant="warning">
      <Row><Col sm="10"><div className="left"> {contractorClasses ? 'A Mighty Kids Video plan or Zoom plan subscription will activate the assessment and customized daily content.' : 'Purchase a video plan subscription to activate the assessment and customized daily content'} </div></Col><Col sm="2"> <div className="right"><Button href="/pricing" variant="warning">Buy Now</Button></div></Col></Row>
    </Alert>}
  </Container>
  <DailySection
    bg="white"
    textColor="dark"
    size="md"
    bgImage=""
    bgImageOpacity={1}
    title="Daily Items (Friday DEMO)"
    subtitle=""
  />
  <TodaysLessonSegment
    bg="white"
    textColor="dark"
    size="md"
    bgImage=""
    bgImageOpacity={1}
    title="Today's Lessons (Friday DEMO)"
    subtitle=""
  />
  </>)
}

export default DemoDay