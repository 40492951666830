import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: '#fafafa',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    maxWidth: 800,
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

export default function SingleLineGridList(props) {
  const classes = useStyles();
  console.log('photos', props.photos)
  return (
    <div className={classes.root}>
      <GridList cellHeight={360} className={classes.gridList} cols={2.5}>
        {props.photos && props.photos.map((tile) => (
          <GridListTile key={tile.src}>
            <img src={tile.src} />
            { props.deletePhoto && <GridListTileBar
              classes={{
                // root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                <IconButton onClick={() => { props.deletePhoto(tile) }} >
                  <HighlightOffIcon className={classes.title} />
                </IconButton>
              }
            />}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}