import React, { useEffect, useState } from 'react'
import ColorOfTheDay from './ColorOfTheDay'
import SectionHeader from '../components/SectionHeader'
import useAudio from '../util/useAudio'
import QuestionModal from './WearingBlueToday'
import { useGetDailyQoD, addDailyQoD, useGetTodaysQuestion } from '../util/db'
import { useChildContext } from '../util/childProfileContext'

function QuestionOfTheDay(props) {
  const { childProfile } = useChildContext()
  const [todaysQuestion, setTodaysQuestion] = useState({ audio: '', text: '' });
  const { data: questionResults, loading, status } = useGetTodaysQuestion();

  useEffect(() => {
    if (questionResults && questionResults.length > 0) {
      setTodaysQuestion(questionResults[0])

    }
  }, [todaysQuestion, questionResults]);
  // const { data, status, error } = useGetDailyQoD(childProfile.id)
  const d = new Date()


  const [playing, toggle] = useAudio(todaysQuestion.audio)
  return (<>
    <QuestionModal childProfile={childProfile} submit={addDailyQoD} playing={playing} toggle={toggle} item={todaysQuestion.imageURL} question={todaysQuestion.text} />
    <SectionHeader
      title={props.title}
      subtitle={props.subtitle}
      size={2}
      spaced={true}
      className="text-center"
    />
  </>
  )
}

export default QuestionOfTheDay
// status={status} error={error}
// {data &&
{/*{data={data && data[0] && data[0].question && questions[d.getDate()]['text'] === data[0].question ? data : []}}*/ }
