import React, { useEffect, useState } from 'react'
import { useGetActiveAlerts } from '../util/db'
import { useAuth } from '../util/auth'
import AlertRow from './AlertRow'
import { Grid, Container } from '@material-ui/core'
// Need to display when classes start for upcoming semester

// display if registrations are available

//

const ShowUserAlerts = (props) => {
  const auth = useAuth()
  const { data } = useGetActiveAlerts(auth?.user?.id)
  const [filteredAlerts, setFilteredAlerts ] = useState([])
    
  useEffect(()=> {
    console.log('alerts for user:',auth?.user?.id)
    console.log('data',data)
    if(data){
      console.log('all alerts:',data)
      let now = new Date()
      let filtered = data.filter(row => {
        let start = row.startDate.toDate()
        let end = row.endDate.toDate()
        return start <= now && end >= now && row.active && !row.hidden
      })
      setFilteredAlerts(filtered)
    }
  },[data])

  return (
    <><Container maxWidth="sm">{filteredAlerts.map(alert => 
      <Grid key={alert.id} item xs={12}><AlertRow key={alert.id} data={alert} /></Grid>,
    )}</Container> </>
  )
}

export default ShowUserAlerts