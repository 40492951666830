import React, { useState, useEffect } from 'react';
import { Radio,RadioGroup,FormControl,FormControlLabel,FormLabel,Grid } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import StripeCardElement from './StripeCardElement';

export default function PaymentMethods(props) {
  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
    props.selectMethod(event.target.value)
  };


  useEffect(() => {
    if (value === 'new') {
      props.setNewCard(true)
    } else {
      props.setNewCard(false)
    }
  }, [value])

  useEffect(()=>{
    if(props.paymentMethods.length === 0){
      setValue('new')
      props.selectMethod('new')
    }
  },[props.paymentMethods])

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Payment Methods</FormLabel>
      <Grid className={'pl-5'}>
        {props.paymentMethods.length > 0 && <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          {props.paymentMethods.map(method =>
            <FormControlLabel key={method.id} value={method.id} control={<Radio />} label={<Grid> <CreditCardIcon />{` **** ${method.card.last4}`}</Grid>} />,
          )}
          <FormControlLabel key={'newCard'} value={'new'} control={<Radio />} label={<Grid> Use New Payment Method</Grid>} />
        </RadioGroup>}
                
        <Grid>
          {value === 'new' && <Grid container>
            <StripeCardElement />
          </Grid>}
        </Grid>
      </Grid>
    </FormControl>
  );
}