import React from 'react'
import SegmentSection from '../components/SegmentSection'
import Section from '../components/Section'
import { Container,Row,Col,Card,ListGroupItem,ListGroup,Button } from 'react-bootstrap'

import SectionHeader from '../components/SectionHeader'
import ScheduleCard from '../components_public/ScheduleCard'
import YearlySchedule from '../components/YearlySchedule'
import { Typography } from '@material-ui/core'


const ResourcesSection = (props) => {

  return (
    <React.Fragment>
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container
          style={{
            maxWidth: '850px',
          }}
        >
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
            className="text-center"
          />

          <Container style={{ paddingTop: 20 }}>
            {/*<NameTrace myName={childProfile.name} />*/}
            {/*{childProfile ? ( //&& auth.user && auth.user.planIsActive ? (*/}
            <React.Fragment>
              <Row className={'pb-5'}>
                <Col sm={4}>
                  <Card>
                    <Card.Body>Download Letter Curves and Lines</Card.Body>
                    <Card.Img height="300px" width="30px" src={'/images/curves-and-lines.png'} />
                    <Card.Body>
                      <Card.Link href="https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Resources%2FCurves_and_Lines.pdf?alt=media&token=f720c95e-2a53-457b-ab73-c0acbb4556c8">
                        <Button>Download</Button>
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={8}>
                                  
                             
                  <Typography variant="h5" align="center" className="pt-5 mb-3">Print and practice activities!</Typography>
                  <Card className="p-3 mb-2 text-center">
                    <Card.Body>Trace the first letter of your name</Card.Body>
                    <Card.Link href="/tracing/1">
                      <Button >Exercise #1</Button>
                    </Card.Link>
                  </Card>
                  {/* <br/> */}
                  <Card className="p-2 mb-2  text-center">
                    <Card.Body>Decorate your name</Card.Body>
                    <Card.Link href="/tracing/2">
                      <Button >Exercise #2</Button>
                    </Card.Link>
                  </Card>
                  <Card className="p-2 mb-2  text-center">
                    <Card.Body>Trace your whole name</Card.Body>
                    <Card.Link href="/tracing/3">
                      <Button >Exercise #3</Button>
                    </Card.Link>
                  </Card>
                  <Card className="p-2 mb-2  text-center">
                    <Card.Body>Name spelling puzzle</Card.Body>
                    <Card.Link href="/tracing/4">
                      <Button >Exercise #4</Button>
                    </Card.Link>
                  </Card>
                </Col>
              </Row>
                             
                           
              <Row>
                <Col className={'text-center align-content-center justify-content-center'}>
                  <div className="pb-3">
                    <Card.Header className={'border'}><Card.Title>Lesson Schedule</Card.Title></Card.Header>
                    <ScheduleCard plan={'zoom'} />
                    {/*//auth.user.planId*/}
                  </div>

                </Col>
                <Col xs={12}>
                  <Card style={{ marginTop: 10 }}>
                    <Card.Header><Card.Title>Supply List</Card.Title></Card.Header>


                    <ListGroup>
                      <ListGroupItem>
                        <ul>
                          <li>Crayons (at least 24 pack)</li>
                          <li>Clothes Pins</li>
                          <li>Markers (at least 8 pack)</li>
                          <li>Small white board and white board marker (can be found at dollar stores if needed)</li>
                          <li>Small chalk board and chalk</li>
                          <li>Bottle of White School Glue</li>
                          <li>2 Glue Sticks, any size</li>
                          <li>Pipe cleaners (chenille stems), assorted colors</li>
                          <li>Small pouch of kids Crafting Beads, assorted colors (use adult judgement on risk of child ingesting. Can replace with more toddler friendly beads if needed)</li>
                          <li>Cotton Balls</li>
                          <li>Pencils (preferred: small “golf” pencils as these are better for pincer grasp development but regular pencils will do)</li>
                          <li>Pair of safety scissors</li>
                          <li>Small pack of assorted color construction paper</li>
                          <li>Spiral notebook or composition book (a sketch book would work as well)</li>
                          <li>1 watercolor paint tray</li>
                          <li>Shaving cream (or worried about ingestion, cool whip will work as well)</li>
                          <li>Several gallon ziplock storage bags or plastic document sleeves</li>
                          <li>Several canisters of play doh in various colors</li>
                          <li>Handwriting without tears “Slide Stamp and See Screen” found on Amazon and Walmart</li>
                        </ul>
                      </ListGroupItem>
                      <Card.Header>
                        <Card.Title>
                          <b>Optional list</b></Card.Title><p> lessons can be done without these, but they may add more to the experience</p></Card.Header>
                      <ListGroupItem>
                        <ul>
                          <li>Small packet of “googly eyes”</li>
                          <li>Small packet of small craft Puff Balls in assorted colors</li>
                          <li>Tempura Paints of assorted colors</li>
                          <li> Do a Dot markers (like bingo dobbers)</li>
                          <li>Set of blocks for stacking</li>
                        </ul>
                      </ListGroupItem>
                    </ListGroup>


                  </Card>
                </Col>
                <Col>
                  <YearlySchedule/>
                </Col>
                              
              </Row>
              <Row />
            </React.Fragment>
          </Container>


        </Container>
      </Section>
    </React.Fragment>)
}

export default ResourcesSection