import React from 'react'
import ResourcesSection from '../components_parent/ResourcesSection'

function ResourcePage(props) {

  return (
    <ResourcesSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Resources"
      subtitle=""
      buttonText="Send message"
      buttonColor="primary"
      showNameField={true}
      inputSize="md"
    />
  )
}

export default ResourcePage