import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useChildContext } from '../../util/childProfileContext';
import MeetingDetails from './MeetingDetails';

//Load class/classes for today's time slot =>
// get the current day of the week

const classDays = { 0: 'sundayClass', 1: 'mondayClass', 2: 'tuesdayClass', 3: 'wednesdayClass', 4: 'thursdayClass', 5: 'fridayClass', 6: 'saturdayClass' }
const date = new Date();
const dayOfWeek = date.getDay();
const dayString = classDays[dayOfWeek];

const DynamicZoomLoader = () => {
  const { currentContractorClasses, currentZoomClasses } = useChildContext()
  const [todaysMeetings, setTodaysMeetings] = useState([])

  useEffect(() => {
    let classList = [];
    if (currentZoomClasses) {
      currentZoomClasses.forEach(element => {
        console.log(element)
        if(element[dayString] === true) classList.push(element)
      });
    }
    if (currentContractorClasses) {
      currentContractorClasses.forEach(element => {
        if(element[dayString] === true) classList.push(element)
      });
    }
    setTodaysMeetings(classList)
  }, [currentZoomClasses, currentContractorClasses])

  return (<div className={'m-5'}>
    <Typography className="pb-5" align="center" variant="h3">Today&apos;s Zoom Meetings</Typography>
    { todaysMeetings.length > 0 ? <React.Fragment>
      {todaysMeetings && todaysMeetings.map(classDetails => <MeetingDetails key={classDetails.id} data={classDetails} />)}
    </React.Fragment> : <Typography align="center" variant="h5">No Meetings Today</Typography>}
  </div>
  )
}

export default DynamicZoomLoader
