import React from 'react'
import ClassRegistration from '../components_student/ClassRegistration/ClassRegistration'
import { requireAuth } from '../util/auth.js'

function ClassRegistrationPage(props) {
  return (
    <ClassRegistration />
  )
}

export default requireAuth(ClassRegistrationPage)