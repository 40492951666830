import React from 'react'
import HeroSection2 from '../components_public/HeroSection2'
import ScheduleCard from '../components_public/ScheduleCard'
import ZoomTimes from '../components_public/ZoomTimes'
import SegmentSection from '../components/SegmentSection'
import YearlySchedule from '../components/YearlySchedule'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ProgramPageMetaTags from '../components_public/MetaTags/ProgramPage';
import Container from 'react-bootstrap/Container';
import VideoEmbed from '../components/VideoEmbed';


function ProgramPage(props) {
  return (
    <>
      <ProgramPageMetaTags />
      <HeroSection2
        bg="primary"
        textColor="white"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="How the program is structured"
        subtitle="Our program is designed to help parents not only navigate distance learning but take away the pressure of finding curriculum, lessons, and activities to advance child learning."
      />        
      <Card>
        <Container
          style={{
            maxWidth: '768px',
          }}
        >
          <center>
            <h3 style={{ marginTop:25 }}>Program explanation video</h3>
          </center>
          <VideoEmbed url={'https://player.vimeo.com/video/681041232?h=fc9dba3202&title=0&byline=0&portrait=0'}/></Container>
      </Card>
      <SegmentSection size={'sm'} title={'Supply List'} subtitle={''}>
        <Card>
          <Card.Header><Card.Title>Items</Card.Title></Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <ul>
                  <li>Crayons (at least 24 pack)</li>
                  <li>Clothes Pins</li>
                  <li>Markers (at least 8 pack)</li>
                  <li>Small white board and white board marker (can be found at dollar stores if needed)</li>
                  <li>Small chalk board and chalk</li>
                  <li>Bottle of White School Glue</li>
                  <li>2 Glue Sticks, any size</li>
                  <li>Pipe cleaners (chenille stems), assorted colors</li>
                  <li>Small pouch of kids Crafting Beads, assorted colors (use adult judgement on risk of child ingesting. Can replace with more toddler friendly beads if needed)</li>
                  <li>Cotton Balls</li>
                  <li>Pencils (preferred: small “golf” pencils as these are better for pincer grasp development but regular pencils will do)</li>
                  <li>Pair of safety scissors</li>
                  <li>Small pack of assorted color construction paper</li>
                  <li>Spiral notebook or composition book (a sketch book would work as well)</li>
                  <li>1 watercolor paint tray</li>
                  <li>Shaving cream (or worried about ingestion, cool whip will work as well)</li>
                  <li>Several gallon ziplock storage bags or plastic document sleeves</li>
                  <li>Several canisters of play doh in various colors</li>
                  <li>Handwriting without tears “Slide Stamp and See Screen” found on Amazon and Walmart</li>
                </ul>

                <b>Optional list (lessons can be done without these, but they may add more to the experience):</b>
                <ul>
                  <li>Small packet of “googly eyes”</li>
                  <li>Small packet of small craft Puff Balls in assorted colors</li>
                  <li>Tempura Paints of assorted colors</li>
                  <li> Do a Dot markers (like bingo dobbers)</li>
                  <li>Set of blocks for stacking</li>
                </ul></Col>
            </Row>
          </Card.Body>
        </Card>
      </SegmentSection>
      <SegmentSection size={'sm'} title={'Standard Lesson Schedule'} subtitle={'Video Schedule for Mighty Kids Video Essentials and standard Zoom Plans'}>
        <ScheduleCard plan="zoom"/>
      </SegmentSection>
           
      <YearlySchedule/>

      <SegmentSection  size="sm" title={'Zoom Sessions'} subtitle={'Zooms happen 2, 3, or 5 times a week at the time you select. The teacher and children in the class will stay the same for the entire semester.'}>
        <ZoomTimes />
      </SegmentSection>
    </>
  )
}

export default ProgramPage
