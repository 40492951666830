import React, { useEffect, useState } from 'react';
import { useGetMKClasses, useGetLinkedTeachers, useGetMKProducts } from '../../util/db';
import { getStripeProducts } from '../../util/stripe'
import Button from '@material-ui/core/Button';
import ClassesList from './ClassesList';
import TeacherList from './TeacherList';
import ProductList from './ProductList';
import ClassDetails from './ClassDetails';
import ClassTeacherCards from './ClassTeacherCards';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';



function ClassesManager(props) {
  const [selectedClass, setClass] = useState({})
  const [isCopy, setIsCopy] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [createNew, setCreateNew] = useState(false);
  const [currentTeachers, setCurrentTeachers] = useState([])
  const [currentProduct, setCurrentProduct] = useState({})
  const { data: teachers, error, loading } = useGetLinkedTeachers();
  const { data: productList } = useGetMKProducts();
  const { data: classes } = useGetMKClasses();
   

  const selectClass = (classData) => {
    console.log(classData)
    setClass(() => classData);
    //selectTeacher(classData.Teachers)
    setCurrentTeachers(classData.Teachers || [])
    setCurrentProduct(classData.productId)
    setShowDetails(true);
    setIsCopy(false)
  };

  const createNewClass = () => {
    setCurrentTeachers([])
    setCurrentProduct()
    setCreateNew(true)
    setIsCopy(false)
    setClass(() => {
      return { id: '', name: '' }
    })
    setShowDetails(true)
  };

  const copyClass = () => {
    setCreateNew(true)
    setIsCopy(true)
    let copy = { ...selectedClass }
    delete copy.id;
    setClass({ copy })
    setShowDetails(true)
  };

  const selectTeacher = (newTeacher) => {
    console.log('newTeacher', newTeacher)
    let tmp = [...currentTeachers];
    if (currentTeachers.find(element => element.id === newTeacher.id)) {
      tmp.splice(currentTeachers.indexOf(newTeacher), 1)
      setCurrentTeachers(tmp);
    } else {
      tmp.push({ ...newTeacher, timeSlots: [] });
      setCurrentTeachers(tmp);
    }
  }

  const updateTimeSlots = (index, timeSlots) => {
    let tmp = [...currentTeachers];
    tmp[index].timeSlots = timeSlots;
    setCurrentTeachers(tmp);
  }

  const selectProduct = (newProduct) => {
    setCurrentProduct(newProduct);
  }

  // useEffect(() => {
  //     const fetchProducts = async () => {
  //         let products = await getStripeProducts();
  //         console.log('products', products)
  //         setProductList(products.data);
  //         setFetchingProducts(true);
  //     }
  //     if (!fetchingProducts) {
  //         fetchProducts()
  //     }
  // }, [fetchingProducts])

  return (
    <>
      <Container fluid>
        <h2>Zoom Meeting Generator</h2>
        <Row>
          <Col xs={3}>
            <Row>
              {loading ? 'loading' :
                <ClassesList currentClassId={selectedClass.id} rowSelect={selectClass} data={classes} />}
            </Row>
            <Row>
              <Button variant="primary" size="md" onClick={createNewClass}>
                                Create New
              </Button>
              {selectedClass.id ?
                <Button variant="primary" size="md" onClick={copyClass}>
                                    Copy Class
                </Button> : null}
            </Row>
          </Col>
          <Col xs={6}>
            {showDetails ?
              <Row>
                <ClassTeacherCards updateTimeSlots={updateTimeSlots} removeTeacher={selectTeacher} currentTeachers={currentTeachers} />
                <ClassDetails isCopy={isCopy} setCreateNew={setCreateNew} createNew={createNew} currentProduct={currentProduct} currentTeachers={currentTeachers} data={selectedClass} />
              </Row>
              : null
            }
          </Col>
          {showDetails ?
            <Col xs={3}>
              <Row className={'p-5'}>
                <h2>Teacher List</h2>
                <p>(Only shows teachers that have been linked to a zoom account)</p>
                <a href={'/admin/manage-teachers'}> manage teachers</a>
                <TeacherList currentTeachers={currentTeachers} rowSelect={selectTeacher} data={teachers} />

              </Row>
              <Row>
                <h2>Stripe Products</h2>

                {productList && <ProductList currentProduct={currentProduct} rowSelect={selectProduct} data={productList} />}
              </Row>
            </Col> : null}
        </Row>
      </Container>
    </>);
}

export default ClassesManager
