import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { LinkContainer } from 'react-router-bootstrap'
import AspectRatio from './AspectRatio'
import Badge from 'react-bootstrap/Badge'
const getIcon = (type) => {
  switch (type) {
      case 'video':
        return 'video'
      case 'printable-pdf':
        return 'file-alt'
      case 'pictures':
        return 'image'
      case 'instructions':
        return 'chalkboard-teacher'
  }
}
function ContentCards(props) {
  return (
    <Row className="justify-content-center">
      {props.items.map((item, index) => (
        <React.Fragment key={index}>
          <Col xs={12} md={6} lg={6} className="py-3" key={index}>
            <LinkContainer to={item.url}>
              <Card.Link>
                <Card>
                  <AspectRatio ratio={1 / 0.5}>
                    <Card.Img
                      src={item.image}
                      alt={item.title}
                      variant="top"
                    />
                  </AspectRatio>
                  <Card.Body>
                    <Card.Title className={'pt-2'}>{item.title}</Card.Title>
                    <Card.Text>{item.body}</Card.Text>
                    <Card.Text>
                      {item.contains.map((type, index) => (
                        <span style={{ fontSize: 40, paddingLeft: 20 }} key={index} ><i className={`fas fa-${getIcon(type)}`} /></span>
                        // <Badge variant="primary" space={2}>
                        //     {type}
                        // </Badge>
                      ))}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Card.Link>
            </LinkContainer>
          </Col>
        </React.Fragment>
      ))}
    </Row>
  )
}

export default ContentCards
