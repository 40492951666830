import React, { useEffect, useState } from 'react'
import AvatarSelection from '../components_student/AvatarSection'
import { Typography,Grid } from '@material-ui/core'
import { useChildContext } from '../util/childProfileContext'


const Menu = (list ) =>
  list.map(({ picture, name, id } ) => {
    return <div key={id} className={'menu-item'}>
      <Grid container  direction="column" alignItems="center" justifyContent="center">
        <AvatarSelection picture={picture}/>
        <Typography align="center">
          {name}
        </Typography>
      </Grid>
    </div>
  })

const ChildSelector = ( { selectChildId } ) => {
  const { childProfile, children } = useChildContext()
  const [selected, setSelected] = useState()
  const [menuItems, setMenuItems] = useState()
  const [ index, setIndex] = useState(0)
  const [ init, setInit] = useState(false)

  useEffect(()=> {
    if(children && childProfile && !init){
      setMenuItems(Menu(children));
      selectProfile(children[0])
      setInit(true)
    }
  },[children,childProfile])

  const selectProfile = (child) => {
    if(child){
      console.log('current child:',child.id)
      setSelected(child)
      selectChildId(child.id)
    }
  }

  const clickLeft = () => {
    setIndex(oldVal => {
      let newVal = oldVal;
      if(oldVal > 0 ){
        newVal = oldVal - 1;
      } else {
        newVal = children.length - 1
      }
      selectProfile(children[newVal])
      return newVal
    })
  }

  const clickRight = () => {
    setIndex(oldVal => {
      let newVal = oldVal;
      if(oldVal < (children.length - 1)){
        newVal = oldVal + 1;
      } else {
        newVal = 0
      }
      selectProfile(children[newVal])
      return newVal
    })
  }

  return (
    <div className="child-selector">
      <Grid direction="row" container alignItems="center" justifyContent="center">
        <i onClick={clickLeft} style={{ fontSize: 25 }} className="fas fa-chevron-circle-left text-primary"/>
        <Grid className="p-5" item  direction="column" alignItems="center" justifyContent="center">
          {selected && <Grid container direction="column" alignItems="center"><AvatarSelection picture={selected.picture}/>
            <Typography align="center">
              {selected.name}
            </Typography> </Grid>}
        </Grid>
        <i onClick={clickRight} style={{ fontSize: 25 }} className="fas fa-chevron-circle-right text-primary"/>
      </Grid>
    </div>
  )
}

export default ChildSelector
