import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TodayIsCard from '../components_student/TodayIsCard';
import Emotion from './Emotion';
import Weather from './Weather';
import QuestionOfTheDay from '../components_student/QuestionOfTheDay';
import LetterOfTheDay from './LetterOfTheDay';
import Card from 'react-bootstrap/Card';
import { Menu, MenuItem, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Spinner from 'react-bootstrap/Spinner';

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const animals = {
  platypus: 'platypus',
  hippo: 'hippo',
  penguin: 'penguin',
  baboon: 'baboon',
  polar: 'polarplunge',
  ape: 'ape',
  tiger: 'tiger',
  //   elephant: 'elephants',
  panda: 'panda',
  giraffe: 'kijami', // giraffes and rhinos
  owl: 'owlaviary',
  koala: 'koala',
  condor: 'condorhd',
};

const setPlayerUrl = (animal) => {
  return `https://zssd-${animals[animal]}.secureplayer.camzonecdn.com/v1.3/CamzoneStreamPlayer?iframe=yes&amp;channel=zssd-${animals[animal]}&amp;muted=yes&amp;mutebutton=no&amp;czlogourl=&amp;toolbar=always&amp;backgroundcolor=000000&amp;toolbarbgcolor=ffffff&amp;toolbaralpha=100&amp;toolbartextcolor=000000&amp;epostcard=yes&amp;toolbartype=new&amp;toolbarposition=topright`;
};
function Daily(props) {
  const currentAnimal = 'tiger';
  const [animal, setAnimal] = useState(currentAnimal);
  const [url, setURL] = useState(setPlayerUrl(animal));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAnimal = (name, uri) => {
    console.log(name, uri);
    setAnimal(name);
    setURL(setPlayerUrl(name));
  };
  return (
    <>
      <Row className="pb-3">
        <Col xs={12} className={'justify-content-center align-content-center pb-5'}>
          <Card className={' shadow-lg bg-white rounded justify-content-center'}>
            <div className="embed-responsive embed-responsive-16by9">
              {!url && <Spinner animation="border" variant="primary" />}
              {url && (
                <iframe
                  key={url}
                  allowFullScreen=""
                  //eslint-disable-next-line
                  allowTransparency="true"
                  frameBorder="0"
                  height="100%"
                  scrolling="no"
                  src={url}
                  width="300"
                  title="Video player"
                />
              )}
            </div>

            <Card.Footer>
              {/* add a div to the right. This div will be the component for the dropdown menu */}
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <div className={'text-centered p-2'}>
                    <h3>Live {animal.capitalize()} Cam</h3>
                    <h6>San Diego Zoo</h6>
                  </div>
                </Grid>
                <Grid xs={2} container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      Change Animal
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {Object.entries(animals).map(([name, uri]) => (
                        <MenuItem
                          key={name}
                          onClick={(event) => {
                            changeAnimal(name, uri);
                            handleClose(event);
                          }}
                        >
                          {name.capitalize()}
                        </MenuItem>
                      ))}
                    </Menu>
                    {/* <Route path="/hippo"><Hippo /></Route> */}
                  </div>
                </Grid>
              </Grid>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <TodayIsCard />
        </Col>
        <Col xs={12} sm={12} md={6}>
          <QuestionOfTheDay />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} className={'pb-4'}>
          <LetterOfTheDay />
        </Col>
        <Col className="h-100" xs={12} sm={12} md={6} lg={8} xl={8}>
          <Row>
            <Col>
              <Emotion
                useAudio={props.useAudio}
                url="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/Audio%2Ffeelings%20question.mp3?alt=media&token=88e4b497-e537-42ef-bfe5-c95b3d107439"
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
              <Weather
                useAudio={props.useAudio}
                url="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/Audio%2FWeather%20question.mp3?alt=media&token=b9baddd2-5981-4664-ad9f-6d2ff51e45b5"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Daily;
