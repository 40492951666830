import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed'
import { slotToLocalTime } from '../../util/util'
import { parseableTimeSlots } from '../../constants'

export default function MeetingDetails(props) {

  const formatTime = (timeSlot, timeString) => {
    let time = ''
    if (timeSlot) {
      time = parseableTimeSlots[timeSlot]
    } else {
      time = timeString
    }
    return time
  }

  const time = formatTime(props.data.timeSlot, props.data.timeString);
  const localTime = slotToLocalTime(time)

  return (<React.Fragment>
    <Row className={'px-3'}><h4>{localTime} ({time} PST)</h4></Row>
    <Row className="pb-5">
      <Col sm={12} md={6}>
        {
          <div className="h-100 w-100" >
            {props.data.teacherVimeo ? <ResponsiveEmbed aspectRatio="16by9">
              <embed type="image/svg+xml" src={props.data.teacherVimeo} />
            </ResponsiveEmbed> :
              <img src={props.data.classPhoto} />}
          </div>
        }

      </Col>
      <Col sm={12} md={6} className={'justify-content-center align-content-center'}>
        <p><h4 >{props.data.className}</h4></p>
        <Row><Col ><b>Meeting ID:</b> {props.data.zoomMeetingId}</Col></Row>
        <Row><Col><b>Meeting Password:</b> {props.data.zoomMeetingPassword}</Col></Row>
        <Row><Col><b>Teacher:</b> {props.data.teacherName}</Col></Row>
        <Row><Col><b>Email:</b> {props.data.teacherEmail}</Col></Row>
        <Row className={'py-3'}><Col><Button size={'lg'} href={props.data.zoomJoinURL} target="_blank">Go to Zoom</Button></Col></Row>
        <span className={'pt-2'} />
                
      </Col>
    </Row>
  </React.Fragment>)
}