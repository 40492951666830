import React, { useEffect, useState } from 'react'
import StarIcon from '@material-ui/icons/Star';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper, Hidden,
} from '@material-ui/core';
import { LinkContainer } from 'react-router-bootstrap'
import { useAuth } from '../util/auth.js'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useGetSemester, useGetSemesters } from '../util/db'
import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import GradientButton from '../components/GradientButton';
import { useRouter } from '../util/router.js';
import FeaturesSection from './FeaturesSection.js';
import AspectRatio from '../components/AspectRatio.js';
import Image from 'react-bootstrap/Image';
// [theme.breakpoints.down('sm')]: {
//     backgroundColor: theme.palette.secondary.main,
// },
// [theme.breakpoints.up('md')]: {
//     backgroundColor: theme.palette.primary.main,
// },
// [theme.breakpoints.up('lg')]: {
//     backgroundColor: green[500],
// },

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 375,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  media: {
    [theme.breakpoints.down('sm')]: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      maxWidth: 500,
      width: '100%',
      height: 'auto',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
}));

function MightyKidsIntroSection(props) {
  const router = useRouter();

  return (
    <Grid className={'p-2 mb-2'} container alignItems="center" justifyContent="center" direction="column">
      <Grid item xs={12} md={6}>
        <div className="Features2__image-container">
          <AspectRatio ratio={4 / 3}>
            <Image src={'/images/super_kids.webp'} alt={'super hero kids playing'} fluid={true} />
          </AspectRatio>
        </div>
        <Typography  className="mb-2" align="center" variant="h4">A virtual preschool for everyone!</Typography>
               
        <Paper className="mt-4 mb-4" elevation={1}>
          <Typography className="p-5" align="center" variant="h6">Mighty Kids provides comprehensive online preschool programs complete with assessments, customized curriculum and tracking, curated by early childhood education experts for a fraction of the cost of traditional preschools.</Typography>
        </Paper>
        <div className="Features2__image-container">
          <AspectRatio ratio={4 / 3}>
            <Image width="80%" src={'/images/hula-frog-badge.webp'} alt={'hula frogs best virtual preschool 2021'} fluid={true} />
          </AspectRatio>
        </div>
      </Grid>

    </Grid>
  )
}

export default MightyKidsIntroSection
