import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    height: 800,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();

  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const userId = props.data[index].id
    const name = props.data[index].name;
    const email = props.data[index].email;
    return (
      <ListItem selected={props.currentUserId === userId} key={userId} onClick={() => { props.rowSelect(props.data[index]) }} button style={style}>
        <ListItemText secondary={email} primary={name} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={800} width={300} itemSize={56} itemCount={props.data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
