import React, { useEffect, useState } from 'react';
import { useGetMeetingsBySemester, useGetSingleMeeting, useGetStudentsForMeeting } from '../../util/db';
import ClassesList from './ClassesList';
import ClassDetails from './ClassDetails';
import { useRouter } from '../../util/router'
import { Grid } from '@material-ui/core'
import SemesterSelector from '../../components/SemesterSelector';
import Container from '@material-ui/core/Container';

const filtersDefault = [
  {
    options: ['All', 'Math', 'Writing', 'Art', 'Language', 'STEM', 'Social Emotional'],
    label: 'Section',
    value: 'All',
  }];

function StudentRoster(props) {
  const router = useRouter()
  const [semesterId, setSemesterId] = useState()


  const [currentClassId, setCurrentClassId] = useState()
  const [meetingId, setMeetingId] = useState(router.query.classId)
  const [currentClassDetails, setCurrentClassDetails] = useState()
  const [selectedProductId, setSelectedProductId] = useState()
  const [showDetails, setShowDetails] = useState()
  const [resolvedData, setResolvedDate] = useState([])

  const { data: studentList } = useGetStudentsForMeeting(currentClassId);
  const { data: classData, error, loading } = useGetMeetingsBySemester(semesterId);
  const { data: singleClass } = useGetSingleMeeting(meetingId);

  const selectClass = (selClass) => {
    console.log('selecting class', selClass)
    setCurrentClassId(() => selClass.id);
    setSelectedProductId(selClass.productId)
    setCurrentClassDetails(selClass)
    setShowDetails(true);
  };


  useEffect(() => {
      
    if (singleClass) {
      console.log('class that we found:',singleClass)
      setCurrentClassId(meetingId)
      setCurrentClassDetails(singleClass)
      setSelectedProductId(singleClass.productId)
      setShowDetails(true);
    }
   
  }, [singleClass])


  useEffect(() => {
    if(singleClass){
      setResolvedDate([singleClass])
      return
    }
    if(classData){
      setResolvedDate([classData])
      return
    }
  },[classData,singleClass])

  return (
    <>  
      <Container maxWidth="xl">
        <h2>Mighty Kids Zoom Classes</h2>
        <Grid container direction="row" xs={12} lg={12}>
          <Grid container direction="column" xs={12} sm={12} md={5} lg={4}>
            <Grid container direction="row">
              { !singleClass &&
                                <>
                                  <Grid container item xs={12}>
                                    <SemesterSelector semesterId={semesterId} setSemesterId={setSemesterId} hideNow={true} />
                                  </Grid>
                                  <Grid container item xs={12}>
                                    {loading ? 'loading' :
                                      <ClassesList currentClassId={currentClassId} rowSelect={selectClass} data={classData} />}
                                  </Grid>
                                </>
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} md={!singleClass ? 7 : 12} lg={!singleClass ? 8 : 12} >
            {showDetails ?
              <ClassDetails currentClassDetails={currentClassDetails} productId={selectedProductId} semesterId={semesterId} classData={resolvedData} studentList={studentList} />
              : null
            }
          </Grid>

        </Grid>
      </Container>
    </>);
}

export default StudentRoster
