import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Grid, Typography, Button } from '@material-ui/core'
import { useRouter } from '../util/router'
import { useGetContractorMeetingsForChild } from '../util/db'
import { useChildContext } from '../util/childProfileContext'
import { setDate } from 'date-fns'
import moment from 'moment'
// Need to display when classes start for upcoming semester

// display if registrations are available

//

const UpcomingContractorClasses = (props) => {
  const router = useRouter()
  const { upcomingContractorClasses } = useChildContext()
  const [ formattedClasses,setFormattedClasses] = useState();

  useEffect(()=>{
    if(upcomingContractorClasses){
      let formatted = upcomingContractorClasses.map( row => ({ date: moment(row.startDate.toDate()).format('dddd, MMM Do'), className:row.className }))
      setFormattedClasses(formatted)
    }
  },[upcomingContractorClasses])

  const goToAssessment = () => {
    router.push('/assessment')
  }

  const goToRegistration = () => {
    router.push('/class-registration')
  }

  return (
    <Grid className="pb-5 pt-1" container direction="column" alignItems={'center'} justify={'center'} >
            
      {formattedClasses?.length > 0 && <Grid alignItems="center" direction="column" container>
        <Typography variant="body1">
                        Upcoming Enrichment Classes:
        </Typography>
        {formattedClasses.map(row => 
          <Grid className="p-1" key={row.id} direction="row" container alignItems="center" justify="center">
                        
            <Typography className="p-1" align="center"color="primary"  variant="subtitle1">{row.className}</Typography>
            <Typography className="p-1" align="center" variant="subtitle2">Begins on {row.date}</Typography>
                        
          </Grid>)}
      </Grid>}
           
    </Grid>
  )
}

export default UpcomingContractorClasses