import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography,Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const testimonials = [
  { text:'We are going to miss Ms Kirsten and Ms Hayley, our circle times and friends! Thank you - it was a fantastic school year!',name:'Melissa K.' },
  { text:'Ally absolutely loved Mighty Kids Virtual Academy Families - Private Group, Ms Kirsten, and her whole class',name:'Carol M.' },
  { text:'Thank you to the awesome Mighty Kids teachers and team for a great learning experience. Zeke loved learning with you! ',name:'Auj Z.' },
];

export default function TestimonialSection() {
  const classes = useStyles();

  return (
  // <div className={classes.root}>
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item >
        <Typography className="p-2" align="center" variant="h4">
                   See what our parents are saying about Mighty Kids Preschool:
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {testimonials.map(testi => (<Paper key={testi.text} className="p-5 m-5" elevation={1}>
          <Typography variant="body1" >
                    &quot;<em>{testi.text}</em>&quot;
          </Typography>
          <Typography className="pt-3" align="center" variant="subtitle1"  >
                        -{testi.name}
          </Typography>
        </Paper>))}
      </Grid>
    </Grid>
           
      
  );
}