import React, { useEffect, useState } from 'react'
import { Card,ListGroup,ListGroupItem,CardGroup } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner';

let LanguageQuestions = [
  {
    standard: 1,
    question: 'q113_ll-s1',
    title: 'Knows the alphabet rote',
    subtitle: 'This standard is focusing on the alphabet in order by memory (for example the ABC song), not recognizing the letters by sight.',

  },
  {
    standard: 2,
    question: 'q106_ll-s2',
    title: 'Please select all the Uppercase letters the child can identify',
    subtitle: 'Please select the uppercase letters that your child can name. You can have them point to these letters with you if you are not sure.',

  },
  {
    standard: 3,
    question: 'q107_ll-s3',
    title: 'Please select all the Lowercase letters the child can identify',
    subtitle: '',

  },
  {
    standard: 4,
    question: 'q115_ll-s4',
    title: 'Please select all the letters your child knows the sound of',
    subtitle: 'Please select the letters that your child knows the sounds of. You can use these letters to test your child if you are not sure.',

  },
  {
    standard: 5,
    question: 'q98_ll-s5',
    title: 'Understands and names parts of a book (cover, words, author, pages, pictures)',
    subtitle: 'Please select the parts of the book your child can easily identify if any. We are looking for the ability of a child to be able to point to the part of the book when asked. If you’re not sure what they know, sit with them with a book name the parts of books listed here and have them point to them as you go.',

  },
  {
    standard: 6,
    question: 'q99_ll-s6',
    title: 'Recognizes own name and names of some family in print',
    subtitle: 'Kids usually recognize their name first, followed by a few family members’ names.',

  },
  {
    standard: 7,
    question: 'q116_ll-s7',
    title: 'Predicts what will likely happen next in a story and can retell a familiar story',
    subtitle: 'Sit with your child and read a somewhat familiar book with them. Pause part of the way through and ask them to tell you what they think will happen next in the story based on context clues (the things going on in the story). When the story is over, ask them what the story was about. It\'s not important that their predictions are always accurate, but that they understand the concept of using the information available to try and figure out what will likely happen next.',

  },
  {
    standard: 8,
    question: 'q117_ll-s8',
    title: 'Tells Steps for a simple activity (take a bath, make a sandwich)',
    subtitle: 'Ask your child to describe the process of making a sandwich or taking a bath. (We’re looking for several simple steps such as: get out bread and peanut butter, spread it on the bread, put pieces of bread together, and cut sandwich in half)',

  },
  {
    standard: 9,
    question: 'q118_ll-s9',
    title: 'Produces and recognizes rhyming words',
    subtitle: 'If you’re not sure of your child’s understanding of rhyming words, here is a simple test: ask your child if the words sat and pen have the same beginning letter/sound. If they answer correctly, ask your child if the words cup and pup rhyme. If they answer correctly ask them which of the following words doesn’t rhyme: top, spoon, and hop.',

  },
  {
    standard: 10,
    question: 'q119_ll-s10',
    title: 'Uses and understands increasingly complex and varied spoken vocabulary (e.g. “I want yogurt with blueberries please" instead of “i’m hungry”',
    subtitle: 'Uses and understands increasingly complex and varied spoken vocabulary (e.g. “I want yogurt with blueberries please instead of “i’m hungry”',

  },
  {
    standard: 11,
    question: 'q120_ll-s11',
    title: 'Understands opposites',
    subtitle: 'If you’re not sure of your child\'s understanding of opposites, ask them if the opposite of big is small or orange if they answer correctly ask them what the opposite of loud is (quiet, soft or the like are acceptable)',
  },

]


const LanguageReport = (props) => {
  const [scores, setScores] = useState(props?.scores?.scores ? props.scores.scores : false)

  useEffect(() => {
    console.log(props.currentIndex)
    console.log('currentInd score', props.scores)
    props?.scores?.scores && setScores(props.scores.scores)
  },[props.currentIndex])

  useEffect(() => {
    props?.scores?.scores && setScores(props.scores.scores)
  }, [props.scores])

  let languageAnswers = {};

  if (props.answers && props.answers.request && props.answers.request.rawRequest) {
    languageAnswers = props.answers.request.rawRequest
  }

  const renderAnswers = (answers) => {
    if (answers) {
      if (typeof answers === 'string') {
        answers = [answers]
      }

      return answers.map((resp, ind) => {
        let text = '';
        let image = '';
        let arr = resp.split('|');
        if (arr[0]) {
          text = arr[0]
        }
        if (arr[1]) {
          image = arr[1]
        }
        return <div key={'math ans' + ind}> {text} {image !== '' ? <img src={image} height="100" width="100" /> : null} </div>

      })
    } else {
      return 'no answer available for this question'
    }
  }

  return (
    <React.Fragment>
      {!scores && <Spinner animation="border" variant="primary" />}
      {scores && props?.scores?.scores && LanguageQuestions.map((row, index) =>
        <React.Fragment key={row.title}>
          <CardGroup className={'my-4'}>
            <Card>
              <ListGroup>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>Learning Standard {row.standard}: <br /> {row.title}</Card.Title>
                  <div className={'text-secondary'}>{row.subtitle}</div>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>response:</Card.Title>
                  <div className={'text-center text-info'}>{renderAnswers(languageAnswers[row.question])}</div>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>Level:</Card.Title>
                  <div className={'text-center text-info'}>{props?.scores?.scores[row.standard]}</div>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </CardGroup>
        </React.Fragment >)}
    </React.Fragment>
  )
}

export default LanguageReport
