import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea,CardActions,CardContent,Typography } from '@material-ui/core';
import AvatarSection from '../../components_student/AvatarSection'
import { useRouter }from '../../util/router'


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {

  const router = useRouter()
  const navigateToChildManager = (childId) => {
    router.push('/admin/manage-students/' + childId)
  }

  const classes = useStyles();
  return (
    props.childList ? props.childList.map((child, index) =>
      <Card key={child.id} className={classes.root}>
        <CardActionArea onClick={()=> navigateToChildManager(child.id)}>

          <CardContent>
            <AvatarSection picture={child.picture} />
            <Typography gutterBottom variant="h5" component="h2">
              {child.name}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                            birthdate:{child.birthdate}
            </Typography>


          </CardContent>
        </CardActionArea>
      </Card>) : null
  );
}