import React from 'react'
import ManageStudents from '../components_admin/ManageStudents/ManageStudents'
import { requireAuth } from '../util/auth.js'

function ManageStudentsPage(props) {
  return (
    <ManageStudents />
  )
}

export default requireAuth(ManageStudentsPage)