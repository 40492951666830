import React from 'react'
import useSound from 'use-sound'
import DateComponent from '../components/Date'
import { getSup } from '../util/util'
import { days, months } from '../util/util'
import { daysAudio, datesAudio, monthsAudio, yearsAudio, todayIs } from '../util/audioFiles';

function NoName(props) {

  const d = new Date()
  const dayName = days[5]
  const monthName = months[6]
  const day = getSup(31)
  const year = d.getFullYear()
  const [dayAudio, { isPlaying: dayPlaying }] = useSound(daysAudio[5], { volume: d.getDay() === 0 ? 0.5 : 1 })
  const [today, { isPlaying: todayPlaying } ] = useSound(todayIs)
  const [yearAudio, { isPlaying: yearPlaying }] = useSound(yearsAudio[2020])
  const [numberAudio, { isPlaying: numPlaying }] = useSound(datesAudio[31])
  const [monthAudio, { isPlaying: monthPlaying }] = useSound(monthsAudio[6])

  return <DateComponent
    playing={{ yearPlaying, todayPlaying, dayPlaying, numPlaying, monthPlaying }}
    year={yearAudio}
    today={today}
    day={dayAudio}
    number={numberAudio}
    month={monthAudio}
    date={`${dayName}, ${monthName} ${day}, ${year}`}
  />
}

export default NoName