import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import FormField from '../components/FormField'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useAuth } from './../util/auth.js'
import { useForm } from 'react-hook-form'

function SettingsPassword(props) {
  const auth = useAuth()
  const [pending, setPending] = useState(false)

  const { register, handleSubmit, errors, reset, getValues } = useForm()

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true)

    auth
      .updatePassword(data.pass)
      .then(() => {
        // Clear form
        reset()
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your password has been updated',
        })
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          // Update state to show re-authentication modal
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit({ pass: data.pass }),
          })
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          })
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false)
      })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formName">
        <FormField
          name="pass"
          type="password"
          label="New Password"
          placeholder="Password"
          error={errors.pass}
          inputRef={register({
            required: 'Please enter a password',
          })}
        />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <FormField
          name="confirmPass"
          type="password"
          label="Confirm New Password"
          placeholder="Confirm Password"
          error={errors.confirmPass}
          inputRef={register({
            required: 'Please enter your new password again',
            validate: (value) => {
              if (value === getValues().pass) {
                return true
              } else {
                return 'This doesn\'t match your password'
              }
            },
          })}
        />
      </Form.Group>
      <Button
        variant={props.buttonColor}
        size={props.inputSize}
        type="submit"
        disabled={pending}
      >
        <span>Save</span>

        {pending && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden={true}
            className="ml-2"
          >
            <span className="sr-only">Sending...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  )
}

export default SettingsPassword
