import React, { useEffect, useState } from 'react'
import { Modal,Row,Col,Button,Card } from 'react-bootstrap'
import '../components/ModalLauncher.scss'
import { useAuth } from '../util/auth'
import Image from 'react-bootstrap/Image';
import { useGetDailyQoDAnswer } from '../util/db'

function QuestionModal({ childProfile, error, status, submit, toggle, playing, question, item }) {
  const auth = useAuth()
  const [showQuestion, setShowQuestion] = useState(false)
  const { data: prevAnswer, status: answerStatus } = useGetDailyQoDAnswer(childProfile?.id);
  const [answer, setAnswer] = useState(false);

  const response = (answer) => {
    console.log('Answer', answer)
    setAnswer(answer);
    submit(childProfile.id,
      {
        owner: childProfile.owner,
        question,
        answer,
      });
  }

  useEffect(() => {
    if (status !== 'loading' && prevAnswer) {
      setAnswer(prevAnswer.answer);
    }
  }, [prevAnswer]);

  return (
    <>
      <Modal
        show={showQuestion}
        onHide={() => {
          setShowQuestion(false); toggle(false)
        }}
        size={'lg'}
        container={document.body}
      >
        <Modal.Body>
          <Row style={{ fontSize: 100 }}>
            <Col xs={12} className={'text-center pb-5'}>
              <h2>{question}</h2>
            </Col>
            <Col xs={12} className={'text-center'}>

              {/*{item && item.type === 'text' && <h1 style={{ color: 'red'}}>{item.data}</h1>}*/}
              {item && item.type === 'image' && <Image style={{ maxWidth: 400 }} src={item.url} rounded />}
              {/*<span className="icon">*/}
              {/*        <i className="ModalLauncher__blueColor fas fa-square" />*/}
              {/*    </span>*/}


            </Col>

            <Col className={'text-center justify-content-center'}>
              <div onClick={() => { response('YES'); setShowQuestion(false); toggle(false) }}>
                <h1 style={{ cursor: 'pointer' }}>YES</h1>
              </div>
            </Col>
            <Col className={'text-center justify-content-center'}>
              <div onClick={() => { response('NO'); setShowQuestion(false); toggle(false) }}>
                <h1 style={{ cursor: 'pointer' }}>NO</h1>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="md" onClick={() => { setShowQuestion(false); toggle(false) }}>
                        Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className={'shadow-lg bg-white rounded text-center h-100'} style={{ cursor: 'pointer' }} onClick={() => { setShowQuestion(true); toggle(false) }}>

        <Card.Body>
          <Row>
            <Col md={12} lg={4}>

              {answer &&
                                <h1>{answer}</h1>}
              {!answer && <span className="icon">
                <i style={{ color: 'grey' }} className="ModalLauncher fas fa-question-circle" />
              </span>}

            </Col>
            <Col xs={12} xl={8} lg={8} md={12}>
              <Card.Title>Today&apos;s Question:</Card.Title>
              <Card.Text
                style={{
                  fontSize: 40,
                }}
              >
                <Card.Text />
                <h3>{question}</h3>
              </Card.Text>

            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${playing ? 'up' : 'off'}`} /></sub>
        </Card.Footer>
      </Card>
    </>
  )
}

export default QuestionModal
