import React, { useEffect, useState } from 'react'
import VideoSection from '../components_student/VideoSection'
import { useRouter } from '../util/router.js'
import { requireAuth } from '../util/auth.js'
import { useGetSingleVideo } from '../util/db'
import { useAuth } from '../util/auth'
import { useChildContext } from '../util/childProfileContext'
import { paddedDateHash } from '../util/util'
import { createVideoHash } from '../util/videoLogic'
import SegmentSection from '../components/SegmentSection'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'

const getIcons = (data, icons = {}) => {
  if (data['Supplies'] && data['Supplies'].length > 1) icons.supply = 'chalkboard-teacher'
  if (data['Download URL'] && data['Download URL'] !== '') icons.file = 'file-alt'
  return icons
}

function LessonsPage(props) {
  const auth = useAuth()
  const { childProfile } = useChildContext()
  const [videoId, setVideoId] = useState();
  const [noVideo, setNoVideo] = useState(true);
  const [comingSoon, setComingSoon] = useState(false);
  const [loading, setLoading] = useState(true);

  const router = useRouter()
   
  const { data: currentVideo } = useGetSingleVideo(videoId);

  useEffect(()=>{
    if(router.query.videoId){
      setVideoId(router.query.videoId)
    }
  },[router])

  useEffect(() => {
    console.log('current video', currentVideo)
    if (currentVideo) {
      setNoVideo(false);
      setLoading(false);
    }

    if (currentVideo && !currentVideo['URL']) {
      setComingSoon(true)
    }
  }, [childProfile, currentVideo])
  return (
    <>
      { comingSoon && <SegmentSection><Row className="m-2">Video Coming Soon!</Row><Row className="m-2"><Button href={`/lesson/${childProfile?.id}`}>Return to Lesson Page</Button></Row></SegmentSection>}
      { loading ? null : <React.Fragment>
        {noVideo ? <SegmentSection><Row className="m-2">No Video Today</Row><Row className="m-2"><Button href={`/lesson/${childProfile?.id}`}>Return to Lesson Page</Button></Row></SegmentSection> :
          <VideoSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title={currentVideo['Name']}
            subtitle=""
            level={currentVideo['Level']}
            episode={currentVideo['Episode']}
            section={currentVideo['Section']}
            standard={currentVideo['Standard']}
            icons={getIcons(currentVideo)}
            file={currentVideo['Download URL'] || []}
            supply={currentVideo['Supplies'] && currentVideo['Supplies'].length > 1 ? currentVideo['Supplies'] : null}
            optionalSupply={currentVideo['OptionalSupplies'] && currentVideo['OptionalSupplies'].length > 1 ? currentVideo['OptionalSupplies'] : null}
            embedUrl={currentVideo['URL']}
            prep={currentVideo['Prep']}
            description={currentVideo['Description']}
          />
        } </React.Fragment>
      }
    </>
  )
}

export default requireAuth(LessonsPage)
