import React from 'react'
import { requireAuth, useAuth } from '../util/auth.js'
import CssBaseline from '@material-ui/core/CssBaseline';
import ContractorProgram from '../components_public/ContractorProgram/ContractorProgram'
import EnrichmentPageMetaTags from '../components_public/MetaTags/EnrichmentPages';

function ContractorProgramPage() {
  return (<>
    <React.Fragment>
      <EnrichmentPageMetaTags />
      <CssBaseline />
      <ContractorProgram />
    </React.Fragment>
  </>
  )
}

export default ContractorProgramPage
