import React, { useState, useEffect, useContext, createContext } from 'react';

import PageLoader from './../components/PageLoader';
import ProfileSelect from '../components_parent/ProfileSelect';
import { useAuth } from './auth';
import useSessionStorage from './sessionStorage';
import {
  useGetActiveSubscriptions,
  useGetChildrenByOwner,
  useGetContractorMeetingsForChild,
  useGetChildSchedule,
  useGetSemesters,
  useGetZoomMeetingsForChild,
} from './db';
import { Typography, Grid } from '@material-ui/core';
import { sub } from 'date-fns';
// Whether to merge user data from database into auth.user
//const MERGE_DB_USER = true

const childProfileContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function ProvideChildContext({ children }) {
  const childContext = useProvideChildContext();
  return (
    <childProfileContext.Provider value={childContext}>
      {children}
    </childProfileContext.Provider>
  );
}

// Hook that enables any component to subscribe to auth state
export const useChildContext = () => {
  return useContext(childProfileContext);
};
// Provider hook that creates auth object and handles state
function useProvideChildContext() {
  // Store auth user object
  const auth = useAuth();
  const [childProfile, setChildProfile] = useSessionStorage(
    'childProfile',
    sessionStorage.getItem('childProfile'),
  );
  const {
    data: children,
    status,
    error,
  } = useGetChildrenByOwner(auth && auth.user ? auth.user.uid : null);
  const [childId, setChildId] = useState(null);
  const { data: childSubscriptions, status: subStatus } =
    useGetActiveSubscriptions(childProfile?.id || childId, auth?.user?.uid);
  const { data: unclaimedSubscriptions, status: unregisteredStatus } =
    useGetActiveSubscriptions('unregistered', auth?.user?.uid);
  const { data: contractorClasses } = useGetContractorMeetingsForChild(
    childProfile?.id,
  );
  const { data: mkClasses } = useGetZoomMeetingsForChild(childProfile?.id);
  const { data: schedules } = useGetChildSchedule(childProfile?.id);
  const { data: semesters } = useGetSemesters();

  const [videoLessonAccess, setVideoLessonAccess] = useState(false);
  const [subscriptions, setSubscriptions] = useState();
  const [generatingSchedule, setGeneratingSchedule] = useState(false);
  const [zoomMeetingAccess, setZoomMeetingAccess] = useState(false);
  const [assessmentAccess, setAssessmentAccess] = useState(false);
  const [needsAssessment, setNeedsAssessment] = useState(false);
  const [availableRegistrations, setAvailableRegistrations] = useState(false);
  const [videoSchedule, setVideoSchedule] = useState();
  const [hasMkPlan, setHasMkPlan] = useState(false);
  const [mightyKidsCorePlans, setMightyKidsCorePlans] = useState();
  const [upcomingContractorClasses, setUpcomingContractorClasses] = useState();
  const [currentContractorClasses, setCurrentContractorClasses] = useState();
  // const [contractorSubscriptions, setContractorSubscriptions] = useState()
  const [currentZoomClasses, setCurrentZoomClasses] = useState();
  const [upcomingZoomClasses, setUpcomingZoomClasses] = useState();
  const [registeredSemesters, setRegisteredSemesters] = useState([]);
  const [currentSemester, setCurrentSemester] = useState();

  useEffect(() => {
    let newSubArrayVal = [];

    if (childSubscriptions && childSubscriptions.length > 0) {
      newSubArrayVal = childSubscriptions;
    }

    if (unclaimedSubscriptions && unclaimedSubscriptions.length > 0) {
      newSubArrayVal = [...newSubArrayVal, ...unclaimedSubscriptions];
    }
    setSubscriptions(newSubArrayVal);
  }, [childSubscriptions, unclaimedSubscriptions]);

  useEffect(() => {
    if (mkClasses) {
      try {
        let today = new Date();
        let futureClasses = mkClasses.filter((row) => {
          return row.startDate.toDate() > today;
        });
        let currentClasses = mkClasses.filter((row) => {
          return row.startDate.toDate() <= today;
        });
        setUpcomingZoomClasses(futureClasses);
        setCurrentZoomClasses(currentClasses);
      } catch (e) {
        console.error(e);
        setUpcomingZoomClasses();
        setCurrentZoomClasses();
      }
    } else {
      setUpcomingZoomClasses();
      setCurrentZoomClasses();
    }
  }, [mkClasses]);

  useEffect(() => {
    if (status === 'success') {
      if (children.length === 0) {
        setChildProfile(null);
      }
    }
  }, [status]);

  useEffect(() => {
    if (contractorClasses) {
      let today = new Date();

      let futureClasses = contractorClasses.filter(
        (row) => row.startDate.toDate() > today,
      );
      let currentClasses = contractorClasses.filter(
        (row) => row.startDate.toDate() <= today,
      );
      setUpcomingContractorClasses(futureClasses);
      setCurrentContractorClasses(currentClasses);
    }
  }, [contractorClasses]);

  useEffect(() => {
    if (childProfile && children) {
      // console.log(childProfile.id)
      children.map(
        ({
          created,
          name,
          picture,
          birthdate,
          id,
          owner,
          classes,
          mathAssessmentDate,
          languageAssessmentDate,
          writingAssessmentDate,
          scheduleGenerated,
        }) => {
          if (childProfile.id === id)
            setChildProfile({
              created,
              name,
              picture,
              birthdate,
              id,
              owner,
              classes,
              mathAssessmentDate,
              languageAssessmentDate,
              writingAssessmentDate,
              scheduleGenerated,
            });
        },
      );
    } else if (children && children.length > 0) {
      //default child
      const {
        created,
        name,
        picture,
        birthdate,
        id,
        owner,
        classes,
        mathAssessmentDate,
        languageAssessmentDate,
        writingAssessmentDate,
        scheduleGenerated,
      } = children[0];
      setChildProfile({
        created,
        name,
        picture,
        birthdate,
        id,
        owner,
        classes,
        mathAssessmentDate,
        languageAssessmentDate,
        writingAssessmentDate,
        scheduleGenerated,
      });
    }
  }, [children]);

  useEffect(() => {
    if (children) {
      children.map(
        ({
          created,
          name,
          picture,
          birthdate,
          id,
          owner,
          classes,
          mathAssessmentDate,
          languageAssessmentDate,
          writingAssessmentDate,
          scheduleGenerated,
        }) => {
          if (id === childId)
            setChildProfile({
              created,
              name,
              picture,
              birthdate,
              id,
              owner,
              classes,
              mathAssessmentDate,
              languageAssessmentDate,
              writingAssessmentDate,
              scheduleGenerated,
            });
        },
      );
    }
  }, [childId]);

  const signout = () => {
    setChildProfile(null);
    setChildId(null);
    setVideoLessonAccess(false);
    setGeneratingSchedule(false);
    setZoomMeetingAccess(false);
    setAssessmentAccess(false);
    setNeedsAssessment(false);
    setAvailableRegistrations(false);
    setVideoSchedule();
    setUpcomingContractorClasses();
    setCurrentContractorClasses();
    // const [contractorSubscriptions, setContractorSubscriptions] = useState()
    setCurrentZoomClasses();
    setUpcomingZoomClasses();
    setRegisteredSemesters([]);
    setCurrentSemester();
  };

  useEffect(() => {
    let newVal;
    if (semesters && registeredSemesters.length > 0) {
      for (let i = 0; i < semesters.length; i++) {
        let result = registeredSemesters.find((id) => id === semesters[i].id);
        if (result) {
          console.log('semester match found!', result);
          newVal = semesters[i];
          break;
        }
      }
    }
    setCurrentSemester(newVal);
  }, [registeredSemesters, semesters]);

  useEffect(() => {
    if (subscriptions?.length > 0) {
      let videoLessons = subscriptions.find((doc) => doc.videoLessons === true);
      let videoMeetings = subscriptions.find(
        (doc) => doc.zoomMeetings === true,
      );
      let assessmentAccess = subscriptions.find(
        (doc) => doc.assessmentAccess === true,
      );
      let availableRegistrations = subscriptions.find(
        (doc) => doc.availableRegistrations?.length > 0,
      );

      let contractorSubscriptions = subscriptions.find(
        (doc) => doc.metadata?.contractorId,
      );
      // setContractorSubscriptions(contractorSubscriptions)

      let currentMKPlans = subscriptions.filter((doc) => doc.mkPlanId);

      if (currentMKPlans && currentMKPlans.length > 0) {
        setHasMkPlan(true);
        setMightyKidsCorePlans(currentMKPlans);
      } else {
        setHasMkPlan(false);
        setMightyKidsCorePlans();
      }
      // console.log('current mighty kids plans',currentMKPlans)
      let mkSemesterList = [];

      currentMKPlans.forEach((mkPlan) => {
        if (mkPlan.semesterId && mkPlan.semesterId !== 'now') {
          mkSemesterList.push(mkPlan.semesterId);
        }
      });
      console.log('current semesters:', mkSemesterList);
      setRegisteredSemesters(mkSemesterList);
      //get mk plan subscription is for to determine when will classes start.

      if (videoLessons) {
        setVideoLessonAccess(true);
      } else {
        setVideoLessonAccess(false);
      }
      if (videoMeetings || contractorSubscriptions) {
        setZoomMeetingAccess(true);
      } else {
        setZoomMeetingAccess(false);
      }
      if (videoLessons || assessmentAccess) {
        setAssessmentAccess(true);
      } else {
        setAssessmentAccess(false);
      }

      if (availableRegistrations) {
        console.log('setting registrations:', availableRegistrations);
        setAvailableRegistrations([availableRegistrations]);
      } else {
        console.log('setting registrations false');
        setAvailableRegistrations(false);
      }
    } else {
      setAvailableRegistrations(false);
      setVideoLessonAccess(false);
      setZoomMeetingAccess(false);
      setAssessmentAccess(false);
      setHasMkPlan(false);
      setRegisteredSemesters([]);
      setMightyKidsCorePlans();
    }
  }, [subscriptions]);

  useEffect(() => {
    if (schedules?.length > 0) {
      let schedule = schedules[0];
      let holidays = schedule?.holidays?.map((date) => {
        const split = date.split('-');
        return new Date(split[0], split[1] - 1, split[2]);
      });
      setVideoSchedule({ ...schedule, holidays });
    } else {
      setVideoSchedule();
    }
  }, [schedules]);

  useEffect(() => {
    if (assessmentAccess || videoLessonAccess) {
      //case 1 new child, never taken assessment
      if (
        childProfile?.writingAssessmentDate &&
        childProfile?.languageAssessmentDate &&
        childProfile?.mathAssessmentDate
      ) {
        //case 2 child has taken assessment and they are ready for a new semester
        if (videoSchedule && videoSchedule.completed) {
          console.log('needs assessemnt!!!');
          setNeedsAssessment(true);
        } else {
          setNeedsAssessment(false);
        }

        if (!childProfile.scheduleGenerated) {
          setGeneratingSchedule(true);
        } else {
          setGeneratingSchedule(false);
        }
      } else {
        setNeedsAssessment(true);
      }
      //we know they need videos
    } else {
      setNeedsAssessment(false);
      setGeneratingSchedule(false);
    }
  }, [videoSchedule, childProfile, assessmentAccess, videoLessonAccess]);
  // if(childId)

  return {
    childProfile,
    hasMkPlan,
    currentSemester,
    mightyKidsCorePlans,
    availableRegistrations,
    needsAssessment,
    assessmentAccess,
    generatingSchedule,
    zoomMeetingAccess,
    videoLessonAccess,
    subStatus,
    upcomingContractorClasses,
    currentContractorClasses,
    currentZoomClasses,
    upcomingZoomClasses,
    contractorClasses,
    status,
    videoSchedule,
    children,
    signout,
    setChildId,
  };
}

// A Higher Order Component for requiring authentication
export const requireChildProfile = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    // Get authenticated user
    const { childProfile, status } = useChildContext();

    if (status === 'loading') {
      return <PageLoader />;
    }

    // useEffect(() => {
    // Redirect if not signed in
    console.log(childProfile);
    if (!(childProfile && childProfile.id)) {
      //history.replace(history.location.pathname.startsWith('/') ? `/settings/children?next=${history.location.pathname}` : '/settings/children')
      return (
        <Grid>
          <Typography align="center" variant="h5">
            A child profile is required to continue:
          </Typography>
          <ProfileSelect />
        </Grid>
      );
    }
    // }, [childProfile])

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    // if (!auth.user) {
    //     return <PageLoader />
    // }

    // Render component now that we have user
    return <Component {...props} />;
  };
};
