import React, { useEffect, useState } from 'react'
import { Card,ListGroup,ListGroupItem,CardGroup } from 'react-bootstrap'
import '../components_public/ScheduleCard.scss'
import Spinner from 'react-bootstrap/Spinner';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

let mathQuestions = [
  {
    question: 'q113_m-1',
    standard: 1,
    title: 'Builds complex towers or buildings with blocks or construction toys',
    subtitle: 'Have your child build a tower and mark how many blocks the child is able to stack successfully',

  },
  {
    standard: 2,
    question: 'q124_m-2',
    title: 'Uses Position words (top, bottom, front, back)',
    subtitle: 'Please select the words your child can consistently use in the correct context (if not sure, show them a block and ask them to show you the top, the bottom, front and back without giving them hints)',
  },
  {
    standard: 3,
    question: 'q125_m-3',
    title: 'Uses time of day and sequence words (morning, night, before, after)',
    subtitle: 'Ask your child what time of day they get out of bed & what time of day they go to bed. Ask them if they eat breakfast before or after they eat lunch and if they eat dinner before or after they eat lunch. Try not to give any hints as we want to make sure they know the terms consistently before they move on. Please mark which words your child has a good handle on.',
  },
  {
    standard: 4,
    question: 'q126_m-4',
    title: 'Sorts objects by attributes (color, size, shape, type)',
    subtitle: 'If you’re not sure of your child’s sorting skills, bring out a pile of small items of different colors, sizes, shapes, and types. Ask your child to sort the pile by each of these attributes one by one. Try not to hint as we want to be sure they are solid on these concepts before moving on.',
  },
  {
    standard: 5,
    question: 'q128_m-5',
    title: 'Counts a set of objects accurately (Demonstrates 1:1 correspondence)',
    subtitle: 'Put out up to 10 small to medium items and ask your child to count these items. Try not to touch each one for them as they count as you are looking for their ability to relate the actual objects to what the numbers they are saying on their own. Please mark the answer that best describes how high your child can count a set of objects.',
  },
  {
    standard: 6,
    question: 'q130_m-6',
    title: 'Matches numerals and quantities (e.g. matches 5 with picture of 5 birds)',
    subtitle: 'Write a number down and place a small pile of small objects in front of the child (beads or cotton balls work well). Ask your child to place the same number of objects as what is written on the paper in front of them. Repeat for numbers 1-10. Mark all numbers they can do.',
  },
  {
    standard: 7,
    question: 'q159_m-7',
    title: 'Writes numbers from 1-10 that start at the top and face correctly',
    subtitle: 'Have your child write numbers starting with 1 and increasing to 10. Select all numbers they can complete correctly.',
  },
  {
    standard: 8,
    question: 'q131_m-8',
    title: 'Measures and understands concepts such as longer, shorter, taller, wider and equal (or same) length',
    subtitle: 'Show your child two items (it helps if they are narrow and simple rectangle shaped items). Have your child tell you which is longer. Now show them two different items and have them tell you which is shorter. Now show them two different items and have them tell you which is wider. Now bring out three items, two are the same and one is different. Have your child tell you which two are the same and which is different. Mark which words the child is able to consistently identify.',
  },
  {
    standard: 9,
    question: 'q132_m-9',
    title: 'Point to each shape and have your child identify it. Mark each one they are able to correctly identify.',
    subtitle: 'How many shapes does your child recognize and name? Circle, triangle, rectangle, square, hexagon.',
  },
  {
    standard: 10,
    question: 'q140_m-10',
    title: 'Have your child count the sides of the shapes on the screen. Mark each one they are able to correctly count.',
    subtitle: 'Knows how many sides common shapes contain (square, rectangle, triangle, hexagon)',
  },
  {
    standard: 11,
    question: 'q141_m-11',
    title: 'Demonstrates patterning (e.g. recognizes, creates and copies patterns at least as difficult as ABABAB: yellow, blue, yellow, blue etc). Use colored chips or other manipulatives to test your child’s strength in patterning',
    subtitle: 'Select which option below best represents your child\'s ability',
  },
  {
    standard: 12,
    question: 'q144_m-12_1',
    title: 'Select the image with the bird UNDER or BELOW the box',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q153_m-12_2',
    title: 'Select the image with the bird BETWEEN the boxes',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q154_m-12_3',
    title: 'Select the image with the bird NEXT TO the box',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q155_m-12_4',
    title: 'Select the image with the bird IN FRONT of the box',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q156_m-12_5',
    title: 'Select the image with the bird BEHIND the box',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q157_m-12_6',
    title: 'Select the image with the bird ABOVE or ON TOP OF the box',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q150_m-12_7',
    title: 'Select the image with the bird OUTSIDE the circle',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 12,
    question: 'q158_m-12_8',
    title: 'Select the image with the bird INSIDE the circle',
    subtitle: 'Have your child point to the correct image of the bird. If they cannot identify the correct image, choose Not Quite Yet',
  },
  {
    standard: 13,
    question: 'q142_m-13',
    title: 'Rote counts (counts numbers from memory without using objects or pictures)',
    subtitle: 'Simply ask your child to count as high as they can out loud without hinting and input the answer below',
  },
  {
    standard: 14,
    scoreNumber: '1',
    question: 'q143_m-14',
    title: 'Can recognize and name colors (Red, Green, Blue, Orange, Yellow, Black, White, purple)',
    subtitle: 'Please mark the colors your child can identify consistently without help.',
  },

]


const MathReport = (props) => {
  const [scores, setScores] = useState(props?.scores?.scores ? props.scores.scores : false)
  const [data, setData] = useState(false)

  console.log(props.answers)
  console.log('scores', props?.scores?.scores)

  useEffect(() => {
    console.log(props.currentIndex)
    console.log('currentInd score', props.scores)
    props?.scores?.scores && setScores(props.scores.scores)
  },[props.currentIndex])

  useEffect(() => {
    props?.scores?.scores && setScores(props.scores.scores)
    props?.scores?.scores && setData({
      labels: labels,
      datasets: [{
        label: 'Math Levels',
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        data: [Object.values(props?.scores?.scores)],
      }],
    })
  }, [props.scores])

  const labels = [
    'Complex Structures - Gross Motor',
    'Position words',
    'Time of day and sequence words',
    'Sorting',
    'Counting Objects',
    'Numeral Object Matching',
    'Writing Numbers',
    'Measuring Concepts',
    'Identify Shapes',
    'Understand Shapes',
    'Patterning',
    'Relational Positions',
    'Wrote Counting',
    'Color Recognition',
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Math Levels',
      },
    },
  };
  let mathAnswers = {};
    
  if (props.answers && props.answers.request && props.answers.request.rawRequest) {
    mathAnswers = props.answers.request.rawRequest
  }

  const renderAnswers = (answers) => {
    if (answers) {
      if (typeof answers === 'string') {
        answers = [answers]
      }

      return answers.map((resp, ind) => {
        let text = '';
        let image = '';
        let arr = resp.split('|');
        if (arr[0]) {
          text = arr[0]
        }
        if (arr[1]) {
          image = arr[1]
        }
        return <div key={'math ans' + ind}> {text} {image !== '' ? <img src={image} height="100" width="100" /> : null} </div>

      })
    } else {
      return 'no answer available for this question'
    }
  }

  return (
    <React.Fragment>
      {!scores && <Spinner animation="border" variant="primary" />}
      {/*{scores && data && <Bar data={data} options={options}/>}*/}
      {scores && props?.scores?.scores && mathQuestions.map((row, index) =>
        <React.Fragment key={row.title}>
          <CardGroup className={'my-4'}>
            <Card>
              <ListGroup>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>Learning Standard {row.standard}: <br /> {row.title}</Card.Title>
                  <div className={'text-secondary'}>{row.subtitle}</div>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>response:</Card.Title>
                  <div className={'text-center text-info'}>{renderAnswers(mathAnswers[row.question])}</div>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Title className={'text-center'}>Level:</Card.Title>
                  <div className={'text-center text-info'}>{props?.scores?.scores[row.standard]}</div>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </CardGroup>
        </React.Fragment >)}
    </React.Fragment>
  )
}

export default MathReport
