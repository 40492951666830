import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import Container from '@material-ui/core/Container';
import MailIcon from '@material-ui/icons/Mail'
import Typography from '@material-ui/core/Typography';
import ContactPageMetaTags from '../components_public/MetaTags/ContactPage';
import { faEnvelope, faHeadset, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookMessenger, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    // padding: theme.spacing(2),
  },
}));

const ContactPage = () =>  {
  const classes = useStyles();

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '20689336',
    region: 'na1',
    formId: '0286c88b-1ff1-4bd1-93e2-e9580398a895',
    target: '#hubspot-contact-form',
  });

  return (
    <React.Fragment>

      <ContactPageMetaTags />
      <Container maxWidth="lg">

        <Grid container alignItems="center" direction="column" alignContent="center">
          <Grid container direction="row" xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h3" component="h1" style={{ paddingTop: 20, paddingBottom: 30 }}>Contact Us</Typography>
          </Grid>

          <Grid container direction="row" xs={8} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1" style={{ paddingTop: 20, paddingBottom: 10 }}>Phone: <a href="tel:14252300279">+1 (425) 230-0279</a></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ paddingTop: 20, paddingBottom: 10 }}>Email: <a href="mailto:info@mightykidsvirtualacademy.com">info@mightykidsvirtualacademy.com</a></Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h4" style={{ paddingTop: 20, paddingBottom: 10 }}>Quick Links</Typography>
          </Grid>
          <Grid container direction="row" sm={8} md={8} justifyContent="center" alignItems="center" style={{ paddingBottom: 30 }}>
            <Grid item xs={6} sm={3} md={2} container direction="column" justifyContent="center" alignContent="center" style={{ paddingBottom: 30 }} >
              <a href="tel:14252300279" target="_blank"
                className="call-button" data-show-screen-name="false" data-show-count="false" rel="noreferrer">
                <FontAwesomeIcon icon={faPhone} size="4x" />
              </a>
              <Typography align="center">Call Us</Typography>

            </Grid>
            <Grid item xs={6} sm={3} md={2} container direction="column" justifyContent="center" alignContent="center" style={{ paddingBottom: 30 }}>
              <a href="mailto:info@mightykidsvirtualacademy.com" target="_blank"
                className="mail-button" data-show-screen-name="false" data-show-count="false" rel="noreferrer">
                <FontAwesomeIcon icon={faEnvelope} size="4x" />
              </a>
              <Typography align="center">Email</Typography>

            </Grid>
            <Grid item xs={6} sm={3} md={2} container direction="column" justifyContent="center" alignContent="center" style={{ paddingBottom: 30 }}>
              <a href="https://m.me/MightyKidsSchool" target="_blank"
                className="facebook-mention-button" data-show-screen-name="false" data-show-count="false" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookMessenger} size="4x" />
              </a>
              <Typography align="center">Messenger</Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={2} container direction="column" justifyContent="center" alignContent="center" style={{ paddingBottom: 30 }}>
              <a href="https://twitter.com/intent/tweet?screen_name=MightyKidsPrek&ref_src=twsrc%5Etfw" target="_blank"
                className="twitter-mention-button" data-show-screen-name="false" data-show-count="false" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="4x" />
              </a>
              <Typography align="center">Tweet Us</Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={2} container direction="column" justifyContent="center" alignContent="center" style={{ paddingBottom: 30 }}>
              <a href="https://mightykids.atlassian.net/servicedesk/customer/portal/3" target="_blank"
                className="twitter-mention-button" data-show-screen-name="false" data-show-count="false" rel="noreferrer">
                <FontAwesomeIcon icon={faHeadset} size="4x" />
              </a>
              <Typography align="center">Support</Typography>

            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-around">
            <Grid  item xs={12} sm={10} md={8} lg={8} container justifyContent="center">
              <div id="hubspot-contact-form" style={{ width: '100vw' }} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
export default ContactPage