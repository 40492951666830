import React from 'react'
import { Link, useRouter } from './../util/router.js'
import './AuthFooter.scss'
function AuthFooter(props) {
  const router = useRouter()
  let next = router.query.next ? `?next=${router.query.next}` : '';
  return (
    <div className="AuthFooter text-center mt-4">
      {props.type === 'signup' && (
        <>
          Have an account already?
          <Link to={`/auth/signin${next}`}>{props.typeValues.linkTextSignin}</Link>
        </>
      )}

      {props.type === 'signin' && (
        <>
          <Link to={`/auth/signup${next}`}>{props.typeValues.linkTextSignup}</Link>
          <Link to="/auth/forgotpass">
            {props.typeValues.linkTextForgotpass}
          </Link>
        </>
      )}
    </div>
  )
}

export default AuthFooter
