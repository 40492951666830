import { FormControl, Select, MenuItem, InputLabel, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useCountryRegion } from 'react-use-country-region';

const supportedCountries = ['US','CA','BS','MX','VE','CL','AR','EC','PE'].sort()
function CardHolder(props) {


  const { getCountryList } = useCountryRegion();
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [ country, setCountry] = useState('');
  const [ region, setRegion] = useState('');
    

  useEffect(()=> {
    if(!country && props.cardHolderData?.address_country){
      setCountry('US')
    }
  },[])

  useEffect(()=> {
    const allCountries = getCountryList();
    const selectOptions = supportedCountries.map( code => {
      return allCountries.find(cty => cty.countryShortCode === code);
    })
    setCountryList(selectOptions)
    console.log(selectOptions)
  },[])

  useEffect(()=>{
    if(country){
      const selected = countryList.find(row => row.countryShortCode === country)
      console.log('selected country:',selected)
      setRegionList(selected.regions)
    }
  },[country])

  const selectCountry = (val) => {
    setCountry(val);
    props.setCardHolderData({ ...props.cardHolderData, address_country: val })
  }

  const selectRegion = (val) => {
    setRegion(val);
    props.setCardHolderData({ ...props.cardHolderData, address_state: val })
  }


  const handleInputChange = e => {
    const { name, value } = e.target
    props.setCardHolderData({ ...props.cardHolderData, [name]: value })
  }

  return(<Grid className={'pl-5 pr-5 pt-3'}>
    <TextField className="mt-3" onChange={handleInputChange} style={{ width:'100%' }} value={props.cardHolderData?.name} name="name" label="Name on Card" variant="standard" />
    <TextField className="mt-3" onChange={handleInputChange} style={{ width:'100%' }} value={props.cardHolderData?.address_line1} name="address_line1" label="Address Line 1" variant="standard" />
    <TextField className="mt-3" onChange={handleInputChange} style={{ width:'100%' }} value={props.cardHolderData?.address_line2} name="address_line2" label="Address Line 2" variant="standard" />
    <TextField className="mt-3" onChange={handleInputChange} style={{ width:'100%' }} value={props.cardHolderData?.address_city} name="address_city" label="City" variant="standard" />

    <FormControl className="mt-3" fullWidth>
      <InputLabel fullWidth id="country-select-label">Country</InputLabel>
      <Select
                
        style={{ width:'100%' }}
        labelId="country-select-label"
        value={country}
        label="Country"
        onChange={event => {
          selectCountry(event.target.value) 
        }}
      >
        <MenuItem key={'none'} value={''}>Select your country</MenuItem>
        {countryList.map( country => <MenuItem key={country.countryShortCode} value={country.countryShortCode}>{country.countryName}</MenuItem>)}
      </Select>
    </FormControl>

    <FormControl className="mt-3" fullWidth>
      <InputLabel id="region">State</InputLabel>
      <Select
        disabled={country === ''}
        style={{ width:'100%' }}
        labelId="region"
        value={region}
        label="State"
        onChange={event => selectRegion(event.target.value)}
      >
        {regionList.map( region => <MenuItem key={region.shortCode} value={region.shortCode}>{region.name}</MenuItem>)}
      </Select>
    </FormControl>
        
  </Grid>
  )
}

export default CardHolder
