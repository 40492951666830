import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Button, Grid } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { longSchedule, slotToLocalTime } from '../../util/util';
import { useGetContractorProfile } from '../../util/db';
import moment from 'moment';
import { useRouter } from '../../util/router.js';
import { SPECIALTIES_MAP } from '../../constants';
const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    // margin: 5,
  },
  media: {
    height: 140,
    width: '100%',
  },
});

export default function MediaCard(props) {
  const router = useRouter();
  const classes = useStyles();
  const { data: contractorData } = useGetContractorProfile(props.data.contractorId);

  const schedule = longSchedule(props.data);
  const times = longSchedule(props.data);
  const classFull = props.data.studentCount >= props.data.maxStudents;

  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  let price = props.data.classPrice
    ? '$' + (Number(props.data.listPrice) / 100).toFixed(2) + ' per week'
    : 'price not set';

  let localTime = props.data.timeString ? slotToLocalTime(props.data.timeString) : '';

  useEffect(() => {
    console.log('contractor id:', props.data.contractorId);
    console.log('contractor data:', contractorData);
  }, [contractorData, props]);

  const selectClass = () => {
    router.push(`/purchase/enrichment/${props.data.contractorId}-${props.data.id}`);
  };

  return (
    <Card key={props.data.id} className={classes.root + ' mb-3 mx-1 p-2'}>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={3} style={{ borderRadius: '26px' }}>
          <div
            style={{
              backgroundImage: `url(${props.data.classPhoto})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              width: '100%',
              height: 200,
              //backgroundSize: 'contain',
            }}
          />
          <Grid item className="mt-2" style={{ textAlign: 'center' }}>
            <Typography className={'py-1'} align="center" variant="subtitle1">
              {price}
            </Typography>
            {!classFull && !alreadyRegistered && (
              // <Grid className="py-2" container direction="column" justifyContent="center" alignItems="center">
              <Button variant="contained" color="primary" onClick={selectClass}>
                Register Now!
              </Button>
              // </Grid>
            )}
            {alreadyRegistered && (
              <Typography align="center" color="primary" className="py-2" gutterBottom variant="subtitle1">
                Currently Registered{' '}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} med={6} direction="row" style={{ overflow: 'scroll' }}>
          <Grid item>
            <Typography gutterBottom variant="h4" component="h4">
              {props.data.className}
            </Typography>
            <Typography variant="subtitle1">Class Meets {schedule}</Typography>
            <Typography align="left" variant="subtitle1" color="textSecondary">
              {localTime}
            </Typography>
            <Typography align="left" variant="subtitle1" color="textSecondary">
              {props.data.classDuration} minutes
            </Typography>

            <Grid direction="row">
              <Grid>
                <Typography className={''} variant="subtitle2">
                  From: {moment(props.data.startDate).format('MMM Do, YYYY')}
                </Typography>
              </Grid>
              <Grid>
                <Typography className={''} variant="subtitle2">
                  Until: {moment(props.data.endDate).format('MMM Do, YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ height: '150px' }}>
              <Typography
                color="textSecondary"
                style={{
                  maxWidth: '80vw',
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                  textOverflow: 'ellipses',
                }}
                gutterBottom
                variant="p"
                component="p"
              >
                {props.data.description}
              </Typography>
              <Stack style={{ flexWrap: 'wrap' }} direction="row" spacing={1}>
                {props.data.tags?.map((tag) => (
                  <Chip color="secondary" key={tag} label={tag} size="small" />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          med={3}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <div
            style={{
              marginTop: '10px',
              WebkitBorderRadius: '20px',
              mozBorderRadius: '20px',
              borderRadius: '20px',
              border: 'none',
              backgroundImage: `url(${contractorData?.profilePhoto})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: 100,
              height: 100,
              backgroundSize: 'cover',
            }}
          />

          <Typography className="pt-3" align="left">
            {contractorData?.teacherName}
          </Typography>

          {props.data.contractorSpecialties?.length && (
            <Grid overflowWrap spacing={1} className="px-3">
              Specializes in:{' '}
              {props.data.contractorSpecialties.map((row) => (
                <Chip
                  style={{ margin: '2px' }}
                  size="small"
                  label={SPECIALTIES_MAP[row]}
                  key={row + contractorData?.teacherName}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
