import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();

  useEffect(()=>{
    console.log(props.currentProduct)
  },[props.currentProduct])

  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const name = props.data[index].name
    const id = props.data[index].id;
    console.log('currentProduct',props.currentProduct)
    console.log('id',id)
    return (
      <ListItem selected={id === props.currentProduct} key={index} onClick={() => { props.rowSelect(id) }} button style={style}>
        <ListItemText primary={name} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={400} width={400} itemSize={46} itemCount={props.data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
