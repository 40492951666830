import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import Features from './Features'
import Image from 'react-bootstrap/Image'

function FeaturesSection(props) {
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <figure className="HeroSection__image-container mx-auto">
          <Image src={props.image} fluid={true} />
        </figure>
        <Features
          items={[
            {
              title: 'Assessments',
              description:
                                'Our initial comprehensive assessment will determine where your child is in all areas of kindergarten readiness including Math, Writing & Fine Motor, Language and Literature, and Social Emotional learning. \
                                The standards we use are research based and pulled from the NAEYC (National Association for the Education of Young Children), The Department of Health as well as well-known and respected curriculum Learning Without Tears. Our curriculum is compiled by our team of Early Childhood Education experts with various college degrees in Early Childhood Education and many years of experience in the field. \
                                Students will reassess every semester to ensure that your child is getting the most out of their time here at Mighty Kids Virtual Academy.',
              image: '/images/assessment.jpg',
            },
            {
              title: 'Daily Lessons & Activities',
              description:
                                'After the initial assessment, we curate three lessons, three days per week and make them accessible in your child’s classroom. These lessons are specific to your child in each standard and no child will receive the same curriculum as all children are unique in their learning. The activities will come in the form of videos and often files to download along with them. \n The classroom also includes other preschool information for your child to explore such as question of the day, date, days of the week, letter of the day, and emotion of the day. These activities can be done by the child along with the video or a parent can watch and perform the activities with the child themselves, if preferred. ',
              image: '/images/heart.jpeg',
            },
            {
              title: 'Daily Live Zoom',
              description:
                                'Live Zoom classes will be held daily and students will have the option of 2, 3, or 5 days per week to attend. Our educators will engage with small groups live in areas of music, skill practice, games, experiments, letters, sound practice and more. \n',
              image:
                                '/images/child_zoom.webp',
            },
            {
              title: 'Grows With Your Child',
              description:
                            'As your child continues to assess every semester, their curriculum will change with them to reflect their growth in each area. If your child is excelling in an area quickly, you will have the opportunity to move your child up between assessments to keep them learning at their unique speed. \n We keep track of your child’s progress and offer opportunities for parents to make observations on their child’s progress and store them in our system for later. Our premium service provides ECE teachers on hand for support and guidance whenever needed.',
              image:
                                '/images/child_grow.jpg',
            },
          ]}

        />
      </Container>
    </Segment>
  )
}

export default FeaturesSection
