import React from 'react'
import { Row,Col } from 'react-bootstrap'

function Clients(props) {
  return (
    <Row className="justify-content-center">
      {props.items.map((item, index) => (
        <Col md="auto" className="py-3 px-4" key={index}>
          <div className="align-bottom">
            <a href={item.url}><img src={item.image} width={item.width} alt={item.name} /></a>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default Clients
