import React from 'react'
import QuestionManager from '../components_admin/QuestionManager/QuestionManager';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function QuestionManagerPage(props) {
  return (<>
    <GoBack />
    <QuestionManager />
  </>
  )
}

export default requireAuth(QuestionManagerPage)
