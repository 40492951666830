import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { useGetAllMeetings } from '../util/db'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {

  const { data } = useGetAllMeetings()

  const classes = useStyles();
    


  useEffect(()=>{
    console.log(props.currentProduct)
  },[props.selectedClasses])

  const selectProduct = (id) => {
    if(props.selectedClasses.find(row => row === id)){
      props.setSelectedClasses(props.selectedClasses.filter(row => row !== id))
    } else {
      let newList = [...props.selectedClasses,id]
      props.setSelectedClasses(newList)
    }
  }

  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const { id, className, teacherName } = data[index]
    console.log('currentProduct',props.currentProduct)
    console.log('id',id)
    return (
      <ListItem selected={ props.selectedClasses.find(row => row === id) }  key={index} onClick={() => { selectProduct(id) }} button style={style}>
        <ListItemText primary={`${className} ${teacherName}`} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={400} width={400} itemSize={46} itemCount={data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
