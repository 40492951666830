import React from 'react';
import { requireAuth, useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { useChildContext } from '../util/childProfileContext';

const prodForm = '220385155374153';
const devForm = '220385155374153'; //'220577154011143'

let form = process.env.NODE_ENV === 'production' ? prodForm : devForm;

const PlayRegistrationPage = () => {
  const auth = useAuth();
  const router = useRouter();
  const { childProfile, status } = useChildContext();
  return (
    <iframe
      allow="fullscreen"
      loading="lazy"
      //eslint-disable-next-line
      allowTransparency="true"
      frameBorder="0"
      scrolling="no"
      src={`https://form.jotform.com/${form}?uid=${auth.user.uid}&cid=${childProfile?.id}&hubspotcontactid=${auth.user.hubspotContactId}&stripecustomerid=${auth.user.stripeCustomerId}&parentName[first]=${auth.user.firstName}&parentName[last]=${auth.user.lastName}&childsName=${childProfile?.name}&email=${auth.user.email}`}
      style={{ width: '100%', height: 1700 }}
      width="900"
      id="writing"
      title="Assessment"
    />
  );
};

export default requireAuth(PlayRegistrationPage);

// https://cdn.jotfor.ms/220385155374153?parentName[last]=Graves&parentName[first]=Dustin&childsName=Ethan&uid=123&cid=456&email=dust8806@gmail.com
