import React, { useState, useEffect } from 'react';
import { addDays } from 'date-fns';
import ControlHolder from './ControlHolder';
import { Grid } from '@material-ui/core';
import ControlDateSelection from './ControlDateSelection';

const DateControl = (props) => {
  return (
    <ControlHolder {...props} text="Date" width={'600px'}>
      <Grid>
        <ControlDateSelection dates={props.dates} setDates={props.setDates} />
      </Grid>
    </ControlHolder>
  );
};

export default DateControl;
