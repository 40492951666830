import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import FormField from '../components/FormField'
import Container from 'react-bootstrap/Container'
import FormAlert from './../components/FormAlert'
import { Checkbox, FormControlLabel } from '@material-ui/core'
// import { Checkbox } from '@material-ui/core'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useAuth } from '../util/auth.js'
import { useForm } from 'react-hook-form'
import { Typography } from '@material-ui/core'

function CollectParentDetails(props) {
  const auth = useAuth()
  const [phoneConsent, setPhoneConsent] = useState(auth.user?.phoneConsent || true)
  const [pending, setPending] = useState(false)
  const [formAlert, setFormAlert] = useState(null)
  const [firstNameGuess,setFirstNameGuess] = useState()
  const [lastNameGuess,setLastNameGuess] = useState()
  const [reauthState, setReauthState] = useState({
    show: false,
  })
  const { register, handleSubmit, errors } = useForm()

  const handleStatus = ({ type, message, callback }) => {
    if (type === 'requires-recent-login') {
      // First clear any existing message
      setFormAlert(null)
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      })
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      })
    }
  }
  const onSubmit = (data) => {
    // console.log('onsubmit:',data)
    data.phoneConsent = phoneConsent
    // Show pending indicator
    setPending(true)

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        handleStatus({
          type: 'success',
          message: 'Your profile has been updated',
        })
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          handleStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          })
        } else {
          // Set error status
          handleStatus({
            type: 'error',
            message: error.message,
          })
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false)
      })
  }

  useEffect(()=> {
    if(auth.user.name){
      const name = auth.user.name;
      if(!auth.user.firstName){
        setFirstNameGuess(name.split(' ')[0])
      }
      if(!auth.user.lastName){
        setLastNameGuess(name.split(' ')[1])
      }
    }
  },[auth.user])



  return (

    <>
      <Typography className="pt-4" align="center" variant="h5">
        Please take a moment to provide us with some additional details
      </Typography>
      <Container
        className="mt-5"
        style={{
          maxWidth: '450px',
        }}
            
      >

            
        {formAlert && (
          <FormAlert
            type={formAlert.type}
            message={formAlert.message}
            className="mx-auto mb-4"
            style={{ maxWidth: '450px' }}
          />
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formFirstName">
            <FormField
              name="firstName"
              type="text"
              label="First Name"
              defaultValue={auth.user.firstName || firstNameGuess}
              placeholder="first name"
              error={errors.name}
              inputRef={register({
                required: 'Please enter a value',
              })}
            />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <FormField
              name="lastName"
              type="text"
              label="Last Name"
              defaultValue={auth.user.lastName || lastNameGuess}
              placeholder="last name"
              error={errors.name}
              inputRef={register({
                required: 'Please enter a value',
              })}
            />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <FormField
              name="phone"
              type="tel"
              label="Phone Number"
              defaultValue={auth.user.phone}
              placeholder="xxx-xxx-xxxx"
              error={errors.email}
              inputRef={register({
                required: 'Please provide a phone number',
              })}
            />
          </Form.Group>

          <FormControlLabel
            labelPlacement="left"
            control={
              <Checkbox
                name="phoneConsent"
                onChange={()=>setPhoneConsent(!phoneConsent)}
                checked={phoneConsent}
                inputProps={{ 'aria-label': 'phoneConsent' }}
              />}
            label="Send me text messages, updates and notifications"
          />




          <Button type="submit" disabled={pending}>
            <span>Save</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Sending...</span>
              </Spinner>
            )}
          </Button>
        </Form>
      </Container>
    </>
  )
}

export default CollectParentDetails
