import React from 'react'
import VideoSection from '../components_student/VideoSection'
import { useRouter } from '../util/router.js'
import { requireAuth } from '../util/auth.js'

function LessonsDemoPage(props) {
  const router = useRouter()

  return (
    <>
      {router.query.section === 'social-emotional' && (
        <VideoSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Your title here"
          subtitle=""
          embedUrl="https://player.vimeo.com/video/439144945?app_id=122963"
        />
      )}
      {router.query.section === 'stem' && (
        <VideoSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="STEM Activity: Clouds"
          subtitle=""
          icons={{ file: 'file-alt', supply: 'chalkboard-teacher' }}
          supply={['Clear plastic or glass cup',
            'Clear Water',
            'Shaving cream or cool whip for edible option',
            'Water with a bit of paint or food coloring mixed in for color',
            'Dropper or spoon for pouring onto “cloud”',
          ]}
          embedUrl="https://player.vimeo.com/video/441976007?app_id=122963"
        />
      )}
      {router.query.section === 'writing-fine-motor' && (
        <VideoSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Your title here"
          subtitle=""
          embedUrl="https://player.vimeo.com/video/439144945?app_id=122963"
        />
      )}

      {router.query.section === 'language-literature' && (
        <VideoSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          file="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/language%2Frhymingpdf.pdf?alt=media&token=ae208d22-6607-4cab-9df7-3dc9db0c6ddb"
          icons={{ file: 'file-alt', supply: 'chalkboard-teacher' }}
          supply={['Rhyming worksheet download',
            'Pencil',
            '3-4 things from around the house that rhyme (example: sock, clock, rock, lock)']}
          bgImageOpacity={1}
          title="Language & Literature: Rhyming"
          subtitle=""
          embedUrl="https://player.vimeo.com/video/442076685?app_id=122963"
        />
      )}

      {router.query.section === 'math' && (
        <VideoSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Math: Patterns"
          supply={['Two colors of play doh']}
          file="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/math%2Fice_cream_patternsM13-4.pdf?alt=media&token=11ca8750-747a-4613-84ec-aec7136a73e7"
          icons={{ file: 'file-alt', supply: 'chalkboard-teacher' }}
          subtitle=""
          embedUrl="https://player.vimeo.com/video/441989859?app_id=122963"
        />
      )}
    </>
  )
}

export default requireAuth(LessonsDemoPage)
