import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { Multiselect } from 'react-widgets';
import { useAllUsers } from '../util/db'

const formatUserList = (list) => {
  if (!list) {
    return []
  }
  let newArray = [];
  list.map((row) => {
    newArray.push(row.id);
  });
  return newArray;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function UserSelector(props) {
  const classes = useStyles();
  const { data } = useAllUsers();

  const [formattedList, setFormattedList] = useState()

  useEffect(()=> {
    if(data?.length > 0){
      setFormattedList(data.map(row => {return { id:row.id, textField: `${row.email} (${row.id})` }}))
    }

  },[data])

  const setSelected = (newList) => {
    props.setSelectedUsers(newList.map(row => row.id))
  }

  if (formattedList) {
    return (
      <Multiselect
        data={formattedList}
        value={props.selectedUsers}
        dataKey="id"
        textField="textField"
        name="Supplies"
        onChange={setSelected}
      />
    );
  }
  return (null);
}
