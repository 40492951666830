import React from 'react';

import { Container } from 'react-bootstrap';
import SectionHeader from '../components/SectionHeader';
import Section from '../components/Section';

import NameTrace from './NameTrace';
import { useChildContext } from '../util/childProfileContext';
import { history } from '../util/router'

const TracingSection = (props) => {
  const { childProfile } = useChildContext()
  // if (!childProfile) history.replace('/lesson')
  return childProfile && (<Section
    bg={props.bg}
    textColor={props.textColor}
    size={props.size}
    bgImage={props.bgImage}
    bgImageOpacity={props.bgImageOpacity}
  >
    <Container>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />

      <NameTrace myName={childProfile.name} />
    </Container>
  </Section>)
}

export default TracingSection