import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from 'react-bootstrap/Button';
import { updateUser } from '../../util/db';

const defaultValues = { name: '', teacher: false, admin: false, contractor: false }

export default function UserDetails(props) {
  const [success, showSuccess] = useState(false);
  const [values, setValues] = useState(defaultValues)

  useEffect(() => {
    if (props.selectedUser) {
      setValues({ ...defaultValues, ...props.selectedUser })
      showSuccess(false);
    }
  }, [props.selectedUser])

  const handleCheckChange = e => {
    const { name, checked } = e.target
    setValues({ ...values, [name]: checked })
  }


  const saveChanges = () => {
    updateUser(props.selectedUser.id, { contractor: values.contractor, teacher: values.teacher, admin: values.admin }).then(() => {
      showSuccess(true)
    })
  }

  const cancelChanges = () => {
    setValues({ ...defaultValues, ...props.selectedUser })
  }

  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '700px' }}>
                    User Updated!🥳
        </Alert>
        : null}
      <Container key={props.selectedUser.id} className={'px-5'}>
        <Row>
          <Col>
            <h2>{props.selectedUser.name}</h2>
            <p>email: {props.selectedUser.email}</p>
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="admin"
                  onChange={handleCheckChange}
                  checked={values.admin}
                  inputProps={{ 'aria-label': 'Teacher' }}
                />}
              label="Admin"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="teacher"
                  onChange={handleCheckChange}
                  checked={values.teacher}
                  inputProps={{ 'aria-label': 'Teacher' }}
                />}
              label="Teacher"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="contractor"
                  onChange={handleCheckChange}
                  checked={values.contractor}
                  inputProps={{ 'aria-label': 'Contractor' }}
                />}
              label="Contractor"
            />
          </Col>
        </Row>
        <Col>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={saveChanges}>Save</Button>
            <Button variant="secondary" onClick={cancelChanges}>Cancel</Button>
          </Row>
        </Col>
           
      </Container>
    </>)
}
