import React from 'react'
import MKProductManager from '../components_admin/MKProductManager/MKProductManager';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function MKPlanManagerPage(props) {
  return (<>
    <GoBack />
    <MKProductManager />
  </>
  )
}

export default requireAuth(MKProductManager)
