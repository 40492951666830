import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { requireAuth } from '../util/auth.js'

function UploadPage() {
  return (
    <Form>
      <Form.Group controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
          We&apos;ll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <Button variant="primary" size="md">
        Submit
      </Button>
    </Form>
  )
}

export default requireAuth(UploadPage)
