import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import SectionHeader from '../components/SectionHeader'
import VideoEmbed from '../components/VideoEmbed'
import Button from 'react-bootstrap/Button'
const getButtons = (file, count) =>
  <div key={count} className={'justify-content-center text-center'}>
    <span style={{ fontSize: 100, paddingLeft: 20 }} >
      <Button size={'lg'} href={file} target="_blank" rel="noopener noreferrer">
        <i className={'fas fa-file-alt pr-2'} />  Download Activity{count ? ` ${count + 1}` : ''} </Button>
    </span>
  </div>

function VideoSection(props) {


  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: '768px',
        }}
      >
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <VideoEmbed url={props.embedUrl} />
        <div>
          <div><b>Subject:</b> {props.section}</div>
          <div><b>Standard:</b> {props.standard}</div>
          <div><b>Level:</b> {props.level}</div>
          <div><b>Episode:</b> {props.episode}</div>
        </div>
        {typeof props.file === 'object' ? props.file.map((button, i) => getButtons(button, i)) : props.file !== '' ? getButtons(props.file) : props.file}

        {props.supply && props.supply !== '' && (<div><span style={{ fontSize: 100, paddingLeft: 20 }} ><i className={`fas fa-${props.icons.supply}`} /></span><br /><h3>Supply List:</h3> <ul>{props.supply.map(item => <li key={item}>{item}</li>)}</ul></div>)}
        {props.optionalSupply && props.optionalSupply !== '' && (<div><h3>Optional Supplies:</h3> <ul>{props.optionalSupply.map(item => <li key={item}>{item}</li>)}</ul></div>)}
        <Card.Text

        >
          {props.description && props.description !== '' && (
            <>
              <h3>Description:</h3>
              <ul><li>{props.description}</li></ul>
            </>
          )}
          {props.prep && props.prep !== '' && (
            <>
              <h3>Prep: </h3>
              <ul><li>{props.prep}</li></ul>
            </>
          )}
        </Card.Text>
      </Container>
    </Segment>
  )
}

export default VideoSection
