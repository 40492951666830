import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Modal, Button, Box } from '@material-ui/core';

const FilterHeadingText = styled('h2')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: '150%',
}));

export default function TypographyTheme(props) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.width || 500,
    bgcolor: 'background.paper',
    //   border: '2px solid #000',
    borderRadius: '2px',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal open={props.open} onClose={props.closeModal}>
      <Box sx={style}>
        <FilterHeadingText>{props.text}</FilterHeadingText>
        <div>
          {' '}
          {props.children}
          <Button onClick={props.closeModal}>Close</Button>
        </div>
      </Box>
    </Modal>
  );
}
