import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'
import { useAuth } from '../util/auth.js'
import PricingCard from './PricingCard'
import './Pricing.scss'

function Pricing(props) {
  const auth = useAuth()

  return (
    <Row className="justify-content-center">
      {props.items.map((item, index) => (
        <PricingCard lg={props.lg} key={index} data={item} buttonText={props.buttonText}/>
      ))}
    </Row>
  )
}

export default Pricing
