import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea,CardActions,CardContent,Typography } from '@material-ui/core';

import AvatarSection from '../../components_student/AvatarSection'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  return (
    props.subscriptions ? props.subscriptions.map((subscription, index) =>
      <Card key={subscription.id} className={classes.root}>
        <CardActionArea>

          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {subscription.id}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                            status: {subscription.status}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                            productId: {subscription.productId}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                            childId: {subscription.childId}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                             available registrations:
            </Typography>
            {subscription.availableRegistrations && Array.isArray(subscription.availableRegistrations) ? subscription.availableRegistrations.map(product => 
              <Typography key={product} gutterBottom variant="p" component="p">
                {product}
              </Typography>) :  <Typography gutterBottom variant="p" component="p">
                             no available registrations
            </Typography>}
          </CardContent>
        </CardActionArea>
      </Card>) : null
  );
}