import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Accordion,AccordionSummary,AccordionDetails,Container,Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGetVideosByIds, useGetSchedule, useScores, useOldScores, getSingleVideo } from '../util/db'
import { paddedDateHash } from '../util/util'
import { useChildContext } from '../util/childProfileContext';


function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

function WeeklySupplies(props) {
  const { videoSchedule } = useChildContext()
  const [videosToGet, setVideosToGet] = useState();
  const [supplyList, setSupplyList] = useState([]);
  const [videoList, setVideoList] = useState([])
   


  useEffect(() => {
    if(videosToGet && videosToGet.length > 0){
      console.log('vids to get: ', videosToGet)
      for(let i = 0; i < videosToGet.length; i++){
        (async () => {
          let res = await getSingleVideo(videosToGet[i])
          console.log('vid list', videoList)
          console.log('adding', res)
          setVideoList((prev) => {
            if(!prev.find(obj => obj.URL === res.URL)){
              return [...prev,res]
            } else {
              return prev
            }
          });
        })();
      }
    }
  },[videosToGet])
   
  useEffect(() => {
    console.log('vid list:',videoList)
    if (videoList && videoList.length > 0) {
      console.log(videoList)
      let supplies = [];
      videoList.forEach(vid => {
        if (vid.Supplies) {
          vid.Supplies.forEach(supply => {
            supply = supply.trim();
            supply = supply.toLowerCase();
            supply = jsUcfirst(supply)
            if(!supplies.includes(supply)){
              supplies = [...supplies, supply]
            }
          })
        }
      })
      // supplies = supplies.map( sup => {
                
      //     return sup
      // })

      supplies.sort((a,b) => a.localeCompare(b))

      console.log('supplies', supplies)
      setSupplyList(supplies)
    }

  }, [videoList]);

  const getWeeksClasses = () => {
    let mon = getMonday(props.date);
    let tue = new Date(mon);
    let wed = new Date(mon);
    let thur = new Date(mon);
    let fri = new Date(mon);
    tue.setDate(mon.getDate() + 1);
    wed.setDate(mon.getDate() + 2);
    thur.setDate(thur.getDate() + 3);
    fri.setDate(mon.getDate() + 4);
    //let week = [mon, wed, fri];

    let monVids = Object.entries(videoSchedule.schedule[paddedDateHash(mon)]|| {}).map(([key,val]) => val)
    let tueVids = Object.entries(videoSchedule.schedule[paddedDateHash(tue)]||{}).map(([key,val]) => val)
    let wedVids = Object.entries(videoSchedule.schedule[paddedDateHash(wed)]||{}).map(([key,val]) => val)
    let thurVids = Object.entries(videoSchedule.schedule[paddedDateHash(thur)]||{}).map(([key,val]) => val)
    let friVids = Object.entries(videoSchedule.schedule[paddedDateHash(fri)]||{}).map(([key,val]) => val)

    let classIds = [...monVids,...tueVids, ...wedVids,...thurVids, ...friVids];
    console.log('classIds:', classIds)
    setVideosToGet(classIds)
  }

  useEffect(() => {
    if (videoSchedule?.schedule) {
      console.log('getting weeks classes', props.date)
      setVideoList(()=>[])
      setSupplyList(()=>[])
      getWeeksClasses()
    }
  }, [props.date,videoSchedule]);

  return (
    <Container className="pb-5 pt-5">
      <Row sm={12} lg={12}>
        <Col sm={12} lg={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant={'h4'}>This week&apos;s supplies</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div><ul>{supplyList.map(item => <li key={item} ><Typography>{item}</Typography></li>)}</ul></div>
            </AccordionDetails>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default WeeklySupplies
