import React from 'react'
import SectionHeader from '../components/SectionHeader'
import Container from 'react-bootstrap/Container'
import Daily from './DailyDemo'

function DailySection(props) {
  return (
    <>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
      <Container>
        <Daily/>
      </Container>
    </>
  )
}

export default DailySection
