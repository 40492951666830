import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';

import { useRouter } from '../../util/router.js'
import ClassCards from './ClassCards'
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  console.log(props)
  const router = useRouter()
  const classes = useStyles();
  const [timeSlotClasses, setTimeSlotClasses] = useState([])

  return (
    <Container maxWidth="sm">{
      props.zoomMeetings ? props.zoomMeetings.map((zoomClass, index) =>
        <ClassCards key={index} registerFunction={() => { props.registerFunction(zoomClass) }} classData={zoomClass} />,
      ) : null}
    </Container>
  );
}