import React, { useEffect, useState } from 'react';
import { Container, Grid, Modal } from '@material-ui/core';

import { useChildContext } from '../../util/childProfileContext';
import { useAuth } from '../../util/auth.js';
import { useRouter } from '../../util/router';
import {
  useGetActiveSubscriptions,
  useGetContractorClasses,
  useGetContractors,
} from '../../util/db';

import ChildNavLock from '../ChildNavLock';
import ContractorCard from './ContractorCard';
import ClassCard from './ClassCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  paper: {
    position: 'relative',
    margin: 'auto',

    // backgroundColor: '#fff',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10vh',
  },
}));

function ClassesMarket(props) {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : undefined;
  const [modalOpen, setModalOpen] = useState(false);
  const { childProfile } = useChildContext();
  const [productId, setProductId] = useState();
  const [currentContractorId, setCurrentContractorId] = useState();
  const [renderReady, setRenderReady] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [noRegistrations, setNoRegistrations] = useState(false);
  const [registeredList, setRegisteredList] = useState([]);
  const [registered, setRegistered] = useState(false);
  const [meetingsBySlot, setMeetingsBySlot] = useState();
  const [contractorObj, setContractorObj] = useState();
  const [currentClass, setCurrentClass] = useState();
  const { data: contractors } = useGetContractors();
  const { data: contractorClasses } = useGetContractorClasses('live');
  const { data: mySubscriptions } = useGetActiveSubscriptions(
    childProfile ? childProfile.id : null,
    auth.user.uid,
  );
  const [showChildLock, setShowChildLock] = useState(false);

  const registerFunction = async (classData) => {
    setShowChildLock(true);
    // thank you for registering
  };

  useEffect(() => {
    if (currentClass && registeredList) {
      if (registeredList.includes(currentClass.id)) {
        setRegistered(true);
      } else {
        setRegistered(false);
      }
    }
  }, [registeredList, currentClass]);

  useEffect(() => {
    console.log(mySubscriptions);
    if (mySubscriptions && mySubscriptions.length > 0) {
      let idList = [];
      mySubscriptions.forEach((sub) => {
        console.log(sub);
        if (sub.metadata?.classId) {
          idList.push(sub.metadata.classId);
        }
      });
      console.log('registered list', idList);
      setRegisteredList(idList);
    }
  }, [mySubscriptions]);

  useEffect(() => {
    if (contractors) {
      let obj = {};
      contractors.forEach((teacher) => {
        obj[teacher.id] = teacher;
      });
      setContractorObj(obj);
    }
  }, [contractors]);

  useEffect(() => {
    if (contractorClasses && !renderReady) {
      let obj = {};
      contractorClasses &&
        contractorClasses.forEach((meeting) => {
          if (
            meeting.studentCount &&
            meeting.studentCount >= meeting.maxStudents
          ) {
            meeting.classFull = true;
          }

          if (obj[meeting.contractorId]) {
            obj[meeting.contractorId].push(meeting);
          } else {
            obj[meeting.contractorId] = [meeting];
          }
        });
      console.log(obj);
      setMeetingsBySlot(obj);
      setRenderReady(true);
    }
  }, [contractorClasses]);

  const selectClass = (classData) => {
    console.log(classData);
    setCurrentClass(classData);
    setCurrentContractorId(classData.contractorId);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Container maxWidth={'lg'}>
      <Grid>
        {meetingsBySlot &&
          contractorObj &&
          Object.entries(meetingsBySlot).map(([key, val]) => (
            <Grid lg={true} key={key}>
              <Grid>
                <ContractorCard
                  registeredList={registeredList}
                  selectClass={selectClass}
                  classList={val}
                  data={contractorObj[key]}
                  key={key}
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Modal
        open={modalOpen && !showChildLock}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div style={{ margin: 'auto' }}>
            {currentClass && (
              <ClassCard
                registerFunction={
                  registered || currentClass.classFull ? null : registerFunction
                }
                closeModal={handleClose}
                classData={currentClass}
              />
            )}
          </div>
        </div>
      </Modal>
      {showChildLock && (
        <ChildNavLock
          setShowModal={setShowChildLock}
          showModal={showChildLock}
          navURL={`/purchase/enrichment/${currentContractorId}-${currentClass.id}`}
        />
      )}
    </Container>
  );
}

export default ClassesMarket;
