import React from 'react';
import MetaTags from 'react-meta-tags';
import CoreMetaTags from './CoreMetaTags';

const PageTitle = 'Mighty Kids Virtual Academy • Preschool, Pre-K, Kindergarten Readiness'
const Page = 'Enrichment Classes - Music, PE, Language & More!'
const SiteTitle = 'Mighty Kids Virtual Academy'
const Slug = 'enrichment-classes'
const Description = 'Enhance your preschool experience with Enrichment Classes. Our extra curricular programs are run by teachers experienced in their topic area. Enroll in 1 class or many! Checkout our classes today.'
const URL = 'https://app.mightykidsvirtualacademy.com'
const imgPath = 'images/enrichment-most-loved-OG.webp'

const EnrichmentPageMetaTags = () =>
  <MetaTags>
    <title>{`${Page} - ${SiteTitle}`}</title>
    {/* Index rules */}
    <meta name="robots" content="index/follow" />
    <meta name="googlebot" content="index, follow" />
    <meta name="description" content={Description} />
    <meta name="DC.title" content={`${Page} - ${SiteTitle}`} />
    <meta name="keywords" content="online music, music, physical education, pe, online pe, spanish, english, preschool, online preschool, pre-k, academy, virtual preschool, virtual academy, mighty kids, distance learning" />
    <meta name="pagename" content={`${Page} - ${SiteTitle}`} />
    <meta name="pageKey" content={Slug} />
    <meta property="og:title" content={`${Page} - ${SiteTitle}`} />
    <meta property="og:description" content={Description} />
    <meta property="og:image" content={`${URL}/${imgPath}`} />
    <meta name="og:url" content={`${URL}/${Slug}`} />
    <CoreMetaTags />
  </MetaTags>

export default EnrichmentPageMetaTags