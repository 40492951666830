import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Letter from './Letter'
import Card from 'react-bootstrap/Card'
import './ModalLauncher2.scss'

const letters = ['s','m','a','t','c','r','i','p','b','f','o','g','h','j','u','l','d','w','e','n','k','q','v','x','y','z']

function ModalLauncher2(props) {
  const [show, setShow] = useState(false)
  const useAudio = (url) => {
    const [audio] = useState(new Audio(url))
    const [playing, setPlaying] = useState(false)

    const toggle = () => setPlaying(!playing)

    useEffect(() => {
      playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false))
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false))
      }
    }, [])

    return [playing, toggle]
  }

  const [playing, toggle] = useAudio(props.url)
  useEffect(() => {
    setTimeout(() => setShow(false), 30000)
  }, [show, setShow])
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        container={document.body}
      >
        <Modal.Body>
          <Row style={{ fontSize: 100 }}>
            <Col xs={12}>
              <h2>Pick yourfavorite color:</h2>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => alert('red')}>
                <span className="icon">
                  <i className="ModalLauncher2__no-classname fas fa-square" />
                </span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="md" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Letter />
      <Card className={'shadow-lg bg-white rounded text-center'} style={{ cursor: 'pointer' }} onClick={() => {toggle()}}>
        <Card.Img variant="top" src="/images/cat.png" />

        <Card.Body>
          <Card.Title>Letter of the day</Card.Title>
          <Card.Text className="text-primary text-center justify-content-center align-content-center"
            style={{
              fontSize: 40,
            }}
          >
                Cc
          </Card.Text>
        </Card.Body>

        <Card.Footer>
          <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${playing ? 'up' : 'off'}`} /></sub>
        </Card.Footer>
      </Card>

    </>
  )
}

export default ModalLauncher2
