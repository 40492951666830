import React from 'react'
import AdminDashboard from '../components_admin/AdminDashboard'
import { requireAuth } from '../util/auth.js'
import CssBaseline from '@material-ui/core/CssBaseline';
function AdminDashboardPage(props) {
  return (<>
    <React.Fragment>
      <CssBaseline />
      <AdminDashboard />
    </React.Fragment>
  </>
  )
}

export default requireAuth(AdminDashboardPage)
