import Avatars from '@dicebear/avatars'
import sprites from '@dicebear/avatars-bottts-sprites'
import alphabet from 'alphabet'
import React from 'react'

let options = { colorful: true, textureChance: 0 }
let avatars = new Avatars(sprites, options)
let arr = []
let nums = ({ x=1 }) => {
  for (x; x < 101; x++) {
    arr.push(x)
  }
}
nums({ x: 1 })
const filterStuff = (char, count) => {
  switch (count) {
      case 13:
        break
      case 51:
        break
      case 74:
        break
      case 144:
        break
      case 141:
        break
      case 118:
        break
      case 125:
        break
      case 142:
        break
      case 150:
        break
      case 87:
        break
      case 132:
        break
      case 90:
        break
      case 80:
        break
      case 64:
        break
      case 56:
        break
      case 57:
        break
      case 106:
        break
      case 105:
        break
      case 98:
        break
      default:
        return encodeURIComponent(avatars.create(char))
  }
}
let filteredLower = alphabet.lower.filter(letter => letter !== 'm')
let alph = [ ...filteredLower, ...alphabet.upper, ...arr ]


const AvatarSelection = ({ picture }) => {

  return (
    <img height={64} src={`data:image/svg+xml;utf8,${encodeURIComponent(avatars.create(picture))}`} />
  )
}

export default AvatarSelection

export const listAllAvatars = () => {return alph.map((char, count) => ({ svg: filterStuff(char, count), char, count }) ).filter(({ svg }) => svg !== undefined)}
