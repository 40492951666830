import React from 'react'
import MKPlanManager from '../components_admin/MKPlanManager/MKPlanManager';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function MKPlanManagerPage(props) {
  return (<>
    <GoBack />
    <MKPlanManager />
  </>
  )
}

export default requireAuth(MKPlanManagerPage)
