import React, { useState } from 'react'
import { Modal,Row,Col,Button,Card } from 'react-bootstrap'
import '../components/ModalLauncher.scss'

function ColorOfTheDay({ toggle, playing }) {
  const [showColor, setShowColor] = useState(false)
  const [color, setColor] = useState(false)

  return (
    <>
      <Modal
        show={showColor}
        onHide={() => {
          setShowColor(false); toggle(false)
        }}
        container={document.body}
      >
        <Modal.Body>
          <Row style={{ fontSize: 100 }}>
            <Col xs={12}>
              <h2>Pick your favorite color:</h2>
            </Col>
            <Col>
              <div onClick={() => {setColor('red'); setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__redColor fas fa-square"/>
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('orange');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__orangeColor fas fa-square"/>
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('yellow');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__yellowColor fas fa-square"/>
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('green');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__greenColor fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('blue')&& setShowColor(false)} }>
                <span className="icon">
                  <i className="ModalLauncher__blueColor fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('purple');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__purpleColor fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('brown');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__brownColor fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('grey');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__greyColor fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('black');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__blackColor fas fa-square" />
                </span>
              </div>
            </Col>
            <Col>
              <div onClick={() => {setColor('white');  setShowColor(false)}}>
                <span className="icon">
                  <i className="ModalLauncher__whiteColor fas fa-square" />
                </span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="md" onClick={() => { setShowColor(false); toggle()}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className={'shadow-lg bg-white rounded text-center h-100'} style={{ cursor: 'pointer' }} onClick={() => {setShowColor(true); toggle(false)}}>
        <Card.Body>
          <Row>
            <Col md={12} lg={4}>

              {color &&
                            <span className="icon">
                              <i style={{ color }}className="ModalLauncher fas fa-square"/>
                            </span>}
              {!color && <span className="icon">
                <i style={{ color: 'grey' }} className="ModalLauncher fas fa-question-circle" />
              </span>}

            </Col>
            <Col xs={12} xl={8} lg={8} md={12}>

              <Card.Title>Today&apos;s Question:</Card.Title>
              <Card.Text
                style={{
                  fontSize: 40,
                }}
              >
                <Card.Text />
                <h3>What&apos;s your favorite color?</h3>
              </Card.Text>

            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${playing ? 'up' : 'off'}`} /></sub>
        </Card.Footer>
      </Card>
    </>
  )
}

export default ColorOfTheDay
