import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const AgeMax = 18;
const AgeMin = 3;
const COLUMNS = 4;

export default function ControlAgeSelection(props) {
  const [state, setState] = useState(props.selectedAges || {});
  //   const [toddler, setToddler] = useState(false);
  //   const [adult, setAdult] = useState(false);
  //   const [family, setFamily] = useState(false);

  const handleChange = (event) => {
    props.setSelectedAges({
      ...props.selectedAges,
      [event.target.name]: event.target.checked,
    });
  };

  const RenderAgeColumns = (start) => {
    let stop = start + COLUMNS;
    stop = Math.min(stop, AgeMax + 1);
    let returnColumns = [];

    for (let k = start; k < stop; k++) {
      returnColumns.push(
        <FormControlLabel
          key={k + 'col'}
          style={{ width: '60px' }}
          control={
            <Checkbox checked={props.selectedAges && props.selectedAges[k] === true} onChange={handleChange} name={k} />
          }
          label={k}
        />,
      );
    }
    return <Grid direction="column">{returnColumns}</Grid>;
  };

  const RenderAgeRows = () => {
    let retArr = [];
    for (let i = AgeMin; i < AgeMax; i += COLUMNS) {
      retArr.push(
        <Grid direction={'column'} key={i + 'row'}>
          {RenderAgeColumns(i)}
        </Grid>,
      );
    }
    return retArr;
  };

  return (
    <Box sx={{ display: 'flex', maxWidth: '300px', margin: '5px' }}>
      {RenderAgeRows()}
      <Grid>
        <FormControlLabel
          style={{ width: '60px' }}
          control={
            <Checkbox
              checked={props.selectedAges?.adult === true}
              onChange={() => props.setSelectedAges({ ...props.selectedAges, adult: !props.selectedAges.adult })}
              name={'adult'}
            />
          }
          label={'Adult'}
        />
        <FormControlLabel
          style={{ width: '60px' }}
          control={
            <Checkbox
              checked={props.selectedAges?.toddler === true}
              onChange={() => props.setSelectedAges({ ...props.selectedAges, toddler: !props.selectedAges.toddler })}
              name={'toddler'}
            />
          }
          label={'Toddler'}
        />
        <FormControlLabel
          style={{ width: '60px' }}
          control={
            <Checkbox
              checked={props.selectedAges?.family === true}
              onChange={() => props.setSelectedAges({ ...props.selectedAges, family: !props.selectedAges.family })}
              name={'family'}
            />
          }
          label={'Family'}
        />
      </Grid>
    </Box>
  );
}
