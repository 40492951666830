import React, { useEffect, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import * as tus from 'tus-js-client';
import PropTypes from 'prop-types';
import { SPECIALTIES } from '../constants';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  TextField,
  Select,
  Button,
  Input,
  Checkbox,
  InputLabel,
  Typography,
  CircularProgress,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import { updateContractor } from '../util/db';
import { uploadContractorImage } from '../util/firebaseStorage';
import PhotoGallery from './PhotoGallery';
import { apiRequest } from '../util/util';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  zoomSlider: {
    width: 100,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const defaultValues = {
  profilePhoto: '',
  photoGallery: [],
  email: '',
  bio: '',
  teacherName: '',
  vimeoURL: '',
  specialties: [],
};

const prefixes = ['Mr', 'Mrs', 'Ms', 'Dr'];

export default function TeacherDetails(props) {
  const imageRef = useRef(null);
  const classes = useStyles();
  const [imageFile, setImageFile] = useState();
  const [values, setValues] = useState(defaultValues);
  const [galleryList, setGalleryList] = useState([]);
  const [success, showSuccess] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState();
  const [galleryLoad, setGalleryLoad] = useState(false);
  const [profileVideo, setProfileVideo] = useState();
  const [videoLoad, setVideoLoad] = useState();
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [zoomValue, setZoomValue] = useState(1);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (isChanged) {
      showSuccess(false);
      props.disableSwitch(true);
    } else {
      props.disableSwitch(false);
    }
  }, [isChanged]);

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues({ ...defaultValues, ...props.data });
    setProfilePhoto(props.data.profilePhoto || '');
    setImageFile(null);
    setGalleryList(props.data.photoGallery || []);
    showSuccess(false);
    setIsChanged(false);
    setVideoLoad(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setIsChanged(true);
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    let profileImg = profilePhoto;
    if (imageFile) {
      profileImg = await saveProfilePhoto();
      setProfilePhoto(profileImg);
      setImageFile(null);
    }
    let toSave = {
      teacherName: values.teacherName || '',
      email: values.email || '',
      bio: values.bio || '',
      photoGallery: galleryList,
      profilePhoto: profileImg,
      specialties: values.specialties,
    };

    await updateContractor(props.contractorId, toSave);
    setIsChanged(false);
    showSuccess(true);
  };

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const saveProfilePhoto = async () => {
    const scaledImage = imageRef.current.getImage();
    let newFile;
    const blobby = () =>
      new Promise((resolve, reject) => {
        scaledImage.toBlob(async (blob) => {
          console.log('blob', blob);
          const file = blobToFile(blob, 'profile-photo.png');
          newFile = file;
          let image = await uploadContractorImage(newFile, props.contractorId);
          console.log(image);
          if (image && image.src) {
            resolve(image.src);
          } else {
            reject();
          }
        });
      });
    let src = await blobby();
    return src;
  };

  const uploadProfilePhoto = async (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setIsChanged(true);
  };

  const uploadGalleryPhoto = async (event) => {
    setGalleryLoad(true);
    const files = event.target.files;

    let imageURLs = [];
    for (const file of files) {
      let upload = await uploadContractorImage(file, props.contractorId);
      imageURLs.push(upload);
    }
    console.log(imageURLs);
    let galleryPhotos = galleryList;
    setGalleryList([...galleryPhotos, ...imageURLs]);
    setGalleryLoad(false);
    setIsChanged(true);
  };

  const deletePhoto = (img) => {
    let galleryPhotos = galleryList;
    let newList = [];
    galleryPhotos.forEach((photo) => {
      if (photo.src == img.src) {
        setDeletedPhotos([...deletedPhotos, img]);
      } else {
        newList.push(photo);
      }
    });
    setGalleryList(newList);
    setIsChanged(true);
  };

  const handleZoom = (event, newValue) => {
    setZoomValue(newValue);
  };

  const uploadProfileVideo = async (event) => {
    setVideoLoad(true);
    console.log('video upload!', event);
    var videoFile = event.target.files[0];
    console.log(videoFile);
    let videoLink = await apiRequest('vimeo/upload', 'POST', {
      size: videoFile.size,
    });
    console.log('video link', videoLink);
    if (videoLink && videoLink.upload) {
      const endpoint = videoLink.upload.upload_link;
      const upload = new tus.Upload(videoFile, {
        endPoint: endpoint,
        uploadUrl: endpoint,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: videoFile.name,
          filetype: videoFile.type,
        },
        headers: {},
        onError: function (error) {
          console.log('Failed because: ' + error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + '%');
        },
        onSuccess: function () {
          console.log('Download %s from %s', upload.file.name, upload.url);
          // setVideoUrl(upload.url);
        },
      });
      // Start the upload
      upload.start();
    }
    // console.log(result)
    setProfileVideo(videoLink.link);
    setIsChanged(true);
  };

  const CancelSaveButtons = () => {
    if (isChanged) {
      return (
        <>
          <Grid container className={'m-3'} justify="center" direction={'row'}>
            <Button
              variant="outline"
              className={classes.button}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
            <Button
              variant="outline"
              className={classes.button}
              onClick={resetValues}
            >
              Cancel Changes
            </Button>
          </Grid>
        </>
      );
    } else if (success) {
      setTimeout(() => showSuccess(false), 2000);
      return (
        <Alert severity="success" color="info">
          Changes saved successfully!
        </Alert>
      );
    }
    return null;
  };

  useEffect(() => {
    console.log('teacher-data:', props.data);
    resetValues();
  }, [props.data.id]);

  return (
    <Grid
      container
      className={'mt-2'}
      alignItems={'center'}
      alignContent={'center'}
      direction={'column'}
    >
      <CancelSaveButtons />
      <Grid container justify="center" spacing={2} direction={'column'}>
        <Grid container className={'mt-1'} justify="center" direction={'row'}>
          {imageFile ? (
            <AvatarEditor
              ref={(editor) => (imageRef.current = editor)}
              image={imageFile}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={zoomValue}
              rotate={0}
            />
          ) : (
            <AvatarEditor
              image={
                profilePhoto !== ''
                  ? profilePhoto
                  : '/images/profile_picture.png'
              }
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={zoomValue}
              rotate={0}
            />
          )}
        </Grid>
        <Grid container className={'mb-3'} justify="center" direction={'row'}>
          <Button
            variant="contained"
            component={'label'}
            className={classes.button}
          >
            Upload Profile Photo (required)
            <input
              hidden
              accept="image/*"
              type="file"
              id="myFile"
              size="50"
              onChange={uploadProfilePhoto}
            />
          </Button>
        </Grid>
        <Grid container className={'mb-3'} justify="center" direction={'row'}>
          <div>
            <form className={classes.root} noValidate autoComplete="on">
              <TextField
                id="name"
                label="Name"
                name="teacherName"
                value={values.teacherName}
                margin="normal"
                style={{ margin: 8 }}
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
              />
              <TextField
                id="email"
                label="email"
                name="email"
                value={values.email}
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
              />
              <TextField
                name="bio"
                label="Teacher Bio"
                onChange={handleInputChange}
                multiline
                rows={8}
                value={values.bio}
                variant="outlined"
                fullWidth
                placeholder="tell us about yourself, your experiences, what inspired you to teach?"
              />
              <InputLabel id="demo-mutiple-checkbox-label">
                Teaching Specialties
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={values.specialties || []}
                onChange={(event) => {
                  let newVal = event.target.value;
                  console.log(newVal);
                  setValues({ ...values, specialties: newVal });
                  setIsChanged(true);
                }}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {SPECIALTIES.map((slot) => (
                  <MenuItem key={slot.value} value={slot.value}>
                    <Checkbox
                      checked={values.specialties.indexOf(slot.value) > -1}
                    />
                    <ListItemText primary={slot.label} />
                  </MenuItem>
                ))}
              </Select>
            </form>
            <Grid
              container
              className={'my-3'}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography align={'center'}>
                A picture is worth a thousand words, share photos with potential
                students to help understand your what you do!
              </Typography>
              <Button
                variant="contained"
                component={'label'}
                className={classes.button}
              >
                Upload Gallery Photos (optional)
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  id="myFile"
                  multiple
                  size="50"
                  onChange={uploadGalleryPhoto}
                />
              </Button>
            </Grid>
            <Grid
              container
              className={'m-3'}
              justify="center"
              direction={'row'}
            >
              <PhotoGallery photos={galleryList} deletePhoto={deletePhoto} />
              {galleryLoad && <CircularProgress />}
            </Grid>
            {/* 
                            {!videoLoad && <Typography align={'center'}>
                                Show us your moves with a video!
                            </Typography>}


                            <Grid container className={'mb-3'} justify="center" direction={'row'}>
                                {!videoLoad && <div>

                                    <Button
                                        variant="contained"
                                        color="secondary"

                                        component={'label'}
                                        className={classes.button}
                                    >
                                        Upload Profile Video (optional)
                                        <input accept="video/*" hidden type="file" id="myFile" multiple size="50" onChange={uploadProfileVideo} />
                                    </Button>

                                </div>}
                                {videoLoad && <CircularProgressWithLabel value={uploadProgress} />}
                                {!videoLoad && profileVideo && <ResponsiveEmbed >
                                    <embed type="image/svg+xml" src={profileVideo} />
                                </ResponsiveEmbed>}
                            </Grid> */}
            <CancelSaveButtons />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
