import React from 'react'
import { requireAuth } from '../util/auth.js'
import DynamicZoomLoader from '../components_student/DynamicZoomLoader/DynamicZoomLoader.js'
import StudentZoomSchedule from '../components_student/StudentZoomSchedule/StudentZoomSchedule'
import { useChildContext  } from '../util/childProfileContext.js'
import UpcomingContractorClasses from '../components/UpcomingContractorClasses.js'
import SubscriptionInfoHelper from '../components/SubscriptionHelperTool.js'
import RouterPrompt from '../components_student/ChildNavPrompt.js'
import { requireChildProfile } from '../util/childProfileContext.js'

function ZoomPage(props) {
  const { zoomMeetingAccess } = useChildContext()

  return (<>
    <SubscriptionInfoHelper/>
    { zoomMeetingAccess && <>
      <DynamicZoomLoader/>
      <StudentZoomSchedule/>
    </>}
    <UpcomingContractorClasses/>
  </>
  )
}

export default requireAuth(requireChildProfile(ZoomPage))