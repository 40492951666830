import React from 'react';
import './styles/global.scss';
import { Switch, Route, Router, Redirect } from './util/router.js';
import { history } from './util/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import NavbarCustom from './components/NavbarCustom';
import IndexPage from './pages_public/index';
import AboutPage from './pages_public/about';
import FaqPage from './pages_public/faq';
import PricingPage2 from './pages_public/pricing2';
import ProductLanding from './pages_public/product-landing';
import ContactPage from './pages_public/contact';
import DashboardPage from './pages_admin/dashboard';
import ParentDashboard from './pages_parent/parent-dashboard';
import VideoManagerPage from './pages_admin/video-manager';
import SemesterManagerPage from './pages_admin/semester-manager';
import ClassesManagerPage from './pages_admin/classes-manager';
import ClassesRegistrationPage from './pages_student/class-registration';
import QuestionManagerPage from './pages_admin/question-manager';
import LetterManagerPage from './pages_admin/letter-manager';
import UserAlertManager from './pages_admin/user-alert-manager';
import TeacherManagerPage from './pages_admin/teacher-manager';
import MKPlanManagerPage from './pages_admin/mk-plan-manager';
import MKProductManagerPage from './pages_admin/mk-product-manager';
import SettingsPage from './pages_parent/settings';
import PurchasePage from './pages_parent/purchase';
import PlayRegistrationPage from './pages_parent/purchase-play';
import ZoomPage from './pages_student/zoom-page';
import AuthPage from './pages_public/auth';
import LessonPage from './pages_student/lesson';
import VideoLessonPage from './pages_student/video-lesson';
import LessonsPage from './pages_student/lessons';
import LessonsDemoPage from './pages_parent/lessons-demo';
import StudentRoster from './pages_admin/student-roster';
import AdminClassRegistration from './pages_admin/admin-class-registration';
import TeachersPage from './pages_parent/teachers';
import UploadPage from './pages_parent/upload';
import NotFoundPage from './pages_public/not-found.js';
import Footer from './components/Footer';
import { ProvideAuth, requireAuth, requireAdmin } from './util/auth.js';
import { ProvideChildContext } from './util/childProfileContext.js';
import AssessmentPage from './pages_student/assessment';
import ReportPage from './pages_student/student-reports';
import ThankYouPage from './pages_parent/thank-you';
import NewsPage from './pages_public/news';
import ProgramPage from './pages_public/program';
import ResourcePage from './pages_public/resources';
import ResourcePageDemo from './pages_public/resources-demo';
import TracingPage from './pages_student/trace-one';
import StudentManagementPage from './pages_admin/manage-students';
import UserManagementPage from './pages_admin/manage-users';
import ContractorDashboard from './pages_contractor/contractor-dashboard';
import ContractorProfile from './pages_contractor/contractor-profile';
import ContractorClasses from './pages_contractor/contractor-classes';
import ClassesMarket from './pages_parent/classes-market';
import ContractorProgram from './pages_public/contractor-program';
import PaymentPortal from './pages_parent/payment-portal';
import ReauthPage from './pages_contractor/contractor-reauth';
import ContractorStripe from './pages_contractor/contractor-stripe-login';
import AdminDashboardPage from './pages_admin/admin-dashboard';
import ContractorClassAdmin from './pages_admin/contractor-class-admin';
import ContractorUserAdmin from './pages_admin/contractor-user-admin';
import ErrorBoundary from './components/ErrorBoundary';
import PostCheckoutPage from './pages_parent/post-checkout';
import HulafrogLanding from './pages_public/hula-frog-landing';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App(props) {
  return (
    <Elements stripe={stripePromise}>
      <ProvideAuth>
        <ProvideChildContext>
          <HubspotProvider>
            <Router history={history}>
              <>
                <NavbarCustom bg="white" variant="light" expand="md" logo="/images/MK-logonew.png" />
                <ErrorBoundary>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/');
                        return null;
                      }}
                    />
                    <Route exact path="/home/:referrer" component={IndexPage} />
                    <Route
                      exact
                      path="/about"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/about');
                        return null;
                      }}
                    />
                    <Route
                      exact
                      path="/faq"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/faq');
                        return null;
                      }}
                    />
                    <Route
                      exact
                      path="/pricing"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/pricing');
                        return null;
                      }}
                    />
                    <Route exact path="/join-now/:pageSlug" component={ProductLanding} />
                    <Route exact path="/join-now/:pageSlug/:referrer" component={ProductLanding} />
                    <Route exact path="/hulafrog" component={HulafrogLanding} />
                    <Route
                      exact
                      path="/contact"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/contact');
                        return null;
                      }}
                    />
                    <Route
                      exact
                      path="/program"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/programs');
                        return null;
                      }}
                    />
                    <Route exact path="/dashboard" component={DashboardPage} />
                    <Route exact path="/parent-dashboard" component={ParentDashboard} />
                    <Route exact path="/zoom-meetings" component={ZoomPage} />
                    <Route exact path="/settings/:section" component={SettingsPage} />
                    <Route exact path="/purchase/:plan" component={PurchasePage} />
                    <Route exact path="/thankyou" component={ThankYouPage} />
                    <Route
                      exact
                      path="/post-checkout/:classType/:resourceId"
                      component={requireAuth(PostCheckoutPage)}
                    />
                    <Route exact path="/auth/:type" component={AuthPage} />
                    <Route
                      exact
                      path="/news"
                      component={() => {
                        window.location.replace('https://www.mightykidsvirtualacademy.com/news');
                        return null;
                      }}
                    />
                    <Route exact path="/class-registration" component={ClassesRegistrationPage} />
                    <Route exact path="/play-registration" component={PlayRegistrationPage} />
                    <Route exact path="/lesson" component={LessonPage} />
                    <Route exact path="/video-lesson/:videoId" component={VideoLessonPage} />
                    <Route exact path="/lesson/:child" component={LessonPage} />
                    <Route exact path="/lesson/:child/assessment" component={AssessmentPage} />
                    <Route exact path="/lesson/:child/resources" component={ResourcePage} />
                    <Route exact path="/tracing/:level" component={requireAuth(TracingPage)} />
                    <Route exact path="/lesson/resources/tracing/:level" component={requireAuth(TracingPage)} />
                    <Route exact path="/lessons/:child/:section" component={LessonsPage} />
                    <Route exact path="/lessons/:child/:section/:day" component={LessonsPage} />
                    <Route exact path="/lessons/:section" component={LessonsDemoPage} />
                    <Route exact path="/resources" component={ResourcePage} />
                    <Route exact path="/assessment" component={AssessmentPage} />
                    <Route exact path="/reports" component={ReportPage} />
                    <Route exact path="/reports/:child/:section" component={ReportPage} />
                    <Route exact path="/teachers" component={TeachersPage} />
                    <Route exact path="/upload" component={UploadPage} />
                    {/* Teacher Routes */}
                    <Route exact path="/teacher/student-roster/:classId" component={StudentRoster} />
                    <Route exact path="/teacher/student-roster" component={StudentRoster} />
                    {/*Admin Section*/}
                    <Route exact path="/admin/manage-students" component={requireAdmin(StudentManagementPage)} />
                    <Route
                      exact
                      path="/admin/manage-students/:childId"
                      component={requireAdmin(StudentManagementPage)}
                    />
                    <Route exact path="/admin/manage-users" component={requireAdmin(UserManagementPage)} />
                    <Route exact path="/admin/manage-users/:userId" component={requireAdmin(UserManagementPage)} />
                    <Route exact path="/admin/manage-videos" component={requireAdmin(VideoManagerPage)} />
                    <Route exact path="/admin/manage-semesters" component={requireAdmin(SemesterManagerPage)} />
                    <Route exact path="/admin/manage-classes" component={requireAdmin(ClassesManagerPage)} />
                    <Route exact path="/admin/manage-questions" component={requireAdmin(QuestionManagerPage)} />
                    <Route exact path="/admin/manage-letters" component={requireAdmin(LetterManagerPage)} />
                    <Route exact path="/admin/manage-teachers" component={requireAdmin(TeacherManagerPage)} />
                    <Route exact path="/admin/manage-alerts" component={requireAdmin(UserAlertManager)} />
                    <Route exact path="/admin/admin-dashboard" component={requireAdmin(AdminDashboardPage)} />
                    <Route
                      exact
                      path="/admin/contractor-classes-admin"
                      component={requireAdmin(ContractorClassAdmin)}
                    />
                    <Route exact path="/admin/contractor-class-admin" component={requireAdmin(ClassesMarket)} />
                    <Route exact path="/admin/contractor-user-admin" component={requireAdmin(ContractorUserAdmin)} />
                    <Route exact path="/admin/plans-and-promotions" component={requireAdmin(MKPlanManagerPage)} />
                    <Route exact path="/admin/manage-products" component={requireAdmin(MKProductManagerPage)} />
                    <Route
                      exact
                      path="/admin/admin-register/:childId"
                      component={requireAdmin(AdminClassRegistration)}
                    />

                    {/*Contractor Section*/}
                    <Route exact path="/enrichment" component={ClassesMarket} />
                    <Route exact path="/contractor-dashboard" component={ContractorDashboard} />
                    <Route exact path="/contractor-reauth" component={ReauthPage} />
                    <Route exact path="/contractor-profile/:mode" component={ContractorProfile} />
                    <Route exact path="/contractor-profile" component={ContractorProfile} />
                    <Route exact path="/contractor-classes" component={ContractorClasses} />
                    <Route exact path="/contractor-classes/:contractorId" component={ContractorClasses} />
                    <Route exact path="/contractor-classes/:contractorId/:classId" component={ContractorClasses} />
                    <Route
                      exact
                      path="/contractor-classes/:contractorId/:classId/:mode"
                      component={ContractorClasses}
                    />
                    <Route exact path="/my-payments" component={ContractorStripe} />
                    <Route
                      exact
                      path="/enrichment"
                      component={() => {
                        window.location.replace(
                          'https://www.mightykidsvirtualacademy.com/programs/extracurricular-classes',
                        );
                        return null;
                      }}
                    />
                    <Route exact path="/enrichment-classes" component={ContractorProgram} />

                    <Route exact path="/purchase/:classType/:resourceId" component={PaymentPortal} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ErrorBoundary>

                <Footer
                  path
                  bg="light"
                  textColor="dark"
                  size="sm"
                  bgImage=""
                  bgImageOpacity={1}
                  description="We make distance preschool fun and simple!"
                  copyright="© 2020 Mighty Kids"
                  logo="/images/MK-logonew.png"
                />
              </>
            </Router>
          </HubspotProvider>
        </ProvideChildContext>
      </ProvideAuth>
    </Elements>
  );
}

export default App;
