import React from 'react'
import PricingSection2 from '../components_public/PricingSection2'
import CssBaseline from '@material-ui/core/CssBaseline';
import PricingPageMetaTags from '../components_public/MetaTags/PricingPage';

function PricingPage(props) {
  return (
    <>
      <PricingPageMetaTags />
      <CssBaseline/>
      <PricingSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Choose the plan that makes sense for you. All Zoom plans include access to assessments, video lessons, and downloadable activities."
      />
    </>
  )
}

export default PricingPage
