import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import 'react-datepicker/dist/react-datepicker.css'

// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import { useGetAllStripeUsers, useGetContractorUsers, useGetTeacherUsers } from '../util/db.js';

import { useAuth } from '../util/auth';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AdminUserSelector = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [active, setActive] = useState()
  const { data: stripeUsers } = useGetAllStripeUsers(active);
  const { data: contractors } = useGetContractorUsers();
  const { data: teachers } = useGetTeacherUsers();
  const [ sorted, setSorted ] = useState();
  const [user, setUser] = useState();
  const [userList, setUserList] = useState([])

  const handleChange = (event) => {
    const newUser = event.target.value;
    setUser(newUser);
    auth.emulateUser(newUser);
  };

  const cancelButton = () => {
    auth.cancelEmulate()
  }

  const activateButton = () => {
    setActive(true)
  }

    

  useEffect(()=>{
    let newList = [];
    if(stripeUsers && stripeUsers.length > 0){
      newList = [...newList,...stripeUsers]
    }
    if(contractors && contractors.length > 0){
      newList = [...newList,...contractors]
    }
    if(teachers && teachers.length > 0){
      newList = [...newList,...teachers]
    }
    if(newList.length > 0){
      setSorted(newList.sort((a,b)=> a.email < b.email))
    }

  },[stripeUsers,contractors,teachers])


  return (
    <>{ active && sorted?.length > 0 ? <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Emulate User</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={user}
        onChange={handleChange}
      >
        { sorted.map( user => (<MenuItem key={user.uid} value={user}>{user.email + ' ' + user.uid }</MenuItem>))}
      </Select>
           
    </FormControl> : null }
    {auth.isEmulated && active &&  <Button onClick={cancelButton}>Cancel Emulate</Button>}
    {!active && <Button onClick={activateButton}>Emulate User</Button>}
    </>
  )
}

export default AdminUserSelector