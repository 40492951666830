import React from 'react'
import Segment from './Segment'
import Spinner from 'react-bootstrap/Spinner'

function PageLoader(props) {
  const { style, ...otherProps } = props

  return (
    <Segment
      bg="white"
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '400px',
        ...style,
      }}
      {...otherProps}
    >
      <Spinner animation="border" variant="primary" />
    </Segment>
  )
}

export default PageLoader
