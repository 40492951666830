import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Multiselect } from 'react-widgets';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Grid,
  Slider,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createContractorClass, updateContractorClass, addNewClassTag, getClassTags } from '../util/db';
import { uploadContractorImage } from '../util/firebaseStorage';

import AvatarEditor from 'react-avatar-editor';
import { useRouter } from '../util/router';
import ControlAgeSelection from './ControlAgeSelection';

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  zoomSlider: {
    width: 100,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const defaultValues = {
  classPhoto: '',
  description: '',
  className: '',
  classPrice: 25,
  maxStudents: 10,
  mondayClass: false,
  tuesdayClass: false,
  wednesdayClass: false,
  thursdayClass: false,
  fridayClass: false,
  contractorId: '',
  status: 'draft',
  classDuration: '30',
  startDate: new Date(),
  endDate: new Date(new Date().setMonth(new Date().getMonth() + 3)),
  timeString: '12:00',
  tags: [],
  selectedAges: {},
  joinAnytime: false,
};

const TagSuggestions = ['Art', 'Biology', 'Math', 'Reading', 'Science', 'Writing', 'Anything'];

export default function EditContractorClass(props) {
  const [startDate, setStartDate] = useState(props.data?.startDate?.toDate() || defaultValues.startDate);
  const [endDate, setEndDate] = useState(props.data?.endDate?.toDate() || defaultValues.endDate);
  const [selectedTime, setSelectedTime] = useState(new Date('2014-08-18T12:00:00'));
  const imageRef = useRef(null);
  const classes = useStyles();
  const [imageFile, setImageFile] = useState();
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [classPhoto, setClassPhoto] = useState(props.data?.classPhoto || '');
  const [isChanged, setIsChanged] = useState(false);
  const [selectedAges, setSelectedAges] = useState(props.data?.selectedAges || {});
  const [classTagOptions, setClassTagOptions] = useState([]);
  const router = useRouter();
  const { data: tagData } = getClassTags();

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setIsChanged(true);
    let toSave = moment(time).format('HH:mm');
    handleChange('timeString', toSave);
  };

  const createTag = async (text) => {
    if (text.length > 1) {
      setClassTagOptions([...classTagOptions, text]);
      setValues({ ...values, tags: [...values.tags, text] });
      await addNewClassTag(text);
    }
  };

  const formatTagList = (list) => {
    if (!list) {
      return [];
    }
    let newArray = [];
    list.map((row) => {
      newArray.push(row.tag);
    });
    return newArray;
  };

  const tagsChange = (tags) => {
    setValues({ ...values, tags });
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
    setIsChanged(true);
  };

  const handleStartChange = (date) => {
    setStartDate(date);
    handleChange('startDate', date);
  };

  const handleEndChange = (date) => {
    setEndDate(date);
    handleChange('endDate', date);
  };

  const resetValues = () => {
    setValues({ ...defaultValues, ...props.data });
    setClassPhoto(props.data?.classPhoto || '');
    setImageFile();
    showSuccess(false);
    setIsChanged(false);
    setSelectedTime(new Date('2014-08-18T' + props.data.timeString));
    setSelectedAges(props.data?.selectedAges || {});
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    value !== '' ? handleChange(name, parseInt(value)) : handleChange(name, '');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    console.log('name', name, 'value', value);
    setValues({ ...values, [name]: value });
    setIsChanged(true);
  };

  useEffect(() => {
    if (tagData) {
      const formatted = setClassTagOptions(formatTagList(tagData));
      console.log(formatted);
      // setClassTagOptions(formatted);
    }
  }, [tagData]);

  const saveChanges = async (e, status) => {
    if (e) e.preventDefault();
    let toSave = { ...values, selectedAges };
    values.classPrice = parseInt(values.classPrice);

    let photoUri = classPhoto;
    if (imageFile) {
      photoUri = await saveClassPhoto();
      setClassPhoto(photoUri);
      setImageFile(null);
      toSave.classPhoto = photoUri;
    }

    toSave.contractorId = props.contractorId;
    if (status) {
      toSave.status = status;
    }
    if (props.editMode === 'new') {
      let res = await createContractorClass(props.contractorId, toSave);
    } else {
      let res = await updateContractorClass(props.contractorId, props.data.id, toSave);
    }
    setIsChanged(false);
    showSuccess(true);
    setTimeout(() => {
      router.push('/contractor-classes');
    }, 2000);
  };

  const submitClass = () => {
    saveChanges(null, 'submitted');
  };

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const saveClassPhoto = async () => {
    const scaledImage = imageRef.current.getImageScaledToCanvas();
    let newFile;
    const blobby = () =>
      new Promise((resolve, reject) => {
        scaledImage.toBlob(async (blob) => {
          const file = blobToFile(blob, 'profile-photo.png');
          newFile = file;
          let image = await uploadContractorImage(newFile, props.contractorId);
          if (image && image.src) {
            resolve(image.src);
          } else {
            reject();
          }
        });
      });
    let src = await blobby();
    return src;
  };

  const uploadClassPhoto = async (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setIsChanged(true);
  };

  const CancelSaveButtons = (classStatus) => {
    if (!success) {
      return (
        <>
          <Grid container className={'m-3'} justify="center" direction={'row'}>
            <Button variant="outline" className={classes.button} onClick={saveChanges}>
              Save Changes
            </Button>
            {classStatus !== 'live' && (
              <Button variant="outline" className={classes.button} onClick={submitClass}>
                Submit Class for Review
              </Button>
            )}
          </Grid>
        </>
      );
    } else if (success) {
      setTimeout(() => {
        showSuccess(false);
        router.push('/contractor-classes');
      }, 1000);
      return (
        <Alert severity="success" color="info">
          Changes Saved!
        </Alert>
      );
    }
    return null;
  };

  useEffect(() => {
    if (props.data && props.data.id) {
      resetValues();
    }
  }, [props.data]);

  useEffect(() => {
    if (isChanged) {
      showSuccess(false);
      props.disableSwitch(true);
    } else {
      props.disableSwitch(false);
    }
  }, [isChanged]);

  return (
    <Grid container className={'mt-2'} alignItems={'center'} alignContent={'center'} direction={'column'}>
      <Grid container justify="center" spacing={2} direction={'column'}>
        <Grid container className={'mt-1'} justify="center" direction={'row'}>
          {imageFile ? (
            <AvatarEditor
              ref={(editor) => (imageRef.current = editor)}
              image={imageFile}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={1}
              rotate={0}
            />
          ) : (
            <AvatarEditor
              image={classPhoto !== '' ? classPhoto : '/images/image_placeholder.png'}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={1}
              rotate={0}
            />
          )}
        </Grid>
        <Grid container className={'mb-3'} justify="center" direction={'row'}>
          <Button variant="contained" component={'label'} className={classes.button}>
            Upload Class Photo (required)
            <input hidden accept="image/*" type="file" id="myFile" size="50" onChange={uploadClassPhoto} />
          </Button>
        </Grid>

        <form className={classes.root} noValidate autoComplete="on">
          <Grid container className={'mb-3'} justify="center" direction={'row'}>
            <div>
              <TextField
                id="name"
                label="Class Title"
                name="className"
                value={values.className}
                margin="normal"
                style={{ margin: 8 }}
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
              />
              <TextField
                name="description"
                label="Class Description"
                onChange={handleInputChange}
                multiline
                rows={8}
                value={values.description}
                variant="outlined"
                fullWidth
                placeholder="What is the class about? Who might be interested?"
              />

              <Typography color={'primary'} className={'mt-4'}>
                Please take a moment to add some tags, this will help parents/students find your class.
              </Typography>
              <Multiselect
                data={classTagOptions}
                value={values.tags}
                name="class tags"
                onChange={tagsChange}
                allowCreate
                onCreate={createTag}
              />
              <Typography color={'primary'} className={'mt-4'}>
                Please select which ages this class is intended for
              </Typography>
              <ControlAgeSelection selectedAges={selectedAges} setSelectedAges={setSelectedAges} />
              <Grid container className={'mb-3'} justify="center" direction={'row'}>
                <Typography color={'primary'} className={'my-3'}>
                  How much will you charge each student per week?
                </Typography>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                  <OutlinedInput
                    disabled={values.status === 'live'}
                    id="outlined-adornment-amount"
                    name="classPrice"
                    type="number"
                    value={values.classPrice}
                    onChange={handleInputNumberChange}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    labelWidth={40}
                  />
                </FormControl>
              </Grid>

              <Grid container className={'my-5'} justify="center" direction={'row'}>
                <Typography color={'primary'} className={'my-3'}>
                  How many students can join before your class is full?
                </Typography>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    disabled={values.status === 'live'}
                    id="filled-number"
                    label="Maximum Students"
                    type="number"
                    name="maxStudents"
                    value={values.maxStudents}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={handleInputNumberChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid container className={'mt-5'} justify="center" direction={'row'}>
                <Grid container justify="center" direction={'row'}>
                  <Typography color={'primary'} className={'mt-3 mb-1'}>
                    What time will this class be held each day(s)?
                  </Typography>
                  <Typography color={'secondary'} className={'mt-1 mb-1'}>
                    Please use Pacific Standard Time (PST)
                  </Typography>
                </Grid>
                <Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      disabled={values.status === 'live'}
                      margin="normal"
                      id="time-picker"
                      label="Class Time"
                      value={selectedTime}
                      onChange={handleTimeChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid className={'mt-4'} container justify="center" direction={'row'}>
                <Typography id="discrete-slider-small-steps" gutterBottom>
                  Class Duration: {values.classDuration} minutes
                </Typography>
                <Slider
                  disabled={values.status === 'live'}
                  className="mt-2"
                  name="classDuration"
                  defaultValue={50}
                  step={5}
                  onChange={(event, value) => setValues({ ...values, classDuration: value })}
                  value={values.classDuration}
                  min={15}
                  max={120}
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid className={'mt-4'} container justify="center" direction={'row'}>
                <Typography color={'primary'}>Which day(s) is this class available?</Typography>
              </Grid>

              <Grid className="mt-3" style={{ display: 'block' }}>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      disabled={values.status === 'live'}
                      name="mondayClass"
                      onChange={handleCheckChange}
                      checked={values.mondayClass}
                    />
                  }
                  label="Mondays"
                />
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      disabled={values.status === 'live'}
                      name="tuesdayClass"
                      onChange={handleCheckChange}
                      checked={values.tuesdayClass}
                    />
                  }
                  label="Tuesdays"
                />
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      disabled={values.status === 'live'}
                      name="wednesdayClass"
                      onChange={handleCheckChange}
                      checked={values.wednesdayClass}
                      value={values.wednesdayClass}
                    />
                  }
                  label="Wednesdays"
                />
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      disabled={values.status === 'live'}
                      name="thursdayClass"
                      onChange={handleCheckChange}
                      checked={values.thursdayClass}
                    />
                  }
                  label="Thursdays"
                />
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      disabled={values.status === 'live'}
                      name="fridayClass"
                      onChange={handleCheckChange}
                      checked={values.fridayClass}
                    />
                  }
                  label="Fridays"
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid className={'mt-4'} container justify="center" direction={'row'}>
                  <Typography color={'primary'}>When should classes start and when do they end?</Typography>
                </Grid>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disabled={values.status === 'live'}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Class Start Date"
                    format="yyyy-MM-dd"
                    value={startDate}
                    onChange={handleStartChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardDatePicker
                    disabled={values.status === 'live'}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Class End Date"
                    format="yyyy-MM-dd"
                    value={endDate}
                    onChange={handleEndChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                {/* <FormControlLabel
                  labelPlacement="top"
                  control={ */}
                <Grid
                  className={'mt-4'}
                  container
                  justifyContent="center"
                  alignItems="center"
                  justify="center"
                  direction={'column'}
                >
                  <Grid>
                    <Typography align="center" color={'primary'} className={'mt-3'}>
                      Allow Students to join after classes have started?
                    </Typography>
                  </Grid>
                  <Grid>
                    <Checkbox
                      name="joinAnytime"
                      onChange={handleCheckChange}
                      checked={values.joinAnytime}
                      value={values.joinAnytime}
                    />
                  </Grid>
                </Grid>
                {/* }
                //   label="Allow Students to join after classes have started?"
                // /> */}
              </MuiPickersUtilsProvider>
              <CancelSaveButtons classStatus={values.status} />
            </div>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
