import React, { useEffect, useState } from 'react'
import ContractorUserAdmin from '../components_admin/ContractorAdmin/ContractorUserAdmin'
import { requireAuth, useAuth } from '../util/auth.js'
import CssBaseline from '@material-ui/core/CssBaseline';
import GoBack from '../components/GoBack'

function ContractorAdmin(props) {
  return (<>
    <React.Fragment>
      <GoBack />
      <CssBaseline />
      <ContractorUserAdmin />
    </React.Fragment>
  </>
  )
}

export default requireAuth(ContractorAdmin)
