import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom'
import ChildNavLock from '../components_parent/ChildNavLock';

export default function RouterPrompt(props) {
  const safePaths = ['video-lesson','lesson']
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [ nextURL, setNextURL ] = useState('');

  const handleBlockedNavigation = (nextLocation) => {
    for( let i = 0; i < safePaths.length; i++){
      if(nextLocation.pathname.includes(safePaths[i])){
        return true
      }
    }
    setNextURL(nextLocation.pathname)
    setShowModal(true)
    return false
  }

  const navigateToPage = () => {
    history.block(() => {});
    history.push(nextURL);
  }

  return  <>
    <Prompt
      when={true}
      message={handleBlockedNavigation}/>
    <ChildNavLock navigateToPage={navigateToPage} showModal={showModal} setShowModal={setShowModal} />
  </>
}