import React, { useEffect, useState } from 'react';
import {
  useGetAvailableRegistrations,
  registerForClass,
  useGetAvailableZoomMeetings,
  hideProductBanner,
} from '../../util/db';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  useChildContext,
  requireChildProfile,
} from '../../util/childProfileContext';
import { useAuth } from '../../util/auth.js';
import { useRouter } from '../../util/router';
import ZoomClasses from './ZoomClasses';
import { apiRequest, slotToLocalTime2 } from '../../util/util';
import { Container, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import { setPageStateUpdate } from '@material-ui/data-grid';

function ClassRegistration(props) {
  const router = useRouter();
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : undefined;
  const [modalOpen, setModalOpen] = useState(false);
  const { childProfile, availableRegistrations } = useChildContext();
  const [productId, setProductId] = useState();
  const [semesterId, setSemesterId] = useState();
  const [renderReady, setRenderReady] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [noRegistrations, setNoRegistrations] = useState(false);
  const [meetingsBySlot, setMeetingsBySlot] = useState({});

  // const { data: unusedRegistrations, error, status } = useGetAvailableRegistrations(childProfile.id, uid)
  const {
    data: availableZoomMeetings,
    error: availError,
    status: availLoading,
  } = useGetAvailableZoomMeetings(productId, semesterId);

  const registerFunction = async (zoomClass) => {
    hideProductBanner(uid);
    setModalOpen(true);
    //let res = registerForClass(uid, childProfile.id, subscriptionId, zoomClass.id)
    let data = {
      uid: uid,
      childId: childProfile.id,
      subscriptionId: subscriptionId,
      zoomClassId: zoomClass.id,
      productId: productId,
    };

    console.log('register data: ', data);
    await apiRequest('private/register', 'POST', data);
    // thank you for registering
  };

  const closeModal = () => {
    router.push('/lesson');
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderReady(true);
    }, 500);
  });

  useEffect(() => {
    if (availableRegistrations && availableRegistrations.length > 0) {
      if (availableRegistrations[0].availableRegistrations?.length > 0) {
        console.log('availableRegistrations,', availableRegistrations);
        let productListTemp = [];

        availableRegistrations[0].availableRegistrations?.forEach(
          (productId) => {
            productListTemp.push(productId);
          },
        );

        console.log('available products:', productListTemp);
        console.log('available registrations: ', availableRegistrations);
        setProductId(availableRegistrations[0].availableRegistrations[0]);
        setSemesterId(availableRegistrations[0].semesterId);
        setSubscriptionId(availableRegistrations[0].id);
        setNoRegistrations(false);
      }
    } else {
      setNoRegistrations(true);
    }
    // }

    console.log('unused registrations', availableRegistrations);
    //console.log('error ',error)
  }, [availableRegistrations]);

  useEffect(() => {
    if (availableZoomMeetings) {
      let obj = {};
      availableZoomMeetings.forEach((meeting) => {
        if (meeting.studentCount && meeting.studentCount > 9) {
          console.log('meeting full');
        } else {
          if (obj[meeting.timeSlot]) {
            obj[meeting.timeSlot].push(meeting);
          } else {
            obj[meeting.timeSlot] = [meeting];
          }
        }
      });
      setMeetingsBySlot(obj);
      // setRenderReady(true)
    }
  }, [availableZoomMeetings]);

  useEffect(() => {
    if (availableRegistrations?.length > 0) {
      if (availableZoomMeetings?.length === 0) {
        console.log('no available classes for product');
      }
    }
  }, [availableZoomMeetings, availableRegistrations]);

  return (
    <Container maxWidth={'small'}>
      <Modal show={modalOpen} onHide={closeModal} container={document.body}>
        <Modal.Header />
        <Modal.Body>
          <Row>
            <Col className={'text-center'}>
              <h4>Thank you for registering!</h4>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="success" size="md">
            {'Go to lesson page'}
          </Button>
        </Modal.Footer>
      </Modal>
      {!renderReady && <CircularProgress />}
      {!noRegistrations && renderReady && (
        <>
          <Row style={{ alignItems: 'center' }}>
            <h2 className={'m-5'}>
              Please register for a class below (shown in your time zone)
            </h2>
          </Row>

          <Row>
            {Object.entries(meetingsBySlot).map(([key, val]) => (
              <Col lg={true} key={key}>
                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    width="100%"
                    display="block"
                    align="center"
                    variant={'h4'}
                  >
                    {slotToLocalTime2(key)}
                  </Typography>
                </Row>
                <Row>
                  <ZoomClasses
                    key={'classes' + key}
                    registerFunction={registerFunction}
                    zoomMeetings={val}
                  />
                </Row>
              </Col>
            ))}
          </Row>
        </>
      )}
      {noRegistrations && renderReady && !modalOpen && (
        <div style={{ textAlign: 'center' }}>
          <Typography
            width="100%"
            display="block"
            className={'pt-5'}
            align="center"
            variant={'h4'}
          >
            No available registrations found (may take a moment for new
            subscriptions to appear).
          </Typography>
          <Button
            onClick={closeModal}
            className={'m-2'}
            variant="success"
            size="md"
          >
            Go to lesson page
          </Button>
        </div>
      )}
    </Container>
  );
}

export default requireChildProfile(ClassRegistration);
