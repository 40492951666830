import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useRouter } from '../util/router'
import { useHistory } from 'react-router-dom'

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max))
}
const textNum = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']


function ChildNavLock(props) {
  const history = useHistory()

  const [answer, setAnswer] = useState(true)
  const [answerOne, setAnswerOne] = useState(null)
  const [answerTwo, setAnswerTwo] = useState(null)
  const [answerThree, setAnswerThree] = useState(null)
  const [firstNum, setFirstNum] = useState(textNum[getRandomInt(10)])
  const [secondNum, setSecondNum] = useState(textNum[getRandomInt(10)])
  const [thirdNum, setThirdNum] = useState(textNum[getRandomInt(10)])
  let inputOne

  const inputTwo = React.createRef()
  const inputThree = React.createRef()
  const { router } = useRouter()
  // console.log(auth.user)

  // const navigateToPage = () => {
  //     console.log('navigate to ', props.navURL)
  //     props.setCanLeave(true)
  //     props.setShowModal(false)
  //     history.push(props.navURL)

  // }

  const handleEnter = (event, nextField) => {

    if (!answer && event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault()
      // Trigger the button element with a click
      props.navigateToPage()
    }
    if (nextField) {
      nextField.current.focus()
      event.preventDefault()
    }
  }
  useEffect(() => {
    if (answerOne === firstNum && answerTwo === secondNum && answerThree === thirdNum) setAnswer(false)
  }, [answerOne, answerTwo, answerThree])

  useEffect(() => {
    // if(show && inputOne) console.log(inputOne, inputOne.current)
    if (props.showModal && inputOne) inputOne.focus()
    if (!props.showModal) {
      setAnswer(true)
      setFirstNum(textNum[getRandomInt(10)])
      setSecondNum(textNum[getRandomInt(10)])
      setThirdNum(textNum[getRandomInt(10)])
    }
  }, [props.showModal])

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      container={document.body}
    >
      <Modal.Header />
      <form onSubmit={props.navigateToPage}>
        <Modal.Body>
          <Row><Col className={'text-center'}><h4>Please Type</h4></Col></Row>
          <Row><Col className={'text-center'}><p style={{ fontType: 'bold' }} className={'text-center'}> {firstNum}, {secondNum}, {thirdNum}</p></Col></Row>

          <Row className={'justify-content-center'}>

            <Col xs={2} sm={1} >
              <input ref={(ref) => inputOne = ref} style={{ padding: 5, marginRight: 5 }} maxLength={1} size={1} onKeyUp={(e) => handleEnter(e, inputTwo)} onChange={(e) => setAnswerOne(textNum[e.target.value])} name="one" type="text" />
            </Col>
            <Col xs={2} sm={1} >
              <input ref={inputTwo} onChange={(e) => setAnswerTwo(textNum[e.target.value])} onKeyUp={(e) => handleEnter(e, inputThree)} maxLength={1} style={{ padding: 5, marginRight: 5 }} size={1} name="two" type="text" />
            </Col>
            <Col xs={2} sm={1} >
              <input ref={inputThree} onChange={(e) => setAnswerThree(textNum[e.target.value])} onKeyDown={(e) => handleEnter(e)} maxLength={1} style={{ padding: 5, marginRight: 5 }} size={1} name="three" type="text" />
            </Col>

          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button onSubmit={(e) => props.navigateToPage()} variant="success" size="md" disabled={answer} type={'submit'}>Go</Button>
        </Modal.Footer>
      </form>
    </Modal>

  )
}

export default ChildNavLock
