import React from 'react'
import SectionHeader from '../components/SectionHeader'

function Letter(props) {
  return (
    <SectionHeader
      title={props.title}
      subtitle={props.subtitle}
      size={2}
      spaced={true}
      className="text-center"
    />
  )
}

export default Letter
