import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Checkbox , TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from 'react-bootstrap/Button';
import DateFnsUtils from '@date-io/date-fns';
import { addMKClass, updateMKClass } from '../../util/db';
import 'react-widgets/styles.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SemesterSelector from '../../components/SemesterSelector';
import { timeSlotArray as timeSlots } from '../../constants'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

const defaultValues = {
  id: 0,
  Name: '',
  Description: '',
  Teachers: [],
  startDate: '',
  endDate: '',
  // topic: '',
  productId: 0,
  MaximumStudents: 10,
  mondayClass: false,
  tuesdayClass: false,
  wednesdayClass: false,
  thursdayClass: false,
  fridayClass: false,
  semesterId: 'now',

}
export default function ClassDetails(props) {
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);

  const [startDate, setStartDate] = useState('2000-05-05');
  const [endDate, setEndDate] = useState('2000-05-05');

  const setSemester = (semesterId)=> {
    setValues({ ...values, semesterId: semesterId })
  }

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues(() => {
      return { ...defaultValues, ...props.data }
    });
    showSuccess(false);
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    console.log('name', name, 'value', value)
    setValues({ ...values, [name]: value })
  }

  const handleCheckChange = e => {
    const { name, checked } = e.target
    console.log('name', name, 'value', checked)
    setValues({ ...values, [name]: checked })
  }

  const handleChange = (name, value) => {
    console.log('name', name, 'value', value)
    setValues({ ...values, [name]: value })
  }

  const saveChanges = async (e) => {
    e.preventDefault();

    showSuccess(false);
    if (props.createNew) {
      let newClass = { ...values };
      delete newClass.id
      console.log('values', values)
      let results = await addMKClass(newClass);
      setValues({ ...values, id: results.id })
      console.log(results)

      props.setCreateNew(false);
    } else {
      console.log(values)
      let results = await updateMKClass(props.data.id, { ...values })
    }

    showSuccess(true)

  }

  useEffect(() => {
    if (props.currentTeachers) {
      setValues({ ...values, Teachers: props.currentTeachers })
    }
  }, [props.currentTeachers])

  useEffect(() => {
    if (props.currentProduct) {
      setValues({ ...values, productId: props.currentProduct })
    }
  }, [props.currentProduct])

  useEffect(() => {
    if (!props.isCopy) {
      resetValues();
    }
    console.log(props.data.endDate)
    setStartDate(moment(props.data.startDate))
    setEndDate(moment(props.data.endDate))
    console.log('updating dates')
  }, [props.data.id]);

  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Class Updated!🥳
        </Alert>
        : null}
      <Container key={props.data.id} className={'px-5 py-5'}>
        <Col>
          {/* <h1>{props.data.id || ""}</h1> */}
          <h2>{props.data.Name || ''}</h2>
          {values.name}

          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'name'} name="Name" value={values.Name} onChange={handleInputChange} label="Class Name" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'desc'} name="Description" value={values.Description} onChange={handleInputChange} label="Class Description" />
          </Row>

          {/* <Row className='my-3'>
                        <TextField fullWidth key={props.data.id + 'topic'} name='Zoom Topic' value={values.topic} onChange={handleInputChange} label="Class Description" />
                    </Row> */}
          {/* <Row className='my-3'>
                        <TextField fullWidth key={props.data.id + 'agenda'} name='agenda' value={values.agenda} onChange={handleInputChange} label="Zoom Agenda" />
                    </Row> */}

          <Row className="my-3">
            <TextField InputLabelProps={{ shrink: true }} disabled fullWidth key={props.data.id + 'stripe plan'} name="Stripe Product" value={values.productId} label="Product" />
          </Row>

          <Row className="my-3">
            <TextField fullWidth name="MaximumStudents" value={values.MaximumStudents} onChange={handleInputChange} label="Maximum Students" />
          </Row>
          <Row className="m-5">
            <SemesterSelector hideNow={true} semesterId={values.semesterId} setSemesterId={setSemester} />
          </Row>
        </Col>
        <Col>
          <Row className="mb-2" style={{ display: 'block' }}>
            <h3>Days of the week</h3>
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="mondayClass"
                  onChange={handleCheckChange}
                  checked={values.mondayClass}
                  inputProps={{ 'aria-label': 'Monday' }}
                />}
              label="Monday"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="tuesdayClass"
                  onChange={handleCheckChange}
                  checked={values.tuesdayClass}
                  inputProps={{ 'aria-label': 'Tuesday' }}
                />}
              label="Tuesday"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="wednesdayClass"
                  onChange={handleCheckChange}
                  checked={values.wednesdayClass}
                  value={values.wednesdayClass}
                  inputProps={{ 'aria-label': 'Wednesday' }}
                />}
              label="Wednesday"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="thursdayClass"
                  onChange={handleCheckChange}
                  checked={values.thursdayClass}
                  // value={values.thursdayClass}
                  inputProps={{ 'aria-label': 'Thursday' }}
                />}
              label="Thursday"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="fridayClass"
                  onChange={handleCheckChange}
                  // value={values.fridayClass}
                  checked={values.fridayClass}
                  inputProps={{ 'aria-label': 'Friday' }}
                />}
              label="Friday"
            />

          </Row>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
      </Container>
    </>)
}
