import React from 'react'
import { Row,Col } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { hideAlert } from '../util/db'
import { useAuth } from '../util/auth'
// Need to display when classes start for upcoming semester

// display if registrations are available

//

const AlertRow = (props) => {
  const auth = useAuth()
  const buttonClick = ()=> {
    window.location.href = props.data.buttonURL
  }
  const hideAlertButton = ()=> {
    hideAlert(auth?.user?.id,props.data.id)
  }
  return (
    <Alert severity={props.data.alertVariant || 'info'}
      action={<>
        {props.data.buttonURL && <Button className="m-1" onClick={buttonClick} color="inherit" size="small">
          {props.data.buttonText}
        </Button>}
        <Button onClick={hideAlertButton} className="m-1" color="inherit" size="small">
                    hide
        </Button>
      </>
      }
    >
      {props.data.header && props.data.header !== '' && <AlertTitle >{props.data.header}</AlertTitle>}
      {props.data.message}
    </Alert>
  )
}

export default AlertRow