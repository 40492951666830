import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRouter } from '../util/router'
import { Button, Typography, Grid } from '@material-ui/core'
import { useAuth } from '../util/auth'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

function ErrorFallback({ error, resetErrorBoundary }) {
  const auth = useAuth()

  return (
    <Grid container justify="center" alignItems="center" direction="column" role="alert">
      <SentimentVeryDissatisfiedIcon style={{ fontSize: 100 }}/>
      <Typography>Looks like something went wrong!</Typography>
      <Typography> {auth.user?.admin ? error.message :'This incident has been automatically reported to our tech team.'} </Typography>
      <Button onClick={resetErrorBoundary}>Go back</Button>
    </Grid>
  )
}

const ErrorHandler = (props) => {
  const router = useRouter()
  const errorCatchAndLog = (error,info)=>{    
    console.log('this error:',error)
  }
  return (
    <ErrorBoundary
      onError={errorCatchAndLog}
      FallbackComponent={ErrorFallback}
      onReset={() => {
        router.goBack()
      }}
    >
      {props.children}
    </ErrorBoundary>
  )
}

export default ErrorHandler