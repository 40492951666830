import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import Clients from './Clients'

function ClientsSection(props) {
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Clients
          items={[
            {
              name: 'hulafrog',
              image: 'https://hulafrog.com/hulastock/hulafrog_logo_white_380.png',
              width: '200px',
              url: 'https://hulafrog.com/main-line-west-chester-pa/5-virtual-preschool-programs-starting-this-fall-to-keep-kids-ages-2-6-learning-engaged',
            },
            {
              name: 'mw',
              image: '/images/mw.png',
              width: '200px',
              url: 'https://www.marketwatch.com/press-release/mighty-kids-virtual-academy-launches-online-preschool-tailored-to-each-child-includes-comprehensive-assessments-to-determine-each-childs-individual-needs-2020-08-10',
            },
            {
              name: 'fox',
              image: '/images/fox.png',
              width: '135px',
              url: 'https://www.wfxg.com/story/42475371/mighty-kids-virtual-academy-launches-online-preschool-tailored-to-each-child-includes-comprehensive-assessments-to-determine-each-childs-individual-needs',
            },
            {
              name: 'cbs',
              image: 'https://www.cbs.com/assets/chromecast/images/assets/logo.png',
              width: '90px',
              url: 'http://www.wboc.com/story/42475371/mighty-kids-virtual-academy-launches-online-preschool-tailored-to-each-child-includes-comprehensive-assessments-to-determine-each-childs-individual-needs',
            },
            {
              name: 'cnbc',
              image: 'https://imagesvc.timeincapp.com/v3/o/image?url=https://www.pngkit.com/png/full/224-2240436_seth-harris-cnbc-logo-white-png.png&c=tc&h=150&w=250',
              width: '90px',
              url: 'https://www.wfmj.com/story/42475371/mighty-kids-virtual-academy-launches-online-preschool-tailored-to-each-child-includes-comprehensive-assessments-to-determine-each-childs-individual-needs',
            },
            {
              name: 'cw',
              image: 'https://static.tumblr.com/9ef77d95160ff5878612093b3f2f818e/hpncd5n/BUXp3i1a2/tumblr_static_7i06wx4wbxoog80o80cckkskk.png',
              width: '120px',
              url: 'https://www.lubbockcw.com/story/42475371/mighty-kids-virtual-academy-launches-online-preschool-tailored-to-each-child-includes-comprehensive-assessments-to-determine-each-childs-individual-needs',
            },
            {
              name: 'ask',
              url: 'http://markets.ask.com/ask/news/read/40270005',
              image: 'https://www.sparxitsolutions.com/assets/images/header-svg/featured-ask.com.png',
              width: '150px',
            },
          ]
          }
        />
      </Container>
    </Segment>
  )
}

export default ClientsSection
