import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import { TextField, FormControlLabel,Checkbox, Switch, Grid } from '@material-ui/core';
import { useAllUsers, useGetChildrenByOwner, useGetUserSubscriptions } from '../../util/db';
import { useRouter } from '../../util/router'
import UserList from './UserList';
import UserDetails from './UserDetails';
import UserSubscriptionsCards from './UserSubscriptionsCards'
import UserChildCards from './UserChildCards'
import PlanSelector from '../PlanSelector'
function UserManagement(props) {
  const router = useRouter()
  const [selectedUser, setUser] = useState();
  const [firstLoad, setFirstLoad] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [ activeMode, setActiveMode] = useState(true);
  const [ selectedPlans, setSelectedPlans] = useState([]);
  const [selectedUserId, setUserId] = useState();
  const [filterVal, setFilterVal] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { data: users, loading } = useAllUsers()
  const { data: childList } = useGetChildrenByOwner(selectedUserId)
  const { data: userSubscriptions } = useGetUserSubscriptions(selectedUserId)


  useEffect(()=> {
    if(router){
      let userId = router.query.userId;
      if(userId && users){
        let currentUser = users.find(obj => obj.id === userId)
        switchUser(currentUser)
      }
    }
  },[router,users])

  const switchUser = async (user) => {
    if(user){
      setUser(user)
      setUserId(user.id)
      setShowDetails(true)
    }
  }

  const selectUser = (user)=>{
    if(router.query.userId){
      router.replace(user.id)
    } else {
      router.replace('/admin/manage-users/' + user.id)
    }
  }

  const handleFilterChange = e => {
    const { name, value } = e.target
    setFilterVal(value)
  }

  useEffect(() => {
    if (users && firstLoad < 3) {
      setFirstLoad(firstLoad + 1)
      setFilteredUsers(users)
    }
  }, [users, filteredUsers])

  useEffect(() => {

    if(users){

      let filtered = users.filter(user => {
        let found = true;
        if(selectedPlans.length > 0){
          found = false;
          found = selectedPlans.some(r=> (user.activePlans && user.activePlans.includes(r)))
        }
        return (user.activeSubscription === activeMode) && found;
      })

      if (filterVal) {
        let lowered = filterVal.toLowerCase()
        filtered = filtered.filter(user => `${user.name}${user.name}${user.email}`.toLowerCase().includes(lowered))  
      }
      setFilteredUsers(filtered)
    }

  }, [filterVal, firstLoad, activeMode, selectedPlans])

  return (
    <>
      <Container className="mx-3">
        <Row>
          <Col xs={5}>
            <h2>User Management</h2>
            <Row>
              {loading ? 'loading' :
                <Col>
                  <Row>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Inactive</Grid>
                      <Grid item>
                        <Switch
                          checked={activeMode}
                          // disabled={disableSwitch}
                          onChange={() => setActiveMode(!activeMode)}
                          color="primary"
                          name="checkedB"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </Grid>
                      <Grid item>Active</Grid>
                    </Grid>
                                        
                  </Row>
                  <PlanSelector selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} />
                  <Row>
                    <TextField onChange={handleFilterChange} value={filterVal} label="search users" variant="outlined" />
                  </Row>
                  <Row>
                    <UserList currentUserId={selectedUserId} rowSelect={selectUser} data={filteredUsers} />
                  </Row>
                </Col>}
            </Row>
          </Col>

          <Col xs={7}>
            {showDetails ?
              <>
                <UserDetails selectedUser={selectedUser} />
                {childList?.length > 0 && <><h2>Children</h2>
                  <UserChildCards childList={childList} /> </>}
                {userSubscriptions?.length > 0 && <>
                  <h2>Subscriptions</h2>
                  <UserSubscriptionsCards subscriptions={userSubscriptions}/>
                </>}
              </>
              : null
            }

          </Col>
        </Row>
      </Container>
    </>);
}

export default UserManagement
