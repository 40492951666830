import React, { useEffect,useState } from 'react'
import PromotionCard from './PromotionCard';

import { useGetActiveMKPlans, useGetMightyKidsPlansBySemester } from '../util/db'
import { Grid, Typography } from '@material-ui/core'

function CustomPricingSection(props) {
  const { data } = useGetMightyKidsPlansBySemester(props.semesterId)

  const [sorted, setSorted] = useState()
  useEffect(()=>{
    if(data){
      let sort = data.sort((a,b)=> (Number(b.price) - Number(a.price)))
      setSorted(sort)
    }
  },[data])

  return (<>
    <Grid container direction="row" align="center" justifyContent="center">
      <Typography className="p-5" align="center" variant="h2">
        {props.headline}
      </Typography>
    </Grid>
    <Grid container direction="row" align="center" justifyContent="center">
      {sorted && sorted.map((item, index) => (
        <PromotionCard key={item.id} data={item}/>
      ))}
    </Grid>
  </>
  )
}

export default CustomPricingSection
