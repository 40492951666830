import React, { useEffect, useState } from 'react';
import { useGetVideos, useGetSupplies } from '../../util/db';
import VideoList from './VideoList';
import VideoFilters from './VideoFilters';
import VideoDetails from './VideoDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getVimeoVideos } from '../../util/videoLogic';
import Button from 'react-bootstrap/Button'

const filtersDefault = [
  {
    options: ['All', 'Math', 'Writing', 'Art', 'Language', 'STEM', 'Social Emotional'],
    label: 'Section',
    value: 'All',
  }];

function VideoManger(props) {
  const { data: vidData, error, loading } = useGetVideos();
  const { data: supplyList, supplyError, supplyLoading } = useGetSupplies();
  const [filters, setFilters] = useState({});
  const [selectedVideo, setVideo] = useState({});
  const [filterControls, setFilterControls] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    genFilterControls();
  }, [filteredVideos]);

  const keysToFilter = [
    'Level', 'Standard', 'Episode',
  ]

  const updateFilter = (key, value) => {
    console.log('updating! old filters', filters)
    let newFilters = key === 'Section' ? {} : { ...filters };
    if (value === '') {
      delete newFilters[key]
    } else {
      newFilters[key] = value;
    }
    setControlValue(key, value);
    setFilters(() => newFilters);
  }

  const setControlValue = async (key, value) => {
    let retArr = [...filterControls];
    for (var i = 0; i < retArr.length; i++) {
      if (retArr[i].label === key) {
        retArr[i].value = value;
      }
    }
    setFilterControls(retArr);
  }

  const genFilterControls = () => {
    // gen options based on videos
    let options = {};
    if (filteredVideos) {
      filteredVideos.map(obj => {
        keysToFilter.map(key => {
          if (obj[key] !== '' && obj[key]) {
            if (options[key]) {
              options[key][obj[key]] = obj[key];
            } else {
              let newObj = {};
              newObj[obj[key]] = obj[key];
              options[key] = newObj;
            }
          }
        });
      });
    }

    let newControls = [...filtersDefault];
    for (const [filterName, filterOptions] of Object.entries(options)) {

      let newFilter = {
        label: filterName,
      };
      let selectOptions = [];
      for (const [key, value] of Object.entries(filterOptions)) {
        selectOptions.push(value);
      }
      if (selectOptions.length > 0) {
        newFilter.options = ['All', ...selectOptions];
        newControls.push(newFilter);
      }
    }
    setFilterControls(newControls);
    console.log('controls updated')
  }


  useEffect(() => {
    console.log('filters', filters)
    if (vidData) {
      let list = vidData;
      for (const [key, value] of Object.entries(filters)) {
        if (value !== 'All') {
          list = list.filter((row) => {
            return row[key] == value;
          });
        }
      }
      console.log('setting filtered videos:', list)
      setFilteredVideos(() => list);
    }
  }, [filters, vidData]);

  const selectVideo = (vid) => {
    console.log('selecting video', vid)
    setVideo(() => vid);
    setShowDetails(true);
  };

  return (
    <>
      <h2>Video Manager</h2>
      <Row>
        <Col>
          <VideoFilters handleChange={updateFilter} filters={filterControls} />
          {loading ? 'loading' :
            <VideoList rowSelect={selectVideo} data={filteredVideos} />}
        </Col>
        <Col>
          {showDetails ?
            <VideoDetails data={selectedVideo} supplyList={supplyList} />
            : null
          }
        </Col>

      </Row>
    </>);
}

export default VideoManger
