import Card from 'react-bootstrap/Card'
import AvatarSelection from './AvatarSection'
import { capitalize } from '../util/util'
import Col from 'react-bootstrap/Col'
import React from 'react'


const ChildProfileCard = ({ name, picture, birthdate, id, setChild, edit = false, setShow }) => {

  return (
    <Col className={'text-center align-content-center justify-content-center'}>
      {console.log('profile card', id)}
      <Card.Link onClick={() => {
        setShow(true);
        setChild({ id,name,picture,birthdate });
        console.log('child Id',id)
      }} href={edit ? '#' : `/lesson/${id}`}>
        <Card className={'shadow-lg p-3 mb-5 bg-white rounded text-center'} style={{ cursor: 'pointer' }} >
          <Card.Body>
            <AvatarSelection picture={picture}/>
            <Card.Text>{capitalize(name)}</Card.Text>
          </Card.Body>
          {edit && <>
            <i className="fas fa-pencil-alt"/>
            <p>Click here to update</p>
          </>
          }
        </Card>
      </Card.Link>
    </Col>
  )
}

export default ChildProfileCard