import React, { useState } from 'react'
import SegmentSection from '../components/SegmentSection'
import Section from '../components/Section'
import { Container,Row,Col,Card,ListGroupItem,ListGroup,Button } from 'react-bootstrap'

const YearlySchedule = () => {
  return (
    <SegmentSection size={'sm'} title={'2021 - 2022 Schedule'} subtitle={''}>
      <Card>
        <ListGroup>
          <ListGroupItem><Row><Col xs={6} md={4}>September 1st</Col>  <Col xs={6} md={4}><b>First Day of School</b></Col><Col /></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>September 5th</Col><Col xs={6} md={4}><b>Labor Day</b></Col>  <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>November 11th</Col>  <Col xs={6} md={4}><b>Veteran’s Day</b></Col> <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row> <Col xs={6} md={4}>November 25th - 26th</Col>  <Col xs={6} md={4}><b>Thanksgiving Break</b></Col>  <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>December 20th - December 31st</Col> <Col xs={6} md={4}><b> Winter Break</b></Col>  <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>January 17th</Col>  <Col xs={6} md={4}><b>Rev. Dr. Martin Luther King Jr. day</b></Col>  <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>January 20th</Col>  <Col xs={6} md={4}><b>Re-assessment and Beginning of Second Semester</b></Col><Col /></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>February 14th - 18th</Col>  <Col xs={6} md={4}><b>Mid Winter Break</b> </Col> <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>April 11th - 15th</Col>  <Col xs={6} md={4}><b>Spring break</b></Col>  <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>May 30th</Col>  <Col xs={6} md={4}><b>Memorial Day</b></Col>  <Col>No Zoom Classes or New Videos</Col></Row></ListGroupItem>
          <ListGroupItem><Row><Col xs={6} md={4}>June 10th</Col>  <Col xs={6} md={4}><b>Last day of school</b></Col><Col /></Row></ListGroupItem>
        </ListGroup>
      </Card>
    </SegmentSection>
  )
}

export default YearlySchedule