import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import ProfileSelect from './ProfileSelect'
import { useChildContext } from '../util/childProfileContext'

function SettingsChildren(props) {
  const { childProfile,setChildProfile } = useChildContext()
  const { handleSubmit } = useForm()

  return (
    <Form onSubmit={handleSubmit()}>
      <ProfileSelect childProfile={childProfile} setChildProfile={setChildProfile} edit/>
    </Form>
  )
}

export default SettingsChildren