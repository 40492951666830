import React from 'react'
import '../components/SectionHeader.scss'

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null
  }
  const descriptions = props.subtitle.split('\n')

  return (
    <header
      className={
        'SectionHeader' + (props.className ? ` ${props.className}` : '')
      }
    >
      {props.title && (
        <h1
          className={
            'font-weight-bold' +
            (props.subtitle && props.spaced ? ' mb-4' : '') +
            (!props.subtitle ? ' mb-0' : '') +
            (props.size ? ` h${props.size}` : '')
          }
        >
          {props.title}
        </h1>
      )}

      {props.subtitle && (
        <span className="SectionHeader__subtitle mb-0">{descriptions.map((description, count) => <p key={count}>{description}</p>)}</span>
      )}
    </header>
  )
}

export default SectionHeader
