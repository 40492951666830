import React from 'react'
import ClassesManager from '../components_admin/ClassesManager/ClassesManager';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function ClassManagerPage(props) {
  return (<>
    <GoBack />
    <ClassesManager />
  </>
  )
}

export default requireAuth(ClassManagerPage)
