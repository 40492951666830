import React, { useEffect, useState } from 'react'
import TeacherProfile from '../components_contractor/TeacherProfile'
import { requireAuth } from '../util/auth.js'
import { useRouter } from '../util/router'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
function ProfilePage(props) {
  const router = useRouter()
  const [mode, setEditMode] = useState(false)

  useEffect(() => {
    if (router.query && router.query.mode === 'edit') {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [router])

  return (<>
    <Grid container><Button onClick={router.goBack} variant="text">Back</Button></Grid>
    <TeacherProfile
      editMode={mode}
      bg="white"
      textColor="dark"
      size="md"
      title="Dashboard"
      subtitle=""
    />

  </>
  )
}

export default requireAuth(ProfilePage)
