import React from 'react';
import MetaTags from 'react-meta-tags';


const CoreMetaTags = () => 
  <>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="revised" content={Date.now()} />
    <meta name="facebook-domain-verification" content="pnscizjou023fjoxn1f8n08knc0pdn" />
    <meta property="og:site_name" content="Mighty Kids Virtual Academy • Preschool, Pre-K, Kindergarten Readiness" />
    <meta name="author" content="Mighty Kids Virtual Academy, info@mightykidsvirtualacademy.com" />
    <meta name="copyright" content="Mighty Kids LLC" />
    <meta name="url" content="https://app.mightykidsvirtualacademy.com" />
    <meta name="identifier-URL" content="https://app.mightykidsvirtualacademy.com" />
    <meta name="keywords" content="preschool, online preschool, pre-k, academy, virtual preschool, virtual academy, mighty kids, distance learning" />
    <meta name="coverage" content="Worldwide" />
    <meta name="distribution" content="Global" />
            
    <meta name="og:site_name" content="Mighty Kids Virtual Academy" />
    <meta property="og:description" content="Virtual Academy including preschool & pre-k distance learning that's fun, easy, and completely customized to your child!" />
    <meta name="fb:page_id" content="482509615567284" />
    <meta name="application-name" content="Mighty Kids Webapp" />
    <meta name="og:email" content="info@mightykidsvirtualacademy.com" />
    <meta name="og:phone_number" content="425-230-0279" />
        
    <meta name="og:locality" content="Seattle" />
    <meta name="og:region" content="WA" />
    <meta name="og:postal-code" content="98101" />
    <meta name="og:country-name" content="USA" />
    <meta property="fb:admins" content="10158859091012392" />
    <meta property="og:type" content="website" />
  </>

export default CoreMetaTags

// <meta property='og:video' content='http://example.com/awesome.swf'>
//     <meta property='og:video:height' content='640'>
//         <meta property='og:video:width' content='385'>
//             <meta property='og:video:type' content='application/x-shockwave-flash'>
//                 <meta property='og:video' content='http://example.com/html5.mp4'>
//                     <meta property='og:video:type' content='video/mp4'>
//                         <meta property='og:video' content='http://example.com/fallback.vid'>
//                             <meta property='og:video:type' content='text/html'>
