import React from 'react'
import TracingSection from '../components_student/TracingSection'
import { requireChildProfile } from '../util/childProfileContext'
function TracingPage(props) {

  return (
    <TracingSection
      bg="white"
      textColor="dark"
      size="sm"
      bgImage=""
      bgImageOpacity={1}
      title=""
      subtitle=""
      buttonText="Send message"
      buttonColor="primary"
      showNameField={true}
      inputSize="md"
    />
  )
}

export default requireChildProfile(TracingPage)