import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import NoName from './NoName';
import Qod from './Qod';
import ModalLauncher2 from './ModalLauncher2';
import Emotion from './DemoEmotion';
import Weather from './DemoWeather';
import { Menu, MenuItem, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link, Route, BrowserRouter as Router } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

const animals = {
  platypus: 'platypus',
  hippo: 'hippo',
  penguin: 'penguin',
  baboon: 'baboon',
  polar: 'polarplunge',
  ape: 'ape',
  tiger: 'tiger',
  //   elephant: 'elephants',
  panda: 'panda',
  giraffe: 'kijami', // giraffes and rhinos
  owl: 'owlaviary',
  koala: 'koala',
  condor: 'condorhd',
};

const setPlayerUrl = (animal) => {
  return `https://zssd-${animals[animal]}.secureplayer.camzonecdn.com/v1.3/CamzoneStreamPlayer?iframe=yes&amp;channel=zssd-${animals[animal]}&amp;muted=yes&amp;mutebutton=no&amp;czlogourl=&amp;toolbar=always&amp;backgroundcolor=000000&amp;toolbarbgcolor=ffffff&amp;toolbaralpha=100&amp;toolbartextcolor=000000&amp;epostcard=yes&amp;toolbartype=new&amp;toolbarposition=topright`;
};

function Daily(props) {
  const currentAnimal = 'tiger';
  const [animal, setAnimal] = useState(currentAnimal);
  const [url, setURL] = useState(setPlayerUrl(animal));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAnimal = (name, uri) => {
    console.log(name, uri);
    setAnimal(name);
    setURL(setPlayerUrl(name));
  };
  return (
    <Router>
      <>
        <Row className="pb-3">
          <Col xs={12} className={'justify-content-center align-content-center pb-5'}>
            <Card className={' shadow-lg bg-white rounded justify-content-center'}>
              <div className="embed-responsive embed-responsive-16by9">
                {!url && <Spinner animation="border" variant="primary" />}
                {url && (
                  <iframe
                    key={url}
                    allowFullScreen=""
                    //eslint-disable-next-line
                    allowTransparency="true"
                    frameBorder="0"
                    height="100%"
                    scrolling="no"
                    src={url}
                    width="300"
                    title="Video player"
                  />
                )}
              </div>

              <Card.Footer>
                {/* add a div to the right. This div will be the component for the dropdown menu */}
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    <div className={'text-centered p-2'}>
                      <h3>Live {animal.capitalize()} Cam</h3>
                      <h6>San Diego Zoo</h6>
                    </div>
                  </Grid>
                  <Grid xs={2} container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <div>
                      <Button aria-controls="simple-menu" aria-haspopup="true" disabled onClick={handleClick}>
                        Change Animal
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {Object.entries(animals).map(([name, uri]) => (
                          <MenuItem
                            key={name}
                            onClick={(event) => {
                              changeAnimal(name, uri);
                              handleClose(event);
                            }}
                          >
                            {name.capitalize()}
                          </MenuItem>
                        ))}
                      </Menu>
                      {/* <Route path="/hippo"><Hippo /></Route> */}
                    </div>
                  </Grid>
                </Grid>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <NoName />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Qod
              useAudio={props.useAudio}
              url="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/Audio%2FWhats%20your%20favorite%20color.mp3?alt=media&token=e2e9eb61-185e-42c0-b9b6-eb6ea8bbc7e6"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className={'pb-4'}>
            <ModalLauncher2
              useAudio={props.useAudio}
              url="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/Audio%2Fletter%20of%20the%20day%20C.mp3?alt=media&token=d582d478-9cfc-4a5a-8b27-c71c4cf4fcf4"
            />
          </Col>
          <Col className="h-100" xs={12} sm={12} md={6} lg={8} xl={8}>
            <Row>
              <Col>
                <Emotion
                  useAudio={props.useAudio}
                  url="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/Audio%2Ffeelings%20question.mp3?alt=media&token=88e4b497-e537-42ef-bfe5-c95b3d107439"
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
                <Weather
                  useAudio={props.useAudio}
                  url="https://firebasestorage.googleapis.com/v0/b/virtual-academy-dev.appspot.com/o/Audio%2FWeather%20question.mp3?alt=media&token=b9baddd2-5981-4664-ad9f-6d2ff51e45b5"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Router>
  );
}
function Platypus() {
  return <h1>platypus</h1>;
}
function Hippo() {
  return <h1>hippo</h1>;
}
function Penguin() {
  return <h1>Penguin</h1>;
}
function Baboon() {
  return <h1>Baboon</h1>;
}
function Polar() {
  return <h1>Polar</h1>;
}
function Ape() {
  return <h1>Ape</h1>;
}
function Tiger() {
  return <h1>Tiger</h1>;
}
function Panda() {
  return <h1>Panda</h1>;
}
function Giraffe() {
  return <h1>Giraffe</h1>;
}
function Owl() {
  return <h1>Owl</h1>;
}
function Koala() {
  return <h1>Koala</h1>;
}
function Condor() {
  return <h1>Condor</h1>;
}

export default Daily;
