import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './_app';
import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';
if (!serviceWorker.isLocalhost) {
  Sentry.init({
    dsn: 'https://2b5eb6d7e8ae4dde882606f89ef8d40d@o1000533.ingest.sentry.io/5959889',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'dev-local',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDom.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
