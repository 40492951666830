import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Grid, Typography, Button } from '@material-ui/core'
import { useRouter } from '../util/router'
import { useGetContractorMeetingsForChild } from '../util/db'
import { useChildContext } from '../util/childProfileContext'
import { setDate } from 'date-fns'
import moment from 'moment'
import { useGetMightyKidsPlan } from '../util/db'
// Need to display when classes start for upcoming semester

// display if registrations are available

//

const SubscriptionInfoHelper = (props) => {
  const router = useRouter()
  const { childProfile, currentSemester, mightyKidsCorePlans, contractorClasses, availableRegistrations, needsAssessment } = useChildContext()
  const [ upcoming, setUpcoming ] = useState();
  const [ upcomingDate, setUpcomingDate ] = useState();
  const [ mkPlanId, setMkPlanId] = useState();
  const { data:upcomingPlan } = useGetMightyKidsPlan(mkPlanId);
  const [ upcomingPlanText, setUpcomingPlanText] = useState()
  useEffect(()=> {
    if(currentSemester && currentSemester.startDate){
      let startDate = currentSemester.startDate.toDate();
      let today = new Date()
      if(startDate > today){
        setUpcoming(true)
        setUpcomingDate( moment(startDate).format('dddd, MMM Do, YYYY'))
      } else {
        setUpcoming(false)
      }
    }
  },[currentSemester])

  useEffect(()=> {

    if(mightyKidsCorePlans && mightyKidsCorePlans.length > 0 && currentSemester){
          
      setMkPlanId(mightyKidsCorePlans[0].mkPlanId)
    }
  },[mightyKidsCorePlans,currentSemester])

  useEffect(()=> {
    if(upcomingPlan){
      setUpcomingPlanText(upcomingPlan.name)
    } else {
      setUpcomingPlanText()
    }
  },[upcomingPlan])

  const goToAssessment = () => {
    router.push('/assessment')
  }

  const goToRegistration = () => {
    router.push('/class-registration')
  }

  return (
    <Grid className="pb-2 pt-2" container direction="column" alignItems={'center'} justifyContent={'center'} >
            
      {upcoming && <>
        {upcomingPlanText && <Typography variant={'h5'} align="center">
          {upcomingPlanText}
        </Typography>}
        <Typography variant={'h6'} align="center">
                Classes start {upcomingDate}
        </Typography>
        <Typography className={'py-2'} variant={'subtitle'} align="center">
                Feel free to browse the demo lesson page and relax!
        </Typography></>
            
      }
      <Grid>
        { availableRegistrations && <Button color="secondary" size="large" onClick={goToRegistration}>Register for Your Zoom Class!</Button>}
        {childProfile  && needsAssessment && <Button color="secondary" size="large" onClick={goToAssessment}>Take the mighty kids learning assessment</Button>}
      </Grid>
    </Grid>
  )
}

export default SubscriptionInfoHelper