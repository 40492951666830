import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import FormField from '../components/FormField'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useAuth } from './../util/auth.js'
import { useForm } from 'react-hook-form'
import { Checkbox, FormControlLabel } from '@material-ui/core'


function SettingsGeneral(props) {
  const auth = useAuth()
  const [pending, setPending] = useState(false)
  const [phoneConsent, setPhoneConsent] = useState(auth.user?.phoneConsent || true)

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true)

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: 'success',
          message: 'Your profile has been updated',
        })
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          })
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          })
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false)
      })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <img src={auth.user.picture ||  '/images/profile_picture.png'} className="mb-3" alt={auth.user.name} width="100" height="100" />
      <Form.Group controlId="formFirstName">
        <FormField
          name="firstName"
          type="text"
          label="First Name"
          defaultValue={auth.user.firstName}
          placeholder="first name"
          error={errors.firstName}
          inputRef={register({
            required: 'Please enter your first name',
          })}
        />
                
      </Form.Group>
      <Form.Group controlId="formLastName">
        <FormField
          name="lastName"
          type="text"
          label="Last Name"
          defaultValue={auth.user.lastName}
          placeholder="last name"
          error={errors.lastName}
          inputRef={register({
            required: 'Please enter your last name',
          })}
        />
      </Form.Group>

      <Form.Group controlId="formEmail">
        <FormField
          name="email"
          type="email"
          label="Email Address"
          defaultValue={auth.user.email}
          placeholder="Email"
          error={errors.email}
          inputRef={register({
            required: 'Please enter your email',
          })}
        />
      </Form.Group>

      <Form.Group controlId="formPhone">
        <FormField
          name="phone"
          type="text"
          label="Cell Phone"
          defaultValue={auth.user.phone}
          placeholder="phone number"
          error={errors.phone}
          inputRef={register({
            required: 'Please enter a phone number',
          })}
        />
                
      </Form.Group>


      <FormControlLabel
        className="ml-1 mb-3"
        labelPlacement="left"
        control={
          <Checkbox
            name="phoneConsent"
            onChange={()=>setPhoneConsent(!phoneConsent)}
            checked={phoneConsent}
            inputProps={{ 'aria-label': 'phoneConsent' }}
          />}
        label="Send me text messages, updates and notifications"
      />
      {/* <Form.Group controlId="formLocation">
                <FormField
                    name="location"
                    type="text"
                    label="Location"
                    defaultValue={auth.user.location}
                    placeholder="Location"
                    error={errors.name}
                    inputRef={register({
                        required: 'Please enter your location',
                    })}
                />
            </Form.Group> */}
           



      <Button type="submit" disabled={pending}>
        <span>Save</span>

        {pending && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden={true}
            className="ml-2"
          >
            <span className="sr-only">Sending...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  )
}

export default SettingsGeneral
