import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useAuth } from '../util/auth'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useRouter } from '../util/router'
export default function ContractorDashboard() {
  const auth = useAuth();
  const router = useRouter()

  return (
    <React.Fragment>
      <CssBaseline />
      { auth && auth.user ?
        <Container maxWidth="sm">
          <Typography>Here are some helpful shortcuts: </Typography>
          <Grid container alignItems="flex-start" direction="column" justify="center">
            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-letters')}
            >
              {'Letter of the day'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-questions')}
            >
              {'Question of the day'}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-classes')}
            >
              {'Mighty Kids Zoom Class Generator'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-teachers')}
            >
              {'Teacher Admin'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-videos')}
            >
              {'Video Lessons Admin'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/contractor-user-admin')}
            >
              {'View Contractors'}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/contractor-classes-admin')}
            >
              {'View Contractor Classes List'}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/plans-and-promotions')}
            >
              {'Mighty Kids Plans and Promotions'}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-products')}
            >
              {'Mighty Kids Products'}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-semesters')}
            >
              {'Semester Dates + Admin'}
            </Button>

            <Button
              variant="text"
              color="primary"
              onClick={() => router.push('/admin/manage-alerts')}
            >
              {'User Alert Manager'}
            </Button>



          </Grid>
        </Container>
        : <CircularProgress />}
    </React.Fragment>
  );
}
