import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import './ModalLauncher2.scss'
import useSound from 'use-sound'
import { useGetTodaysLetter } from '../util/db'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function LetterOfTheDay(props) {
  const classes = useStyles();
  const { data: letterData } = useGetTodaysLetter()
  const [todaysLetter, setTodaysLetter] = useState({ audio: '', image: '', worksheet: '', letter: '' })
  const preventDefault = (e) => {
    e.stopPropagation();
  }
  useEffect(() => {
    if (letterData && letterData.length > 0) {
      setTodaysLetter(letterData[0])
    }
  }, [letterData])

  const DownloadFile = (props) => {
    return <a target="_blank" onClick={preventDefault} download href={props.href} rel="noreferrer">{props.children}</a>
  }

  const [play, { stop, pause, isPlaying }] = useSound(todaysLetter.audio)
  return (
    <Card className={'shadow-lg bg-white rounded text-center'} style={{ cursor: 'pointer' }} onClick={() => {
      isPlaying ? pause() : play() //toggle()
    }}>
      <Card.Img variant="top" src={todaysLetter.image} />
      <Card.Body>
        <Card.Title>Letter of the day</Card.Title>
        <Card.Text className="text-primary text-center justify-content-center align-content-center"
          style={{
            fontSize: 40,
          }}
        >
          {todaysLetter.letter.toUpperCase()}{todaysLetter.letter}

        </Card.Text>
        {todaysLetter.worksheet && todaysLetter.worksheet !== '' && <Typography className={classes.root}>
          <Link component={DownloadFile} href={todaysLetter.worksheet} onClick={preventDefault}> Download Worksheet </Link>
        </Typography>
          // <a style={{ zPosition: 100 }} href={letterData.worksheet} download>Download Worksheet</a>
        }
      </Card.Body>
      <Card.Footer>

        <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${isPlaying ? 'up' : 'off'}`} /></sub>
      </Card.Footer>
    </Card>

  )
}

export default LetterOfTheDay
