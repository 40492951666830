import React,  { useEffect, useState, useRef } from 'react'
import InnerHtml from 'dangerously-set-html-content'
import { Modal, LinearProgress } from '@material-ui/core';
import { unauthenticatedRequest } from '../util/util';
// import useScrollBottom from 'react-scroll-bottom-hook'
import { Button } from '@material-ui/core'
function PrivacyPolicy(props) {
  const  scrollRef = useRef();
  const [isBottom,setIsBottom] = useState(false);
  const [termsHTML, setTermsHTML] = useState()

  const initialize = async () => {
    let results = await unauthenticatedRequest('https://www.iubenda.com/api/terms-and-conditions/37982661/no-markup')
    setTermsHTML(results.content)
  }

  useEffect(()=>{
    initialize()
  },[])

  const onScroll = () => {
    const scrollTop = scrollRef.current.scrollTop
    const height = scrollRef.current.scrollHeight;
    const offsetHeight = scrollRef.current.offsetHeight;
    const diff = height - scrollTop - offsetHeight;
    if(diff < 10){
      setIsBottom(true)
    }
  }

  return (
    <div>
      <div
        style={{ paddingBottom:'20px', height: '80vh', outline:0 }}
      >
               
        <div onScroll={onScroll} ref={scrollRef} style={{ overflowY:'scroll',height:'100%' }}>
          <InnerHtml html={termsHTML} />
        </div>
        { !termsHTML && <LinearProgress />}
               
      </div>
      <div className="text-center pt-3" >
        {!props.agreeState &&  <Button onClick={props.agreeToTerms} color="primary" variant="contained" disabled={!isBottom}>    
          { isBottom ? 'I have Read and Agreed to Terms and Conditions.' : 'Scroll to Bottom to Accept Terms and Conditions'}
        </Button>}

        { props.agreeState && <Button onClick={props.closeModal} color="primary" variant="contained">    
                    Close
        </Button>}
      </div>
    </div>
  )
}

export default PrivacyPolicy
