import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import NewChild from '../components_student/NewChild'
import Container from 'react-bootstrap/Container'
import { requireAuth } from '../util/auth'
import ChildProfileCard from '../components_student/ChildProfileCard'
import { useChildContext } from '../util/childProfileContext'

const ProfileSelect = ({ edit = false }) => {
  const defaultValues = { name:'',birthdate:'',picture:'x' }
  const { children } = useChildContext()
  const [show, setShow] = useState(false)
  const [showCurrent, setShowCurrent] = useState(false)
  const [ currentChild, setCurrentChild] = useState(defaultValues)

  const selectChild = (child)=>{
    console.log('child:',child)
    setCurrentChild(child)
  }

  const newChild = ()=>{
    setShow(true)
    setCurrentChild(defaultValues)
  }

  return (
    <Container style={{ paddingTop: 20 }}>

      <Row className={'justify-content-center'}>
        {children?.length > 0 && !currentChild && <Col xs={12} className={'text-center pb-5'}><h2>Select a profile</h2></Col>}
        {currentChild && <NewChild
          show={showCurrent}
          setShow={setShowCurrent}
          setCurrentChild={setCurrentChild}
          currentChild={currentChild}
          create={false}
        />}
        {children && children.map(({ name, picture, birthdate, id }, count) => (
          <React.Fragment key={id}>
            <Col xs={12} md={edit ? 12 : 4}><ChildProfileCard
              key={count}
              name={name}
              picture={picture}
              birthdate={birthdate}
              id={id}
              setChild={selectChild}
              edit={edit}
              setShow={setShowCurrent}
            /></Col>
          </React.Fragment>

        ))}
        <Col xs={12} >
          <Row className={'justify-content-center'}>
            <Col xs={12} sm={12} md={12} lg={edit ? 12 : 12}>
                    
              <Col xs={12} className={'text-center pb-5'}><h2>Create a profile</h2></Col>
              <NewChild
                create={true}
                show={show}
                setShow={setShow}
                setCurrentChild={setCurrentChild}
                currentChild={currentChild}
              />
              <Card className={'shadow-lg p-3 mb-5 bg-white rounded text-center'} style={{ cursor: 'pointer' , maxWidth:'250px', margin:'auto' }} onClick={newChild}>
                <Card.Body>
                  <span className="icon pb-3">
                    <i style={{ fontSize: 64, color: 'grey', paddingRight: 15, paddingLeft: 15, paddingBottom: 5 }} className="fas fa-plus-circle" />
                  </span>
                  <Card.Text>Add Child</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default requireAuth(ProfileSelect)
