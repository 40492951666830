import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import { updateChild } from '../../util/db';
import { useRouter } from '../../util/router'
import CardContent from '@material-ui/core/CardContent';
import { CardActions } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function StudentDetails(props) {
  const router = useRouter();
  const [success, showSuccess] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (props.selectedStudent) {
      showSuccess(false)
      setValues(props.selectedStudent)
    }
  }, [props.selectedStudent])


  const handleInputChange = e => {
    const { name, value } = e.target
    console.log('name', name, 'value', value)
    setValues({ ...values, [name]: value })
  }

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues(props.selectedStudent)
    showSuccess(false);
  }


  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    //handle registration changes
    let student = props.selectedStudent;
    let studentUpdate = { birthdate: values.birthdate, name: values.name }
    await updateChild(studentUpdate, student.id)
    showSuccess(true)

  }

  const navigateToParent = () => {
    router.push('/admin/manage-users/' + props.selectedStudent.owner)
  }


  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Student Updated!🥳
        </Alert>
        : null}
      <Container key={props.selectedStudent.id} className={'px-5'}>
        <Col>
          <Card style={{ padding: 5, marginBottom: 25 }}>
            <CardContent>
              <h6>Student</h6>
              <h3>{values.name}</h3>
              <p>birthdate: {values.birthdate}</p>
              <p>child id: {props.selectedStudent.id}</p>
              <Row className="my-3" style={{ padding: 15 }}>
                <TextField fullWidth key={props.selectedStudent.id + 'name'} name="name" value={values.name} onChange={handleInputChange} label="Student Name" />
              </Row>
              <Row className="my-3" style={{ padding: 15 }}>
                <TextField fullWidth key={props.selectedStudent.id + 'bday'} name="birthdate" value={values.birthdate} onChange={handleInputChange} label="birthdate" />
              </Row>
            </CardContent>
            <CardActions>
              <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
              <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
            </CardActions>
          </Card>
          <Card>
            <CardContent>
              <h2>Parent or Gaurdian</h2>
              <br/>
              <p>Name: {props.selectedStudent.ownerName}</p>
              <p>email: {props.selectedStudent.email}</p>
              <p>owner id: {props.selectedStudent.owner}</p>
            </CardContent>
            <CardActions>
              <Button onClick={navigateToParent}> View Parent Details </Button>
            </CardActions>
          </Card>

                   
        </Col>
        <Col />
      </Container>
    </>)
}
