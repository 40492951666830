import React, { useState, useRef, useEffect } from 'react';
import Section from '../components/Section';
import PageLoader from '../components/PageLoader';
import Container from 'react-bootstrap/Container';
import SectionHeader from '../components/SectionHeader';
import { requireAuth, useAuth } from '../util/auth';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CircularProgress } from '@material-ui/core';
import { useAssessments } from '../util/db';
import moment from 'moment';
import CardDeck from 'react-bootstrap/CardDeck';
import { Link } from '../util/router.js';
import { getCurrentSchedule } from '../util/util';
import { useChildContext } from '../util/childProfileContext';

const mathForm = process.env.REACT_APP_MATH_FORM;
const langForm = process.env.REACT_APP_LANG_FORM;
const writeform = process.env.REACT_APP_WRITE_FORM;

function AssessmentSection(props) {
  const auth = useAuth();
  const { childProfile, assessmentAccess, subStatus, videoSchedule } = useChildContext();
  const [assessmentStart, setAssessmentStart] = useState();
  const [showFine, setShowFine] = useState(null);
  const [showLang, setShowLang] = useState(null);
  const [showMath, setShowMath] = useState(null);
  const [needMath, setNeedMath] = useState(false);
  const [needWriting, setNeedWriting] = useState(false);
  const [needLanguage, setNeedLanguage] = useState(false);
  const [nextAssessment, setNextAssessment] = useState('');
  const { data: writing, status: writeStatue, error: writeError } = useAssessments(childProfile?.id, 'writing', 1);
  const { data: language, status: langStatue, error: langError } = useAssessments(childProfile?.id, 'language', 1);
  const { data: math, status: mathStatue, error: mathError } = useAssessments(childProfile?.id, 'math', 1);
  const [preload, setPreload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPreload(false);
    }, 1000);
  });

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => setTimeout(() => scrollToRef(myRef), 500);

  const getNextSemester = async () => {
    //if child has schedule use that to determine the next assessment date.
    if (videoSchedule && !videoSchedule.completed) {
      console.log('latest child vid schedule:', videoSchedule);
      const nextAssessmentString = videoSchedule.endDate
        ? moment(videoSchedule.endDate.toDate()).format('MMM Do YYYY').toString()
        : '';
      setNextAssessment(nextAssessmentString);
      let classesStart = videoSchedule.startDate.toDate();
      let twoWeeksBefore = classesStart.setDate(classesStart.getDate() - 30);
      setAssessmentStart(twoWeeksBefore);
    } else if (assessmentAccess) {
      // count any assessments in the last month or so:
      let now = new Date();
      let recentAssessment = now.setDate(now.getDate() - 30);
      setAssessmentStart(recentAssessment);
    }
  };

  useEffect(() => {
    if (childProfile) {
      console.log('getting semester');
      getNextSemester();
    }
  }, [childProfile, assessmentAccess, videoSchedule]);

  useEffect(() => {
    if (childProfile) {
      if (!childProfile.writingAssessmentDate) {
        setNeedWriting(true);
      } else {
        setNeedWriting(false);
      }
      if (!childProfile.mathAssessmentDate) {
        setNeedMath(true);
      } else {
        setNeedMath(false);
      }
      if (!childProfile.languageAssessmentDate) {
        setNeedLanguage(true);
      } else {
        setNeedLanguage(false);
      }
    }
  }, [childProfile]);

  useEffect(() => {
    if (writing) setShowFine(false);
    if (language) setShowLang(false);
    if (math) setShowMath(false);
  }, [writing, language, math]);

  if (subStatus === 'loading') {
    return <PageLoader />;
  }
  return (
    <React.Fragment>
      {console.log(writeform, mathForm, langForm)}
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container
          style={{
            maxWidth: '850px',
          }}
        >
          <SectionHeader title={props.title} subtitle={props.subtitle} size={2} spaced={true} className="text-center" />
          <Container className={'pb-5'} style={{ paddingTop: 20 }}>
            <Row className={'pb-5'}>
              <div>
                Our initial comprehensive assessment will determine where your child is in all areas of Kindergarten
                readiness in Math, Writing & Fine motor, Language & Literature, and Social/ Emotional learning. Tailored
                curriculum will come to your account three days a week based on the assessment. The standards we use are
                research based and pulled from standards used by the NAEYC (National Association for the Education of
                Young Children), the department of Health and well-known and respected curriculum Learning Without
                Tears. It is compiled by our team of Early Childhood Education experts with various college degrees in
                Early Childhood Education and many years of experience in the field. Assessments will continue every
                semester for the length of your child’s time in preschool/Pre-K.
              </div>
              <div className="pt-5">
                Parents/caregivers will be responsible for digitally filling out the easy to follow assessment based on
                what their child can do within each standard. It will walk you through the process and we will track
                everything for you. You will have the opportunity to change your answers before the semester is up if
                your child is advancing more rapidly in a particular area.
              </div>
            </Row>
            {preload && <CircularProgress className={'center'} />}
            {assessmentAccess && !preload && (
              <Row className={'justify-content-center'}>
                <Col xs={12}>
                  <div className={'pb-5 text-center'}>
                    <h2>Select an Assessment below to get started</h2>
                    <span className={'text-danger'}>
                      Assessment best done on a larger tablet screen, laptop, or desktop computer!
                    </span>
                  </div>
                </Col>
                <CardDeck>
                  {/*<Col xs={12} md={4} className={'text-center align-content-center justify-content-center'}>*/}

                  <Card className={'shadow-lg bg-white rounded text-center'} style={{ cursor: 'pointer' }}>
                    <Card.Link
                      onClick={() => {
                        if (needWriting) {
                          executeScroll();
                          setShowFine(true);
                          setShowLang(false);
                          setShowMath(false);
                        }
                      }}
                    >
                      <Card.Body>
                        <div className={'icon'}>
                          <i className="fas fa-pencil-alt" />
                        </div>
                        <Card.Text>Writing & Fine Motor</Card.Text>
                        {needWriting && (
                          <Card.Text>
                            {' '}
                            <span className={'text-success'}> Start Assessment</span>
                          </Card.Text>
                        )}

                        {writing && writing.length > 0 && !needWriting && nextAssessment != '' && (
                          <Card.Text>
                            <b>Next Assessment:</b>
                            <br />
                            {nextAssessment}
                          </Card.Text>
                        )}
                        {writing && writing.length > 0 && (
                          <Card.Text>
                            <b>Last Completed:</b>
                            <br /> {moment(writing[0].created.toDate()).format('MMM Do YYYY').toString()}
                          </Card.Text>
                        )}
                      </Card.Body>
                    </Card.Link>
                  </Card>

                  {/*</Col>*/}
                  {/*<Col xs={12} md={4} className={'text-center align-content-center justify-content-center'}>*/}

                  <Card className={'shadow-lg bg-white rounded text-center'} style={{ cursor: 'pointer' }}>
                    <Card.Link
                      onClick={() => {
                        if (needLanguage) {
                          executeScroll();
                          setShowFine(false);
                          setShowLang(true);
                          setShowMath(false);
                        }
                      }}
                    >
                      <Card.Body>
                        <div className={'icon'}>
                          <i className="fas fa-book" />
                        </div>
                        <Card.Text>Language & Literacy</Card.Text>
                        {needLanguage && (
                          <Card.Text>
                            <span className={'text-success'}>Start Assessment</span>
                          </Card.Text>
                        )}
                        {language && language.length > 0 && !needLanguage && nextAssessment != '' && (
                          <Card.Text>
                            <b>Next Assessment:</b>
                            <br /> {nextAssessment}
                          </Card.Text>
                        )}
                        {language && language.length > 0 && (
                          <Card.Text>
                            <b>Last Completed:</b>
                            <br /> {moment(language[0].created.toDate()).format('MMM Do YYYY').toString()}
                          </Card.Text>
                        )}
                      </Card.Body>
                    </Card.Link>
                  </Card>

                  {/*</Col>*/}
                  {/*<Col xs={12} md={4} className={'text-center align-content-center justify-content-center'}>*/}

                  <Card className={'shadow-lg bg-white rounded text-center'} style={{ cursor: 'pointer' }}>
                    <Card.Link
                      onClick={() => {
                        if (needMath) {
                          executeScroll();
                          setShowFine(false);
                          setShowLang(false);
                          setShowMath(true);
                        }
                      }}
                    >
                      <Card.Body>
                        <div className={'icon'}>
                          <i className="fas fa-calculator" />
                        </div>
                        <Card.Text>Math</Card.Text>
                        {needMath && (
                          <Card.Text className={'pt-4'}>
                            <span className={'text-success'}>Start Assessment</span>
                          </Card.Text>
                        )}
                        {math && math.length > 0 && !needMath && nextAssessment != '' && (
                          <Card.Text className={'pt-4'}>
                            <b>Next Assessment:</b>
                            <br /> {needMath ? 'Start Now!' : nextAssessment}
                          </Card.Text>
                        )}
                        {math && math.length > 0 && (
                          <Card.Text>
                            <b>Last Completed:</b>
                            <br /> {moment(math[0].created.toDate()).format('MMM Do YYYY').toString()}
                          </Card.Text>
                        )}
                      </Card.Body>
                    </Card.Link>
                  </Card>

                  {/*</Col>*/}
                </CardDeck>
              </Row>
            )}
          </Container>

          {showFine && auth && auth.user && auth.user.uid && (
            <iframe
              allow="fullscreen"
              loading="lazy"
              //eslint-disable-next-line
              allowTransparency="true"
              frameBorder="0"
              scrolling="no"
              src={`https://form.jotform.com/${writeform}?uid=${auth.user.uid}&cid=${childProfile?.id}`}
              style={{ width: '100%', height: 900 }}
              width="900"
              id="writing"
              title="Assessment"
            />
          )}
          {showLang && auth && auth.user && auth.user.uid && (
            <iframe
              allow="fullscreen"
              loading="lazy"
              //eslint-disable-next-line
              allowTransparency="true"
              frameBorder="0"
              scrolling="no"
              src={`https://form.jotform.com/${langForm}?uid=${auth.user.uid}&cid=${childProfile?.id}`}
              style={{ width: '100%', height: 900 }}
              width="900"
              id="writing"
              title="Assessment"
            />
          )}
          {showMath && auth && auth.user && auth.user.uid && (
            <iframe
              allow="fullscreen"
              loading="lazy"
              //eslint-disable-next-line
              allowTransparency="true"
              frameBorder="0"
              scrolling="no"
              src={`https://form.jotform.com/${mathForm}?uid=${auth.user.uid}&cid=${childProfile?.id}`}
              style={{ width: '100%', height: 900 }}
              width="900"
              id="writing"
              title="Assessment"
            />
          )}
          {!assessmentAccess && !preload && (
            <>
              <div className="pb-5 text-center">
                <h3>You must have an active plan to view the assessment!</h3>
              </div>
              <div className="pb-5">
                <img
                  style={{ maxWidth: 300 }}
                  className="rounded mx-auto d-block"
                  alt="assessment image"
                  src="/images/assessment_page.webp"
                />
              </div>
            </>
          )}
          <div ref={myRef} />
        </Container>
        {math && math.length > 0 && language && language.length > 0 && writing && writing.length > 0 && (
          <Row className={'justify-content-center my-4'}>
            <Link to={`/reports/${childProfile?.id}/math`}>View Assessment Results</Link>
          </Row>
        )}
      </Section>
    </React.Fragment>
  );
}

export default requireAuth(AssessmentSection);
