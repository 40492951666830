import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';

function valuetext(value) {
  let amPM = 'am';
  let halfhour = ':00';
  if (value >= 12) {
    amPM = 'pm';
  }
  if (value >= 13) {
    value = value - 12;
  }
  if (value % 1 != 0) {
    value = value - 0.5;
    halfhour = ':30';
  }
  return `${value}${halfhour}${amPM}`;
}

const minDistance = 0.5;

export default function TimeOfDaySlider(props) {
  // const [value, setValue] = React.useState([12, 15]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      props.setValue([Math.min(newValue[0], props.value[1] - minDistance), props.value[1]]);
    } else {
      props.setValue([props.value[0], Math.max(newValue[1], props.value[0] + minDistance)]);
    }
  };

  return (
    <Box sx={{ width: 300, margin: 'auto' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography>{valuetext(props.value[0])}</Typography>
        </Grid>
        <Grid>
          <Typography>{valuetext(props.value[1])}</Typography>
        </Grid>
      </Grid>
      <Slider
        getAriaLabel={() => 'Time of Day'}
        value={props.value}
        step={0.5}
        min={6}
        max={18}
        onChange={handleChange1}
        valueLabelDisplay={'off'}
        getAriaValueText={valuetext}
        disableSwap
      />
    </Box>
  );
}
