import React, { useEffect, useState } from 'react'
import { Container, Button,Card,Col,Row,Alert,Popover,OverlayTrigger } from 'react-bootstrap'
import Box from '@material-ui/core/Box';
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import moment from 'moment'

import Segment from '../components/Segment'

import SegmentSection from '../components/SegmentSection'

import SectionHeader from '../components/SectionHeader'
import LessonContentCards from './LessonContentCards'
import WeeklySupplies from './WeeklySupplies'

import DynamicZoomLoader from './DynamicZoomLoader/DynamicZoomLoader'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useChildContext } from '../util/childProfileContext'
import { useAuth } from '../util/auth'
import { useAssessments, useGetSchedule, useGetChildSchedule } from '../util/db'
import { useRouter } from '../util/router'
import { getCurrentSchedule, paddedDateHash, dateHash } from '../util/util'
import useSessionStorage from '../util/sessionStorage'
// import * as MButton from '@material-ui/core/Button'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    minHeight: 36,
    padding: '0 30px',
  },
  register: {
    background: 'linear-gradient(45deg, #ffa500 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    minHeight: 36,
    padding: '0 30px',
  },
});

const holidays = ['Labor Day', 'Veterans Day', 'Thanksgiving Day', 'Day after Thanksgiving Day', 'Christmas Day', 'Day after Christmas Day']

// const schoolStart = new Date(2020, 8, 10)
// const semesterStart = new Date(2020, 0, 30)
const todayDate = new Date()
const daysOfPreview = 14;
const nextWeek = new Date(todayDate.getTime() + (14 * 24 * 60 * 60 * 1000));

function TodaysLessonCards(props) {
  const largeScreen = useMediaQuery('(min-width:768px)');
  const router = useRouter()
  const auth = useAuth()
  const classes = useStyles()
  const { childProfile, videoSchedule, needsAssessment } = useChildContext()
  // const [oldChild, setOldChild] = useState(false)
  const [selectedDay, setSelectedDay] = useSessionStorage('selectedDay', new Date())
  const [selectedDate, setSelectedDate] = useSessionStorage('dateFunc', new Date())
  //const [showVideos, setShowVideos] = useState(true);
  // const [newAssessment, setNewAssessment] = useState(false);
  const [disabledDays, setDisabledDays] = useState();
  // const [videoSchedule, setVideoSchedule] = useState();
  const [dayItems, setDayItems] = useState();
  const [showVideosToday, setShowVideosToday] = useState();



  useEffect(() => {
    if (videoSchedule) {
      console.log('child video schedule holidays', videoSchedule.holidays)
      setDisabledDays([...videoSchedule.holidays, { before: videoSchedule.startDate.toDate() },{ after: nextWeek } ,{ daysOfWeek:videoSchedule.disabledDays }])
    }
    console.log('video schedule on lesson page',videoSchedule)
  }, [videoSchedule])

  useEffect(() => {
    if (videoSchedule) {
      let currentSchedule = videoSchedule.schedule
      const d = selectedDay ? new Date(selectedDay) : new Date()
      let dayHash = paddedDateHash(d)
      console.log('current schedule', currentSchedule, 'date hash', dayHash)

      if (currentSchedule && currentSchedule[dayHash]) {
        setShowVideosToday(true)
      } else {
        setShowVideosToday(false)
              
      }
    }
  }, [videoSchedule, selectedDay])


  const handleDaysClick = (day, { selected, disabled }) => {
    if (disabled) {
      return
    }
    // console.log('-----',day, selected)
    setSelectedDay(selected ? undefined : day)
    setSelectedDate(day.toString())
    // console.log(countDiff(day))

  }

  const tileMap = {
    'Writing':'writing',
    'Social Emotional': 'social',
    'Math':'math',
    'STEM': 'stem',
    'Art':'art',
    'Language':'language',
  }

  const tiles = {
    writing: {
      image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1042764446-2f9ebc034452cfdebd67f67bf3a81d63d8f7d64ab7630d43e4b3d55032b5d21a-d_450.jpeg?alt=media&token=722a2175-bb21-4a40-80d3-7c27e9b35c3c',
      gif: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2FWR-S5-L1-E7-high.gif?alt=media&token=05bbb8cd-d6fb-4d73-8b07-f5d5d95ee774',
      title: 'Writing',
      // body: 'Writing Intro!',
      url: `/lessons/${childProfile?.id}/writing-fine-motor`,
      contains: ['video'],
    },
    social: {
      image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1229911241-46cde95f0818a6bcaab0f39e402c887c5752e8c35728c84154bd76e804294988-d_450.jpeg?alt=media&token=94f392e0-01cc-4e79-b3c1-65dc7c9a2623',
      gif: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2FSE-E38-high.gif?alt=media&token=18f24905-d02c-4472-a9c9-4e0ced034684',
      title: 'Social/Emotional',
      // body: 'Social & Emotional Intro',
      url: `/lessons/${childProfile?.id}/social-emotional`,
      contains: ['video'],
    },
    math: {
      image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1251400705-9f02e0734aa63f712a15ca30698e8fa580ad6170d0958b34f_450.jpeg?alt=media&token=3ae667e2-6fee-4cb3-8a0d-cc79ad89de96',
      gif: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2FMA-S1-L1-E1-high.gif?alt=media&token=0e1a9b16-d6e4-419b-8b30-328df629e10c',
      title: 'Math',
      // body: 'Social & Emotional Intro',
      url: `/lessons/${childProfile?.id}/math`,
      contains: ['video'],
    },
    stem: {
      image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1229940779-536e16574f872b62bf90a8f3f185e0308806b6a6ff3a3eecccb599cf8aba2da7-d_450.jpeg?alt=media&token=d6365207-61e4-4f8c-83cf-563a4ba4bf2f',
      gif: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2FSTEM-E38-high.gif?alt=media&token=ed7d7ff6-1281-47e2-a866-cc98090c7678',
      title: 'STEM',
      // body: 'STEM',
      url: `/lessons/${childProfile?.id}/stem`,
      contains: ['video'],
    },
    language: {
      image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1170226702-54591da4d30492c147b51de750b02f908ad4ad68b67544f64caa3c6c991a10ee-d_450.jpeg?alt=media&token=8237485e-5a5b-4276-9098-ddf40cfd3156',
      gif: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2FLL-S6-L5-E4-high.gif?alt=media&token=da762f21-5384-433f-b475-9f9ada5bd904',
      title: 'Language & Literature',
      // body: 'Second Intro to Language & Literature!',
      url: `/lessons/${childProfile?.id}/language-literature`,
      contains: ['video'],
    },
    art: {
      image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1167837864-36f1538ac02244ba37856fec2235a297925e9997b340fd9b3c6d56cb6715f5fa-d_450.jpeg?alt=media&token=be137703-e2f4-47d0-9fdd-aeed1e4362af',
      gif: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2FART-E36-high.gif?alt=media&token=c9f6d5ad-0d48-4941-a66e-6552653fd4f9',
      title: 'Art',
      // body: 'Second Intro to Language & Literature!',
      url: `/lessons/${childProfile?.id}/art`,
      contains: ['video'],
    },
    language2: {
      image: 'https://i.vimeocdn.com/video/950542901_295x166.jpg',
      title: 'Language & Literature',

      // body: 'Intro to Language & Literature!',
      url: `/lessons/${childProfile?.id}/language-literature/previous`,
      contains: ['video'],
    },
  }

  useEffect( () => {
    if(videoSchedule && videoSchedule.schedule){
      const d = selectedDay ? new Date(selectedDay) : new Date()
      const selectedDateHash = paddedDateHash(d);
      console.log('vid schedule',videoSchedule)
      console.log('date hash',selectedDateHash)
      let daysVideos = videoSchedule.schedule[selectedDateHash];
      console.log('days videos',daysVideos)
      let daysTiles = [];
      if(daysVideos){
        Object.entries(daysVideos).map(([key, val]) => {
          daysTiles.push({ ...tiles[tileMap[key]], url:'/video-lesson/' + val })
        });
        console.log('days items:',daysTiles)
        setDayItems(daysTiles)
      } else {
        setDayItems([])
        //setShowVideos(false)
      }
    }

  },[selectedDay,videoSchedule])


  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container>

        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <>

          <Row><Col className={'justify-content-center text-center'}><Alert variant={'success'}>You view this weeks lessons and downloads by clicking &quot;Go To Lesson&quot; and selecting a future date from the current week!</Alert></Col></Row>
          <WeeklySupplies date={selectedDate} />

          <Row className="pb-3"><Box clone order={{ xs: 1 }}><Col xs={6} md={4}><OverlayTrigger
            trigger="click"
            key={'bottom'}
            placement={'bottom'}
            rootClose
            rootCloseEvent={'click'}
            overlay={
              <Popover id={'popover-positioned-bottom'}>
                <Popover.Title as="h3">Previous Lesson Days</Popover.Title>
                <Popover.Content>
                  <DayPicker
                    hideOnDayClick={true}
                    todayButton="Go to Today"
                    hideDayPicker
                    onDayClick={handleDaysClick}
                    selectedDays={selectedDay}
                    modifiers={{
                      today: new Date(),
                      holidays: videoSchedule ? [...videoSchedule.holidays]: [],
                    }}
                    const modifiersStyles={{
                      today: {
                        color: 'red',
                        backgroundColor: 'white',
                      },
                      holidays: {
                        color: '#ffc107',
                        backgroundColor: '#fffdee',
                      },
                    }}
                    // selectedDays={new Date()}
                    onTodayButtonClick={(day, modifiers) => { setSelectedDay(day); setSelectedDate(todayDate) }} //console.log(day, modifiers)
                    //   fromMonth={ Object.keys(childProfile.videoSchedule.schedule)[0].split('-')[1]}
                    // toMonth={new Date(2021, 5)}
                    disabledDays={disabledDays}
                    // showOutsideDays
                  />
                </Popover.Content>
              </Popover>
            }
          >
            <Button style={{ maxHeight: 48 }} variant="outlined" color="primary">Go To Lesson</Button>
          </OverlayTrigger>
          {/*<Button variant={'outline-primary'} onClick={() => props.setPrevious(true)}>Previous Lessons</Button>*/}
          </Col>
          </Box>
          <Box clone order={{ xs: 3, sm: 2 }}><Col xs={6} sm={6} md={3} className={'justify-content-center text-center pt-2'}>Lessons from {moment(selectedDate).format('dddd, MMM Do, YYYY')}</Col></Box>

          <Box clone order={{ xs: 3, sm: 3 }}>
            <Col xs={6} md={4}><div className={largeScreen ? 'float-right' : ''}><Button className={classes.root} variant={'success'} onClick={() => { setSelectedDay(todayDate); setSelectedDate(todayDate.toString()) }}>Go To Today</Button></div></Col>
          </Box>
          </Row>

          <Row>

            {showVideosToday && <LessonContentCards
              items={dayItems}
            />}
            {!showVideosToday && <Col sm={12}><SegmentSection>
              <Container className={'text-center'} style={{ height: 250 }}>
                <h1>No New Videos Today</h1>
                <p>Select a previous lesson day</p>
              </Container>
            </SegmentSection></Col>}

          </Row>
        </> 
      </Container>
    </Segment>
  )
}

export default TodaysLessonCards
