import React, { useEffect, useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import { CircularProgress } from '@material-ui/core'
import Button from 'react-bootstrap/Button'
import Holidays from 'date-holidays'
import Container from 'react-bootstrap/Container'
import Confetti from 'confetti-react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import '../components_student/LessonPage.scss'
import AvatarSection from '../components_student/AvatarSection'
import ProfileSelect from '../components_parent/ProfileSelect'
import UpdateBillingAlert from '../components_parent/UpdateBillingAlert'
import DemoDay from '../components_student/DemoDay'
import TodaysLessonCards from '../components_student/TodaysLessonCards'
import DailySection from '../components_student/DailySection'
import RouterPrompt from '../components_student/ChildNavPrompt'
import useSessionStorage from '../util/sessionStorage'
import { useAuth, requireAuth } from '../util/auth.js'
import { useChildContext  } from '../util/childProfileContext.js'
import { useGetConfettiDates, useGetAvailableRegistrations, useGetConfig } from '../util/db'
import { Link, useRouter } from '../util/router'
import SubscriptionInfoHelper from '../components/SubscriptionHelperTool'
import UpcomingContractorClasses from '../components/UpcomingContractorClasses'
import ShowUserAlerts from '../components/ShowUserAlerts'
import DynamicZoomLoader from '../components_student/DynamicZoomLoader/DynamicZoomLoader'

const holidays = ['Labor Day', 'Veterans Day', 'Thanksgiving Day', 'Day after Thanksgiving Day', 'Christmas Day', 'Day after Christmas Day', 'New Year\'s Eve','New Year\'s Day', 'Martin Luther King Jr. Day','Memorial Day']

function LessonPage(props) {
  const auth = useAuth()
  const { childProfile, videoLessonAccess, needsAssessment, generatingSchedule ,zoomMeetingAccess, videoSchedule ,status,subStatus } = useChildContext()
  const [confetti, setConfetti] = useState(false)
  const [preload, setPreload] = useState(true)
  const [confettiMessage, setConfettiMessage] = useState('')
  const [showConfetti, setShowConfetti] = useState(false)
  const [isBirthday, setIsBirthday] = useState(false)
  const [previous, setPrevious] = useSessionStorage('previous')
  const [showRegisterButton, setShowRegisterButton] = useState(false)
  const [showDiplomaButton, setShowDiplomaButton] = useState(false)
  const d = new Date()
  const hd = new Holidays()
  const { width, height } = useWindowSize()
  const { data: confettiDates } = useGetConfettiDates()
  const { data: unusedRegistrations } = useGetAvailableRegistrations(childProfile?.id, auth.user?.uid)
  const { data: lessonConfig, status: lessonStatus } = useGetConfig('lesson')
  const router = useRouter();

  useEffect(() => {
    if(unusedRegistrations?.length > 0 && unusedRegistrations[0].availableRegistrations?.length && unusedRegistrations[0].availableRegistrations?.length > 0){
      setShowRegisterButton(true)
    } else {
      setShowRegisterButton(false)
    }
  },[unusedRegistrations,childProfile,auth])

  useEffect( ()=> {
    if(auth.user){
      console.log('user stripe account linked:',auth.user.stripeActive) 
    }   

    console.log('user stripe account:',auth.user)

  },[auth])

  useEffect(() => {
    if (confettiDates && confettiDates.length > 0 && !isBirthday) {
      let todaysConfetti = confettiDates[0]
      setConfettiMessage(todaysConfetti.message)
      setConfetti(true)
      setShowConfetti(true)
    }

  }, [confettiDates, isBirthday])

  useEffect(() => {
    console.log('lessonstatus', lessonStatus)
    console.log('lessonconfig', lessonConfig)
    if(lessonStatus != 'loading' && lessonConfig && !showDiplomaButton) {
      setShowDiplomaButton(lessonConfig.diploma)
    }
  }, [lessonConfig, showDiplomaButton, lessonStatus])

  useEffect(() => {
    if (childProfile && childProfile.birthdate) {
      let splitDate = childProfile.birthdate.split('-')
      if (splitDate.length > 2 && (d.getMonth() + 1) === parseInt(splitDate[1]) && d.getDate() === parseInt(splitDate[2])) {
        setConfettiMessage(`Happy Birthday ${childProfile.name}!!!`)
        setShowConfetti(true)
        setConfetti(true)
        setIsBirthday(true)
        return
      }
      return
    }
  }, [childProfile, isBirthday])

  hd.init('US', 'WA', 'se')
  useEffect(() => {
    setTimeout(() => setConfetti(false), 15000)
  }, [confetti])

  const goToRegistrationPage = () => {
    router.push('/class-registration')
  }

  const goToAssessment = () => {
    router.push(`/lesson/${childProfile?.id}/assessment`)
  }

  useEffect(()=>{
    setTimeout(()=> {setPreload(false)},500)
  },[])

  return (
    <>
      <RouterPrompt />
      <ShowUserAlerts/>
      <SubscriptionInfoHelper/>
           
      {!preload && auth.user.stripeActive && !childProfile && <ProfileSelect />}
      { !preload && childProfile && videoLessonAccess && (
        <>
          <UpdateBillingAlert />

          <Container>
            <Row>
              {childProfile && <Col className={'text-center justify-content-center'}>
                <AvatarSection picture={childProfile.picture} />
                <div><h1>Hi, {childProfile.name}</h1></div>
              </Col>}

              {confetti && <Confetti width={width} height={height} tweenDuration={15000} />}

            </Row>
            {showDiplomaButton &&
                            <Row><Col className={'text-center align-content-center justify-content-center'}>
                              <h3>End of Year Diplomas are here!</h3>
                              <p>To set the name on the diploma, please configure your child&apos;s profile name.</p>
                              <a href={`https://res.cloudinary.com/mighty-kids-virtual-academy/image/upload/g_center,l_text:${lessonConfig.font}:${childProfile.name},x_0,y_0/${lessonConfig.image}`} target="_blank" rel="noreferrer"><Button style={{ marginTop: 10, marginBottom: 10 }} variant={'outline-success'}>Get Diploma</Button></a>
                            </Col></Row>}
            {showConfetti &&
                            <Row><Col className={'text-center align-content-center justify-content-center'}>
                              <h3>{confettiMessage}</h3>
                              {!confetti && <Button style={{ marginTop: 10, marginBottom: 10 }} variant={'outline-success'} disabled={confetti} onClick={() => { setConfetti(true); }}>Replay Confetti</Button>}
                            </Col></Row>}
                            
          </Container>
          <React.Fragment>
            <DailySection
              bg="white"
              textColor="dark"
              size="md"
              bgImage=""
              bgImageOpacity={1}
              title="Daily Items"
              subtitle=""

            />
            {(generatingSchedule && !videoSchedule) && <Alert className="mt-5" key="1" variant="warning">
              <Row><Col sm="10" ><Row style={{ justifyContent:'center' }}><CircularProgress className={'m-2'}/></Row><Row><div style={{ width:'100%' }} className="right text-center">Preparing your childs personal video schedule, please allow several minutes for this process to complete.</div></Row></Col></Row>
            </Alert>}
            { needsAssessment && <Row><Col className={'justify-content-center text-center'}><Alert variant={'warning'}>{videoSchedule?.completed ? 'A new' : 'An'} assessment is needed to provide a customized learning experience for your child.<br /> <Button onClick={goToAssessment} variant="warning">Complete Now</Button></Alert></Col></Row>}
                
            {videoSchedule && !needsAssessment && <TodaysLessonCards
              bg="white"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              setPrevious={setPrevious}
              previous={previous}
              hd={hd}
              holiday={holidays.includes(hd.isHoliday(d).name)}
              title={'Lessons'}
              subtitle=""
            />}</React.Fragment>

          {zoomMeetingAccess && <DynamicZoomLoader/>}


        </>
      )}
      {((videoLessonAccess !== true && childProfile) || !auth.user.stripeActive )  &&  !preload && (
        <DemoDay />
      )}
      <UpcomingContractorClasses/>
    </>
  )
}

export default requireAuth(LessonPage)