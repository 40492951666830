import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import ContentCards from '../components/ContentCards'

function TodaysLessonSegment(props) {
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <ContentCards
          items={[
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1229940779-536e16574f872b62bf90a8f3f185e0308806b6a6ff3a3eecccb599cf8aba2da7-d_450.jpeg?alt=media&token=d6365207-61e4-4f8c-83cf-563a4ba4bf2f',
              title: 'STEM',
              body:
                'Today we will discuss how clouds work!',
              url: '/lessons/stem',
              contains: ['video', 'instructions'],
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1251400705-9f02e0734aa63f712a15ca30698e8fa580ad6170d0958b34f_450.jpeg?alt=media&token=3ae667e2-6fee-4cb3-8a0d-cc79ad89de96',
              title: 'Math',
              body:
                'We will be discussing patterns with ice cream!',
              url: '/lessons/math',
              contains: ['video', 'instructions', 'printable-pdf'],
            },
            {
              image: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/assets%2F1170226702-54591da4d30492c147b51de750b02f908ad4ad68b67544f64caa3c6c991a10ee-d_450.jpeg?alt=media&token=8237485e-5a5b-4276-9098-ddf40cfd3156',
              title: 'Language & Literature',
              body:
                'It\'s rhyme time for today\'s lesson!',
              url: '/lessons/language-literature',
              contains: ['video', 'instructions', 'printable-pdf'],
            },
            {
              image:
                '/images/zoom-training-3-792x418.jpg',
              title: 'Zoom',
              body:
                'Information about your zoom connection will be here if purchased.',
              url: '#',
              contains: ['video'],
            },
          ]}
        />
      </Container>
    </Segment>
  )
}

export default TodaysLessonSegment
