import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { shortSchedule,renderDate } from '../../util/util.js';

const useStyles = makeStyles({
  root: {
    maxWidth: 445,
    margin: 5,
    width: '100%',
        
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [registered, setRegistered] = useState(false)
  const [classFull, setClassFull] = useState(false)

  const renderLineItems = () => {
    console.log('upcoming invoiceData',props.invoiceData)
    if(props.invoiceData?.lines?.data?.length > 0){
      console.log('displaying line items:',props.invoiceData.lines.data)
      let LineRows = props.invoiceData.lines.data.map(lineItem => {
        console.log(lineItem)
        return (
          <Grid  style={{ width:'100%' }} container key={lineItem.id} className={['p-1',classes.root]}>
            <Grid style={{ width:'100%' }}>
              <Typography align="left" variant="subtitle1" color="textSecondary" >
                {lineItem.description}
              </Typography>
            </Grid>
                        
          </Grid>)

      })
      console.log(LineRows)
      return <>{LineRows}</>
    }
    return null
  }

  return (
    <Grid container direction="column"
      justify="center"
      alignItems="flex-start"
      style={{ width:'100%' }}
    >
            
      <Typography align="center" variant="title1" color="" >
                   Upcoming Invoice: (${ (props.invoiceData?.amount_due / 100).toFixed(2)} will be charged automatically on {renderDate(props.invoiceData?.period_start * 1000)})
      </Typography>
         
      <Grid  style={{ width:'100%' }}>
        {renderLineItems()}
      </Grid>

    </Grid>
  );
}