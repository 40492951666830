import React from 'react'
import TeamBiosSection2 from '../components_parent/TeamBiosSection2'
import { requireAuth } from '../util/auth.js'

function TeachersPage(props) {
  return (
    <TeamBiosSection2
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Meet Your Teachers"
      subtitle=""
    />
  )
}

export default requireAuth(TeachersPage)
