import React from 'react'

import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import CardGroup from 'react-bootstrap/CardGroup'
import './ScheduleCard.scss'

const ScheduleCard = (plan) => (
  <React.Fragment>
    <CardGroup>
      <Card>
        <ListGroup>
          <ListGroupItem>
            <Card.Title className={'text-center'}>Monday</Card.Title>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Math</div>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center art-space'}>Art</div>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Writing</div>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <Card>
        <ListGroup>
          <ListGroupItem>
            <Card.Title className={'text-center'}>Wednesday</Card.Title>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Social/Emotional</div>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Language & Literature</div>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Writing</div>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <Card >
        <ListGroup>
          <ListGroupItem>
            <Card.Title className={'text-center'}>Friday</Card.Title>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Math</div>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>Language & Literature</div>
          </ListGroupItem>
          <ListGroupItem>
            <div className={'text-center'}>STEM</div>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </CardGroup>
  </React.Fragment>
)

export default ScheduleCard
