import React, { useEffect, useState } from 'react'
import ThankYouSection from '../components_parent/ThankYou'
import { useRouter } from '../util/router'
import { requireAuth,useAuth } from '../util/auth.js'
import { Typography, Grid, Paper, Button, CircularProgress } from '@material-ui/core'
import ProfileSelect from '../components_parent/ProfileSelect'
import { useChildContext  } from '../util/childProfileContext.js'
import { makeStyles } from '@material-ui/core/styles';
import { useGetMightyKidsPlan, useGetMightyKidsProduct } from '../util/db'
import ZoomTimeSlotPoll from '../components_parent/ZoomTimeSlotPoll';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    // padding: theme.spacing(2),
  },
}));

function ThankYouPage(props) {
  const classes = useStyles();
  const router = useRouter()
  const auth = useAuth();
  const { childProfile, needsAssessment,status,subStatus, availableRegistrations } = useChildContext()
  const [mkPlanId,setMkPlanId] = useState();
  const [mkProductId,setMkProductId] = useState();
  const [registrationReady, setRegistrationReady ] = useState();
  const [ showTimeSlotSelect, setShowTimeSlotSelect] = useState();
  const [semesterId,setSemesterId] = useState();
  const { data:mightyKidsPlan } = useGetMightyKidsPlan(mkPlanId)
  const { data:mightyKidsProduct } = useGetMightyKidsProduct(mkProductId)
  const [ loading, setLoading] = useState(true)
  const classType = router.query.classType;
  const resourceId = router.query.resourceId;

  useEffect(()=>{
    setTimeout(()=> setLoading(false),1000)
  },[])

  useEffect(()=> { 
    if(classType === 'core'){
      console.log( 'settting mkplan id',resourceId)
      setMkPlanId(resourceId);
    }
  },[router])

  useEffect(()=>{
    if(mightyKidsPlan){
      console.log('plan data',mightyKidsPlan)
      setMkProductId(mightyKidsPlan.productId)
    }
  },[mightyKidsPlan])

  useEffect(() => {
    console.log('mighty kids product',mightyKidsProduct)
    if(mightyKidsProduct){
      console.log('product',mightyKidsProduct)
      if(mightyKidsPlan.semesterId !== 'now' && mightyKidsProduct.zoomMeetings){
        setRegistrationReady(mightyKidsPlan.classesReady)
      }
    }
     
    console.log('reggie ready:',!registrationReady)
    console.log('zooom meeaties:',mightyKidsProduct?.zoomMeetings)
    console.log('childProfle:',childProfile)

  },[mightyKidsProduct])

  const goToAssessment = () => {
    router.push('/assessment')
  }

  const goToLessons = () => {
    router.push('/lesson')
  }

  const goToRegistration = () => {
    router.push('/class-registration')
  }

  const goToZoomMeetings= () => {
    router.push('/zoom-meetings')
  }

  return (<>
    { loading ? <Grid container alignItems="center" justifyContent="center"><CircularProgress/></Grid>:<>
      <Grid className={'mt-5'} container alignItems="center" direction="column" alignContent="center">
        <Grid container alignItems="center" direction="column" alignContent="center">
          <Typography variant="h4" align="center">
                    Thank you for your purchase!
          </Typography>
        </Grid>
               
        <Grid container alignItems="center" direction="column" alignContent="center">
          {(auth.user.stripeActive && !childProfile) && <> 
            <Typography className={'p-3'} align="center">
                            First thing we need to do is create a profile for your child:
            </Typography>
            <ProfileSelect />
          </>}
        </Grid>
      </Grid>
      <Grid container alignItems="center" direction="column" alignContent="center">
        { classType === 'core' && 
                    <Grid container direction="column" alignItems="center" justify="center">
                      {!registrationReady && mightyKidsProduct?.zoomMeetings && childProfile && <>
                        <Typography className="pt-5" variant="h6" align="center">
                                Looks like registration for this class has not opened yet, take a moment to indicate your preferred times so we can offer better class times for you and your child!
                        </Typography>
                        <ZoomTimeSlotPoll mightyKidsPlan={mightyKidsPlan}/>
                      </>}
                      { availableRegistrations && <Button onClick={goToRegistration}>Register for a Zoom Class</Button>}
                      {childProfile  && needsAssessment && <>
                        <Typography className="pt-5" variant="h6" align="center">
                               Learning Assessments!
                        </Typography>
                        <Typography align="center" className="p-5">
                                In order to provide customized videos lessons we require that you take 3 learning assessments. You may complete them now or take it anytime before classes start!
                        </Typography>
                        <Button color="primary" onClick={goToAssessment}>Go to assessment</Button>
                            
                      </>}
                      <Button style={{ maxWidth:200 }} className="m-3" color="secondary" variant="contained" onClick={goToLessons}>Go to <br/>lesson page</Button>
                    </Grid>
        }

        { classType === 'enrichment' && 
                    <Grid container direction="column" alignItems="center" justify="center">
                      <Typography className={'p-3'} align="center">
                            Thank you for signing up for one of our Enrichment Classes!
                            Classes are available on your Zoom Meetings Page.
                      </Typography>
                      <Button style={{ maxWidth:200 }} className="m-3" color="primary" variant="contained" onClick={goToZoomMeetings}>Go to the Zoom meetings Page</Button>
                    </Grid>}
           
      </Grid>
    </>}
  </>
  )
}

export default requireAuth(ThankYouPage)