import React from 'react'
import { Container,Row,Col,Button } from 'react-bootstrap'

import SectionHeader from '../components/SectionHeader'
import VideoEmbed from '../components/VideoEmbed'
import Segment from '../components/Segment'

import { useAuth } from '../util/auth'
import './HeroSection.scss'

function HeroSection(props) {
  const auth = useAuth()
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={5} className="text-center text-lg-left">
            <div className={'pt-2 pl-3 pr-2 pb-0 mb-2'} style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={1}
                spaced={true}
              /></div>
            <Button
              variant={props.buttonColor}
              size="lg"
              onClick={auth && auth.user && auth.user.uid ? props.signedInOnClick : props.buttonOnClick }
            >
              {auth && auth.user && auth.user.uid ? 'Go to Account' : props.buttonText}
            </Button>
          </Col>
          <Col className="offset-lg-1 mt-5 mt-lg-0 ">
            <figure className="HeroSection__image-container mx-auto">
              <Container
                style={{
                  maxWidth: '768px',
                }}
              >
                <VideoEmbed url={props.video}/></Container>                        </figure>
          </Col>
        </Row>
      </Container>
    </Segment>
  )
}

export default HeroSection
