import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Col } from 'react-bootstrap';
import Alert from '@material-ui/lab/Alert';
import {
  CardMedia,
  FormControlLabel,
  Button,
  Modal,
  Checkbox,
  Grid,
  TextField,
  Chip,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Container,
  Typography,
  CssBaseline,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { makeStyles } from '@material-ui/core/styles';

import { useChildContext } from '../../util/childProfileContext';
import moment from 'moment-timezone';
import { useRouter } from '../../util/router';
import { apiRequest } from '../../util/util';
import InvoicePreview from './InvoicePreview';
import UpcomingPreview from './UpcomingPreview';
import PaymentMethods from './PaymentMethods';
import { conversionsEvent } from '../../util/util';
import ChildSelector from '../ChildSelector';
import SubscriptionPurchaseCard from './SubscriptionPurchaseCard';
import PrivacyPolicyModal from '../../components_iubenda/PrivacyPolicyModal';
import TermsAndConditionsModal from '../../components_iubenda/TermsAndConditionsModal';
import CardHolder from './CardHolderInfo';
import { useAuth } from '../../util/auth';

const isProd = process.env.NODE_ENV !== 'development';
//https://app.mightykidsvirtualacademy.com/terms
//https://app.mightykidsvirtualacademy.com/privacy

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  textField: {
    width: '100%',
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    width: '400',
    backgroundColor: '#fff',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10vh',
  },
}));

function PaymentPortal(props) {
  const auth = useAuth();
  const { childProfile, children, setChildId } = useChildContext();
  const router = useRouter();
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [paymentMethods, setPaymentMethods] = useState('loading');
  const [selectedMethod, setSelectedMethod] = useState();
  const [terms, setTerms] = useState(isProd ? false : true);
  const [privacy, setPrivacy] = useState(isProd ? false : true);
  const [showPurchaseButton, setShowPurchaseButton] = useState(false);
  const [coupon, setCoupon] = useState();
  const [thankYou, showThankYou] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [unsavedCard, setUnsavedCard] = useState(false);
  const [cardError, setCardError] = useState();
  const [processingError, setProcessingError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [discounts, setDiscounts] = useState(0);
  const [childId, setChildIdPP] = useState();
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [paymentDescription, setPaymentDescription] = useState();
  const [freeTrialEnd, setFreeTrialEnd] = useState();
  const [freeTrial, setFreeTrial] = useState(true);
  const [openPP, setOpenPP] = useState(false);
  const [cardHolderData, setCardHolderData] = useState({ address_country: 'US' });

  let [debounceTimer, setDebounceTimer] = useState();

  const selectChildId = (id) => {
    setChildIdPP(id);
    setChildId(id);
  };

  useEffect(() => {
    if (props.mkPlanData && props.mkPlanData.trialPeriod) {
      let today = new Date();
      console.log('trial period:', props.mkPlanData.trialPeriod);
      today.setDate(today.getDate() + Number(props.mkPlanData.trialPeriod));
      setFreeTrialEnd(moment(today).format('MMM Do, YYYY'));
    } else if (props.mkPlanData) {
      setFreeTrialEnd(moment().format('MMM Do, YYYY'));
    }
  }, [props.mkPlanData]);

  useEffect(() => {
    if ((props.mkPlanData && Number(props.mkPlanData.trialPeriod) === 0) || props.subType === 'once') {
      setFreeTrial(false);
    }
  }, [props.mkPlanData]);

  useEffect(() => {
    let diff = props.currentInvoice?.subtotal - props.currentInvoice?.amount_due;
    setDiscounts(diff);
  }, [props.currentInvoice]);

  useEffect(() => {
    if (props.classType === 'core' && props.currentInvoice) {
      let startDate = new Date();
      if (props.semesterData?.startDate) {
        startDate = props.semesterData.startDate.toDate();
      }
      const now = new Date();
      //Classes have already started
      if (props.mkPlanData.semesterId !== 'now' && startDate > now && props.subType === 'monthly') {
        setPaymentDescription(
          `Total due on ${moment(startDate).format('MMM Do, YYYY')}: $${(
            props.currentInvoice?.amount_due / 100
          ).toFixed(2)}`,
        );
      } else {
        setPaymentDescription(
          `You will be charged: $${(props.currentInvoice?.amount_due / 100).toFixed(2)} ${
            freeTrial ? 'at the end of the trial period.' : 'on checkout.'
          }`,
        );
      }
    } else if (props.classType === 'enrichment' && props.enrichmentClassData) {
      const startDate = props.enrichmentClassData.startDate.toDate();
      const now = new Date();
      if (startDate > now) {
        setPaymentDescription(
          `Total due on ${moment(startDate).format('MMM Do, YYYY')}: $${(
            props.currentInvoice?.amount_due / 100
          ).toFixed(2)}`,
        );
      } else {
        setPaymentDescription(`Total due now: $${(props.currentInvoice?.amount_due / 100).toFixed(2)}`);
      }
    }
  }, [props.classType, props.currentInvoice.amount_due, props.enrichmentClassData]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const res = await apiRequest('stripe/payment_methods');
      setPaymentMethods(res.data);
    };
    fetchPaymentMethods();
  }, [processingError, unsavedCard]);

  useEffect(() => {
    if (terms && privacy && selectedMethod) {
      setShowPurchaseButton(true);
    }
  }, [selectedMethod, privacy, terms]);

  useEffect(() => {
    if (auth?.user) {
      let fromUser = {};
      if (auth.user.name) {
        fromUser.name = auth.user.name;
      }
      if (auth.user.email) {
        fromUser.email = auth.user.email;
      }

      setCardHolderData({ ...cardHolderData, ...fromUser });
    }
  }, [auth]);

  useEffect(() => {
    console.log(JSON.stringify(cardHolderData));
  }, [cardHolderData]);

  const selectPaymentMethod = (method) => {
    setSelectedMethod(method);
  };

  const subscribeToClass = async () => {
    if (!paymentInProgress) {
      setPaymentInProgress(true);
      let paymentMethod;

      if (unsavedCard) {
        console.log('using new payment method');
        paymentMethod = await addNewCard();
        if (!paymentMethod) {
          setPaymentInProgress(false);
          return;
        }
      }

      if (paymentMethod || selectedMethod) {
        console.log('sub type: ', props.subType);
        let data = {
          paymentMethodId: paymentMethod || selectedMethod,
          classType: props.classType,
          resourceId: props.resourceId,
          childId: childId || childProfile?.id,
          couponId: props.couponId,
          subType: props.subType || 'monthly',
        };

        setModalOpen(true);
        const res = await apiRequest('stripe/create-subscription', 'POST', data);
        if (res.error) {
          setProcessingError(res.error);
          setPaymentInProgress(false);
        } else {
          const price =
            props.subType === 'monthly'
              ? parseInt(props.mkPlanData.price) * 10
              : Math.round(parseInt(props.currentInvoice.amount_due) / 100);
          console.log({
            eventName: 'purchase completed',
            price,
            subType: props.subType,
            classType: props.classType,
            resourceId: props.resourceId,
          });
          console.log('purchase completed');
          conversionsEvent({
            eventName: 'purchase completed',
            price,
            subType: props.subType,
            classType: props.classType,
            resourceId: props.resourceId,
            email: auth.user.email,
          });
          setProcessingError(false);
          if (res.data === 'schedule-created') {
            setSuccessMessage('Your new subscription has been scheduled successfully!');
          }
          if (res.data === 'subscription-created') {
            setSuccessMessage('Your new subscription has been created successfully!');
          }
          setTimeout(handleClose, 2000);
        }
        console.log('create sub res:', res);
      }
    }
  };

  const handleClose = () => {
    if (!processingError) {
      router.push(`/post-checkout/${props.classType}/${props.resourceId}`);
    }
    showThankYou(false);
  };

  const addNewCard = async () => {
    setCardError();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card, cardHolderData);
    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message);
      setCardError(result.error.message);
      return false;
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      return addPaymentMethod(result.token);
    }
  };

  const addPaymentMethod = async (token) => {
    const data = {
      token: token.id,
    };
    console.log(data);
    const res = await apiRequest('stripe/new_card', 'POST', data);
    return res.id;
    // console.log(res)
    // setSelectedMethod(res)
  };

  const handleClickAddCode = () => {
    //setValues({ ...values, showPassword: !values.showPassword });
    props.setCouponCodes([coupon]);
    //setCoupon('')
  };

  const handleCouponChange = (e) => {
    const { name, value } = e.target;
    console.log(value, 'this val');
    setCoupon(value);
    debounce(() => props.setCouponCodes([value]), 500);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      handleClickAddCode();
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    console.log('coupon codes', props.couponCodes);
  }, [props.couponCodes]);

  const debounce = (func, delay) => {
    clearTimeout(debounceTimer);
    setDebounceTimer(setTimeout(func, delay));
  };

  return (
    <Container maxWidth={'sm'}>
      <CssBaseline />

      <Grid className={'pt-5'} container direction="column" justify="center" alignItems="center">
        <Typography>Mighty Kids Checkout</Typography>

        {children.length > 1 && <ChildSelector selectChildId={selectChildId} />}
        <SubscriptionPurchaseCard
          freeTrialEnd={freeTrialEnd}
          semesterData={props.semesterData}
          upcomingInvoice={props.upcomingInvoice}
          currentInvoice={props.currentInvoice}
          mkPlanData={props.mkPlanData}
          enrichmentClassData={props.enrichmentClassData}
          resourceId={props.resourceId}
          subType={props.subType}
          classType={props.classType}
        />
        <Paper className={'p-3 mt-3'} style={{ width: '100%' }}>
          <InvoicePreview invoiceData={props.currentInvoice} />
          {props.upcomingInvoice && <UpcomingPreview invoiceData={props.upcomingInvoice} />}

          {discounts !== 0 && (
            <Typography color="secondary" className={'pt-2 pb-1'} align="center" variant="subtitle1">
              Discounts Applied: ${(discounts / 100).toFixed(2)}
            </Typography>
          )}

          <Typography className={'pt-2 pb-1'} align="center" variant="subtitle1">
            {paymentDescription}
          </Typography>

          {freeTrialEnd && freeTrial && (
            <Typography color={'secondary'} className={'pt-2 pb-1'} align="center" variant="subtitle1">
              Billing starts: {freeTrialEnd}
            </Typography>
          )}

          <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Discounts</InputLabel>
            <OutlinedInput
              disabled={props.couponId}
              id="outlined-adornment-password"
              type={'text'}
              placeholder="enter promo code here"
              value={coupon}
              onChange={handleCouponChange}
              onKeyDown={keyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add code"
                    onClick={handleClickAddCode}
                    onMouseDown={handleMouseDown}
                    edge="end"
                  >
                    {!props.couponId ? <AddIcon /> : <CheckCircleIcon color="primary" />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </Paper>

        <Grid container className={'mt-5'}>
          {paymentMethods !== 'loading' ? (
            <PaymentMethods
              setNewCard={setUnsavedCard}
              selectMethod={selectPaymentMethod}
              paymentMethods={paymentMethods}
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>

        {unsavedCard && (
          <Grid>
            <CardHolder cardHolderData={cardHolderData} setCardHolderData={setCardHolderData} />
          </Grid>
        )}

        <Grid direction="column" justify="center" alignItems="flex-start" className={'mt-5 pl-5'}>
          <PrivacyPolicyModal setPrivacy={setPrivacy} privacy={privacy} />
          <TermsAndConditionsModal setTerms={setTerms} privacy={terms} />
        </Grid>
      </Grid>
      {cardError && <Alert severity="error">{cardError}</Alert>}
      <Grid className={'mt-2'} container xs={12} align="center" justify="center">
        <Button
          disabled={!showPurchaseButton || paymentInProgress}
          onClick={subscribeToClass}
          style={{ maxHeight: 48 }}
          variant="contained"
          color="primary"
        >
          Confirm Subscription
        </Button>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid container className={classes.paper} direction="column" justify="center" alignItems="center">
          {thankYou ? (
            <Typography>
              {successMessage ? 'Thank You!' : 'Please wait while we process your order...'}
            </Typography>
          ) : (
            <Grid container direction="column" justify="center" alignItems="center">
              <Typography align="center">
                {successMessage ? successMessage : 'Processing your order...'}
              </Typography>
              {!successMessage && <CircularProgress className={'m-2'} />}
            </Grid>
          )}

          {processingError && (
            <>
              <Typography>A problem has occurred processing your order</Typography>
              <Grid container direction="column" justify="center" alignItems="center">
                <Typography align="center">{processingError}</Typography>

                <Typography align="center">
                  You may try again, or contact support@mightykids.com if the problem persists
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
    </Container>
  );
}

export default PaymentPortal;
