import React, { useEffect, useState } from 'react'
import StarIcon from '@material-ui/icons/Star';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper, Hidden,
} from '@material-ui/core';
import { LinkContainer } from 'react-router-bootstrap'
import { useAuth } from '../util/auth.js'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useGetSemester, useGetMightyKidsPlan, useGetContractorClass } from '../util/db'
import moment from 'moment'

import { Card, Switch } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import GradientButton from '../components/GradientButton';
import { useRouter } from '../util/router.js';
import { slotToLocalTime } from '../util/util.js'
import { parseableTimeSlots } from '../constants'
import CancelSubscription from './CancelSubscription.js';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 375,
  },
  media: {
        
    height: 0,
    paddingTop: '56.25%', // 16:9
      
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
}));

function SubscriptionCard(props) {
  
  const router = useRouter();
  const auth = useAuth()
  const [item,setItem] = useState(props.data)
  const lastDay = moment(item.cancel_at * 1000 ).format('dddd MMM Do YYYY');
  const subscriptionId = props.data.id;
  const now = new Date();

  const [daysUntilStart, setDaysUntilStart] = useState(item.semesterId === 'now' ? 'Start anytime.' : null)
  const [endDateString,setEndDateString] = useState();
  const [availableTimeSlots,setAvailableTimeSlots] = useState([])
  const [ quotedPrice, setQuotedPrice ] = useState(item.price);
  const { data: semesterData } = useGetSemester(item.semesterId !== 'now' ? item.semesterId : null)
  const { data: mkPlanData } = useGetMightyKidsPlan(item.mkPlanId);
  const { data: contractorClassData } = useGetContractorClass(item.contractorId, item.classId);
  const [ subType, setSubType] = useState('month');
  const [ amount, setAmount ] = useState(0);
  const [ cancelModalOpen, setCancelModalOpen ] = useState(false);
  const [ trialEndDate, setTrialEndDate ] = useState()
  const [ upcomingAmount, setUpcomingAmount] = useState()

  const classes = useStyles();
  const theme = useTheme();


  useEffect(()=>{
    if(mkPlanData){
      setItem({ ...item,...mkPlanData })
    }

  },[mkPlanData])


  useEffect(() => {
    setItem({ ...item,...props.data })
  },[props.data])

  useEffect(()=>{
    if(contractorClassData){
      const classData = {
        bannerText:'Enrichment Class',
        name: contractorClassData.className,
        photoURL: contractorClassData.classPhoto,
        longText: contractorClassData.description,
        subType: 'weekly',
        startDate: contractorClassData.startDate.toDate(),
        endDate: contractorClassData.endDate.toDate(),
        price: contractorClassData.listPrice / 100,
        classType:'enrichment',
      }
      setItem({ ...item,...classData })
    }

  },[contractorClassData])

  useEffect(()=> {
    if(item && item.plan){
      if(item.subType === 'yearly'){
        setSubType('year')
        console.log(item)
        //after they've been invoiced/charged
        if(item?.upcomingInvoice?.total){
          setUpcomingAmount((item.upcomingInvoice.total/100).toFixed(2))
        }
        if(item?.lastInvoice?.total){
          setAmount((item.lastInvoice.total/100).toFixed(2))
        }
      } else if(item.subType === 'once'){
        setSubType('once')
        setAmount(item.oneTimePrice)
      } else if(item.subType === 'weekly'){
        setSubType('week')
        setAmount(item.price)
      } else {
        setSubType('month')
        setAmount(item.price)
      }
      if(item.trial_end){
        setTrialEndDate('Free trial ends ' + moment(item.trial_end * 1000).format('dddd MMM Do YYYY'));
      }
    }
  },[item])
    
  useEffect( () => {
    if(semesterData){
      let startDate =  semesterData.startDate.toDate();
      let endDate =  semesterData.endDate.toDate();
      setEndDateString('Program ends ' + moment(endDate).format('dddd MMM Do YYYY'));

      if(startDate > now){
        let Difference_In_Time = semesterData.startDate.toDate().getTime() -  now.getTime();
        // To calculate the no. of days between two dates
        let Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

        if(Difference_In_Days < 14){
          setDaysUntilStart('Classes start in ' + Difference_In_Days + ' days!')
        } else {
          setDaysUntilStart('Classes start ' + moment(startDate).format('dddd MMM Do YYYY'))
        }
      } else {
        setDaysUntilStart('Currently Active')
      }
         
    }


  },[semesterData])


  useEffect(()=> {
    console.log(subType)
    console.log( ['month','week'].includes(subType) )
  },[subType])
   
  const cancelButtonClick = ()=>{
    setCancelModalOpen(true)
  }



  return (
    <Grid className={'p-5 mb-5'} container>
      <CancelSubscription subscriptionId={subscriptionId} modalOpen={cancelModalOpen} setModalOpen={setCancelModalOpen} />
      {/* <Grid item xs={12}>
                <Typography  className="p-2" align="center" variant="h3">{item.bannerText}</Typography>
            </Grid> */}

      <Grid container className={'pt-2 pr-1'} align="center" direction="column" alignItems="center" justifyContent="center">
        <Card className={classes.root}>
          <CardMedia
            style={{ maxWidth: 500 }}
            component="img"
            alt={item.photoURL}
            // height="140"
            image={item.photoURL}
            title={item.name}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
                          
              <CardHeader title={item.name} />
              <Typography variant="subtitle1" style={{ paddingLeft: 15, overflowWrap:'break-word', overflow: 'auto', maxWidth:'100%'  }}>
                {item.longText}
              </Typography>

              <hr width={'100%'}/>
              <Grid container className={'pt-2'} align="center"  direction="column" alignItems="center" justifyContent="center">
                               
                <Typography   align="center" variant="overline" >
                  {daysUntilStart}
                </Typography>
                <Typography   align="center" variant="overline" >
                  {endDateString}
                </Typography>
                               
              </Grid>
              <CardActions disableSpacing>
                <Grid container align="center" alignItems={'center'} justifyContent="center" direction={'row'}>
                  <Grid item direction="column" align="center" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 10 }}>
                    {(item.status === 'trialing' && !item.cancel_at_period_end) && <Typography color="primary">
                      {trialEndDate}
                    </Typography>}
                    { item.classType === 'enrichment' && <Grid>
                      <Typography className={'pt-1'} variant="subtitle2" color="textSecondary" >
                                        Start Date: {moment(item.startDate).format('MMM Do, YYYY')}
                      </Typography>
                      <Typography className={'pt-1'} variant="subtitle2" color="textSecondary" >
                                        End Date: {moment(item.endDate).format('MMM Do, YYYY')}
                      </Typography>
                    </Grid>}
                    {subType === 'month' && <Typography color="secondary" className={'p-2 pt-1'} variant="h4">
                                            ${amount} monthly.
                    </Typography>}
                    {subType === 'week' && <Typography color="secondary" className={'p-2 pt-1'} variant="h4">
                                            ${amount} per week.
                    </Typography>}

                    {subType === 'once' && item.status === 'active' && <Typography color="primary" className={'p-2 pt-1'} variant="subtitle">
                                            ${amount} has been charged for the whole course.
                    </Typography>}


                    {subType === 'year' && item.status === 'active' && <Typography color="primary" className={'p-2 pt-1'} variant="subtitle">
                                            ${amount} has been charged for the year.
                    </Typography>}

                    {((subType === 'year' && item.status === 'trialing') && !item.cancel_at_period_end) && <Typography color="primary" className={'p-2 pt-1'} variant="subtitle">
                                            ${upcomingAmount} will be charged at the end of your free trial.
                    </Typography>}
                                        
                    {item.cancel_at_period_end && <Typography color="secondary" className={'p-2 pt-1'} variant="subtitle">
                                          This subscription has been canceled, and will end {lastDay}
                    </Typography>}

                  </Grid>
                  <Grid container direction="column" alignContent="center" alignItems="center" justifyContent="center" align="center" >
                                        
                                       
                                     
                    {((['month','week'].includes(subType) && !item.cancel_at_period_end ) || item.status === 'trialing' && !item.cancel_at_period_end) && <Button color="secondary" variant="contained" onClick={cancelButtonClick} label={'Cancel Subscription'}>Cancel Subscription</Button>}
                    {(subType === 'year' && !item.cancel_at_period_end) && <Typography className="pt-2" variant="subtitle">
                                            *Yearly subscriptions are non-refundable following the trial period.
                    </Typography>}
                  </Grid> 
                </Grid>
              </CardActions>
            </CardContent>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SubscriptionCard
