import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { useGetMKPlans } from '../util/db'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {

  const { data } = useGetMKPlans()

  const classes = useStyles();
    


  useEffect(()=>{
    console.log(props.currentPlan)
  },[props.selectedPlans])

  const selectPlan = (id) => {
    if(props.selectedPlans.find(row => row === id)){
      props.setSelectedPlans(props.selectedPlans.filter(row => row !== id))
    } else {
      let newList = [...props.selectedPlans,id]
      props.setSelectedPlans(newList)
    }
  }

  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const name = data[index].name
    const id = data[index].id;
    console.log('currentPlan',props.currentPlan)
    console.log('id',id)
    return (
      <ListItem selected={ props.selectedPlans.find(row => row === id) }  key={index} onClick={() => { selectPlan(id) }} button style={style}>
        <ListItemText primary={name} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={400} width={400} itemSize={46} itemCount={data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
