import React, { useState } from 'react';
import ControlHolder from '../ControlHolder';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleDays from './ControlClassDays';
import ToggleTimes from './ControlTimeOfDay';
import TimeOfDaySlider from './TimeOfDaySlider';

const ControlClassTime = (props) => {
  return (
    <ControlHolder {...props} text="Class Time">
      <Grid>
        <Grid style={{ textAlign: 'center' }}>
          <ToggleDays days={props.days} setDays={props.setDays} />
        </Grid>
        <Grid>
          <ToggleTimes value={props.times} setValue={props.setTimes} />
          <TimeOfDaySlider value={props.times} setValue={props.setTimes} />
        </Grid>
      </Grid>
    </ControlHolder>
  );
};

export default ControlClassTime;
