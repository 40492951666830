import React from 'react';
import {
  MenuItem,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Select,
  Input,
  InputLabel,
  Checkbox,
  Button,
  Typography,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '../../util/router.js';
import { timeSlotArray as timeSlots } from '../../constants';
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MediaCard(props) {
  const router = useRouter();
  const classes = useStyles();
  console.log(props.currentTeachers);

  const handleChange = (index, vals) => {
    props.updateTimeSlots(index, vals);
  };

  return props.currentTeachers.map((teacher, index) => (
    <Card key={teacher.id} className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {teacher.name}
          </Typography>

          <InputLabel id="demo-mutiple-checkbox-label">Time Slots</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={teacher.timeSlots || []}
            onChange={(event) => {
              let newVal = event.target.value;
              console.log(newVal);
              handleChange(index, newVal);
            }}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {timeSlots.map((slot) => (
              <MenuItem key={slot} value={slot}>
                <Checkbox checked={teacher.timeSlots.indexOf(slot) > -1} />
                <ListItemText primary={slot} />
              </MenuItem>
            ))}
          </Select>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          onClick={() => props.removeTeacher(teacher)}
          size="small"
          color="primary"
        >
          Remove
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => router.push('/admin/manage-teachers?id=' + teacher)}
        >
          Edit Teacher
        </Button>
      </CardActions>
    </Card>
  ));
}
