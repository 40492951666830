import React, { useState, useRef, useEffect } from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import './StickyFilterHeader.scss';
import { Search } from '@material-ui/icons';
import SearchAppBar from './SearchAppBar';

export default function StickyFilterHeader(props) {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <div className="filters" style={{ marginTop: sticky.offset }}>
      <div className="header">
        <Typography gutterBottom className={'my-5 text-center'} variant="subtitle" component="h1">
          Browse Our Premium Courses
        </Typography>
      </div>
      <div id="sticky-header" className={`navbar${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}>
        <SearchAppBar {...props} />
      </div>
    </div>
  );
}
