import React, { useEffect, useState } from 'react';
import { Grid,Button, Typography } from '@material-ui/core';
import { useGetMKProducts } from '../../util/db';
import { getStripeProducts, getStripePrices } from '../../util/stripe'
import ProductList from './ProductList';
import ProductDetails from './ProductDetails';


function MKPlanManager(props) {
  const { data, error, loading } = useGetMKProducts();
    
  const [selectedPlan, setPlan] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [productList, setProductList] = useState([])

  const selectPlan = (plan) => {
    setCreateNew(false)
    setPlan(plan);
    setShowDetails(true);
  };

  const createNewPlan = () => {
    setCreateNew(true)
    setPlan( { videoLessons:false,
      zoomMeetings:false,
      acceleratedVideos:false,
      registration:false,
      assessmentAccess: false,
    })
    setShowDetails(true)
  };

  useEffect(() => {
    const fetchProducts = async () => {
      let products = await getStripeProducts();
      console.log('products', products)
      setProductList(products.data);
    }
    fetchProducts()
  }, [])

  useEffect(()=>{
    console.log(data)
  },[data])


  return (
    <>
      <Grid className="mx-3">
        <a href="/admin/manage-semesters">Manage Semesters</a>
        <br/>
        <a href="/admin/plans-and-promotions">Manage Plans</a>
        <Typography align="center">Product Management</Typography>
        <Grid container> 
                   
          <Grid xs={4}>
            <Grid >
              {loading ? 'loading' :
                <ProductList rowSelect={selectPlan} data={data} />}
            </Grid>
            <Grid className="m-3">
              <Button variant="primary" onClick={createNewPlan}>
                                Create New
              </Button>
            </Grid>
          </Grid>
          <Grid xs={8} className={'p-4'}>
            {showDetails ?
              <ProductDetails
                setCreateNew={setCreateNew}
                setPlan={setPlan}
                products={productList}
                createNew={createNew} 
                data={selectedPlan} />
              : null
            }
          </Grid>
        </Grid>
      </Grid>
    </>);
}

export default MKPlanManager
