import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { List,Grid, Typography } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Pricing from '../../components_public/Pricing'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 800,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();

  const renderRow = (rowProps) => {
    const name = rowProps.name;
    const productId = rowProps.productId;
       
    return (
      <ListItem onClick={() => { props.rowSelect(rowProps) }} button key={rowProps.id} >
        <ListItemText primary={name} secondary={productId} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <List className={classes.root} itemCount={props.data.length}>
        {props.data.map( row => renderRow(row))}
      </List>
    );
  }
  return (null);
}
