import React, { useEffect, useState } from 'react'
import StarIcon from '@material-ui/icons/Star';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper, Hidden, Accordion,AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LinkContainer } from 'react-router-bootstrap'
import { useAuth } from '../util/auth.js'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useGetSemester, useGetAvailableZoomMeetings } from '../util/db'
import moment from 'moment'

import { Card, Switch } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import GradientButton from '../components/GradientButton';
import { useRouter } from '../util/router.js';
import { slotToLocalTime } from '../util/util.js'
import { parseableTimeSlots } from '../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 375,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function PromotionCard(props) {
  
  const router = useRouter();
  const auth = useAuth()
  const item = props.data;
  const now = new Date();
  const [ prepayYear, setPrepayYear] = useState(false)
  const [ noMonthly, setNoMonthly] = useState(false)
  const { data: availableZooms } = useGetAvailableZoomMeetings(item.productId ,item.semesterId)
  const [daysUntilStart, setDaysUntilStart] = useState(item.semesterId === 'now' ? 'Start anytime.' : null)
  const [endDateString,setEndDateString] = useState();
  const [availableTimeSlots,setAvailableTimeSlots] = useState([])
  const [ quotedPrice, setQuotedPrice ] = useState(item.price);
  const [ subType, setSubType ] = useState('monthly');
  const [ quotedSavingsPercent, setQuotedSavingsPercent] = useState(item.yearlySavingsPercent)

  const { data: semesterData } = useGetSemester(item.semesterId !== 'now' ? item.semesterId : null)
  const classes = useStyles();
  const theme = useTheme();

  useEffect(()=> {
    if(!item.monthlyPriceId){
      setNoMonthly(true)
      if(prepayYear){
        setSubType('once')
      } else{
        setSubType('yearly')
      }
    } else {
      if(!item.yearlyPriceId){
        setSubType('monthly')
      } else {
        if(prepayYear){
          setSubType('once')
        } else{
          setSubType('yearly')
        }
      }
      setNoMonthly(false)
    }
  },[item,prepayYear])

  useEffect(()=> {
    if(item.classesReady && availableZooms?.length){
      console.log('showing time slots')
      let timeSlots = {}
      let availArray = []
      const filtered = availableZooms.filter(doc => doc.studentCount < 10)
      if (filtered.length > 0){
        for (const row of filtered){
          timeSlots[row.timeSlot] = true;
        }
        for (const [key,val] of Object.entries(timeSlots)){
          const localTime = slotToLocalTime(parseableTimeSlots[key])
          availArray.push(localTime)
        }
        setAvailableTimeSlots(availArray)
      } else {
        setAvailableTimeSlots(['Classes Full!'])
      }
    } else {
      setAvailableTimeSlots([])
    }
  },[availableZooms])
    
  useEffect( () => {
    if(semesterData){
      let startDate =  semesterData.startDate.toDate();
      let endDate =  semesterData.endDate.toDate();
      setEndDateString('Goes until ' + moment(endDate).format('dddd MMM Do YYYY'));

      if(startDate > now){
        setPrepayYear(true)
        let Difference_In_Time = semesterData.startDate.toDate().getTime() -  now.getTime();
        // To calculate the no. of days between two dates
        let Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

        if(Difference_In_Days < 14){
          setDaysUntilStart('Classes start in ' + Difference_In_Days + ' days!')
        } else {
          setDaysUntilStart('Classes start ' + moment(startDate).format('dddd MMM Do YYYY'))
        }
      } else {
        setDaysUntilStart('Available Now!')
        setSubType('yearly')
      }
    }


  },[semesterData])
   
  const purchaseButtonClick = ()=>{

    const redirect = auth.user
      ? `/purchase/core/${item.id}?sub_duration=${subType}`
      : `/auth/signup?next=/purchase/core/${item.id}?sub_duration=${subType}`
    router.push(redirect)
  }

  const toggleMonthly = () => {
    if(subType === 'monthly'){
      if(prepayYear){
        setSubType('once')
      } else {
        setSubType('yearly')
      }
    } else if(subType === 'yearly' || subType === 'once'){
      setSubType('monthly')
    }
  }

  useEffect(()=>{
    if(subType === 'monthly'){
      setQuotedPrice(props.data.price)
    } else if(prepayYear){
      if(noMonthly){
        setQuotedPrice(Number(item.oneTimePrice).toFixed(0))
      } else{
        setQuotedPrice(Number(item.oneTimeQuote).toFixed(0))
        setQuotedSavingsPercent(item.oneTimeSavingsPercent)
      }
    } else {
      setQuotedPrice(Number(props.data.yearlyQuote).toFixed(0))
      setQuotedSavingsPercent(item.yearlySavingsPercent)
    }
  },[subType,noMonthly,prepayYear])

  return (
    <Grid className="mb-5" item sm={12} md={ props.fullSize ? 12 : 6} lg={props.fullSize ? 12 : 4}  xs={12}>
      <Grid item xs={12}>
        <Typography  className="p-2" align="center" variant="h4">{item.bannerText}</Typography>
      </Grid>

      <Grid container className={'pt-2 pr-1'} align="center" direction="column" alignItems="center" justifyContent="center">
        <Card className={classes.root}>
          <CardMedia
            style={{ maxWidth: 500 }}
            component="img"
            alt={item.photoURL}
            // height="140"
            image={item.photoURL}
            title={item.name}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <CardHeader title={item.name} />
              <Typography variant="subtitle1" style={{ paddingLeft: 15, overflowWrap:'break-word', maxWidth:'100%'  }}>
                {item.longText}
              </Typography>
              <Grid container direction="column" alignItems="center" className="pr-5">
                <List >
                  {item.perks?.toString().split('\n').map((perk,ind) => (
                    <ListItem key={perk}>
                      <ListItemIcon>
                        <StarIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={perk}
                        //color={ind % 2 ? 'secondary' : 'secondary'}
                        color="blue"
                      />
                    </ListItem>))}
                </List>
              </Grid>
              <hr width={'100%'}/>
              <Grid container className={'pt-2'} align="center"  direction="column" alignItems="center" justifyContent="center">
                               
                <Typography   align="center" variant="overline" >
                  {daysUntilStart}
                </Typography>
                <Typography   align="center" variant="overline" >
                  {endDateString}
                </Typography>
                               
              </Grid>
              <CardActions disableSpacing>
                <Grid container align="center" alignItems={'center'} justifyContent="center" direction={'row'}>
                  <Grid item direction="column" align="center" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 10 }}>
                                        
                    {/* <Typography color="secondary" className={'p-2 pt-1'} variant="subtitle2">
                                    save {item.yearlySavingsPercent}% when you pay upfront!
                                        </Typography> */}
                    { !noMonthly  ? <>
                      { item.yearlyPriceId ? <><Grid component="label" container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item>Monthly</Grid>
                        <Grid item>
                          <Switch
                            checked={subType !== 'monthly'}
                            onChange={toggleMonthly}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                        <Grid item><Typography color="secondary">{item.yearlyText && item.yearlyText !== '' ? item.yearlyText : 'Yearly'} (save {quotedSavingsPercent}%)</Typography></Grid>
                                              
                      </Grid>
                      <Typography color="secondary" className={'p-2 pt-1'} variant="h4">
                                        ${quotedPrice} monthly.
                      </Typography>
                      </>:  <Typography color="secondary" className={'p-2 pt-1'} variant="h4">
                                        ${quotedPrice} monthly
                      </Typography>}
                    </> : 
                      <>
                        <Typography color="secondary" className={'p-2 pt-1'} variant="h4">
                                                  ${quotedPrice} {item.yearlyText && item.yearlyText !== '' ? item.yearlyText : 'Yearly'}
                        </Typography>
                      </>}
                                     
                  </Grid>
                  <Grid container direction="column" alignContent="center" alignItems="center" justifyContent="center" align="center" item xs={9} sm={9} md={4} lg={3} >
                    <GradientButton onClick={purchaseButtonClick} label={item.buttonText || 'Sign up now!'} />
                                      
                                      
                  </Grid>
                  {availableTimeSlots.length > 0 &&
                                    <Grid className="mt-5">
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography className={classes.heading}>View Available Class Times</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container justifyContent="center" alignItems="center" direction="column">
                                            {availableTimeSlots.map( time => <Grid item key={time + item.id}>
                                              <Typography   align="center" variant="overline" >
                                                {time}
                                              </Typography>
                                            </Grid>)}
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion></Grid>}
                                   
                </Grid>
              </CardActions>
            </CardContent>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PromotionCard
