import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography,FormControl,InputLabel,Select ,MenuItem ,FormControlLabel, Checkbox } from '@material-ui/core';
import { updateMKPlan, createMKPlan, useGetSemesters } from '../../util/db';
import { Grid,Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getStripePrices } from '../../util/stripe';
import { uploadProductImage } from '../../util/firebaseStorage';
import SemesterSelector from '../../components/SemesterSelector';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { setMonth } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
          
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const defaultValues = {
  name: '',
  note: '',
  perks: '',
  sale: false,
  active:false,
  videoLessons:false,
  zoomMeetings:false,
  acceleratedVideos:false,
  successURL: '',
  availableRegistrations: '',
  semesterId: 'now',
  endBehavior:'release',
  photoURL:'',
  bannerText:'',
  longText: '',
  classesReady: false,
  pageSlug:'',
  trialPeriod:'',
  buttonText:'',
  yearlyPriceId: 0,
  oneTimePriceId: 0,
  monthlyPriceId: 0,
  priceId: 0,
  yearlySavingsPercent:0,
  oneTimeSavingsPercent:0,
  yearlyText:'',
  yearlyQuote:0,
  oneTimeQuote:0,
}

export default function VideoDetails(props) {
  const [startDate, setStartDate] = useState(props.data.startDate || '2021-06-28');
  const [endDate, setEndDate] = useState(props.data.startDate || '2021-09-28');
  const classes = useStyles();
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [warning, showWarning] = useState(false);
  const [productId, setProductId] = useState(props.data?.productId || 0)
  const [monthlyPriceId, setMonthlyPriceId] = useState(0)
  const [yearlyPriceId, setYearlyPriceId] = useState(0)
  const [oneTimePriceId, setOneTimePriceId] = useState(0)
  const [oneTimePricesList, setOneTimePricesList] = useState([])
  const [monthlyPricesList, setMonthlyPricesList] = useState([])
  const [yearlyPricesList, setYearlyPricesList] = useState([])
  const [feeProductId, setFeeProductId] = useState(0)
  const [semesterId, setSemesterId] = useState('now')
  const [feePriceId, setFeePriceId] = useState(0)
  const [feePricesList, setFeeList] = useState([])

  const [imgValue, setImage] = useState();
  const [imageLoad, setImageLoad] = useState(false);
    
  const imageUpload = async (event) => {
    setImageLoad(true);
    const file = event.target.files[0];
    let imageURL = await uploadProductImage(file);
    setValues({ ...values, photoURL: imageURL })
    setImage(imageURL)
    setImageLoad(false);
  }

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log(props.data)
    setImageLoad(false)
    setImage(props.data?.photoURL)
    setValues({ 
      ...defaultValues, ...props.data });
    setYearlyPriceId(props.data?.yearlyPriceId || 0)
    setMonthlyPriceId(props.data?.monthlyPriceId  || 0)
    setOneTimePriceId(props.data?.oneTimePriceId || 0)
    setProductId(props.data.productId)
    //setPriceId(props.data.priceId)
      

    setSemesterId(props.data?.semesterId || 'now')
    showSuccess(false);
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    if(name === 'productId'){
      setProductId(value)
    } else if(name === 'monthlyPriceId'){
      setMonthlyPriceId(value)
    } else if(name === 'oneTimePriceId'){
      setOneTimePriceId(value)
    } else if(name === 'registrationPriceId'){
      setFeePriceId(value)
    } else if(name === 'registrationProductId'){
      setFeeProductId(value)
    } else if(name === 'yearlyPriceId'){
      setYearlyPriceId(value)
      setValues(() => { return ({ ...values, yearlyQuote: getYearlyPrice(value) })})
    } else {
      setValues({ ...values, [name]: value })
    }
  }

  const handleCheckChange = e => {
    const { name, checked } = e.target
    setValues({ ...values, [name]: checked })
  }

  const getSubscriptionPrice = () => {
    const subscription = monthlyPricesList.find(price => price.id === monthlyPriceId)
    if(!subscription) return 0
    return (subscription.unit_amount / 100).toFixed(0)
  }

  const getOneTimePrice = () => {
    const oneTime = oneTimePricesList.find(price => price.id === oneTimePriceId)
    if(!oneTime) return 0
    return (oneTime.unit_amount / 100).toFixed(0)
  }

  const getYearlyPrice = (id) => {
    const result = yearlyPricesList.find(price => price.id === id)
    if(!result) return 0
    return (result.unit_amount / 1200).toFixed(2) 
  }

  const getFeePrice = () => {
    const registration = feePricesList.find(price => price.id === feePriceId)
    if(!registration) return 0
    return (registration.unit_amount / 100).toFixed(0)
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    showWarning(false)
    let toSave = { ...values, semesterId }
    if(productId) toSave.productId = productId;
    if(feeProductId) toSave.feeProductId = feeProductId;
    if(monthlyPriceId){ 
      toSave.monthlyPriceId = monthlyPriceId;
      toSave.price = getSubscriptionPrice();
    }
    if(yearlyPriceId){ 
      toSave.yearlyPriceId = yearlyPriceId;
    }
    if(feePriceId){
      toSave.feePriceId = feePriceId;
      toSave.registrationFee = getFeePrice();
    }
    if(oneTimePriceId){ 
      toSave.oneTimePriceId = oneTimePriceId;
      toSave.oneTimePrice = getOneTimePrice();
    }

    console.log('to save',toSave)
    if (props.createNew) {
      const res = await createMKPlan(toSave)
      props.setPlan({ ...props.data, id: res.id })
      props.setCreateNew(false)
    } else {
      await updateMKPlan(props.data.id, toSave)
    }
    showSuccess(true)
  }


  useEffect(() => {
    resetValues();
  }, [props.data]);

  useEffect(()=>{
    if(productId){
      const fetchPrices = async () => {
        let prices = await getStripePrices(productId);
        console.log('prices:',prices)
        const yearlyPrices = prices.data.filter(price => price.type === 'recurring' && price.recurring.interval === 'year')
        const monthlyPrices = prices.data.filter(price => price.type === 'recurring' && price.recurring.interval === 'month')
        const oneTimePrices = prices.data.filter(price => price.type === 'one_time')
        setYearlyPricesList(yearlyPrices)
        console.log('monthly prices list:',monthlyPrices)
        setMonthlyPricesList(monthlyPrices);
        setOneTimePricesList(oneTimePrices);
      }
      fetchPrices()
    }
  },[productId,props.data])

  useEffect(()=> {
    if(monthlyPriceId && yearlyPriceId){
      console.log('updating savings percent')
      const yearPrice = Number(getYearlyPrice(yearlyPriceId));
      const monthPrice = Number(getSubscriptionPrice());
      if(yearPrice == monthPrice || monthlyPriceId === 0 || yearlyPriceId === 0 ){
        setValues({ ...values,yearlySavingsPercent:0 })
        return;
      }
      console.log(yearPrice,monthPrice)
      let savings = Math.floor((((monthPrice - yearPrice) / yearPrice)) * 100);
      setValues({ ...values,yearlySavingsPercent:savings })

    }
  },[monthlyPriceId,yearlyPriceId, monthlyPriceId, yearlyPricesList, monthlyPricesList])


  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Content Updated!🥳
        </Alert>
        : null}
      <Grid>
               
        <h2>{values.name || ''}</h2>
        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'text'} name="name" value={values.name} onChange={handleInputChange} label="Plan Name" />
        </Grid>

        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'pageSlug'} name="pageSlug" value={values.pageSlug} onChange={handleInputChange} label="page slug (url)" />
        </Grid>

        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'button text'} name="buttonText" value={values.buttonText} onChange={handleInputChange} label="Button Text" />
        </Grid>

        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'text'} name="bannerText" value={values.bannerText} onChange={handleInputChange} label="Banner Text" />
        </Grid>

        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'long text'} name="longText" value={values.longText} onChange={handleInputChange} label="Long Text" />
        </Grid>
               
        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'text'} name="note" value={values.note} onChange={handleInputChange} label="Note" />
        </Grid>
        <Grid className="my-3">
          <TextField fullWidth key={props.data.id + 'trial'} name="trialPeriod" value={values.trialPeriod} onChange={handleInputChange} label="Trial Period in days (enter 0 to disable) " />
        </Grid>
                
        <Grid className="my-3">
          <TextField multiline rows={4} fullWidth key={props.data.id + 'perks'} 
            name="perks" value={values.perks} onChange={handleInputChange} label="Perks" 
          />
        </Grid>
        <Grid>
          <SemesterSelector semesterId={semesterId} setSemesterId={setSemesterId}/>
        </Grid>

             
        <Grid container direction={'column'}>
               
          <FormControl className={classes.formControl}>
            <InputLabel id="select-product"> Subscription</InputLabel>
            <Select
                    
              name="productId"
              labelId="select-product"
              value={productId}
              onChange={handleInputChange}
            >   
              { props.products.map(product => <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>)}
            </Select>
          </FormControl>

          <Grid className="my-3">
            <TextField fullWidth key={props.data.id + 'yearlyTextOverride'} name="yearlyText" value={values.yearlyText} onChange={handleInputChange} label="Yearly Text Overide." />
          </Grid>

          { oneTimePricesList.length > 0  && ( oneTimePriceId === 0 || oneTimePricesList.find(row => row.id === oneTimePriceId)) ? <FormControl className={classes.formControl}>
            <InputLabel id="select-onetime">One Time Price</InputLabel>
            <Select
                
              name="oneTimePriceId"
              labelId="select-onetime"
              value={oneTimePriceId}
              onChange={handleInputChange}
            >   
              <MenuItem key={'none'} value={0}>None</MenuItem>
              { oneTimePricesList.map(price => <MenuItem key={price.id} value={price.id}>{'$' + (price.unit_amount / 100).toFixed(2)}</MenuItem>)}
            </Select>

            <Grid className="my-3">
              <TextField fullWidth key={props.data.id + 'onetimesavings'} name="oneTimeSavingsPercent" value={values.oneTimeSavingsPercent} onChange={handleInputChange} label="One Time Savings Percent" />
            </Grid>
            <Grid className="my-3">
              <TextField fullWidth key={props.data.id + 'onetimequote'} name="oneTimeQuote" value={values.oneTimeQuote} onChange={handleInputChange} label="One Time Monthly Quote" />
            </Grid>
          </FormControl> : 'no one time prices set for this product!'}
                         
          { monthlyPricesList.length > 0 && ( monthlyPriceId === 0 || monthlyPricesList.find(row => row.id === monthlyPriceId)) ? <FormControl className={classes.formControl}>
            <InputLabel id="select-price">Monthly Subscription Price</InputLabel>
            <Select
              fullWidth
              name="monthlyPriceId"
              labelId="select-price"
              value={monthlyPriceId}
              onChange={handleInputChange}
            >    <MenuItem key={'none'} value={0}>None</MenuItem>
              { monthlyPricesList.map(price => <MenuItem key={price.id} value={price.id}>{'$' + (price.unit_amount / 100).toFixed(2)}</MenuItem>)}
            </Select>
          </FormControl> : 'no monthly prices set for this product!'}
                  
                  
               
        </Grid>

        <Grid container direction={'column'}>
          <Grid alignContent="flex-start" justify="flex-start" direction="row">
            { yearlyPricesList.length > 0 && (yearlyPriceId === 0 || yearlyPricesList.find(row => row.id === yearlyPriceId)) ? <FormControl className={classes.formControl}>
              <InputLabel id="select-yearly">Yearly Subscription</InputLabel>
              <Select
                name="yearlyPriceId"
                labelId="select-yearly"
                value={yearlyPriceId}
                onChange={handleInputChange}
              >   
                <MenuItem key={'none'} value={0}>None</MenuItem>
                { yearlyPricesList.length > 0 && yearlyPricesList.map(price => <MenuItem key={price.id} value={price.id}>{'$' + (price.unit_amount / 100).toFixed(2)}</MenuItem>)}
              </Select>
            </FormControl> : 'no yearly prices set for this product!'}
            <Grid className="my-3">
              <Typography>yearly price per month: ${values.yearlyQuote}</Typography>
            </Grid>
            <Grid className="my-3">
              <TextField fullWidth key={props.data.id + 'yearlysavings'} name="yearlySavingsPercent" value={values.yearlySavingsPercent} onChange={handleInputChange} label="Yearly Savings Percent" />
            </Grid>

            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="sale"
                  onChange={handleCheckChange}
                  checked={values.sale}
                  inputProps={{ 'aria-label': 'Sale' }}
                />}
              label="sale"
            />

            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="classesReady"
                  onChange={handleCheckChange}
                  checked={values.classesReady}
                  inputProps={{ 'aria-label': 'Zoom Classes Ready For Registration' }}
                />}
              label="Zoom Classes ready for registration"
            />
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  name="active"
                  onChange={handleCheckChange}
                  checked={values.active}
                  inputProps={{ 'aria-label': 'active' }}
                />}
              label="active"
            />
            <Grid className="my-3">
              {imageLoad ?
                <Alert
                  variant="warning"
                  className="text-center mx-auto"
                  style={{ maxWidth: '300px' }}>
                                uploading image...
                </Alert>
                :
                <img width="300" src={imgValue} key={imgValue} />
              }
            </Grid>
            <Grid className="my-3">
              <label htmlFor="image" className="btn" >Select Image</label>
              <input type="file" id="image" multiple size="50" onChange={imageUpload} />
            </Grid>
                   
          </Grid>
        </Grid> 
        <Grid className="justify-content-around my-2">
          <Button variant="contained" onClick={(e) => saveChanges(e)}>Save</Button>
          <Button variant="outlined" onClick={(e) => resetValues(e)}>Cancel</Button>
        </Grid>

      </Grid>
    </>)
}
