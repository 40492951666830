import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,Typography,Button } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { prettyTimeSlots } from '../../constants';



const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minWidth: 200,
    margin: 2,
  },
  media: {
    height: 140,
  },
});



export default function MediaCard(props) {
  const classes = useStyles();

  let Schedule = props.classData.mondayClass ? 'Mon ' : '';
  Schedule += props.classData.tuesdayClass ? 'Tue ' : '';
  Schedule += props.classData.wednesdayClass ? 'Wed ' : '';
  Schedule += props.classData.thursdayClass ? 'Thu ' : '';
  Schedule += props.classData.fridayClass ? 'Fri' : '';
  return (
    <Card key={props.classData.id} className={classes.root}>
      <CardActionArea>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h5">
            {props.classData.className}
          </Typography>
          <Typography gutterBottom variant="p" component="p">
            {prettyTimeSlots[props.classData.timeSlot]}
          </Typography>
          <Typography gutterBottom variant="p" component="p">
            {Schedule}
          </Typography>
          <Typography gutterBottom variant="p" component="p">
                        Teacher: {props.classData.teacherName} {props.classData.teacherEmail}
          </Typography>

        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button onClick={props.registerFunction} size="small" color="primary">
                    Register
        </Button>
      </CardActions>
    </Card>
  );
}