import React, { useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { useRouter } from '../../util/router.js'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 5,
  },
  media: {
    height: 140,
  },
});

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

export default function MediaCard(props) {
  const router = useRouter()
  const classes = useStyles();
  console.log(props.currentTeachers)

  // const handleChange = (index, vals) => {
  //     props.updateTimeSlots(index, vals);
  // };   
  useEffect(()=>{
    console.log(props)
  },[props])


  return (<>
    {props.zoomClasses ? props.zoomClasses.map((zoomClass, index) =>
      <Card key={zoomClass.id} className={classes.root}>
        <CardActionArea>

          <CardContent>
            <Typography gutterBottom variant="h5" component="h5">
              {zoomClass.zoomMeetingTopic}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                    Join URL: {zoomClass.zoomJoinURL}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                    Password: {zoomClass.zoomMeetingPassword}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                    Meeting ID: {zoomClass.zoomMeetingId}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                    Start Meeting: {zoomClass.zoomStartURL}
            </Typography>
            <Typography gutterBottom variant="p" component="p">
                    Time Slot: {zoomClass.timeSlot}
            </Typography>
            {/* <Typography gutterBottom variant="p" component="p">
                    Start Date: {zoomClass.startDate.toDate().toString()}
                        </Typography>
                        <Typography gutterBottom variant="p" component="p">
                    End Date: {zoomClass.endDate.toDate().toString()}
                        </Typography> */}

          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" href={`/teacher/student-roster/${zoomClass.id}`}>
                View Students
          </Button>
        </CardActions>
      </Card> ) : null }
  </>
  )
}