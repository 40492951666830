import React from 'react';
import { Grid,Container,Typography } from '@material-ui/core'
import { useRouter } from '../../util/router.js'
import { useGetContractorClasses } from '../../util/db'

import ClassMiniRow from '../../components_contractor/ClassMiniRow';


export default function ClassesList(props) {
  const router = useRouter()
  const { data: liveClasses } = useGetContractorClasses('live')
  const { data: submitted } = useGetContractorClasses('submitted')
  const { data: drafts } = useGetContractorClasses('draft')

  const viewClass = (data) => {
    router.push(`/contractor-classes/${data.contractorId}/${data.id}/view`)
  }

  return (<>
    <Container maxWidth="sm">
      <Grid>
        {liveClasses && liveClasses.length > 0 && <Grid container><Typography align="center" variant="h5">Published:</Typography>{liveClasses.map(classData => <ClassMiniRow selectClass={viewClass} key={classData.id} data={classData} />)} </Grid>}
        {submitted && submitted.length > 0 && <Grid container><Typography align="center" variant="h5">In Review:</Typography> {submitted.map(classData => <ClassMiniRow selectClass={viewClass} key={classData.id} data={classData} />)}</Grid>}
        {drafts && drafts.length > 0 && <Grid container><Typography align="center" variant="h5">Drafts:</Typography> {drafts.map(classData => <ClassMiniRow selectClass={viewClass} key={classData.id} data={classData} />)}</Grid>}
      </Grid>
    </Container >
  </>)

}