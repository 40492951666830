import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-bottts-sprites';
import { useAuth } from '../util/auth';
import { useGetChildrenByOwner, useGetMeetingsByTeacher } from '../util/db';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory, useLocation } from 'react-router-dom';
import { useChildContext } from '../util/childProfileContext.js';
import AdminUserSelector from '../components_admin/AdminUserSelector';

let options = { colorful: true, textureChance: 0 };
let avatars = new Avatars(sprites, options);

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
const textNum = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

function NavbarCustom(props) {
  const auth = useAuth();
  const { childProfile, assessmentAccess, videoLessonAccess, zoomMeetingAccess, setChildId, signout } =
    useChildContext();
  const history = useHistory();
  const location = useLocation();
  let inputOne;

  const inputTwo = React.createRef();
  const inputThree = React.createRef();
  let buttonRef;
  // console.log(auth.user)
  const { data: children, status } = useGetChildrenByOwner(auth && auth.user ? auth.user.uid : null);

  const { data: myClasses } = useGetMeetingsByTeacher(auth.user?.uid);

  /*** HUBSPOT PAGE CODE ***/
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['trackPageView']);

  useEffect(() => {
    window?.HubSpotConversations?.widget?.refresh();
  }, [location]);
  /*** END HUBSPOT ***/

  const regularMenu = (
    <React.Fragment>
      <LinkContainer to={'/lesson'}>
        <Nav.Link active={false}>Today&apos;s Lessons</Nav.Link>
      </LinkContainer>
      <LinkContainer to={'/resources'}>
        <Nav.Link active={false}>Resources</Nav.Link>
      </LinkContainer>
      {assessmentAccess && (
        <LinkContainer to={'/assessment'}>
          <Nav.Link active={false}>Assessment</Nav.Link>
        </LinkContainer>
      )}
      <LinkContainer to={'/enrichment-classes'}>
        <Nav.Link active={false}>Enrichment Classes</Nav.Link>
      </LinkContainer>
      {zoomMeetingAccess && (
        <LinkContainer to={'/zoom-meetings'}>
          <Nav.Link active={false}>Zoom Meetings</Nav.Link>
        </LinkContainer>
      )}
      {/* <LinkContainer to={childProfile ? '/parent-dashboard' : '/parent-dashboard'}>
            <Nav.Link active={false}>Dashboard</Nav.Link>
        </LinkContainer> */}
    </React.Fragment>
  );

  const contractorMenu = (
    <React.Fragment>
      <LinkContainer to={'/contractor-dashboard'}>
        <Nav.Link active={false}>Dashboard</Nav.Link>
      </LinkContainer>
      <LinkContainer to={'/contractor-classes'}>
        <Nav.Link active={false}>My Classes</Nav.Link>
      </LinkContainer>
      <LinkContainer to={'/contractor-profile/view'}>
        <Nav.Link active={false}>Teacher Profile</Nav.Link>
      </LinkContainer>
      <LinkContainer to={'/my-payments'}>
        <Nav.Link active={false}>Payments</Nav.Link>
      </LinkContainer>
    </React.Fragment>
  );

  return (
    <Navbar style={{ zIndex: 1001 }} bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img className="d-inline-block align-top" src={props.logo} alt="Logo" height="30" />
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="navbar-nav" label={'Menu'} className="border-0">
          Menu
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav" className="justify-content-end text-right">
          <Nav>
            {!auth.user && (
              <React.Fragment>
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/program">
                  <Nav.Link>Program</Nav.Link>
                </LinkContainer>

                <LinkContainer to="/pricing">
                  <Nav.Link>Pricing</Nav.Link>
                </LinkContainer>

                <LinkContainer to="/enrichment">
                  <Nav.Link>Enrichment</Nav.Link>
                </LinkContainer>

                <LinkContainer to="/about">
                  <Nav.Link>About</Nav.Link>
                </LinkContainer>

                <LinkContainer to="/faq">
                  <Nav.Link active={false}>FAQ</Nav.Link>
                </LinkContainer>

                <LinkContainer to="/contact">
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
              </React.Fragment>
            )}
            {auth.user && (
              <React.Fragment>
                {auth.user.contractor ? contractorMenu : regularMenu}
                <NavDropdown id="dropdown" title="Account" alignRight={true}>
                  <>
                    <Dropdown.Header>Child</Dropdown.Header>
                    {children &&
                      childProfile &&
                      children.map(({ id, picture, name, birthdate, zoomSlot }) => (
                        <LinkContainer key={id} onClick={() => setChildId(id)} to={`/lesson/${id}`}>
                          <NavDropdown.Item active={id === childProfile.id}>
                            <img
                              style={{ margin: 5 }}
                              height={24}
                              src={`data:image/svg+xml;utf8,${encodeURIComponent(avatars.create(picture))}`}
                            />
                            {name}
                          </NavDropdown.Item>
                        </LinkContainer>
                      ))}
                  </>
                  {!children && (
                    <LinkContainer to={childProfile && '/lesson'}>
                      <NavDropdown.Item active={false}>Add Child</NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {auth.user.admin && (
                    <React.Fragment>
                      <Dropdown.Header>Admin</Dropdown.Header>
                      <LinkContainer to={'/admin/admin-dashboard'}>
                        <NavDropdown.Item>Admin Dashboard</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to={'/admin/manage-alerts'}>
                        <NavDropdown.Item>User Alert Manager</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to={'/admin/manage-students'}>
                        <NavDropdown.Item>Manage Students</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to={'/admin/manage-users/'}>
                        <NavDropdown.Item>Users Management</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to={'/teacher/student-roster'}>
                        <NavDropdown.Item>Zoom Classes Admin</NavDropdown.Item>
                      </LinkContainer>
                    </React.Fragment>
                  )}

                  {auth.user.teacher && (
                    <React.Fragment>
                      <Dropdown.Header>My Classes Shortcuts</Dropdown.Header>
                      {myClasses?.length > 0 &&
                        myClasses.map((myClass) => (
                          <>
                            <LinkContainer to={`/teacher/student-roster/${myClass.id}`}>
                              <NavDropdown.Item>{myClass.className + ' ' + myClass.timeSlot}</NavDropdown.Item>
                            </LinkContainer>{' '}
                          </>
                        ))}
                    </React.Fragment>
                  )}

                  {!auth.user.contractor && <Dropdown.Divider />}

                  {auth.user.contractor && (
                    <React.Fragment>
                      <LinkContainer to={'/contractor-classes'}>
                        <NavDropdown.Item>My Classes</NavDropdown.Item>
                      </LinkContainer>
                    </React.Fragment>
                  )}

                  {auth.user.contractor && (
                    <React.Fragment>
                      <LinkContainer to={'/my-payments'}>
                        <NavDropdown.Item>Stripe Account Access</NavDropdown.Item>
                      </LinkContainer>
                    </React.Fragment>
                  )}

                  {auth.user.contractor && (
                    <React.Fragment>
                      <LinkContainer to={'/contractor-profile/view'}>
                        <NavDropdown.Item>Teacher Profile</NavDropdown.Item>
                      </LinkContainer>
                    </React.Fragment>
                  )}
                  {/*<LinkContainer to="/dashboard">*/}
                  {/*    <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>*/}
                  {/*</LinkContainer>*/}
                  <LinkContainer to={'/settings/general'}>
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>

                  <Dropdown.Divider />

                  <LinkContainer to="/auth/signout">
                    <NavDropdown.Item
                      active={false}
                      onClick={(e) => {
                        e.preventDefault();
                        signout();
                        auth.signout();
                      }}
                    >
                      Sign out
                    </NavDropdown.Item>
                  </LinkContainer>
                  {(auth.user?.admin || auth.isEmulated) && (
                    <React.Fragment>
                      <AdminUserSelector />{' '}
                    </React.Fragment>
                  )}
                </NavDropdown>
              </React.Fragment>
            )}

            {!auth.user && (
              <Nav.Item>
                <LinkContainer to="/auth/signin">
                  <Nav.Link active={false}>Sign in</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
