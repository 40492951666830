import React, { useEffect, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { Grid, Container, Typography, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetContractorClasses, useGetContractors } from '../../util/db';
import { useRouter, useHistory, useLocation } from '../../util/router';
import ControlClassTime from './ControlClassTime/TimeControl';
import ClassCard from './ClassCard2';
import AgeControl from './AgeControl';
import DateControl from './DateControl';
import SpecialtyFilterControl from './SpecialtyFilterControl';
import TextSearch from './TextSearch';
import StickyFilterHeader from './StickyFilterHeader';

const DaysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const Defaults = {
  days: [],
  times: [6, 18],
  ages: {},
  dates: [
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ],
  specialties: [],
  text: '',
};

function ClassesMarket(props) {
  const router = useRouter();
  const history = useHistory();
  const [textFilter, setTextFilter] = useState(Defaults.text);
  const [days, setDays] = useState(Defaults.days);
  const [times, setTimes] = useState(Defaults.times);
  const [ages, setAges] = useState(Defaults.ages);
  const [dates, setDates] = useState(Defaults.dates);
  const [specialties, setSpecialties] = useState([]);
  const { data: contractorClasses } = useGetContractorClasses('live');
  const [searchable, setSearchable] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showAgesModal, setShowAgesModal] = useState(false);
  const [showTimesModal, setShowTimesModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [timesFilterActive, setTimesFilterActive] = useState(false);
  const [agesFilterActive, setAgesFilterActive] = useState(false);
  const [calendarFilterActive, setCalendarFilterActive] = useState(false);
  const [textFilterActive, setTextFilterActive] = useState(false);
  const [specialtyFilterActive, setSpecialtyFilterActive] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);

  useEffect(() => {
    const query = router.query;
    if (query.search) {
      try {
        let queryString = query.search;
        queryString = decodeURIComponent(queryString);
        const queryObj = JSON.parse(queryString);

        let { textFilter, days, times, ages, dates, specialties } = queryObj;
        if (dates.length) {
          dates[0].startDate = new Date(dates[0].startDate);
          if (dates[0].endDate?.length) {
            dates[0].endDate = new Date(dates[0].endDate);
          }
          setDates(dates);
        }
        setTextFilter(textFilter);
        setDays(days);
        setTimes(times);
        setAges(ages);

        setSpecialties(specialties);
      } catch (err) {
        console.log('error attempting to sync to search params');
      }
    }
  }, []);

  const updateQueryParams = (historyParams) => {
    let string = JSON.stringify(historyParams);
    string = `search=${encodeURIComponent(string)}`;
    history.push({ search: string });
  };

  useEffect(() => {
    if (contractorClasses) {
      setSearchable(
        contractorClasses.map((row) => {
          row.searchText = `${row.description}+${row.className}+${row.tags?.join('+')}`.toLowerCase();
          const time = row.timeString.split(':');
          row.searchTime = parseInt(time[0]) * 60 + parseInt(time[1]);
          if (row.startDate.toDate) {
            row.startDate = row.startDate.toDate();
          } else {
            console.error('class start date is missing or not a timestamp');
          }
          if (row.endDate.toDate) {
            row.endDate = row.endDate.toDate();
          } else {
            console.error('class end date is missing or not a timestamp');
          }
          return row;
        }),
      );
    }
  }, [contractorClasses]);

  const clearFilters = () => {
    setSpecialties(Defaults.specialties);
    setTextFilter('');
    setDays(Defaults.days);
    setTimes(Defaults.times);
    setAges(Defaults.ages);
    setDates(Defaults.dates);
  };

  useEffect(() => {
    if (timesFilterActive || agesFilterActive || calendarFilterActive || textFilterActive || specialtyFilterActive) {
      setFiltersActive(true);
    } else {
      setFiltersActive(false);
    }
  }, [timesFilterActive, agesFilterActive, calendarFilterActive, textFilterActive, specialtyFilterActive]);

  useEffect(() => {
    let filtered = searchable;
    //filter text
    if (textFilter.length > 0) {
      filtered = filtered.filter((row) => {
        return row.searchText.includes(textFilter.toLowerCase());
      });
      setTextFilterActive(true);
    } else {
      setTextFilterActive(false);
    }
    //filter days of week
    if (days.length > 0) {
      let activeKeys = days.map((number) => `${DaysOfWeek[number]}Class`);
      filtered = filtered.filter((row) => {
        return activeKeys.find((activeDay) => row[activeDay]);
      });
      setTimesFilterActive(true);
    } else {
      setTimesFilterActive(false);
    }
    let activeAges = Object.entries(ages)
      .filter((val) => val[1])
      .map((row) => row[0]);

    if (activeAges.length > 0) {
      filtered = filtered.filter((row) => {
        return activeAges.find((activeAge) => row.selectedAges && row.selectedAges[activeAge]);
      });
      setAgesFilterActive(true);
    } else {
      setAgesFilterActive(false);
    }

    // filter times
    filtered = filtered.filter((row) => {
      const min = times[0] * 60;
      const max = times[1] * 60;
      return row.searchTime >= min && row.searchTime <= max;
    });

    if (times[0] !== 6 || times[1] !== 18) {
      setTimesFilterActive(true);
    }

    //filter dates
    filtered = filtered.filter((row) => {
      let startDate = startOfDay(dates[0].startDate);
      let endDate = dates[0].endDate ? endOfDay(dates[0].endDate) : null;
      if (startDate > startOfDay(new Date()) || endDate) {
        console.log('calender:', startDate > startOfDay(new Date()), endDate);
        setCalendarFilterActive(true);
      } else {
        setCalendarFilterActive(false);
      }

      if (row.joinAnytime) {
        if (endDate) {
          return row.endDate <= endDate;
        } else {
          return row.endDate >= startDate;
        }
      } else {
        if (endDate) {
          return row.startDate >= startDate && row.startDate <= endDate;
        } else {
          return row.startDate >= startDate;
        }
      }
    });

    //filter specialties
    if (specialties.length > 0) {
      const selectedSpecialties = specialties.map((row) => row.value);
      filtered = filtered.filter((row) => {
        return selectedSpecialties.find(
          (specialty) => row.contractorSpecialties && row.contractorSpecialties.includes(specialty),
        );
      });
      setSpecialtyFilterActive(true);
    } else {
      setSpecialtyFilterActive(false);
    }
    setSearchResults(filtered);

    updateQueryParams({ textFilter, days, times, ages, dates, specialties });
  }, [textFilter, days, times, ages, dates, specialties, searchable]);

  const selectClass = (classData) => {
    router.push(`/purchase/enrichment/${classData.contractorId}-${classData.id}`);
  };

  return (
    <Container>
      <StickyFilterHeader
        filtersActive={filtersActive}
        clearFilters={clearFilters}
        setShowTimesModal={setShowTimesModal}
        setShowAgesModal={setShowAgesModal}
        setShowCalendarModal={setShowCalendarModal}
        timesFilterActive={timesFilterActive}
        agesFilterActive={agesFilterActive}
        calendarFilterActive={calendarFilterActive}
        specialties={specialties}
        setSpecialties={setSpecialties}
        setTextFilter={setTextFilter}
        textFilter={textFilter}
      />

      <ControlClassTime
        open={showTimesModal}
        closeModal={() => setShowTimesModal(false)}
        days={days}
        setDays={setDays}
        times={times}
        setTimes={setTimes}
      />

      <AgeControl open={showAgesModal} closeModal={() => setShowAgesModal(false)} ages={ages} setAges={setAges} />

      <DateControl
        open={showCalendarModal}
        closeModal={() => setShowCalendarModal(false)}
        dates={dates}
        setDates={setDates}
      />

      {/* <Grid container>
        <SpecialtyFilterControl specialties={specialties} setSpecialties={setSpecialties} />
      </Grid>
      <Grid>
        <TextSearch value={textFilter} setValue={setTextFilter} />
      </Grid> */}

      <Grid className={'pt-5 mt-5'} container justifyContent="space-around">
        {searchResults?.map((row) => (
          <Grid key={row.id} item xs="12">
            <ClassCard data={row} key={row.id} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ClassesMarket;
