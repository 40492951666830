import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 500,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VirtualizedList(props) {
  const classes = useStyles();


  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const classData = props.data[index];
    const classId = classData.id
    const Name = classData.Name;
    const Teacher = classData.Teachers.map(teacher => teacher.name);
    /* eslint-disable react/jsx-no-duplicate-props */
    let Schedule = classData.mondayClass ? 'mon ' : '';
    Schedule += classData.tuesdayClass ? 'tue ' : '';
    Schedule += classData.wednesdayClass ? 'wed ' : '';
    Schedule += classData.thursdayClass ? 'thu ' : '';
    Schedule += classData.fridayClass ? 'fri' : '';
    /* eslint-enable react/jsx-no-duplicate-props */
    return (
      <ListItem selected={classId === props.currentClassId} onClick={() => { props.rowSelect(props.data[index]) }} button style={style} key={props.data[index].id}>
        <ListItemText primary={`${Name ? Name : ''} `} secondary={Teacher + ' ' + Schedule} />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  if (props.data) {
    return (
      <div className={classes.root}>
        <FixedSizeList height={500} width={300} itemSize={65} itemCount={props.data.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
  return (null);
}
