import React, { useState } from 'react'
import { Card,Row,Col,Modal,Button } from 'react-bootstrap'
import { WiCloudy, WiDaySunny, WiFog, WiRain, WiSnow, WiStrongWind, WiTrain } from 'weather-icons-react'
import SectionHeader from '../components/SectionHeader'
import './Weather.scss'
import useAudio from '../util/useAudio'

function Weather(props) {

  const [showweather, setWeatherShow] = useState(false)
  const [weather, setWeather] = useState({ icon: WiTrain, name: 'No Guess' })
  const [playing, toggle] = useAudio(props.url)
  // useEffect(() => {
  //     setTimeout(() => { setWeatherShow(false)}, 30000)
  // }, [showweather, setWeatherShow])

  return (
    <>
      <Modal
        show={showweather}
        onHide={() => {setWeatherShow(false)}}
        size="lg"
        container={document.body}
      >
        <Modal.Body>
          <Row style={{ fontSize: 100 }}>
            <Col xs={12}>
              <h2>What is your weather guess?</h2>
            </Col>
          </Row>
          <Row className="text-center" xs={3} md={3} lg={3} xl={3} style={{ fontSize: 100 }}>
            <Col xs={4} md={4} lg={4} xl={4} style={{ maxHeight: 20 }}>
              <div onClick={() => {setWeather({ icon: WiDaySunny, name: 'Sunny', color: 'warning' });  toggle(false); setWeatherShow(false)} }>
                <span style={{ fontSize: 20 }}>Sunny</span>
                <span className="icon">
                  <WiDaySunny className="text-warning" size={100} />
                  {/*<i className="ModalLauncher__orangeColor wi wi-day-sunny"/>*/}
                </span>
              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => {setWeather({ icon: WiRain, name: 'Rainy', color: 'secondary' });   toggle(false);  setWeatherShow(false)}}>
                <span style={{ fontSize: 20 }} className="text-center">Rainy</span>
                <span className="icon">
                  <WiRain className="text-secondary" size={100} />
                </span>
              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => {setWeather({ icon: WiStrongWind, name: 'Windy', color: 'default' });   toggle(false);  setWeatherShow(false)}}>
                <span style={{ fontSize: 20 }} className="text-center">Windy</span>
                <span className="icon">
                  <WiStrongWind className="text-default" size={100} />
                  {/*<i className="ModalLauncher__blueColor fas fa-frown"/>*/}
                </span>
              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => {setWeather({ icon: WiSnow, name: 'Snow', color: 'info' });   toggle(false);  setWeatherShow(false)}}>
                <span style={{ fontSize: 20 }} className="text-center">Snowy</span>
                <span className="icon">
                  <WiSnow className="text-info" size={100} />
                  {/*<i className="ModalLauncher__greenColor fas fa-frown-open" />*/}
                </span>

              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => {setWeather({ icon: WiCloudy, name: 'Cloudy', color: 'secondary' });  toggle(false); setWeatherShow(false)} }>
                <span style={{ fontSize: 20 }} className="text-center">Cloudy</span>
                <span className="icon">
                  <WiCloudy className="text-secondary" size={100} />
                  {/*<i className="text-warning fas fa-laugh" />*/}
                </span>

              </div>
            </Col>
            <Col xs={4} md={4} lg={4} xl={4}>
              <div onClick={() => {setWeather({ icon: WiFog, name: 'Fog', color: 'secondary' });   toggle(false);  setWeatherShow(false)}}>
                <span style={{ fontSize: 20 }} className="text-center">Foggy</span>
                <span className="icon">
                  <WiFog className="text-secondary" size={100} />
                  {/*<i className="ModalLauncher__purpleColor fas fa-surprise" />*/}
                </span>

              </div>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="md" onClick={() => { setWeatherShow(false);  toggle(false)  }}>
                        Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className={'shadow-lg my-4 bg-white rounded text-center h-100'} style={{ cursor: 'pointer' }} onClick={() => {setWeatherShow(true); toggle()}}>
        <Card.Body>
          <Row>
            <Col md={12} lg={4}>


              <span className="icon">
                {weather && weather.name === 'No Guess' && <i style={{ fontSize: 100 }} className="fas text-default warning fa-question-circle" />}
                {weather && weather.name !== 'No Guess' &&<weather.icon className={`text-${weather.color}`} size={100} />}
              </span>

            </Col>
            <Col xs={12} xl={8} lg={8} md={12}>


              <Card.Title>Today&apos;s Weather</Card.Title>

              <Card.Text
                style={{
                  fontSize: 35,
                }}
              >
                {weather.name}
              </Card.Text>

            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <sub><i style={{ fontSize: 15, padding: 4 }} className={`fas text-secondary fa-volume-${playing ? 'up' : 'off'}`} /></sub>
        </Card.Footer>
      </Card>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
    </>
  )
}

export default Weather
