import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ClassCards from './ClassCards'

export default function MediaCard(props) {
  return (
    <Container maxWidth="sm">{
      props.zoomMeetings ? props.zoomMeetings.map((zoomClass, index) =>
        <ClassCards key={index} registerFunction={() => { props.registerFunction(zoomClass) }} classData={zoomClass} />,
      ) : null}
    </Container>
  );
}