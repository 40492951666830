import React, { useEffect, useState } from 'react';
import { useGetLetters } from '../../util/db';
import LetterList from './LetterList';
import LetterDetails from './LetterDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'

function LetterManager(props) {
  const { data: letterList, error, loading } = useGetLetters();
  const [selectedLetter, setLetter] = useState();
  const [showDetails, setShowDetails] = useState(false);


  useEffect(() => {
    if (letterList) {
      console.log(letterList)
    }
  }, [letterList])

  const selectLetter = (letter) => {
    console.log('selecting letter', letter)
    setLetter(() => letter);
    setShowDetails(true);
  };


  return (
    <>
      <Container className="mx-3">
        <h2>Letter of the day</h2>
        <Row>
          <Col>
            <Row>
              {loading ? 'loading' :
                <LetterList currentLetter={selectedLetter} rowSelect={selectLetter} data={letterList} />}
            </Row>
          </Col>
          <Col>
            {showDetails ?
              <LetterDetails data={selectedLetter} />
              : null
            }
          </Col>
        </Row>
      </Container>
    </>);
}

export default LetterManager
