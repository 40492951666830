import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Col } from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert';
import { CardMedia,FormControlLabel,Button, Modal,Checkbox,Grid, TextField, Chip,
  Paper,CircularProgress,FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Container, Typography,CssBaseline } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { apiRequest } from '../util/util';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),

    },
    paper: {
      height: 140,
      width: 100,
    },
  },
  textField: {
    width: '100%',
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    width: '400',
    backgroundColor: '#fff',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10vh',
  },
}));

export default function CancelSubscription(props) {
  const classes = useStyles()



  const handleClose = () => {
    props.setModalOpen(false)
  }

  const handleCancel = async () => {
    await apiRequest('stripe/cancel-subscription', 'POST', { subscriptionId:props.subscriptionId })
    props.setModalOpen(false)
  }


  return(
    <Modal
      open={props.modalOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid container
        className={classes.paper}
        direction="column"
        justify="center"
        alignItems="center">

        <Typography>Are you sure you want to cancel your Mighty Kids Subscription?</Typography>
        <Typography className="pt-2" color="secondary" align="left">Trial subsciptions will be canceled immediately.</Typography>
        <Typography className="pt-2" color="secondary" align="left">Monthly/Weekly subsciptions will finish at the end of their billing cycle</Typography>
                
        <Grid className="mt-2" direction="row">
          <Button className={'p-2 m-3'} variant="contained" color="secondary" onClick={handleCancel}>Cancel Now</Button>  
          <Button className={'p-2 m-3'} color="primary" variant="contained" onClick={handleClose}>Nevermind</Button>
        </Grid>

      </Grid>
    </Modal>)
}