import React from 'react'
import Segment from '../components/Segment'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import TeamBios2 from './TeamBios2'

function TeamBiosSection2(props) {
  return (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <TeamBios2
          items={[
            {
              avatar: '/images/staff/sonia_small.jpg',
              name: 'Sonia Graves',
              role: 'Founder & Director',
              bio: 'Born and raised in the PNW, she has experience working in Special Education classrooms, traditional preschool classrooms, kids Technology camp and college training in Early Childhood Education. She is a mother of three and enjoys helping kids reach their full potential.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/dani_small.jpg',
              name: 'Dani Bean',
              role: 'Lead Teacher',
              bio: 'Dani has a double bachelor\'s degree in English and Early Childhood Education. She has over 15 years of ECE experience and is a mom of two pre-school aged kids.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/Haley-small.jpg',
              name: 'Haley Futialo',
              role: 'Teacher',
              bio: 'Haley has a BS in Human Development and Family Studies and Early Childhood Education. She has taught elementary and preschool in the public school system and is a mom of 2.',
            },
            {
              avatar: '/images/staff/kirsten_small.jpg',
              name: 'Kirsten Alcaraz',
              role: 'Teacher',
              bio: 'Kirsten is an experienced preschool educator, has a BA in Psychology from Cal Poly and is a mom of two pre-school aged kids.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/cameron_small.jpg',
              name: 'Cameron McBride',
              role: 'Customer Relations Manager',
              bio: 'Cameron is an Army veteran, who spent his career in Communications. Now out of the Army, he is pursuing a degree in management. As the father of 4 girls, he enjoys hanging out with his family and his gym time.',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
            {
              avatar: '/images/staff/dustin_small.jpg',
              name: 'Dustin Graves',
              role: 'Founder & Engineer',
              bio: 'Dustin is a Software Engineer with over 12 years of experience in designing technology solutions. He serves on the Board for the Mount Baker Council, BSA. Dustin has a BS in Information Technology and has worked in several different industries including healthcare, defense, telematics and media. Dustin can be found hanging out with his family or cycling.\n',
              twitterUrl: 'https://twitter.com',
              facebookUrl: 'https://facebook.com',
              linkedinUrl: 'https://linkedin.com',
            },
          ]}
        />
      </Container>
    </Segment>
  )
}

export default TeamBiosSection2
