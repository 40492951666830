import React, { useEffect, useState } from 'react';
import { useGetQuestions, useGetSemesters } from '../../util/db';
import SemesterList from './SemesterList';
import SemesterDetails from './SemesterDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
function SemesterManager(props) {
  const { data: semesterList, error, loading } = useGetSemesters();
  const [selectedSemester, setSemester] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const selectSemester = (semester) => {
    setCreateNew(false)
    console.log('selecting video', semester)
    let copySemester = { ...semester }
    copySemester.startDate = semester.startDate?.toDate() || new Date();
    copySemester.endDate = semester.endDate?.toDate() || new Date();
    copySemester.midterm = semester.midterm?.toDate() || new Date();
    setSemester(copySemester);
    setShowDetails(true);
  };

  const createNewSemester = () => {
    setCreateNew(true)
    setSemester(() => {
      return { startDate: new Date(), midtermEnabled:true, midterm: new Date(), endDate: new Date(), name:'new semester' } //adding an arbitrary old date so new questions get asked sooner
    })
    setShowDetails(true)
  };

  useEffect(() => {
    console.log('question List', semesterList)
  }, [semesterList])

  return (
    <>
      <Container className="mx-3">
        <h2>Semester Manager</h2>
        <Row>
          <Col>
            <Row>
              {loading ? 'loading' :
                <SemesterList rowSelect={selectSemester} data={semesterList} />}
            </Row>
            <Row className="m-3">
              <Button variant="primary" size="md" onClick={createNewSemester}>
                                Create New
              </Button>
            </Row>
          </Col>
          <Col>
            {showDetails && selectedSemester ?
              <SemesterDetails createNew={createNew} data={selectedSemester} />
              : null
            }
          </Col>
        </Row>
      </Container>
    </>);
}

export default SemesterManager
