export const datesAudio = {
  1: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F1staudio.mp3?alt=media&token=c5280dd1-f59c-43ad-812c-25db673932fd',
  2: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F2ndaudio.mp3?alt=media&token=2f79b102-d7f2-478d-a1b0-c37bb5134f46',
  3: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F3rdaudio.mp3?alt=media&token=c3a9a174-56be-4408-b419-2433f011c174',
  4: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F4thaudio.mp3?alt=media&token=0886fc7b-f137-4ee1-a70b-89aab8e735d9',
  5: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F5thaudio.mp3?alt=media&token=5a0388f5-cde2-4286-86dc-33b7e6f0bbb2',
  6: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F6thaudio.mp3?alt=media&token=40a2efcb-82b8-43b8-aaa0-52197fb4f59c',
  7: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F7thaudio.mp3?alt=media&token=461d2336-e64d-4e97-8fa1-1a8a597637e7',
  8: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F8thaudio.mp3?alt=media&token=73ce3bef-5507-466d-a854-837077d21b3d',
  9: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F9thaudio.mp3?alt=media&token=7c3b23a6-7deb-475a-98aa-db20eb6e5e49',
  10: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F10thaudio.mp3?alt=media&token=071e5f4f-6380-431c-998a-5028b99fb78b',
  11: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F11thaudio.mp3?alt=media&token=0ac6d2bb-f5ad-48e5-994b-b67cbb7e763c',
  12: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F12thaudio.mp3?alt=media&token=6e9a7ef8-c51e-42c3-a91a-cbcdf7a8a948',
  13: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F13thaudio.mp3?alt=media&token=0adc082f-e2ce-4c1e-b7f1-8d0f61d66a43',
  14: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F14thaudio.mp3?alt=media&token=79611f80-2edc-4adf-90db-a3d166929936',
  15: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F15thaudio.mp3?alt=media&token=61ffb5f0-f8d1-4146-b2ec-a2f2cef88da5',
  16: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F16thaudio.mp3?alt=media&token=79b68ffe-64e7-445c-9154-e5701f40c201',
  17: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F17thaudio.mp3?alt=media&token=61779af1-f46b-4e6c-ba45-3cf1fe122698',
  18: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F18thaudio.mp3?alt=media&token=7c0c8432-5404-46e7-9714-b5006b3eab80',
  19: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F19thaudio.mp3?alt=media&token=6efdcb47-6dc0-4cf7-b798-6838a429f6a6',
  20: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F20thaudio.mp3?alt=media&token=7d3b740e-054a-4658-8e3c-3b2b218c146d',
  21: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F21staudio.mp3?alt=media&token=2972ad18-25b1-4aa4-905b-0e67299f878e',
  22: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F22ndaudio.mp3?alt=media&token=1d9f751f-80ff-426d-b630-1a7f53d5e2c7',
  23: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F23rdaudio.mp3?alt=media&token=c031dd13-ea57-479f-8aae-7242ced3aa3b',
  24: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F24thaudio.mp3?alt=media&token=06546cc3-379a-4aad-a42b-f53ff6a044ba',
  25: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F25thaudio.mp3?alt=media&token=6395da56-c785-47ee-b234-8dbb6bdb3123',
  26: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F26thaudio.mp3?alt=media&token=8a940d48-02b2-4483-97a0-dbd4df2c83ce',
  27: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F27thaudio.mp3?alt=media&token=54ab9362-b181-4b35-84af-e11ff46c00a3',
  28: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F28thaudio.mp3?alt=media&token=d03d7592-7740-43dc-8e93-ffe2211f601b',
  29: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F29thaudio.mp3?alt=media&token=fcc7daa8-93a3-4e2b-8358-ac2cc5021558',
  30: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F30thaudio.mp3?alt=media&token=fe544cda-8043-4c72-aca6-6564a7e9c953',
  31: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2F31staudio.mp3?alt=media&token=fb0e31c4-d5df-4f26-92d6-db0c3aa00dbf',
}
export const daysAudio = [
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fsundayaudio.mp3?alt=media&token=0944cb5d-4798-4917-ac37-a11aaf1e43c4',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fmondayaudio.mp3?alt=media&token=0c07a644-74c6-4cc7-9fab-1291fbfbdb1b',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Ftuesdayaudio.mp3?alt=media&token=51c322c1-96d3-4a91-b291-8c839424d55d',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fwednesdayaudio.mp3?alt=media&token=7e02f077-0d46-4b79-98ba-801e513b7169',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fthursdayaudio.mp3?alt=media&token=a3ee26ac-7b4a-4907-bb14-4d8fd42a3d6d',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Ffridayaudio.mp3?alt=media&token=b31c7bd0-330b-4892-80a6-6789a3ce2c2e',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fsaturdayaudio.mp3?alt=media&token=21790598-5401-4a00-83ad-2f9b3966e829',
]
export const monthsAudio = [
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fjanuaryaudio.mp3?alt=media&token=431930a5-2084-4d5a-8732-8b8628a41ef1',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2FFebruary.mp3?alt=media&token=6c412f7c-d7f6-414a-8efe-a2ad6ef97bf0',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fmarchaudio.mp3?alt=media&token=ea318753-6f67-4545-926d-871bc922f9a4',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Faprilaudio.mp3?alt=media&token=02ed4f25-08b3-45c4-9846-aa655ae8accf',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fmayaudio.mp3?alt=media&token=ca012b6f-3ba1-45f9-94a0-e662a6b7c3a1',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fjuneaudio.mp3?alt=media&token=3d66964b-98bf-43ae-9db3-4900502ba79e',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fjulyaudio.mp3?alt=media&token=38538137-dc26-4b95-86da-c712a95f415e',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Faugustaudio.mp3?alt=media&token=682268c1-eb8c-4617-83cf-8eff1034dc26',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fseptemberaudio.mp3?alt=media&token=b6f2eae9-2e65-43fc-8ed3-56622fc49a2e',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Foctoberaudio.mp3?alt=media&token=59b4d9a6-0df3-4b38-8dc4-a7dbf8a1e54f',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fnovemberaudio.mp3?alt=media&token=4b970d49-3e7e-41c7-a750-fbc5134fd3d0',
  'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2Fdecemberaudio.mp3?alt=media&token=275256db-30c7-4ce8-af5c-01704c81816a',
]
export const yearsAudio = { 2020: 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2FDATE-2020.mp3?alt=media&token=0f1d7cbf-1f49-4d5c-9f31-acf713497bb6' }
export const todayIs = 'https://firebasestorage.googleapis.com/v0/b/virtual-academy-prod.appspot.com/o/Audio%2Fdate-clips%2FDATE-todayis.mp3?alt=media&token=b1a2a698-94a9-45ee-9232-b017e7096407'