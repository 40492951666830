import React, { useEffect, useState } from 'react'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import './AvatarSelector.scss'

import { listAllAvatars } from './AvatarSection'

// list of items
const list = [
  ...listAllAvatars(),
]
// One item component
// selected prop will be passed

// All items component
// Important! add unique key
export const Menu = (list ) =>
  list.map(({ char, svg } ) => {
    return <div key={char} className={'menu-item'}>
      <img height={64} src={`data:image/svg+xml;utf8,${svg}`} />
    </div>
  })


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  )
}


const ArrowLeft = Arrow({ text: <i style={{ fontSize: 25 }} className="fas fa-chevron-circle-left text-primary"/>, className: 'arrow-prev' })
const ArrowRight = Arrow({ text: <i style={{ fontSize: 25 }} className="fas fa-chevron-circle-right text-primary"/>, className: 'arrow-next' })

const AvatarSelector = ({ setPicture, current }) => {
  const [selected, setSelected] = useState(current)
  const [menuItems, setMenuItems] = useState(Menu(list))

  useEffect(() => {
    if(selected) {
      setPicture(selected)
    }
    console.log('selected:',selected)
  }, [selected])

  useEffect(()=>{
    console.log('current',current)
    setTimeout(()=>setSelected(current),200)
  },[current])

  return (
    <div>
      <ScrollMenu
        data={menuItems}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected || 'x'}
        onSelect={setSelected}
        scrollToSelected={true}
                
            
      />
    </div>
  )
}

export default AvatarSelector
