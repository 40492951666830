import React, { useEffect, useState } from 'react'
import ColorOfTheDay from './ColorOfTheDay'
import SectionHeader from '../components/SectionHeader'

function Qod(props) {
  const useAudio = (url) => {
    const [audio] = useState(new Audio(url))
    const [playing, setPlaying] = useState(false)

    const toggle = () => setPlaying(!playing)

    useEffect(() => {
      playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false))
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false))
      }
    }, [])

    return [playing, toggle]
  }

  const [playing, toggle] = useAudio(props.url)
  return (
    <>
      <ColorOfTheDay useAudio={useAudio} playing={playing} toggle={toggle} />
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
    </>
  )
}

export default Qod
