import React, { useEffect, useState } from 'react'
import { useChildContext } from '../util/childProfileContext'
import { useGetAllEmotions } from '../util/db'
import Container from 'react-bootstrap/Container'
import '../components_student/Emotion.scss';
import Row from 'react-bootstrap/Row'
import 'react-day-picker/lib/style.css'

let iconClasses = {
  Happy: 'ModalLauncher__orangeColor fas fa-smile',
  Angry: 'ModalLauncher__redColor fas fa-angry',
  Sad: 'ModalLauncher__blueColor fas fa-frown',
  Scared: 'ModalLauncher__greenColor fas fa-frown-open',
  Excited: 'text-warning fas fa-laugh',
  Surprised: 'ModalLauncher__purpleColor fas fa-surprise',
  Shy: 'ModalLauncher__brownColor fas fa-meh',
  Silly: 'text-warning fas fa-grin-tongue-wink',
  Embarrassed: 'fas fa-flushed',
}

function TodaysLessonCards(props) {
  const { childProfile } = useChildContext()
  const { data } = useGetAllEmotions(childProfile.id)
  const [emotionsArray, setEmotionsArray] = useState([]);

  useEffect(() => {
    console.log('emotion data', data)
    if (data && data.length > 0) {
      let tempObj = {}
      let tempArr = [];
      data.forEach(row => {
        tempObj[row.emotion.name] !== undefined ? tempObj[row.emotion.name]++ : tempObj[row.emotion.name] = 1;
      })
      for (const [key, value] of Object.entries(tempObj)) {
        tempArr.push({ name: key, value })
      }
      tempArr.sort((a, b) => {
        return b.value - a.value
      })
      setEmotionsArray(tempArr)
    }
  }, [data])

  return (

    <Container>
      <div>
                This semester&apos;s emotional frequency
      </div>
      {emotionsArray.map(row =>
        <Row key={row.name} className="text-center" xs={3} md={3} lg={3} xl={3} style={{ fontSize: 100 }}>

          <div style={{ margin: 'auto' }}>
            <span className="icon">
              <i className={iconClasses[row.name]} />
            </span>
            <div style={{ fontSize: 20 }} className="text-center">{row.name}</div>
            <div style={{ fontSize: 15 }} className="text-center">{row.value + ' day' + (row.value > 1 ? 's' : '')}</div>
          </div>

        </Row>,
      )
      }
    </Container >
  )
}

export default TodaysLessonCards
