import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useChildContext } from '../../util/childProfileContext';
import MeetingDetails from '../DynamicZoomLoader/MeetingDetails';

//Load class/classes for today's time slot =>
// get the current day of the week
const weeklySchedule = ['monday','tuesday','wednesday','thursday','friday']
const DynamicZoomLoader = () => {
  const { currentContractorClasses,currentZoomClasses } = useChildContext()
  const [ dailySchedule, setDailySchedule ] = useState();   

  useEffect(() => {
    if(currentContractorClasses?.length > 0 || currentZoomClasses?.length > 0){
      let classList = [];
      if (currentZoomClasses) {
        console.log('current zoom classes:',currentZoomClasses)
        currentZoomClasses.forEach(element => {
          console.log(element)
          classList.push(element)
        });
      }
      if (currentContractorClasses) {
        console.log('current contractor classes',currentContractorClasses)
        currentContractorClasses.forEach(element => {
          console.log(element)
          classList.push(element)
        });
      }

      let newSched = {}
      for (const day of weeklySchedule){
        let res = classList.filter(doc => doc[day + 'Class'] === true)
        if(res.length > 0){
          newSched[day] = res
        }
      }
      setDailySchedule(newSched)
      console.log(newSched)
    }
  }, [currentZoomClasses, currentContractorClasses])

  const renderDay = (key,val) => {
    return (<><Typography variant="h3" className={'pt-3 pb-2'}>{(key).toUpperCase()}</Typography>
      {val.map(meeting => <MeetingDetails key={meeting.id} data={meeting} />)}
    </>)
  }
  return (<> {dailySchedule && <div className={'p-5'}>
    <Typography className="pb-5" align="center" variant="h3">Weekly Zoom Schedule:</Typography>
    <React.Fragment className={'py-4'}>
      { dailySchedule && Object.entries(dailySchedule).map( ([key,values]) => renderDay(key,values)) }
    </React.Fragment>
  </div>}</>
  )
}

export default DynamicZoomLoader