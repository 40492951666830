export const prettyTimeSlots = {
  430: '4:30am PDT/7:30am EDT',
  510: '5:10am PDT/8:10am EDT',
  550: '5:50am PDT/8:50am EDT',
  630: '6:30am PDT/9:30am EDT',
  710: '7:10am PDT/10:10am EDT',
  750: '7:50am PDT/10:50am EDT',
  830: '8:30am PDT/11:30am EDT',
  910: '9:10am PDT/12:10pm EDT',
  950: '9:50am PDT/12:50pm EDT',
  1030: '10:30am PDT/1:30pm EDT',
  1110: '11:10am PDT/2:10pm EDT',
  1150: '11:50am PDT/2:50pm EDT',
  1230: '12:30pm PDT/3:30pm EDT',
  1310: '1:10pm PDT/4:10pm EDT',
  1350: '1:50pm PDT/4:50pm EDT',
  1430: '2:30pm PDT/5:30pm EDT',
  1510: '3:10pm PDT/6:10pm EDT',
};
// parseable time slots
export const parseableTimeSlots = {
  430: '04:30',
  510: '05:10',
  550: '05:50',
  630: '06:30',
  710: '07:10',
  750: '07:50',
  830: '08:30',
  910: '09:10',
  950: '09:50',
  1030: '10:30',
  1110: '11:10',
  1150: '11:50',
  1230: '12:30',
  1310: '13:10',
  1350: '13:50',
  1430: '14:30',
  1510: '15:10',
};

export const timeSlotArray = [
  '430',
  '510',
  '550',
  '630',
  '710',
  '750',
  '830',
  '910',
  '950',
  '1030',
  '1110',
  '1150',
  '1230',
  '1310',
  '1350',
  '1430',
  '1510',
];

export const SPECIALTIES = [
  { label: 'Adhd', value: 'adhd' },
  { label: 'Autism', value: 'autism' },
  { label: 'Behavioral health', value: 'behavioral_health' },
  { label: 'Developmental delays', value: 'developmental_delays' },
  { label: 'Hearing Impairments', value: 'hearing_impairments' },
  { label: 'Intellectual Disabilities', value: 'intellectual_disabilities' },
  {
    label: 'Learning Disablities/Dyslexia',
    value: 'learning_disabilities_and_dyslexia',
  },
  { label: 'Physical Impairments', value: 'physical_impairments' },
  {
    label: 'Sensory Perception Disorders',
    value: 'sensory_perception_disorders',
  },
  { label: 'Trauma', value: 'trauma' },
];

export const SPECIALTIES_MAP = {
  adhd: 'Adhd',
  autism: 'Autism',
  behavioral_health: 'Behavioral health',
  developmental_delays: 'Developmental delays',
  hearing_impairments: 'Hearing Impairments',
  intellectual_disabilities: 'Intellectual Disabilities',
  learning_disabilities_and_dyslexia: 'Learning Disablities/Dyslexia',
  physical_impairments: 'Physical Impairments',
  sensory_perception_disorders: 'Sensory Perception Disorders',
  trauma: 'Trauma',
};
