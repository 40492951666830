import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { TextField,FormControl,InputLabel,Select ,MenuItem ,FormControlLabel, Checkbox } from '@material-ui/core';
import { updateMKProduct } from '../../util/db';
import { Grid,Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
          
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const defaultValues = {
  videoLessons:false,
  zoomMeetings:false,
  acceleratedVideos:false,
  assessmentAccess: false,
  availableRegistrations: '',
}

export default function ProductDetails(props) {
  const [startDate, setStartDate] = useState(props.data.startDate || '2021-06-28');
  const [endDate, setEndDate] = useState(props.data.startDate || '2021-09-28');
  const classes = useStyles();
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [warning, showWarning] = useState(false);
  const [productId, setProductId] = useState(props.data?.productId || 0)
  const [priceId, setPriceId] = useState(props.data?.priceId || 0)
  const [pricesList, setPricesList] = useState([])
  const [feeProductId, setFeeProductId] = useState(0)
  const [semesterId, setSemesterId] = useState('now')
  const [feePriceId, setFeePriceId] = useState(0)
  const [feePricesList, setFeeList] = useState([])

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues({ ...defaultValues, ...props.data });
    setProductId(props.data.productId)
    showSuccess(false);
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    if(name === 'productId'){
      const product = props.products.find(prod => prod.id === value)
      setProductId(value)
      setValues({ ...values, productId: product.id, name:product.name })
    } else {
      setValues({ ...values, [name]:value })
    }
  }

  const handleStartChange = (date) => {
    console.log('date', date)
    setStartDate(date);
    let toSave = moment(date).format('yyyy-MM-DD')
    setValues({ ...values, startDate: toSave })

  };

  const handleEndChange = (date) => {
    setEndDate(date);
    let toSave = moment(date).format('yyyy-MM-DD')
    setValues({ ...values, endDate: toSave })
  };

  const handleCheckChange = e => {
    const { name, checked } = e.target
    setValues({ ...values, [name]: checked })
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    showWarning(false)
    let toSave = { ...values }
    if(productId){
      toSave.productId = productId;
      console.log('to save',toSave)
      const res = await updateMKProduct(productId,toSave)
      showSuccess(true)
    }
  }


  useEffect(() => {
    resetValues();
  }, [props.data.id]);






  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Content Updated!🥳
        </Alert>
        : null}
      <Grid>
               
        <h2>{values.name || ''}</h2>

        <Grid container direction={'column'}>
               
          <FormControl className={classes.formControl}>
            <InputLabel id="select-product">Product</InputLabel>
            <Select
                    
              name="productId"
              labelId="select-product"
              value={productId}
              onChange={handleInputChange}
            >   
              { props.products.map(product => <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>)}
            </Select>
          </FormControl>
                   
                   
                  
               
        </Grid>

        <Grid container direction={'column'}>
          <FormControlLabel
            labelPlacement="top"
            control={
              <Checkbox
                name="videoLessons"
                onChange={handleCheckChange}
                checked={values.videoLessons}
                inputProps={{ 'aria-label': 'Video (vimeo) Lessons' }}
              />}
            label="Video (vimeo) Lessons"
          />
          <FormControlLabel
            labelPlacement="top"
            control={
              <Checkbox
                name="acceleratedVideos"
                onChange={handleCheckChange}
                checked={values.acceleratedVideos}
                inputProps={{ 'aria-label': 'Accelerated Video Lessons' }}
              />}
            label="Accelerated Video Lessons"
          />
          <FormControlLabel
            labelPlacement="top"
            control={
              <Checkbox
                name="zoomMeetings"
                onChange={handleCheckChange}
                checked={values.zoomMeetings}
                inputProps={{ 'aria-label': 'Live Zoom Lessons' }}
              />}
            label="Live Zoom Lessons"
          />
                        
          <FormControlLabel
            labelPlacement="top"
            control={
              <Checkbox
                name="assessmentAccess"
                onChange={handleCheckChange}
                checked={values.assessmentAccess}
                inputProps={{ 'aria-label': 'Assessment Access (only)' }}
              />}
            label="Assessment Access"
          />
        </Grid>
      </Grid>
                 
               
      <Grid className="justify-content-around my-2">
        <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
        <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
      </Grid>

               
    </>)
}
