import React from 'react'
import ContractorClassAdmin from '../components_admin/ContractorAdmin/ContractorClassAdmin'
import { requireAuth } from '../util/auth.js'
import CssBaseline from '@material-ui/core/CssBaseline';

function ContractorAdmin(props) {
  return (<>
    <React.Fragment>
      <CssBaseline />
      <ContractorClassAdmin />
    </React.Fragment>
  </>
  )
}

export default requireAuth(ContractorAdmin)
