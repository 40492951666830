import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { TextField, Button } from '@material-ui/core';
import { useGetAllChildren, useGetZoomMeetingsForChild } from '../../util/db';
import { useRouter } from '../../util/router'
import ZoomClasses from './ZoomClasses'
import StudentList from './StudentList';
import StudentDetails from './StudentDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function ManageStudents(props) {
  const router = useRouter()
  const [selectedStudent, setStudent] = useState();
  const [firstLoad, setFirstLoad] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [childId, setChildId] = useState();
  const [filterVal, setFilterVal] = useState();
  const [filteredChildren, setFilteredChildren] = useState();
  const { data: children, loading } = useGetAllChildren()
  const { data: zoomClasses } = useGetZoomMeetingsForChild(childId)
  // const { data: registrationData, loading: registrationDataLoading } = getRegistrationByChild(childId);

  useEffect(()=> {
    if(router){
      let childId = router.query.childId;
      if(childId && children){
        let currentUser = children.find(obj => obj.id === childId)
        switchChild(currentUser)
      }
    }
  },[router,children])

  const selectChild = (child) => {
    if(router.query.child){
      router.replace(child.id)
    } else {
      router.replace('/admin/manage-students/' + child.id)
    }
  }

  const switchChild = async (child) => {
    console.log(child)
    setStudent(child)
    setChildId(child.id)
    setShowDetails(true)
  }

  const handleFilterChange = e => {
    const { name, value } = e.target
    // console.log('name', name, 'value', value)
    setFilterVal(value)
  }

  useEffect(() => {
    if (children && firstLoad < 3) {
      setFirstLoad(firstLoad + 1)
      setFilteredChildren(children)
    }
  }, [children, filteredChildren])

  useEffect(() => {
    console.log('updating child list', filterVal)
    if (filterVal && children) {
      console.log('updating child list', filterVal)
      let lowered = filterVal.toLowerCase()
      let filtered = children.filter(child => `${child.name}${child.ownerName}${child.email}`.toLowerCase().includes(lowered))
      setFilteredChildren(filtered)
    }
  }, [filterVal, children])

  return (
    <>
      <Container maxWidth="xl">
        <Grid container direction="row" xs={12}><h2>Manage Students</h2></Grid>
        <Grid container direction="row" xs={12}>
          <Grid container direction="column" xs={12} md={3} lg={3}>

            <Grid container direction="row" xs={12}>
              {loading ? 'loading' :
                <>
                  <Grid item xs={12}><TextField fullWidth onChange={handleFilterChange} value={filterVal} label="search students" variant="outlined" /></Grid>
                  <Grid item xs={12}><StudentList rowSelect={selectChild} data={filteredChildren} /></Grid>
                </>}
            </Grid>
          </Grid>

          <Grid container direction="column" xs={12} md={8} lg={8}>
            <Grid container direction="row" xs={12}>
              {showDetails ?
                <Grid container direction="column" xs={12} md={8}><StudentDetails selectedStudent={selectedStudent} /></Grid>
                : null
              }
              {showDetails &&
                                <Grid container direction="column" alignItems="center" xs={12} md={4}>
                                  <Grid container direction="row" alignItems="center">

                                    <Grid item xs={12}><
                                      Typography align="center" variant="h6" style={{ paddingBottom: 10, paddingTop: 25 }}>Zoom Classes</Typography>
                                    </Grid>
                                    <Grid item xs={12} alignContent="center" justifyContent="center" style={{ paddingBottom: 10 }}>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Button variant="outlined" onClick={()=>router.push('/admin/admin-register/' + selectedStudent?.id)}>Register Student for Class</Button>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >

                                        <ZoomClasses zoomClasses={zoomClasses} />
                                      </Box>
                                    </Grid>

                                  </Grid>
                                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>);
}

export default ManageStudents
