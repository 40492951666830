import React from 'react'
import AdminClassesList from './AdminClassesList';

export default function ContractorDashboard() {
  return (
    <React.Fragment>
      <AdminClassesList />
    </React.Fragment>
  );
}
