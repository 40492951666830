import React, { useState } from 'react';
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { slotToLocalTime2 } from '../util/util';
import { useChildContext } from '../util/childProfileContext';
import { voteForTimeSlot } from '../util/db';
import { useAuth } from '../util/auth.js';
import { prettyTimeSlots } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function BasicButtonGroup(props) {
  const auth = useAuth()
  const { childProfile, availableRegistrations } = useChildContext();
  const uid = auth.user ? auth.user.uid : undefined;

  const classes = useStyles();
  const smallScreen = useMediaQuery('(min-width:700px)');
  const [firstChoice, setFirstChoice] = useState()
  const [secondChoice, setSecondChoice] = useState()


  const selectFirstChoice = (timeSlot,val) => {
    setFirstChoice(val)
    submitVote('first',timeSlot)
  }

  const selectSecondChoice = (timeSlot,val) => {
    setSecondChoice(val)
    submitVote('second',timeSlot)
  }

  const submitVote = (option,timeSlot) => {
    voteForTimeSlot({ [option]:timeSlot, childId:childProfile.id, owner: uid, semesterId: props.mightyKidsPlan.semesterId, mkPlanId:props.mightyKidsPlan.id, updated: new Date() })
  }


  return (
    <div className={classes.root}>
      {(!firstChoice || !secondChoice) && <Typography className={'pt-3'} variant="h6">
                For your upcoming subscription&apos;s Zoom meetings, please indicate which time(s) work best for you
      </Typography>}

      <Typography className={'pt-3'} variant="h6">
               My Zoom Class Preferences:
      </Typography>

      <Typography variant="h6">
                First Choice: {firstChoice || '???'}
      </Typography>

      { <ButtonGroup orientation={ 'vertical'} variant="contained" color="primary" aria-label="text primary button group">
        {Object.entries(prettyTimeSlots).map(([key,val])=> {return <Button variant={val == firstChoice ? 'contained' : 'outline'} key={key} onClick={()=>selectFirstChoice(key,val)}><Typography>{key === 'later' ? val : slotToLocalTime2(key) + ' - ' + val}</Typography></Button>})}
      </ButtonGroup> }

      <Typography  className={'pt-5'}  variant="h6">
                Second Choice: {secondChoice || '???'}
      </Typography>

      { <ButtonGroup orientation={'vertical'} variant="contained" color="primary" aria-label="text primary button group">
        {Object.entries(prettyTimeSlots).map(([key,val])=> {return <Button variant={val == secondChoice ? 'contained' : 'outline'} key={key} onClick={()=>selectSecondChoice(key,val)}><Typography>{key === 'later' ? val : slotToLocalTime2(key) + ' - ' + val}</Typography></Button>})}
      </ButtonGroup>}

           
    </div>
  );
}
