import React, { useState } from 'react';
import ControlHolder from './ControlHolder';
import { Grid } from '@material-ui/core';
import ControlAgeSelection from '../../components_contractor/ControlAgeSelection';

const ControlAge = (props) => {
  return (
    <ControlHolder {...props} text="Ages">
      <Grid>
        <ControlAgeSelection selectedAges={props.ages} setSelectedAges={props.setAges} />
      </Grid>
    </ControlHolder>
  );
};

export default ControlAge;
