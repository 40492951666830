import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, FormControl,Select, InputLabel,MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from 'react-bootstrap/Button';
import { updateAlert, createAlert } from '../../util/db';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import DateFnsUtils from '@date-io/date-fns';
import ProductSelector from '../ProductSelector';
import PlanSelector from '../PlanSelector';
import UserSelector from '../UserSelector';
import ClassSelector from '../ClassSelector';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import AlertRow from '../../components/AlertRow';


// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//             width: '50ch',
//         },
//     },
// }));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const defaultValues = {
  header: '',
  message:'',
  buttonURL:'',
  buttonText:'',
  startDate: new Date(),
  endDate: new Date(),
  selectedProducts: [],
  selectedClasses: [],
  selectedPlans: [],
  selectedUsers: [],
  active:false,
  sendEmail:false,
  createAlert:false,
  alertVariant:'info',
}

export default function AlertDetails(props) {
  const [startDate, setStartDate] = useState(moment(props.data?.startDate || new Date()).format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment(props.data?.endDate || new Date()).format('yyyy-MM-DD'));
  const [values, setValues] = useState(defaultValues);
  const [success, showSuccess] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedClasses, setSelectedClasses] = useState([])
  const [selectedPlans, setSelectedPlans] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [warning, showWarning] = useState(false);
  const classes = useStyles();
  const handleStartChange = (date) => {
    console.log('date', date)
    setStartDate( moment(date).utc().format('yyyy-MM-DD'));
    // let toSave = moment(date).format('yyyy-MM-DD')
    date.setHours(0,0,0,0);
    console.log('saved date:', date)
    setValues({ ...values, startDate: date })

  };

  const handleEndChange = (date) => {
    // setEndDate(date);
    setEndDate( moment(date).utc().format('yyyy-MM-DD'));
    // let toSave = moment(date).format('yyyy-MM-DD')
    date.setHours(0,0,0,0);
    setValues({ ...values, endDate: date })
  };

  const resetValues = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValues({ ...defaultValues, ...props.data });
    setStartDate(moment(props.data.startDate).format('yyyy-MM-DD'))
    setEndDate(moment(props.data.endDate).format('yyyy-MM-DD'))
    setSelectedClasses(props.data.selectedClasses || [])
    setSelectedPlans(props.data.selectedPlans || [])
    setSelectedProducts(props.data.selectedProducts  || [])
    setSelectedUsers(props.data.selectedUsers || [])
    showSuccess(false);
  }


  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleCheckChange = e => {
    const { name, checked } = e.target
    setValues({ ...values, [name]: checked })
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    showSuccess(false);
    let toSave = {
      alertVariant:values.alertVariant,
      active: values.active,
      sendEmail: values.sendEmail,
      createAlert: values.createAlert,
      startDate: values.startDate,
      endDate: values.endDate,
      header: values.header,
      message: values.message,
      buttonText: values.buttonText,
      buttonURL: values.buttonURL,
      selectedClasses,
      selectedPlans,
      selectedProducts,
      selectedUsers,
            
    }
    console.log('to save:'.toSave)
    if (props.createNew) {
      await createAlert(toSave)
    } else {
      await updateAlert(props.data.id, toSave)
    }
    showSuccess(true)
  }



  useEffect(() => {
    resetValues();
  }, [props.data.id]);


  return (
    <>
      { success ?
        <Alert
          variant="success"
          className="text-center mx-auto"
          style={{ maxWidth: '300px' }}>
                    Alert Updated!🥳
        </Alert>
        : null}

             
      <Container key={props.data.id} className={'px-5'}>
        <Col>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
        <Col>
          {props.data && <AlertRow data={props.data}/>}
          <h2>{values.header || ''}</h2>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'header'} name="header" value={values.header} onChange={handleInputChange} label="Header" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'message'}
              multiline
              rows={2}
              name="message" value={values.message} onChange={handleInputChange} label="Message" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'actionURL'} name="buttonURL" value={values.buttonURL} onChange={handleInputChange} label="Button URL" />
          </Row>
          <Row className="my-3">
            <TextField fullWidth key={props.data.id + 'actionText'} name="buttonText" value={values.buttonText} onChange={handleInputChange} label="Button Text" />
          </Row>

          <Grid className="my-3">
                       
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Row>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Alert Start"
                  format="yyyy-MM-dd"
                        
                  value={startDate}
                  onChange={handleStartChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Row>
              <Row>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Alert End"
                               
                  format="yyyy-MM-dd"
                  value={endDate}
                  onChange={handleEndChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Row>
              <FormControl className={classes.formControl}>
                <InputLabel>Alert Severity</InputLabel>
                <Select
                  className={classes.formControl}
                  name="alertVariant"
                  value={values.alertVariant}
                  onChange={handleInputChange}
                >
                  <MenuItem value={'info'}>info</MenuItem>
                  <MenuItem value={'success'}>success</MenuItem>
                  <MenuItem value={'error'}>error</MenuItem>
                  <MenuItem value={'warning'}>warning</MenuItem>
                </Select>
              </FormControl>
              <Row>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      name="active"
                      onChange={handleCheckChange}
                      checked={values.active}
                      inputProps={{ 'aria-label': 'Active' }}
                    />}
                  label="Active"
                />

                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      name="sendEmail"
                      onChange={handleCheckChange}
                      checked={values.sendEmail}
                      inputProps={{ 'aria-label': 'Send Email' }}
                    />}
                  label="Send Email"
                />

                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      name="createAlert"
                      onChange={handleCheckChange}
                      checked={values.createAlert}
                      inputProps={{ 'aria-label': 'Create Alert' }}
                    />}
                  label="Create Alert"
                />
              </Row>
            </MuiPickersUtilsProvider>


          </Grid>

        </Col>
                
        <h2>Alert Recipients</h2>

        <h4>Products</h4>
        <ProductSelector selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
        <h4>Classes</h4>
        <ClassSelector selectedClasses={selectedClasses} setSelectedClasses={setSelectedClasses} />
        <h4>Plans</h4>
        <PlanSelector selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} />
        <h4>Users</h4>
        <UserSelector selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />

        <Col>
          <Row className="justify-content-around my-2">
            <Button variant="primary" onClick={(e) => saveChanges(e)}>Save</Button>
            <Button variant="secondary" onClick={(e) => resetValues(e)}>Cancel</Button>
          </Row>
        </Col>
      </Container>
    </>)
}
