import React, { useEffect, useState } from 'react';
import {
  useGetAvailableRegistrations,
  useGetChild,
  useGetAvailableZoomMeetings,
  useGetAllMeetings,
  useGetUserSubscriptions,
} from '../../util/db';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  useChildContext,
  requireChildProfile,
} from '../../util/childProfileContext';
import { useAuth } from '../../util/auth.js';
import { useRouter } from '../../util/router';
import ZoomClasses from './ZoomClasses';
import { apiRequest } from '../../util/util';
import {
  Container,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { setPageStateUpdate } from '@material-ui/data-grid';

function ClassRegistration(props) {
  const router = useRouter();

  const [modalOpen, setModalOpen] = useState(false);
  const [productId, setProductId] = useState();
  const [productList, setProductList] = useState([]);
  const [renderReady, setRenderReady] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [noRegistrations, setNoRegistrations] = useState(false);
  const [meetingsBySlot, setMeetingsBySlot] = useState({});
  const [userId, setUserId] = useState();
  const [childId, setChildId] = useState();
  const { data: childData } = useGetChild(childId);
  const { data: subscriptions } = useGetUserSubscriptions(userId);
  const {
    data: allZoomMeetings,
    error: availError,
    status: availLoading,
  } = useGetAllMeetings();

  const registerFunction = async (zoomClass) => {
    //let res = registerForClass(uid, childProfile.id, subscriptionId, zoomClass.id)
    let data = {
      userId: userId,
      childId: childId,
      subscriptionId: subscriptionId,
      zoomClassId: zoomClass.id,
      productId: zoomClass.productId,
    };
    await apiRequest('private/register', 'POST', data);
    setModalOpen(true);
    // thank you for registering
  };

  const closeModal = () => {
    router.goBack();
  };

  useEffect(() => {
    if (childData?.owner) {
      setUserId(childData.owner);
    }
  }, [childData]);

  useEffect(() => {
    if (subscriptions?.length > 0 && !subscriptionId) {
      let available = subscriptions.find(
        (obj) => obj.availableRegistrations !== false,
      );
      if (available) {
        setSubscriptionId(available.id);
      } else {
        setSubscriptionId(subscriptions[0].id);
      }
    }
  }, [subscriptions]);

  useEffect(() => {
    if (router.query.childId) {
      setChildId(router.query.childId);
    }
  }, [router]);

  useEffect(() => {
    setTimeout(() => {
      setRenderReady(true);
    }, 500);
  });

  useEffect(() => {
    if (allZoomMeetings) {
      let obj = {};
      allZoomMeetings.forEach((meeting) => {
        if (meeting.studentCount && meeting.studentCount > 9) {
          console.log('meeting full');
        } else {
          if (obj[meeting.timeSlot]) {
            obj[meeting.timeSlot].push(meeting);
          } else {
            obj[meeting.timeSlot] = [meeting];
          }
        }
      });
      setMeetingsBySlot(obj);
      // setRenderReady(true)
    }
  }, [allZoomMeetings]);

  const slotToLocalTime = (timeSlot) => {
    let minute = timeSlot.slice(-2);
    let hour = timeSlot.slice(0, timeSlot.length - 2);
    let pacificTime = moment().tz('America/Los_Angeles');
    pacificTime.set('hour', hour);
    pacificTime.set('minute', minute);
    return pacificTime.local().format('HH:mm A z');
  };

  return (
    <Container maxWidth={'small'}>
      <Modal show={modalOpen} onHide={closeModal} container={document.body}>
        <Modal.Header />
        <Modal.Body>
          <Row>
            <Col className={'text-center'}>
              <h4>Thank you for registering!</h4>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="success" size="md">
            {noRegistrations ? 'Go to lesson page' : 'next registration'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <Typography>userId:{userId}</Typography>
      </Row>
      <Row>
        <Typography>childId:{childId}</Typography>
      </Row>
      <Row>
        <Typography>subscription:{subscriptionId}</Typography>
      </Row>

      {subscriptions && (
        <FormControl>
          <InputLabel>Subscription</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={subscriptionId}
            onChange={(rowClass, value) => {
              setSubscriptionId(value.props.value);
            }}
          >
            {subscriptions.map((val) => {
              return (
                <MenuItem key={val.id} value={val.id}>
                  {val.id} avail subs: {val.availableRegistrations}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <Row>
        {Object.entries(meetingsBySlot).map(([key, val]) => (
          <Col lg={true} key={key}>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Typography
                width="100%"
                display="block"
                align="center"
                variant={'h4'}
              >
                {slotToLocalTime(key)}
              </Typography>
            </Row>
            <Row>
              <ZoomClasses
                key={'classes' + key}
                registerFunction={registerFunction}
                zoomMeetings={val}
              />
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ClassRegistration;
