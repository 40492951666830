import React from 'react'
import SemesterAdmin  from '../components_admin/SemesterAdmin/SemesterAdmin';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'

function SemesterManagerPage(props) {
  return (<>
    <GoBack />
    <SemesterAdmin />
  </>
  )
}

export default requireAuth(SemesterManagerPage)
