import React from 'react'
import Section from '../components/Section'
import Container from 'react-bootstrap/Container'
import SectionHeader from '../components/SectionHeader'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


function ThankYouSection(props) {
  return (
    <React.Fragment>
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container
          style={{
            maxWidth: '850px',
          }}
        >
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
            className="text-center"
          />
          <Container style={{ paddingTop: 20 }}>
            <Row className={'pb-5'}>
              <Col className={'text-center align-content-center justify-content-center'}>
                <div>
                  <Button onClick={props.buttonOnClick}>{props.buttonText}</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default ThankYouSection
