import React, { useEffect } from 'react';

import HeroSectionVideo from '../components_public/HeroSectionVideo';
import FeaturesSection from '../components_public/FeaturesSection';
import NewsletterSection from '../components_public/NewsletterSection';
import PricingSection from '../components_public/PricingSection2';
import ClientsSection from '../components_public/ClientsSection';
import CtaSection2 from '../components_public/CtaSection2';
import HeroSection2 from '../components_public/HeroSection2';
import TeamBiosSection from '../components_public/TeamBiosSection';
import FaqSection from '../components_public/FaqSection';

import { useRouter } from '../util/router.js';
import TestimonialSection from '../components_public/TestimonialSection';
import { conversionsEvent } from '../util/util';
import CustomPricingSection from '../components_public/CustomPricingSection';
import { useAuth } from '../util/auth';
import IndexPageMetaTags from '../components_public/MetaTags/IndexPage';
import TwoWeekFreeTrialSection from '../components_public/TwoWeekFreeTrialSection';

const isProd = process.env.NODE_ENV !== 'development';

const customPricingArray = isProd
  ? [
    { semesterId: 'K5i727JUQacKgPfxHwKy', headline: 'Our Pricing' },
    { semesterId: 'now', headline: 'Video Only Programs' },
  ]
  : [
    { semesterId: 'K5i727JUQacKgPfxHwKy', headline: 'Our Pricing' },
    { semesterId: 'now', headline: 'Video Only Programs' },
  ];

function IndexPage(props) {
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => conversionsEvent({ eventName: 'page visit' }), 1000);
  }, []);
  return (
    <>
      <IndexPageMetaTags />
      <HeroSectionVideo
        bg="white"
        textColor="dark"
        size="md"
        bgImage="/images/rocket.webp"
        bgImageOpacity={0.7}
        title="Mighty Kids Virtual Academy"
        subtitle="Welcome to Mighty Kids Virtual Academy. We provide a comprehensive online preschool program complete with assessments, customized curriculum, and tracking, curated by early childhood education experts for a fraction of the cost of traditional preschools. Start your two-week free trial today!"
        buttonText="Sign Up for a Free Trial!"
        buttonColor="primary"
        video="https://player.vimeo.com/video/523912201"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push('/pricing');
        }}
        signedInOnClick={() => router.push('/lesson')}
      />
      <TwoWeekFreeTrialSection />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
        image="/images/super_kids.webp"
      />
      <ClientsSection
        bg="secondary"
        textColor="white"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="As seen on"
        subtitle=""
      />
      {customPricingArray.map((section) => (
        <CustomPricingSection
          key={section.semesterId}
          headline={section.headline}
          semesterId={section.semesterId}
        />
      ))}

      <HeroSection2
        bg="primary"
        textColor="white"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="We make preschool distance learning fun and easy!"
        subtitle="Our program is designed to help parents not only navigate distance learning but take away the pressure of finding curriculum, lessons, and activities to advance child learning."
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <TestimonialSection />
      <CtaSection2
        bg="dark"
        textColor="light"
        size="lg"
        bgImage="/images/children_profile.webp"
        bgImageOpacity={0.8}
        title=""
        subtitle=""
        buttonText="Explore"
        buttonColor="light"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push('/pricing');
        }}
      />
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default IndexPage;
