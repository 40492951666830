import React from 'react'
import AssessmentSection from '../components_student/AssessmentSection'

function AssessmentPage(props) {

  return (
    <AssessmentSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Assessment"
      subtitle=""
      buttonText="Send message"
      buttonColor="primary"
      showNameField={true}
      inputSize="md"
    />
  )
}

export default AssessmentPage