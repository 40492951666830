import React from 'react'
import Segment from './Segment'
import Container from 'react-bootstrap/Container'
import { Link } from './../util/router.js'
import './Footer.scss'
import Iubenda from 'react-iubenda-policy'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Footer(props) {

  return !window.location.pathname.includes('tracing') && (
    <Segment
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
    >
      <Container>
        <div className={'text-center pb-3'}>
                    Made with <span style={{ color: 'red' }}>❤</span> in Seattle
        </div>
        <div className="FooterComponent__inner">

          <div className="brand left">
            <Link to="/">
              <img src={props.logo} alt="Logo" />
            </Link>
          </div>
          <div className="links right">
            <Row xs={2} sm={2} md={2}>
              <Col xs={4} sm="auto">
                <Link to="/program">Program</Link>
              </Col>
              <Col xs={4} sm="auto">
                <Link to="/pricing">Pricing</Link>
              </Col>
              <Col xs={4} sm="auto">
                <Link to="/about">About</Link>
              </Col>
              <Col xs={4} sm="auto">
                <Link to="/faq">FAQ</Link>
              </Col>
              <Col xs={4} sm="auto">
                <Link to="/contact">Contact</Link>
              </Col>
              <Col xs={4} sm="auto">
                <Link to="/news">News & Press</Link>
              </Col>
              <Col xs={8} sm="auto">
                <Iubenda id="37982661" type="terms-and-conditions" styling="nostyle">
                            Terms and conditions
                </Iubenda>
              </Col>
              <Col xs={6} sm="auto">
                <Iubenda id="37982661" type="privacy" styling="nostyle">
                            Privacy Policy
                </Iubenda>
              </Col>
              <Col xs={6} sm="auto">
                <Iubenda id="37982661" type="cookie" styling="nostyle">
                            Cookie Policy
                </Iubenda>
              </Col>

            </Row>
          </div>

          <div className="social right">
            <a
              href="https://twitter.com/MightyKidsPreK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-twitter" />
              </span>
            </a>
            <a
              href="https://www.facebook.com/MightyKidsSchool"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-facebook-f" />
              </span>
            </a>
            <a
              href="https://www.instagram.com/mightykidspreschool/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-instagram" />
              </span>
            </a>
            <a
              href="https://www.linkedin.com/company/mighty-kids-stem-preschool"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-linkedin" />
              </span>
            </a>

          </div>
          <div className="copyright left">{props.copyright}</div>
        </div>

      </Container>
    </Segment>
  )
}

export default Footer
