import React from 'react'
import UserAlertManager from '../components_admin/UserAlertManager/UserAlertManager';
import { requireAuth } from '../util/auth.js'
import GoBack from '../components/GoBack'
function ClassManagerPage(props) {
  return (<>
    <GoBack />
    <UserAlertManager />
  </>
  )
}

export default requireAuth(ClassManagerPage)
